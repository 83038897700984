import Vue from "vue";

export const getDevices = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get(`/devices`, {
			params: query,
		});

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getDevice = async (id) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get(`/devices/${id}`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const pingDevice = async (payload) => {
	const { id, command } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post(`/devices/${id}/ping`, { command });

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const pingDevices = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post(`/devices/ping`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteDevices = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post(`/devices/delete`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateDevicesFirmware = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post(`/devices/firmware`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getDeviceLogs = async (payload) => {
	const { id, query } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get(`/devices/${id}/logs`, {
			params: query,
		});

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const calibrateDeviceBattery = async (payload) => {
	const { id, value } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post(`/devices/${id}/calibrate-battery`, { value });

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const calibrateDevicesBattery = async (payload) => {
	try {
		console.log(payload);
		const { data } = await Vue.prototype.$ioteeAdminApi.post(`/devices/calibrate-battery`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getDeviceSystemLogs = async (payload) => {
	const { id, query } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get(`/devices/${id}/system-logs`, {
			params: query,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getLogFiles = async (payload) => {
	const { id } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeStorageApi.get("/logs", {
			headers: {
				device: id,
			},
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
