import Vue from "vue";

export const getSites = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/sites/list");

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const getSite = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/sites`);

		return data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const updateSite = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/sites`, payload);

		return data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const getSiteUserGroups = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/sites/user-groups`);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const addSiteUserGroup = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/sites/user-groups`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const updateSiteUserGroup = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/sites/user-groups/${payload._id}`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const deleteSiteUserGroup = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.delete(`/sites/user-groups/${payload._id}`);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const getSiteServices = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/services`);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const addSiteService = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/services`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const updateSiteService = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/services/${payload._id}`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const deleteSiteService = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.delete(`/services/${payload._id}`);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const onboardStripeConnectAccount = async () => {
	try {
		const data = await Vue.prototype.$ioteeDashboardApi.post(`/sites/stripe/onboard`);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const uploadLogo = async (payload) => {
	try {
		const data = await Vue.prototype.$ioteeDashboardApi.post(`/sites/logo`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};

export const uploadCoverPhoto = async (payload) => {
	try {
		const data = await Vue.prototype.$ioteeDashboardApi.post(`/sites/cover`, payload);

		return data.data;
	} catch (err) {
		throw err.response.data.message;
	}
};
