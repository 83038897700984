<template>
	<v-hover v-slot="{ hover }">
		<v-card
			class="rounded-lg"
			flat
			color="white"
			@click="$emit('cardClick')"
			:elevation="isSelectedCar || hover ? 6 : 0"
			:style="isSelectedCar ? 'border: 3px solid #406dfa !important; border-radius: 8px' : ' '"
		>
			<v-badge bordered :color="badgeColor" :icon="item.carIcon" style="position: absolute; top: 10px"> </v-badge>
			<v-card-text class="px-1">
				<v-row class="d-flex">
					<v-col class="d-flex flex-grow-1 justify-start align-start py-0" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: calc(100% - 140px)">
						<span>
							<v-list-item two-line>
								<v-list-item-content class="accent--text">
									<v-list-item-title class="font-weight-bold"> Car {{ item.carNumber }} </v-list-item-title>
									<span v-if="item.ride && item.ride.meta && item.ride.meta.name" class="font-weight-bold text-wrap mt-1">
										<v-icon small class="mb-1" color="accent"> mdi-account-outline </v-icon>
										{{ item.ride.meta.name }}
									</span>
									<v-list-item-subtitle v-if="item.carName" class="caption font-weight-bold d-block text-trunicate">
										{{ item.carName }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</span>
					</v-col>

					<v-col class="d-flex justify-end align-start align-self-center py-0" style="min-width: 140px; flex-shrink: 0">
						<span class="d-flex flex-column align-end">
							<v-row>
								<v-col>
									<v-chip small color="transparent" class="font-weight-bold">
										<v-icon left small color="success">mdi-speedometer</v-icon>
										{{ item.gpsSpeed ? deviceSpeed(item.gpsSpeed) : "Unknown" }}
									</v-chip>
									<fleet-lock-button :device="item" />
								</v-col>
							</v-row>
							<v-row v-if="item.ride && item.ride.service">
								<v-col class="pt-0 text-right mr-2">
									<service-chip :serviceId="item.ride.service" :priceId="item.ride.price" />
								</v-col>
							</v-row>
						</span>
					</v-col>
				</v-row>

				<v-row v-show="isSelectedCar" class="px-4">
					<v-col class="d-flex justify-space-between">
						<span>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-chip v-if="showTimer(item)" class="mr-2 primary--text font-weight-bold" color="primary lighten-4" small label>
											<v-icon left small>mdi-lock-clock</v-icon>
											{{ item.lockTimeRemaining }}
										</v-chip>
									</span>
								</template>
								<span class="font-weight-bold"> Mins until Auto Lock </span>
							</v-tooltip>
						</span>

						<span>
							<span v-if="vehicle(item).model" class="caption font-weight-bold mr-1">
								<v-icon color="accent" small class="mb-1"> mdi-circle-medium </v-icon>
								{{ vehicle(item).model }}
							</span>

							<span v-if="vehicle(item).power" class="caption font-weight-bold">
								<v-icon color="accent" small class="mb-1">
									{{ powerIcon }}
								</v-icon>
								{{ vehicle(item).power }}
							</span>

							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<span v-if="vehicle(item).power !== 'Gas'" v-bind="attrs" v-on="on">
										<v-icon class="ml-1" :color="batteryDisplay(item).color">
											{{ batteryDisplay(item).icon }}
										</v-icon>
									</span>
								</template>
								<span class="font-weight-bold">
									{{ batteryDisplay(item).text }}
								</span>
							</v-tooltip>
						</span>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-hover>
</template>

<script>
import { mapState } from "vuex";
import ServiceChip from "@/components/ServiceChip";
import FleetLockButton from "@/components/fleet/LockModal";

export default {
	components: {
		FleetLockButton,
		ServiceChip,
	},
	props: ["item"],
	data() {
		return {
			sheet: false,
			cartImg: require("../../../public/Cart.png"),
		};
	},

	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			selectedDeviceId: (state) => state.devices.selectedDeviceId,
		}),

		isSelectedCar() {
			if (this.selectedDeviceId) {
				return this.item._id === this.selectedDeviceId ? true : false;
			}

			return false;
		},

		badgeColor() {
			if (this.isOnline(this.item)) {
				if (this.item.inUse) return "#e9c36a";
				return "success";
			} else {
				return "error";
			}
		},

		powerIcon() {
			let icon;

			switch (this.vehicle(this.item).power) {
				case "Gas":
					icon = "mdi-gas-station-outline";
					break;
				default:
					icon = "mdi-lightning-bolt-outline";
					break;
			}

			return icon;
		},
	},
	methods: {
		showTimer(item) {
			return Number(item.lockTimeRemaining) > 0 && Number(item.lockTimeRemaining) < Number(this.selectedSite.autoLock) && !item.inUse ? true : false;
		},
	},
};
</script>
