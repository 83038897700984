<template>
  <div>
    <v-row>
      <v-col class="mx-auto text-center d-flex justify-space-around">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              label="Date"
              color="white"
              class="accent--text"
              :small="small"
              :outlined="outlined"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :small="small" left>mdi-calendar-month-outline</v-icon>
              {{ computedFrom }}
            </v-btn>
          </template>
          <v-date-picker
            no-title
            color="primary"
            v-model="startDate"
            @input="menu1 = false"
            @change="filter"
            :locale="$i18n.locale"
          ></v-date-picker>
        </v-menu>
        <v-icon class="mx-3" small :color="dark ? 'accent' : 'white'">
          mdi-arrow-right
        </v-icon>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              label="Date"
              color="white"
              class="accent--text"
              :small="small"
              :outlined="outlined"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon :small="small" left>mdi-calendar-month-outline</v-icon>
              {{ computedTo }}
            </v-btn>
          </template>
          <v-date-picker
            no-title
            color="primary"
            v-model="endDate"
            @input="menu2 = false"
            @change="filter"
            :locale="$i18n.locale"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  props: {
    outlined: {
      default: false,
    },
    dark: {
      default: false,
    },
    small: {
      default: true,
    },
    defaultDate: {
      default: null,
    },
  },
  data() {
    return {
      startDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      endDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      menu1: false,
      menu2: false,
    };
  },
  watch: {
    defaultDate() {
      if (this.defaultDate) {
        this.startDate = this.defaultDate;
        this.endDate = this.defaultDate;
      }
    },
  },
  mounted() {
    if (this.defaultDate) {
      this.startDate = this.defaultDate;
      this.endDate = this.defaultDate;
    }
  },
  computed: {
    computedFrom() {
      return this.startDate ? format(parseISO(this.startDate), "d/MM/yy") : "";
    },

    computedTo() {
      return this.endDate ? format(parseISO(this.endDate), "d/MM/yy") : "";
    },
  },
  methods: {
    async filter() {
      const filter = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      console.log("fetching");
      this.$emit("filter", filter);
    },
  },
};
</script>
