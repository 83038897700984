import io from "socket.io-client";

const socket = io(process.env.VUE_APP_BASE_REALTIME_API, {
	transports: ["websocket"],
	auth: (cb) => {
		cb({
			token: localStorage.getItem("user"),
		});
	},
	autoConnect: false,
});

export default socket;
