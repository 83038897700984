<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-title>
					<v-icon small left>mdi-form-textbox-password</v-icon>
					{{ $t("carModal.unlockCode") }}
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>{{ $t("carModal.unlockCode") }}</span>
			</v-card-text>
			<v-card-text>
				<v-form
					ref="form"
					v-model="form"
					lazy-validation
					@submit.prevent="submit"
				>
					<v-text-field
						prepend-inner-icon="mdi-form-textbox-password"
						placeholder="Unlock Code"
						hide-details="auto"
						type="number"
						v-model="value"
						:rules="codeRules"
						outlined
						rounded
						dense
						clearable
						class="mb-3"
					></v-text-field>

					<v-btn block rounded @click="submit" color="primary" class="mt-3">
						Save
					</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			loading: false,
			codeRules: [
				(v) => /^-?\d+\.?\d*$/.test(v) || this.$i18n.t("rules.numbersOnly"),
				(v) => !!v || this.$i18n.t("rules.required"),
				(v) => (v && v.length === 6) || this.$i18n.t("rules.6Digits"),
			],
			value: null,
			form: true,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				this.$emit("updateUnlockCodes", this.value);
				this.dialog = false;
			}
		},

		reset() {
			this.value = null;
			this.$refs.form.resetValidation();
		},
	},
};
</script>
