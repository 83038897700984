<template>
	<v-container>
		<div v-if="schedules.length">
			<v-card flat outlined class="mb-4">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="font-weight-bold subtitle-1 primary--text">{{ $t("services.schedule.title") }}</v-list-item-title>
						<v-list-item-subtitle class="caption font-weight-bold accent--text">{{ $t("services.schedule.subtitle") }}</v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action>
						<v-avatar color="primary" size="44">
							<v-icon color="white">mdi-clock</v-icon>
						</v-avatar>
					</v-list-item-action>
				</v-list-item>
			</v-card>
			<v-card flat class="mb-2" outlined v-for="(schedule, index) in schedules" :key="index">
				<v-card-text>
					<v-row>
						<v-col cols="3">
							<v-select
								v-model="schedule.day"
								:items="availableDaysOfWeek(index)"
								:rules="[rules.required]"
								item-value="value"
								item-text="name"
								:label="$t('services.schedule.day')"
								dense
								outlined
								hide-details
								@change="updateSchedules"
							></v-select>
						</v-col>
						<v-col cols="4">
							<time-picker v-model="schedule.start" :key="index" :rules="[rules.required]" :label="$t('services.schedule.start')" @change="updateSchedules" />
						</v-col>
						<v-col cols="4">
							<time-picker v-model="schedule.end" :key="index" :label="$t('services.schedule.end')" @change="updateSchedules" />
						</v-col>
						<v-col cols="1" class="d-flex align-center justify-center">
							<v-icon small @click="removeSchedule(index)">mdi-delete</v-icon>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
		<div v-else>
			<v-alert outlined type="success" icon="mdi-calendar-month" text class="caption">
				{{ $t("services.schedule.allDaysHint") }}
			</v-alert>
		</div>
		<v-btn block depressed color="primary" @click="addSchedule" :disabled="noDaysLeft">{{ $t("services.schedule.addTo") }}<v-icon right>mdi-calendar-clock</v-icon> </v-btn>
	</v-container>
</template>

<script>
import TimePicker from "@/components/TimePicker.vue";

export default {
	components: {
		TimePicker,
	},
	props: {
		existingSchedules: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			schedules: [],
			daysOfWeek: [
				{
					name: this.$t("general.days.short.0"),
					value: 0,
				},
				{
					name: this.$t("general.days.short.1"),
					value: 1,
				},
				{
					name: this.$t("general.days.short.2"),
					value: 2,
				},
				{
					name: this.$t("general.days.short.3"),
					value: 3,
				},
				{
					name: this.$t("general.days.short.4"),
					value: 4,
				},
				{
					name: this.$t("general.days.short.5"),
					value: 5,
				},
				{
					name: this.$t("general.days.short.6"),
					value: 6,
				},
			],
			selectedDayNumber: null,
			rules: {
				required: (value) => (value !== undefined && value !== null && value !== "") || this.$t("rules.required"),
			},
		};
	},
	watch: {
		existingSchedules: {
			handler(schedules) {
				this.schedules = schedules.map((schedule) => {
					return {
						day: schedule.day,
						start: schedule.start,
						end: schedule.end,
					};
				});
			},
			immediate: true,
			deep: true,
		},
	},
	computed: {
		noDaysLeft() {
			return this.daysOfWeek.every((day) => this.schedules.some((schedule) => schedule.day === day.value));
		},
	},
	methods: {
		availableDaysOfWeek(selectedIndex) {
			return this.daysOfWeek.map((day) => ({
				...day,
				disabled: this.schedules.some((schedule, index) => index !== selectedIndex && schedule.day === day.value),
			}));
		},

		addSchedule() {
			const nextAvailableDay = this.daysOfWeek.find((day) => !this.schedules.some((schedule) => schedule.day === day.value));

			if (nextAvailableDay) {
				this.schedules.push({ day: nextAvailableDay.value, start: "06:00", end: "19:00" });
			}

			this.updateSchedules();
		},

		removeSchedule(index) {
			this.schedules.splice(index, 1);

			this.updateSchedules();
		},

		updateSchedules() {
			this.$emit("update:schedules", this.schedules);
		},
	},
};
</script>
