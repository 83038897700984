<template>
	<v-dialog v-model="visible" max-width="500px">
		<v-card outlined class="rounded-xl text-center" v-if="booking">
			<v-alert v-if="requiresPayment" icon="mdi-cash" transition="scale-transition" type="error" class="mb-0 font-weight-bold" dense>
				<v-row align="center">
					<v-col class="grow"> This booking requires payment. </v-col>
					<v-col class="shrink">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon color="white" small v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
							</template>
							<span> Please ensure the customer has paid for this booking, using the booking app, before allowing them to use the car. </span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-alert>
			<v-col class="pa-3">
				<v-row class="text-center pa-2 py-4">
					<v-col cols="3" class="pr-0">
						<p class="mb-0 caption accent--text font-weight-bold">{{ translatedFormattedDate(new Date(booking.start), "EE do") }}</p>
						<p class="mb-2 title accent--text font-weight-bold">{{ formattedStartTime }}</p>
						<v-chip label color="grey" class="font-weight-bold" small>
							{{ $t("general.start") }}
						</v-chip>
					</v-col>
					<v-col cols="6" class="px-0">
						<div class="dotted-timeline">
							<div class="timeline-icon">
								<v-icon small color="accent lighten-4"> mdi-record-circle-outline </v-icon>
							</div>
							<div class="timeline-line"></div>
							<div class="timeline-icon">
								<v-icon small color="accent lighten-4"> mdi-golf-cart </v-icon>
							</div>
							<div class="timeline-line"></div>
							<div class="timeline-icon">
								<v-icon small color="accent lighten-4"> mdi-record-circle-outline </v-icon>
							</div>
						</div>

						<v-chip class="mt-6 title white--text pa-5 elevation-6" label color="primary"> {{ $t("carDetail.car") }} {{ device.carNumber }} </v-chip>
					</v-col>
					<v-col cols="3" class="pl-0">
						<p class="mb-0 caption accent--text font-weight-bold">{{ translatedFormattedDate(new Date(booking.end), "EE do") }}</p>
						<p class="mb-2 title accent--text font-weight-bold">{{ formattedEndTime }}</p>
						<v-chip label color="grey" class="font-weight-bold" small>
							{{ $t("general.end") }}
						</v-chip>
					</v-col>
				</v-row>

				<hr style="border: 1px dashed #c7d6fe" />

				<v-row v-if="booking.bookingType !== 'cart_maintenance'" class="text-center pa-4">
					<v-col cols="12" sm="4">
						<p class="subtitle-2 accent--text mb-0">{{ booking.name }}</p>
						<p class="subtitle-2 accent--text mb-0">
							{{ booking.email }}
						</p>
					</v-col>

					<v-col cols="12" sm="4">
						<service-chip v-if="booking.service" :serviceId="booking.service" :priceId="booking.price" />
					</v-col>
					<v-col cols="12" sm="4">
						<span class="title warning--text font-weight-bold">
							<v-icon left color="warning">mdi-key-variant</v-icon>
							{{ booking.code }}
						</span>
					</v-col>
				</v-row>

				<v-row v-else>
					<v-col cols="12" class="px-0 pb-0 mt-2">
						<v-alert type="error" icon="mdi-wrench" class="font-weight-bold mb-0 rounded-b-xl">Maintenance</v-alert>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { format, differenceInCalendarDays } from "date-fns";
import ServiceChip from "@/components/ServiceChip.vue";

export default {
	components: {
		ServiceChip,
	},
	data() {
		return {
			visible: false,
			booking: null,
		};
	},
	computed: {
		...mapGetters(["deviceFromId"]),

		device() {
			return this.deviceFromId(this.booking.device);
		},

		formattedStartTime() {
			return format(new Date(this.booking.start), "HH:mm");
		},

		formattedEndTime() {
			const endTime = new Date(this.booking.end);
			const startTime = new Date(this.booking.start);
			startTime.setHours(0, 0, 0, 0); // Reset time to start of the day

			const dayDifference = differenceInCalendarDays(endTime, startTime);

			if (dayDifference !== 0) {
				// Show day difference for times not on the current day
				return `${format(endTime, "HH:mm")} (${dayDifference > 0 ? "+" : ""}${dayDifference})`;
			}

			return format(endTime, "HH:mm");
		},

		requiresPayment() {
			return (this.booking.source === "app" && this.booking.status === "payment_pending") || !this.booking.prePaid;
		},
	},
	methods: {
		open(dialogData) {
			console.log(dialogData);
			this.booking = dialogData;
			this.visible = true;
		},

		close() {
			this.visible = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.dotted-timeline {
	display: flex;

	.timeline-line {
		width: 100%;
		position: relative;
		border-bottom: 1px solid #c7d6fe;
	}
	.timeline-icon {
		position: relative;
		top: 8px;
	}
}

.paymentChip {
	position: absolute;
	top: -10px;
	right: 0;
	z-index: 100;
}
</style>
