<template>
	<v-dialog :fullscreen="$vuetify.breakpoint.mobile" v-model="dialog" width="500" scrollable max-height="100%">
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="accent" class="elevation-2 openBtn" outlined small dark v-bind="attrs" v-on="on">
				{{ btnTitle }}
			</v-btn>
		</template>

		<v-card class="elevation-24 rounded-0">
			<v-card-title class="accent white--text">
				Edit {{ user.email }}
				<v-spacer></v-spacer>
				<v-btn icon class="mr-0" color="white" small @click="dialog = false">
					<v-icon>mdi-close-circle-outline</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text class="pa-0">
				<v-expansion-panels accordion flat class="mb-3">
					<v-expansion-panel>
						<v-expansion-panel-header> Email & Role </v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-form ref="form1" v-model="valid" lazy-validation autocomplete="off">
								<v-select v-model="defaultItem.role" :items="items" :rules="[rules.required]" dense label="Role" outlined></v-select>
								<v-text-field label="Email" hide-details="auto" :rules="[rules.required, rules.email]" v-model="defaultItem.email" outlined dense class="mb-3"></v-text-field>
								<v-spacer></v-spacer>
								<v-btn @click="save()" :loading="loading1" :disabled="loading1" block class="primary"> Save </v-btn>
							</v-form>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header> Password </v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-form ref="pswdform" v-model="validPassword" lazy-validation>
								<v-text-field
									label="Password"
									hide-details="auto"
									v-model="password"
									outlined
									dense
									class="mb-3"
									:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPass ? 'text' : 'password'"
									@click:append="showPass = !showPass"
								></v-text-field>
								<v-spacer></v-spacer>
								<v-btn @click="savePassword()" :loading="loading3" :disabled="loading3" block class="primary"> Save </v-btn>
							</v-form>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header> Site Assignment </v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-form class="my-4" ref="siteIDform" v-model="validSite" lazy-validation>
								<site-select :model="defaultItem.sites" @siteID="siteChange" :multiple="true" class="mb-3" />
								<v-btn :disabled="loading2" :loading="loading2" color="primary" block @click="saveSites()"> Save </v-btn>
							</v-form>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header> Delete </v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-btn :loading="loading4" :disabled="loading4" color="error" class="mr-2" block @click="deleteUser()"> Delete User </v-btn>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card-text>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import Confirm from "@/components/Confirm";
import SiteSelect from "@/components/SitesPicker";

export default {
	components: {
		SiteSelect,
		Confirm,
	},
	props: {
		btnTitle: {
			default: "Edit",
		},
		user: {
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			items: ["guest", "basic", "user", "pro", "service", "admin", "superadmin"],
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				email: (v) => /.+@.+\..+/.test(v) || this.$i18n.t("rules.emailValid"),
			},
			valid: true,
			validSite: true,
			validPassword: true,
			loading1: false,
			loading2: false,
			loading3: false,
			loading4: false,
			password: "",
			showPass: false,
		};
	},
	computed: {
		defaultItem() {
			return Object.assign({}, this.user);
		},
	},
	methods: {
		siteChange(value) {
			this.defaultItem.sites = value;
		},

		async save() {
			if (this.$refs.form1.validate()) {
				this.loading1 = true;
				const settings = {
					id: this.defaultItem._id,
					body: {
						email: this.formatEmail(this.defaultItem.email),
						role: this.defaultItem.role,
					},
				};

				await this.$store.dispatch("admin/updateUser", settings);
				this.loading1 = false;
				this.cancel();
			}
		},

		async savePassword() {
			if (this.$refs.pswdform.validate()) {
				this.loading3 = true;
				const settings = {
					id: this.defaultItem._id,
					body: {
						password: this.password,
					},
				};
				await this.$store.dispatch("admin/updateUser", settings);
				this.loading3 = false;
				this.cancel();
			}
		},

		async saveSites() {
			if (this.$refs.siteIDform.validate()) {
				this.loading2 = true;
				const settings = {
					id: this.defaultItem._id,
					body: {
						sites: this.defaultItem.sites,
					},
				};
				await this.$store.dispatch("admin/updateUser", settings);
				this.loading2 = false;
				this.cancel();
			}
		},

		async deleteUser() {
			const res = await this.$refs.confirm.open("Delete User?", `Delete ${this.defaultItem.email}?`, "error", "mdi-delete-alert");

			if (res === true) {
				this.loading4 = true;

				await this.$store.dispatch("admin/deleteUser", this.defaultItem._id);
				this.loading4 = false;
				this.cancel();
			}
		},

		cancel() {
			this.dialog = false;
		},
	},
};
</script>
<style scoped>
.openBtn {
	padding-left: 9px !important;
	padding-right: 9px !important;
	min-width: 0 !important;
}
</style>
