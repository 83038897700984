import Vue from "vue";
// import { getUnixTime } from "date-fns";
// import store from "@/store";
import i18n from "@/i18n";

Vue.mixin({
	methods: {
		isOnline(device) {
			return device.onlineStatus;
		},

		onlineStatus(device) {
			var data;

			if (this.isOnline(device)) {
				// if last time less than 1 hour ago = Online - Green
				data = {
					text: "Online",
					color: "success",
				};
			} else {
				// if last time more than 6 hours (pingSeconds) = Offline - Red
				data = {
					text: "Offline",
					color: "error",
				};
			}
			return data;
		},

		conIcon(icon) {
			return icon === "W" ? "mdi-wifi" : "mdi-radio-tower";
		},

		conText(icon) {
			return icon === "W" ? "Wi-Fi" : i18n.t("general.cellular");
		},

		wifiIcon(wifiSignal) {
			var signal;
			if (wifiSignal) {
				signal = Math.min(Math.max(2 * (Number(wifiSignal) + 100), 0), 100);
			}

			if (signal === null) {
				return "mdi-wifi-strength-off-outline";
			} else if (signal >= 90) {
				return "mdi-wifi-strength-4";
			} else if (signal >= 75) {
				return "mdi-wifi-strength-3";
			} else if (signal >= 50) {
				return "mdi-wifi-strength-2";
			} else if (signal >= 10) {
				return "mdi-wifi-strength-1";
			} else if (signal <= 0) {
				return "mdi-wifi-strength-off-outline";
			} else {
				return "mdi-wifi-strength-outline";
			}
		},

		gprsIcon(gprsSignal) {
			const signal = Number(gprsSignal);
			if (signal === 99) {
				return "mdi-dots-horizontal";
			} else if (signal >= 23) {
				return "mdi-signal-cellular-3";
			} else if (signal >= 15) {
				return "mdi-signal-cellular-2";
			} else if (signal >= 8) {
				return "mdi-signal-cellular-1";
			} else {
				return "mdi-signal-cellular-outline";
			}
		},

		signalDisplay(car) {
			var data;

			if (car.conType === "W") {
				data = {
					icon: this.wifiIcon(car.wifiSignal),
					text: car.wifiSignal ? `${car.wifiSignal}` : null,
				};
			} else {
				data = {
					icon: this.gprsIcon(car.gprsSignal),
					text: car.gprsSignal ? `${car.gprsSignal}` : null,
				};
			}
			return data;
		},

		wifiDisplay(car) {
			const data = {
				icon: this.wifiIcon(car.wifiSignal),
				text: car.wifiSignal ? `${car.wifiSignal}` : null,
				color: car.conType === "W" ? "success" : "primary lighten-4",
				textColor: car.conType === "W" ? "white--text" : "primary--text",
			};

			return data;
		},

		gprsDisplay(car) {
			const data = {
				icon: this.gprsIcon(car.gprsSignal),
				text: car.gprsSignal ? `${car.gprsSignal}` : null,
				color: car.conType === "G" ? "success" : "primary lighten-4",
				textColor: car.conType === "G" ? "white--text" : "primary--text",
			};

			return data;
		},
	},
});
