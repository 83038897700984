import * as bookingsService from "@/services/guest/bookings.service";

export default {
	namespaced: true,
	state: {
		bookings: null,
		booking: null,
	},
	mutations: {
		setBookings(state, bookings) {
			state.bookings = bookings;
		},

		setBooking(state, booking) {
			state.booking = booking;
		},
	},
	actions: {
		async getBookings({ commit }, payload) {
			const { data: bookings } = await bookingsService.getBookings(payload);
			commit("setBookings", bookings);
		},

		async getBooking({ commit }, payload) {
			const booking = await bookingsService.getBooking(payload);

			commit("setBooking", booking);
		},

		async createBooking(_, payload) {
			return await bookingsService.createBooking(payload);
		},

		async updateBooking({ commit }, payload) {
			const booking = await bookingsService.updateBooking(payload);

			commit("setBooking", booking);
		},

		async toggleLockWithBooking(_, payload) {
			return await bookingsService.toggleLockWithBooking(payload);
		},

		async retrieveBooking(_, payload) {
			return await bookingsService.retrieveBooking(payload);
		},
	},
};
