<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					dark
					v-bind="attrs"
					v-on="on"
					class="rounded-r rounded-l-0 padlessBtn primary"
					height="40"
					depressed
				>
					Scan
				</v-btn>
			</template>

			<v-card>
				<v-card-title
					class="title accent white--text"
					style="border-bottom: 3px solid #406dfa !important"
				>
					Scan Barcode/QR Code
					<v-spacer></v-spacer>
					<v-btn @click="dialog = false" icon small>
						<v-icon color="white">mdi-close-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pa-0">
					<div v-if="loading" class="mx-auto text-center pa-12">
						<v-progress-circular
							:size="70"
							:width="6"
							color="primary"
							indeterminate
							class="mx-auto"
						>
							<v-icon color="primary">mdi-camera</v-icon>
						</v-progress-circular>
					</div>
					<stream-barcode-reader
						v-if="scanner"
						@decode="onDecode"
						@loaded="loaded"
					></stream-barcode-reader>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import StreamBarcodeReader from "@/components/utilities/StreamBarcodeReader";

export default {
	components: {
		StreamBarcodeReader,
	},
	data() {
		return {
			dialog: false,
			scanner: false,
			loading: true,
		};
	},
	watch: {
		dialog(val) {
			if (val) {
				this.scanner = true;
			} else {
				this.scanner = false;
				this.loading = true;
			}
		},
	},
	methods: {
		onDecode(val) {
			this.$emit("scanResult", val);
			this.dialog = false;
		},

		loaded() {
			this.loading = false;
		},
	},
};
</script>
