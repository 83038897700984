<template>
	<div>
		<v-dialog v-model="dialog" width="350">
			<template v-slot:activator="{ on, attrs }">
				<v-btn small depressed v-bind="attrs" v-on="on" color="primary" class="mr-2">
					<v-icon small left>mdi-magnify</v-icon>
					Find Code
				</v-btn>
			</template>

			<v-card class="rounded-xl elevation-24">
				<v-card-text class="title pt-4 font-weight-bold text-center">
					<span>Scan Tag or Search Code</span>
				</v-card-text>
				<v-card-text class="caption font-weight-bold text-center">
					<span>Scan tag or enter Code to search for Driver</span>
				</v-card-text>
				<v-card-text>
					<v-form ref="form" v-model="form" lazy-validation @submit.prevent="findCode">
						<v-text-field
							autofocus
							prepend-inner-icon="mdi-smart-card-outline"
							placeholder="Tag or Code"
							label="Tag or Code"
							hide-details="auto"
							v-model="value"
							outlined
							rounded
							dense
							clearable
							class="mb-3"
						></v-text-field>
						<v-btn block rounded @click="findCode" :loading="loading" :disabled="!form" color="primary" class="mt-3"> Save </v-btn>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="codeDialog" width="350">
			<v-card v-if="tag" class="rounded-xl elevation-24">
				<v-card-text class="title pt-4 font-weight-bold text-center">
					<span>Code Found</span>
				</v-card-text>
				<v-card-text class="font-weight-bold text-center pb-0">
					<p>Driver: {{ tag.driver.name }}</p>
					<p>Email: {{ tag.driver.email }}</p>
					<p v-if="tag.driver.company">Company: {{ tag.driver.company }}</p>
					<p v-if="tag.driver.phone">Phone: {{ tag.driver.phone }}</p>
				</v-card-text>
				<v-card-actions>
					<v-btn block rounded @click="close" color="primary" class="mt-3"> Close </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			dialog: false,
			codeDialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			value: null,
			form: true,
			tag: null,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),

		hexValue() {
			return Number(this.value);
		},
	},
	methods: {
		async findCode() {
			try {
				this.loading = true;

				const tag = await this.$store.dispatch("findCode", { site: this.selectedSite._id, code: this.hexValue });

				this.tag = tag;
				this.codeDialog = true;

				this.loading = false;
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
				this.reset();
			}
		},

		reset() {
			this.value = null;
			this.dialog = false;
			this.$refs.form.resetValidation();
		},

		close() {
			this.codeDialog = false;
			this.tag = null;
		},
	},
};
</script>
