<template>
	<v-col cols="12">
		<v-row>
			<v-col class="title accent--text text-center mx-auto mt-n14" cols="8" lg="6" md="8" sm="6">
				{{ $t("loginPage.chooseNewPass") }}
			</v-col>
		</v-row>
		<v-row>
			<v-col class="white--text text-center mx-auto" cols="8" lg="6" md="8" sm="6">
				<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="resetPassword">
					<v-text-field
						v-model="password"
						:rules="[rules.required, rules.passwordMatch, rules.min]"
						:label="$t('settingsPage.accountSettings.newPswd')"
						dense
						outlined
						color="accent"
						:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showPass ? 'text' : 'password'"
						@click:append="showPass = !showPass"
					></v-text-field>
					<v-text-field
						v-model="confirmPassword"
						:rules="[rules.required, rules.passwordMatch, rules.min]"
						:label="$t('settingsPage.accountSettings.confNewPswd')"
						dense
						outlined
						color="accent"
						:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showPass ? 'text' : 'password'"
						@click:append="showPass = !showPass"
					></v-text-field>
					<v-divider class="mb-6"></v-divider>
					<v-hover v-slot="{ hover }">
						<v-btn block :loading="btnLoading" :disabled="valid === false || btnLoading" type="submit" class="primary" :elevation="hover ? 12 : 2">
							{{ $t("loginPage.resetPass") }}
						</v-btn>
					</v-hover>
				</v-form>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="caption accent--text text-center mx-auto" cols="8" lg="6" md="8" sm="6">
				<v-btn text color="primary" @click="login">
					{{ $t("loginPage.logInButton") }}
				</v-btn>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	data() {
		return {
			password: "",
			confirmPassword: "",
			showPass: false,
			valid: true,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				min: (v) => (v && v.length >= 6) || this.$i18n.t("rules.length8"),
				passwordMatch: (value) => value === this.password || this.$i18n.t("rules.pswdConf"),
			},
			btnLoading: false,
		};
	},
	methods: {
		async resetPassword() {
			if (this.$refs.form.validate()) {
				this.btnLoading = true;
				try {
					const settings = {
						userId: this.$route.query.id,
						token: this.$route.query.token,
						password: this.confirmPassword,
					};

					await this.$store.dispatch("resetPassword", settings);

					const message = this.$i18n.t("loginPage.passReset");
					this.$toast.success(message);

					this.$router.push({
						path: `/login`,
					});

					this.btnLoading = false;
				} catch (err) {
					const message = err.response.data.message;
					this.$toast.error(message);

					this.btnLoading = false;
				}
			}
		},

		login() {
			this.$router.push({
				path: `/login`,
			});
		},
	},
};
</script>
