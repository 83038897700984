<template>
	<v-card width="100%" flat>
		<v-card-title class="font-weight-bold">
			{{ $t("services.title") }}
			<v-spacer></v-spacer>
			<currency-picker :site="selectedSite" />
			<v-btn color="primary" small depressed @click="addService">{{ $t("services.addService") }}</v-btn>
		</v-card-title>
		<v-card-subtitle>{{ $t("services.subtitle") }}</v-card-subtitle>

		<v-data-table :headers="headers" :items="services" :loading="loading" :hide-default-footer="true" :no-data-text="$t('services.general.create')">
			<template v-slot:top>
				<!-- Edit/Create Sevice Modal -->
				<v-dialog v-model="dialog" max-width="800px" persistent v-if="editedItem">
					<v-card>
						<v-card-title class="font-weight-bold">
							<span v-if="editedIndex === -1">{{ $t("services.addService") }}</span>
							<span v-else>{{ $t("services.editService") }}</span>
						</v-card-title>

						<v-card-subtitle>
							{{ $t("services.subtitle2") }}
						</v-card-subtitle>

						<v-card-text class="py-0 mt-4">
							<v-form ref="form" lazy-validation>
								<v-row>
									<v-col cols="8">
										<v-text-field
											outlined
											dense
											v-model="editedItem.name"
											:label="$t('services.general.name')"
											placeholder="18 Holes"
											:rules="[rules.required]"
											required
											hide-details
											autofocus
										></v-text-field>
									</v-col>
									<v-col cols="4">
										<v-text-field
											outlined
											dense
											v-model="editedItem.duration"
											:label="$t('services.general.duration')"
											v-mask="'##:##'"
											placeholder="hh:mm"
											:rules="[rules.required, rules.validTimeFormat, rules.validDuration]"
											required
											hide-details
											persistent-hint
											:suffix="$t('general.hour')"
										></v-text-field>
									</v-col>
								</v-row>

								<v-divider class="my-4"></v-divider>

								<v-card class="my-4" flat color="grey">
									<v-col>
										<div v-if="editedItem.prices">
											<v-card class="mb-2" outlined v-for="price in editedItem.prices" :key="price._id">
												<v-container fluid>
													<v-row>
														<v-col cols="3" class="text-left">
															<div class="caption">{{ $t("services.general.name") }}</div>
															<div>
																<span class="font-weight-bold">{{ price.name }}</span>
															</div>
															<div>
																<v-chip class="font-weight-bold accent--text" dark label x-small color="blue lighten-3">
																	<v-icon color="blue darken-2" x-small left> mdi-account-group</v-icon>
																	{{ getUserGroupName(price.userGroup) }}
																</v-chip>
															</div>
														</v-col>
														<v-col cols="3" class="text-left">
															<div class="caption">{{ $t("services.general.price") }}</div>
															<div>
																<span class="font-weight-bold">
																	{{ formattedPrice(price.amount, selectedSite.currency) }}
																	<span v-if="price.credits && price.credits > 0">
																		/
																		<v-icon small color="accent">mdi-ticket-confirmation-outline</v-icon>
																		{{ price.credits }}
																	</span>
																</span>
															</div>
														</v-col>
														<v-col cols="5" class="text-left">
															<div class="caption">{{ $t("services.general.activeDays") }}</div>
															<div v-if="price.availableTimes.length">
																<v-chip v-if="price.availableTimes.length === 7" x-small class="font-weight-bold">
																	{{ $t("general.all") }}
																</v-chip>
																<v-chip v-else v-for="(day, index) in price.availableTimes" :key="index" x-small class="font-weight-bold">
																	{{ $t(`general.days.short.${day.day}`) }} {{ day.start }} - {{ day.end }}
																</v-chip>
															</div>
															<div v-else>
																<v-chip x-small class="font-weight-bold"> {{ $t("general.all") }} </v-chip>
															</div>
														</v-col>
														<v-col cols="1" class="text-right d-flex justify-center align-center">
															<v-icon class="mr-2" small @click="editPriceItem(price)">mdi-pencil</v-icon>
															<v-icon class="mr-2" small @click="deletePriceItem(price._id)">mdi-delete</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-card>
										</div>
										<v-btn block depressed color="primary" @click="addPrice">{{ $t("services.addPrice") }}<v-icon right>mdi-cash-plus</v-icon> </v-btn>
									</v-col>
								</v-card>
							</v-form>
						</v-card-text>

						<v-divider></v-divider>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" outlined @click="cancelService">{{ $t("general.cancel") }}</v-btn>
							<v-btn color="primary" :loading="loading" depressed @click="saveService"> {{ $t("general.save") }}</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<!-- Edit/Create Price Modal -->
				<price-form ref="priceForm" :selectedSite="selectedSite" @save="savePriceItem" v-if="editedItem" :key="editedItem._id" />
			</template>

			<template v-slot:item.duration="{ item }">
				{{ minutesToHHMM(item.duration) }}
			</template>

			<template v-slot:item.price="{ item }">
				<v-chip x-small class="font-weight-bold">
					{{ item.prices.length }}
				</v-chip>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" @click="editService(item)">mdi-pencil</v-icon>
				<v-icon small @click="deleteSevice(item)">mdi-delete</v-icon>
			</template>
		</v-data-table>
		<confirm ref="confirm" />
	</v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Confirm from "@/components/Confirm.vue";
import PriceForm from "@/components/sitesettings/PriceForm.vue";
import CurrencyPicker from "@/components/sitesettings/CurrencyPicker.vue";

export default {
	components: {
		Confirm,
		PriceForm,
		CurrencyPicker,
	},
	data() {
		return {
			headers: [
				{ text: this.$t("services.general.name"), value: "name" },
				{ text: this.$t("services.general.duration"), value: "duration" },
				{ text: this.$t("services.general.prices"), value: "price" },
				{ text: this.$t("services.general.actions"), align: "right", value: "actions", sortable: false },
			],
			isDirty: false,
			loading: false,
			dialog: false,
			editedItem: null,
			editedIndex: -1,
			rules: {
				required: (value) => !!value || this.$t("rules.required"),
				number: (value) => !isNaN(value) || this.$t("rules.numbersOnly"),
				validTimeFormat: (value) => {
					const pattern = /^\d{2}:\d{2}$/; // Pattern HH:MM
					return pattern.test(value) || this.$t("rules.validTimeFormat");
				},
				validDuration: (value) => {
					const [hours, minutes] = value.split(":").map(Number);
					const totalMinutes = hours * 60 + minutes;
					return totalMinutes >= 30 || this.$t("rules.validDuration");
				},
			},
		};
	},
	mounted() {
		this.getSiteServices();

		if (this.$route.query.addService) {
			this.addService();
		}
	},
	watch: {
		editedItem: {
			handler() {
				if (this.dialog) this.isDirty = true;
			},
			deep: true,
		},
	},
	computed: {
		...mapState({
			services: (state) => state.sites.services,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		...mapGetters({
			getUserGroupName: "getUserGroupName",
		}),
	},
	methods: {
		generateTempId() {
			return `temp-${Date.now()}`;
		},

		setEmptyServiceObject() {
			return {
				_id: this.generateTempId(),
				name: "",
				duration: "06:00",
				prices: [],
				site: this.selectedSite._id,
				currency: this.selectedSite.currency,
			};
		},

		timeToMinutes(time) {
			if (!time) return 0;
			const [hours, minutes] = time.split(":");
			return Number(hours) * 60 + Number(minutes);
		},

		minutesToHHMM(minutes) {
			const hh = Math.floor(minutes / 60);
			const mm = minutes % 60;
			return `${hh.toString().padStart(2, "0")}:${mm.toString().padStart(2, "0")}`;
		},

		async getSiteServices() {
			this.loading = true;

			try {
				await this.$store.dispatch("getSiteServices", this.selectedSite._id);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},

		addService() {
			this.editedIndex = -1;
			this.editedItem = this.setEmptyServiceObject();
			this.dialog = true;

			this.$nextTick(() => {
				this.isDirty = false;
			});
		},

		editService(item) {
			this.editedItem = { ...item, duration: this.minutesToHHMM(item.duration) };
			this.editedIndex = this.services.indexOf(item);
			this.dialog = true;

			this.$nextTick(() => {
				this.isDirty = false;
			});
		},

		async deleteSevice(item) {
			const res = await this.$refs.confirm.open(this.$t("services.deleteService"), this.$t("services.deleteServiceConfirm"), "accent", "mdi-help-circle-outline");

			if (res === false) {
				return;
			}

			try {
				await this.$store.dispatch("deleteSiteService", item);
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},

		removeTempId(item) {
			if (item._id && item._id.startsWith("temp-")) {
				const newItem = { ...item };
				delete newItem._id;
				return newItem;
			}
			return item;
		},

		async saveService() {
			if (this.$refs.form.validate()) {
				this.loading = true;

				// Process the main service object
				let service = this.removeTempId({ ...this.editedItem });

				// Process each price in the nested array
				service.prices = service.prices.map((price) => this.removeTempId(price));

				service.duration = this.timeToMinutes(service.duration);

				try {
					if (this.editedIndex > -1) {
						await this.$store.dispatch("updateSiteService", service);

						this.$toast.success(this.$t("services.toast.serviceUpdated"));
					} else {
						await this.$store.dispatch("addSiteService", service);

						this.$toast.success(this.$t("services.toast.serviceCreated"));
					}
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.loading = false;
					this.resetForm();
				}
			}
		},

		resetForm() {
			this.dialog = false;
			this.editedItem = this.setEmptyServiceObject();
			this.editedIndex = -1;
			this.isDirty = false;

			this.$nextTick(() => {
				this.$refs.form.resetValidation();
			});

			this.getSiteServices();
		},

		async cancelService() {
			if (this.isDirty) {
				const res = await this.$refs.confirm.open(this.$t("general.close"), this.$t("general.closeWithoutSave"), "accent", "mdi-help-circle-outline");

				if (res === false) {
					return; // Stop if the user does not confirm
				}
			}
			this.resetForm();
		},

		addPrice() {
			this.$refs.priceForm.open();
		},

		editPriceItem(price) {
			this.$refs.priceForm.open(price);
		},

		savePriceItem(priceData) {
			// Save the new or edited price to the service
			if (priceData.editing) {
				// Find and update the existing price
				const index = this.editedItem.prices.findIndex((p) => p._id === priceData._id);
				if (index !== -1) {
					this.editedItem.prices.splice(index, 1, priceData);
				}
			} else {
				// Add the new price to the service
				this.editedItem.prices.push(priceData);
			}
		},

		// Add a method to delete a price from the service
		async deletePriceItem(priceId) {
			const res = await this.$refs.confirm.open(this.$t("services.deletePrice"), this.$t("services.deletePriceConfirm"), "accent", "mdi-help-circle-outline");

			if (res === false) {
				return;
			}

			const index = this.editedItem.prices.findIndex((p) => p._id === priceId);
			if (index !== -1) {
				this.editedItem.prices.splice(index, 1);
			}
		},
	},
};
</script>
