<template>
	<div>
		<v-card v-if="loading" class="rounded-lg" outlined>
			<v-card-text class="text-center">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-card-text>
		</v-card>

		<not-found v-else-if="!booking" :title="$t('s2r.booked.notFound.title')" :subtitle="$t('s2r.booked.notFound.subtitle')" />

		<div v-else>
			<div v-if="isReservationCodePresent">
				<h3 class="mb-4 ml-2">{{ $t("s2r.booked.yourBooking") }}</h3>
				<v-card class="rounded-lg" outlined>
					<v-list>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="accent"> mdi-account </v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title class="accent--text font-weight-bold">
									{{ booking.name }}
								</v-list-item-title>
								<v-list-item-subtitle class="text-wrap">
									{{ booking.email }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-list>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="accent"> mdi-calendar</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title class="accent--text font-weight-bold">
									{{ translatedFormattedDate(booking.start, "EE do MMM") }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-timeline dense class="purple--text darken-1">
						<v-timeline-item color="purple lighten-4" small icon="mdi-golf-cart" icon-color="purple darken-1" fill-dot>
							<v-row class="pt-1 purple--text">
								<v-col cols="3">
									<strong>
										{{ translatedFormattedDate(booking.start, "HH:mm") }}
									</strong>
								</v-col>
								<v-col>
									<span class="subtitle-2">{{ $t("s2r.booked.start") }}</span>
								</v-col>
							</v-row>
						</v-timeline-item>

						<v-timeline-item class="my-6 ml-n1" hide-dot>
							<v-chip label color="purple lighten-4" class="font-weight-bold purple--text">
								<v-icon left small>mdi-clock</v-icon>
								{{ bookingDuration }}
							</v-chip>
						</v-timeline-item>

						<v-timeline-item color="purple lighten-4" small icon="mdi-home" icon-color="purple darken-1" fill-dot>
							<v-row class="pt-1">
								<v-col cols="3">
									<strong>
										{{ translatedFormattedDate(booking.end, "HH:mm") }}
									</strong>
								</v-col>
								<v-col>
									<span class="subtitle-2">{{ $t("s2r.booked.end") }}</span>
								</v-col>
							</v-row>
						</v-timeline-item>
					</v-timeline>

					<v-card-text v-if="booking.note">
						<v-alert outlined color="purple darken-1" prominent dense border="left" icon="mdi-message-fast-outline">
							<strong>{{ $t("s2r.booked.message") }}:</strong>
							<br />
							{{ booking.note }}
						</v-alert>
					</v-card-text>
				</v-card>

				<v-card outlined class="mt-4" v-if="booking.prePaid">
					<v-list>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="accent"> mdi-clock </v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title class="accent--text font-weight-bold"> {{ $t("s2r.booked.accessFrom") }}: </v-list-item-title>
								<v-list-item-title class="title font-weight-bold text-wrap">
									{{ bookingStartWithOffset }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-card-text>
						<v-btn :disabled="!isBookingActive" :loading="loadingUnlock" color="success" class="font-weight-bold rounded-lg" block @click="unlockDevice">
							{{ isBookingActive ? `${$t("s2r.unlockCart")}! 🔓` : countdown }}
						</v-btn>
					</v-card-text>
				</v-card>

				<v-card outlined class="mt-4" v-else>
					<v-list>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="accent"> mdi-cash </v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title class="accent--text font-weight-bold">
									{{ $t("s2r.booked.requiresPayment.title") }}
								</v-list-item-title>
								<v-list-item-subtitle class="mt-2 font-weight-bold text-wrap">
									{{ $t("s2r.booked.requiresPayment.subtitle") }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-card-text>
						<v-btn
							block
							class="rounded-lg font-weight-bold d-flex align-center"
							color="primary"
							depressed
							@click="
								$router.push({
									name: 'RidePrices',
									params: {
										id: $route.params.id,
									},
									query: {
										bookingId: $route.params.bookingId,
										reservationCode: booking.code,
									},
								})
							"
						>
							<span class="text-center" style="flex-grow: 1">
								{{ $t("s2r.payment.options") }}
							</span>
							<v-icon class="ml-auto">mdi-credit-card-fast-outline</v-icon>
						</v-btn>
					</v-card-text>
				</v-card>
			</div>

			<div v-else>
				<v-card class="rounded-lg" outlined>
					<v-list>
						<v-list-item>
							<v-list-item-icon>
								<v-icon color="accent"> mdi-calendar-star </v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title class="accent--text font-weight-bold"> {{ $t("s2r.booked.code.title") }}: </v-list-item-title>
								<v-list-item-subtitle class="accent--text text-wrap">
									{{ $t("s2r.booked.code.required") }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-card-text class="pt-0">
						<bottom-sheet type="booking" :btnTitle="$t('s2r.booked.enterShort')" @code-entered="saveUnlockCode" />
					</v-card-text>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import BottomSheet from "@/components/scanToRide/BottomSheet";
import NotFound from "../../components/scanToRide/NotFound.vue";
// import DevicesService from "@/services/devicesService";

export default {
	props: ["device", "site"],
	components: {
		BottomSheet,
		NotFound,
	},
	data() {
		return {
			loading: true,
			loadingUnlock: false,
			booking: null,
			countdown: "",
			intervalId: null,
			isBookingActive: false,
		};
	},
	mounted() {
		this.getBooking();
	},
	computed: {
		bookingDuration() {
			return this.formatDurationToTime(this.booking.start, this.booking.end);
		},

		bookingStartWithOffset() {
			let start = new Date(this.booking.start);
			start.setMinutes(start.getMinutes() - this.booking.site.bookingOffset);

			return this.translatedFormattedDate(start, "HH:mm");
		},

		isReservationCodePresent() {
			return this.$route.query.reservationCode !== undefined && this.$route.query.reservationCode === this.booking.code;
		},
	},
	methods: {
		async getBooking() {
			try {
				const { data } = await this.$ioteeApiV2.get(`/bookings/${this.$route.params.bookingId}`);

				if (!data) return;

				this.booking = data;
				this.startCountdown();
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},

		timUntilBooking() {
			let now = new Date();
			let start = new Date(this.booking.start);
			start.setMinutes(start.getMinutes() - this.booking.site.bookingOffset);

			return this.formatDurationToTime(now, start);
		},

		startCountdown() {
			this.intervalId = setInterval(() => {
				let now = new Date();
				let start = new Date(this.booking.start);
				start.setMinutes(start.getMinutes() - this.booking.site.bookingOffset);

				// if time left is 0 or negative, clear interval and reset countdown
				if (now >= start) {
					clearInterval(this.intervalId);
					this.countdown = "";
					this.isBookingActive = true; // Set isBookingActive here
				} else {
					this.countdown = this.timUntilBooking();
				}
			}, 1000); // update every second
		},

		saveUnlockCode(code) {
			if (code !== this.booking.code) {
				const message = this.$t("s2r.booked.code.error");
				this.$toast.error(message);
			} else if (this.$route.query.reservationCode !== code) {
				this.$router.push({
					query: { ...this.$route.query, reservationCode: code },
				});
			}
		},

		async unlockDevice() {
			this.loadingUnlock = true;

			try {
				const { data: session } = await this.$ioteeApiV2.post(`/bookings/${this.booking.id}/unlock`, {
					code: this.booking.code,
				});

				this.$router.push({
					name: "RideSession",
					params: {
						id: this.device.id,
						sessionId: session,
					},
				});
			} catch (err) {
				this.$toast.error(err);
			} finally {
				this.loadingUnlock = false;
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.intervalId);
	},
};
</script>
