<template>
	<v-dialog v-model="dialog" width="850" scrollable :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
		<v-card>
			<v-card-title class="title accent white--text" style="border-bottom: 3px solid #406dfa !important">
				{{ isEditing ? $t("bookings.editBooking") : $t("bookings.addBooking") }}
			</v-card-title>
			<v-btn @click="dialog = false" icon small style="position: absolute; right: 15px; top: 15px; z-index: 5">
				<v-icon color="white">mdi-close-circle-outline</v-icon>
			</v-btn>

			<v-card-text v-if="!services.length" style="max-height: 700px" class="pa-4 text-center">
				<v-alert icon="mdi-alert-octagram-outline" dense>
					{{ $t("bookings.bookingRequirements") }}

					<br />
					<br />
					{{ $t("bookings.bookingRequirements2") }}
				</v-alert>

				<v-btn color="primary" to="/settings/booking?addService=true" depressed>
					{{ $t("services.addService") }}
					<v-icon right>mdi-cog-outline</v-icon>
				</v-btn>
			</v-card-text>

			<v-card-text v-else style="max-height: 700px" class="px-2">
				<v-row>
					<!-- ? Form -->
					<v-col cols="12" sm="7">
						<v-form ref="form" v-model="valid" autocomplete="off" class="pa-0">
							<v-list class="pt-0">
								<v-list-item class="my-4">
									<v-list-item-content class="pa-0">
										<v-list-item-title class="font-weight-bold accent--text mb-2"> {{ $t("general.date") }}: </v-list-item-title>
										<v-menu ref="dateSelect" v-model="dateMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													:value="bookingDate"
													label="Booking Date"
													single-line
													prepend-inner-icon="mdi-calendar-month-outline"
													outlined
													dense
													readonly
													v-bind="attrs"
													v-on="on"
													hide-details
												></v-text-field>
											</template>
											<v-date-picker v-model="selectedDate" :min="minDateSelect" no-title scrollable />
										</v-menu>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-content class="pa-0">
										<v-list-item-title class="font-weight-bold accent--text"> {{ $t("services.service") }}: </v-list-item-title>
										<v-list-item-subtitle class="pt-1">
											<v-select
												v-model="selectedService"
												item-text="name"
												item-value="_id"
												:return-object="false"
												:items="services"
												:placeholder="$t('services.serviceSelect')"
												:menu-props="{ offsetY: true }"
												outlined
												dense
												single-line
												hide-details
												class="mb-1"
												@input="getSlots"
											>
												<template v-slot:append-item>
													<v-list-item>
														<v-list-item-content>
															<v-btn small block color="primary" to="/settings/booking?addService=true">
																<v-icon small left>mdi-plus</v-icon>
																Add a service
															</v-btn>
														</v-list-item-content>
													</v-list-item>
												</template>
											</v-select>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item class="mt-2" v-if="selectedService && selectedService.prices.length > 0">
									<v-list-item-content class="pa-0">
										<v-list-item-title class="font-weight-bold accent--text"> Price Group: <span class="caption">(Optional)</span> </v-list-item-title>
										<v-list-item-subtitle class="pt-1">
											<v-select
												v-model="selectedPrice"
												item-text="name"
												item-value="_id"
												:return-object="false"
												:items="selectedService.prices"
												placeholder="Select a Price Group"
												:menu-props="{ offsetY: true }"
												outlined
												dense
												single-line
												hide-details
												class="mb-1"
											/>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-card flat color="grey" class="mx-4 mt-4">
									<v-list-item @click="showSlots = !showSlots" :disabled="loadingSlots">
										<v-list-item-content>
											<span class="subtitle-2 font-weight-bold">
												{{ selectedSlot ? `Selected: ${booking.start}` : "Available Slots:" }}
											</span>
										</v-list-item-content>
										<v-list-item-action>
											<v-btn small icon>
												<v-icon :color="selectedSlot ? 'primary' : 'accent'">
													{{ showSlots ? "mdi-chevron-up" : selectedSlot ? "mdi-pencil-circle-outline" : "mdi-chevron-down" }}
												</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>
									<v-expand-transition>
										<v-card-text class="pt-0" v-show="showSlots">
											<v-list-item v-if="!availableSlots.length">
												<v-list-item-icon>
													<v-icon color="error">mdi-calendar-remove-outline</v-icon>
												</v-list-item-icon>
												<v-list-item-content class="pa-0">
													<v-list-item-title class="font-weight-bold accent--text"> No available slots </v-list-item-title>
												</v-list-item-content>
											</v-list-item>
											<v-virtual-scroll v-else :items="availableSlots" :item-height="50" height="300" class="booking-slot-list" ref="slots">
												<template v-slot:default="{ item }">
													<v-list-item>
														<v-list-item-action>
															<v-chip v-if="item.available" small label :color="devicesCount >= 1 ? 'accent' : 'error darken-2'" class="font-weight-bold">
																<v-icon left small> mdi-golf-cart </v-icon>
																{{ item.available }} / {{ devicesCount }}
															</v-chip>
															<v-chip v-else small label color="accent" class="font-weight-bold">
																<v-icon small> mdi-pencil </v-icon>
															</v-chip>
														</v-list-item-action>

														<v-list-item-content>
															<v-btn
																@click="selectSlot(item)"
																block
																small
																depressed
																:color="selectedSlot && selectedSlot === item ? 'success' : 'primary'"
																class="font-weight-bold"
															>
																{{ item.start }}
																<v-icon style="position: absolute; right: 0" small>mdi-arrow-right</v-icon>
															</v-btn>
														</v-list-item-content>
													</v-list-item>
												</template>
											</v-virtual-scroll>
										</v-card-text>
									</v-expand-transition>
								</v-card>

								<v-list-item class="mt-4">
									<v-list-item-content class="pa-0">
										<v-list-item-title class="font-weight-bold accent--text"> Cart: </v-list-item-title>
										<v-list-item-subtitle>
											<v-select
												:loading="loadingDevices"
												v-model="selectedDevice"
												:items="availableDevices.available"
												:label="$t('carDetail.carNumber')"
												:menu-props="{ offsetY: true }"
												:rules="[rules.required]"
												prepend-inner-icon="mdi-golf-cart"
												item-value="_id"
												:return-object="false"
												outlined
												dense
												single-line
												hide-details
												class="mb-1"
												:no-data-text="$t('bookings.modal.noCarts')"
												:disabled="!availableDevices.available.length || !selectedSlot"
											>
												<template slot="selection" slot-scope="{ item }">
													{{ $t("carDetail.car") }} {{ item.carNumber }}

													<v-chip class="ml-4 padlessBtn" x-small label color="warning" v-if="item._id === availableDevices.recommended">
														<v-icon x-small>mdi-star</v-icon>
													</v-chip>
												</template>
												<template slot="item" slot-scope="{ item }">
													{{ $t("carDetail.car") }} {{ item.carNumber }}

													<v-chip class="ml-4 padlessBtn" x-small label color="warning" v-if="item._id === availableDevices.recommended">
														<v-icon x-small>mdi-star</v-icon>
													</v-chip>
												</template>
											</v-select>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item class="mt-4">
									<v-list-item-content class="pa-0">
										<v-list-item-title class="font-weight-bold accent--text"> {{ $t("bookings.modal.name") }}: </v-list-item-title>
										<v-list-item-subtitle class="pt-1">
											<v-combobox
												v-model="selectedDriver"
												:rules="[rules.requiredDriver]"
												:items="members"
												:loading="loadingMembers"
												@update:search-input="membersSearch = $event"
												hide-no-data
												hide-selected
												item-value="_id"
												item-text="name"
												placeholder="Max Mustermann"
												prepend-inner-icon="mdi-account"
												return-object
												:menu-props="{ offsetY: true }"
												outlined
												dense
												clearable
											>
												<template v-slot:item="{ item }">
													<v-list-item-content>
														<v-list-item-title>{{ item.name }}</v-list-item-title>
														<v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-combobox>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-content class="pa-0">
										<v-list-item-title class="font-weight-bold accent--text">
											{{ $t("bookings.modal.email") }}
											<i class="caption">(Optional)</i> :
										</v-list-item-title>
										<v-list-item-subtitle class="pt-1 pb-5">
											<v-text-field label="max@example.com" type="email" v-model="booking.email" :rules="[rules.emailValid]" hide-details outlined solo flat dense></v-text-field>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>

							<v-card color="primary lighten-3 mx-3" flat>
								<v-card-text class="px-1">
									<v-list-item>
										<v-list-item-content class="pa-0">
											<v-list-item-title class="font-weight-bold accent--text text-wrap">
												<v-checkbox
													color="white"
													class="mb-2 mt-0"
													v-model="booking.scheduleUnlock"
													:label="$t('bookings.autoUnlock')"
													persistent-hint
													:hint="$t('bookings.autoUnlockDesc')"
												></v-checkbox>
											</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-card-text>
							</v-card>
						</v-form>
					</v-col>

					<!-- ? Booking Details -->
					<v-col cols="12" sm="5">
						<div style="position: sticky; top: 14px">
							<v-card color="grey" flat>
								<v-card-title class="subtitle-1 font-weight-bold accent--text">{{ $t("bookings.modal.details") }}:</v-card-title>
								<v-card-text>
									<v-list dense color="grey">
										<v-list-item v-if="booking.service">
											<v-list-item-icon>
												<v-icon color="primary" small>mdi-golf</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title class="font-weight-bold accent--text">
													{{ selectedService.name }}
												</v-list-item-title>
												<!-- <v-list-item-subtitle v-if="selectedPrice" class="accent--text caption">
													{{ b.name }}
												</v-list-item-subtitle> -->
											</v-list-item-content>
										</v-list-item>

										<div v-if="selectedSlot">
											<v-list-item>
												<v-list-item-icon>
													<v-icon color="primary" small>mdi-clock-outline</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<v-list-item-title class="font-weight-bold accent--text">
														{{ selectedSlot.start }} <v-icon x-small class="mx-3" color="accent">mdi-arrow-right-bold</v-icon> {{ selectedSlot.end }}
													</v-list-item-title>
													<v-list-item-subtitle class="accent--text caption"> Available from: {{ startTimeWithBookingOffset }} </v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>

											<v-list-item>
												<v-list-item-icon>
													<v-icon color="primary" small>mdi-calendar-month</v-icon>
												</v-list-item-icon>
												<v-list-item-content>
													<span class="font-weight-bold accent--text">{{ bookingDate }}</span>
												</v-list-item-content>
											</v-list-item>
										</div>

										<v-list-item v-if="selectedDevice">
											<v-list-item-icon>
												<v-icon color="primary" small>mdi-golf-cart</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<span class="font-weight-bold accent--text">
													{{ deviceFromId(booking.device).carNumber }}
													<v-chip class="ml-4 padlessBtn" x-small label color="warning" v-if="selectedDevice._id === availableDevices.recommended">
														<v-icon x-small>mdi-star</v-icon>
													</v-chip>
												</span>
											</v-list-item-content>
										</v-list-item>

										<v-list-item v-if="booking.name">
											<v-list-item-icon>
												<v-icon color="primary" small>mdi-account</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title class="font-weight-bold accent--text">
													{{ booking.name }}
												</v-list-item-title>
												<div v-if="booking.email">
													<v-list-item-subtitle class="font-weight-bold accent--text">
														{{ booking.email }}
													</v-list-item-subtitle>
													<i>
														<small>
															{{ $t("bookings.modal.info") }}
														</small>
													</i>
												</div>
											</v-list-item-content>
										</v-list-item>

										<v-list-item v-if="booking.scheduleUnlock">
											<v-list-item-content>
												<v-list-item-subtitle class="font-weight-bold mt-2">
													<v-chip small label color="purple" dark>
														<v-icon small left>mdi-timer-outline</v-icon>
														{{ $t("bookings.autoUnlock") }}
													</v-chip>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>

										<v-list-item v-if="hasRole('admin')">
											<v-list-item-icon>
												<v-icon color="primary" small>mdi-identifier</v-icon>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title class="font-weight-bold accent--text">
													{{ booking._id }}
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-card-text>
							</v-card>

							<v-card color="grey" class="mt-4" flat v-if="booking.email">
								<v-card-title class="subtitle-1 font-weight-bold accent--text">{{ $t("bookings.modal.noteTitle") }}:</v-card-title>
								<v-card-text>
									<v-textarea
										clearable
										hide-details="auto"
										:hint="$t('bookings.modal.noteMsg')"
										v-model="booking.note"
										rows="2"
										prepend-icon="mdi-comment"
										auto-grow
										outlined
										solo
										flat
									></v-textarea>
								</v-card-text>
							</v-card>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-actions v-if="services.length">
				<v-btn color="error" class="padlessBtn" v-if="isEditing" @click="deleteBooking" depressed>
					<v-icon left>mdi-delete</v-icon>
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn @click="close" text color="primary">
					{{ $t("general.cancel") }}
				</v-btn>
				<v-btn color="primary" @click="createBooking" :loading="loading" :disabled="!valid || !selectedSlot">
					{{ $t("general.save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, addMinutes, subMinutes, parseISO } from "date-fns";
import Confirm from "../Confirm.vue";

export default {
	props: {
		currentDay: String,
	},
	components: { Confirm },
	data() {
		return {
			dateMenu: false,
			selectedDate: format(new Date(), "yyyy-MM-dd"),

			booking: {
				start: null,
				end: null,
				service: null,
				price: null,
				device: null,
				email: null,
				scheduleUnlock: false,
				driver: null,
				name: null,
				note: null,
			},

			loading: false,
			loadingDevices: false,
			loadingSlots: false,
			loadingMembers: false,

			membersSearch: "",

			showSlots: false,

			availableDevices: {
				available: [],
				recommended: null,
			},
			availableSlots: [],

			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				requiredDriver: (value) => {
					return value ? true : this.$i18n.t("rules.required");
				},
				emailValid: (value) => !value || /.+@.+\..+/.test(value) || this.$i18n.t("rules.emailValid"),
				min: (value) => (value && value >= 0.5) || this.$i18n.t("rules.minValue"),
				length: (value) => (value && value.length === 6) || this.$i18n.t("rules.6Digits"),
				numberOnly: (value) => /^\d+$/.test(value) || this.$i18n.t("rules.numbersOnly"),
			},

			valid: true,
			dialog: false,

			isEditing: false,
		};
	},
	watch: {
		dialog(val) {
			if (val) {
				this.selectedDate = format(new Date(this.currentDay), "yyyy-MM-dd");
			} else {
				this.close();
			}
		},

		showSlots(val) {
			if (val) {
				this.$nextTick(() => {
					this.scrollToSelectedSlot();
				});
			}
		},

		selectedDate() {
			if (this.booking.service) {
				this.getSlots();
			}
		},

		membersSearch(val) {
			setTimeout(() => {
				if (val === this.membersSearch) {
					this.getMembers({ name: val });
				}
			}, 700);
		},
	},

	computed: {
		...mapState({
			members: (state) => state.members.members,
			devices: (state) => state.devices.devices,
			services: (state) => state.sites.services,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		...mapGetters(["devicesCount", "deviceFromId"]),

		selectedService: {
			get() {
				return this.services.find((service) => service?._id === this.booking.service);
			},
			set(val) {
				this.booking.service = val;

				if (!this.isEditing) {
					this.booking.start = null;
					this.booking.end = null;
				} else {
					this.getAvailableDevices();
				}
			},
		},

		selectedPrice: {
			get() {
				return this.selectedService?.prices.find((price) => price._id === this.booking.price);
			},
			set(val) {
				this.booking.price = val;
			},
		},

		selectedSlot: {
			get() {
				return this.availableSlots.find((slot) => slot?.start === this.booking.start);
			},
			set(val) {
				if (val) {
					this.booking.start = val.start;
					this.booking.end = val.end;
					this.showSlots = false;
				}
			},
		},

		selectedDevice: {
			get() {
				return this.devices.find((device) => device?._id === this.booking.device);
			},
			set(val) {
				if (!val) {
					this.booking.device = null;
					return;
				}
				this.booking.device = val;
			},
		},

		selectedDriver: {
			get() {
				if (this.booking.driver) {
					// If there's a driver ID, find the corresponding driver in the members list and return the name
					const driver = this.members.find((member) => member._id === this.booking.driver);
					return driver ? driver.name : null;
				} else {
					// If there's no driver ID, return the name from the booking object
					return this.booking.name;
				}
			},
			set(value) {
				if (typeof value === "string") {
					this.booking.driver = null;
					this.booking.email = null;
					this.booking.name = value;
				} else {
					this.booking.driver = value._id;
					this.booking.name = value.name;
					this.booking.email = value.email;
				}
			},
		},

		bookingDate() {
			return this.translatedFormattedDate(parseISO(this.selectedDate), "EE, do MMM yyyy");
		},

		minDateSelect() {
			return format(new Date(), "yyyy-MM-dd");
		},

		fullStartDate() {
			return this.combineDateWithTimeString(this.selectedSlot.start, new Date(this.selectedDate)).toISOString();
		},

		fullEndDate() {
			return addMinutes(new Date(this.fullStartDate), this.selectedService.duration).toISOString();
		},

		startTimeWithBookingOffset() {
			return format(subMinutes(new Date(this.fullStartDate), this.selectedSite.bookingOffset), "HH:mm");
		},
	},
	methods: {
		setDefaultBookingValues() {
			this.booking = {
				start: null,
				end: null,
				service: null,
				price: null,
				device: null,
				email: null,
				scheduleUnlock: false,
				driver: null,
				name: null,
				note: null,
			};
		},

		async open(booking) {
			this.dialog = true;

			await this.getSiteServices();

			if (booking) {
				this.isEditing = true;
				this.selectedDate = format(new Date(booking.start), "yyyy-MM-dd");

				// if booking.start is in the past, return
				if (new Date(booking.end) < new Date()) {
					this.$toast.error("You can't edit past bookings");
					this.close();
					return;
				}

				this.booking = {
					...booking,
					start: format(parseISO(booking.start), "HH:mm"),
					end: format(parseISO(booking.end), "HH:mm"),
				};

				await this.getSlots();
				await this.getAvailableDevices();
			}
		},

		async getSiteServices() {
			this.loading = true;

			try {
				await this.$store.dispatch("getSiteServices", this.selectedSite._id);

				if (this.services.length && !this.isEditing) {
					this.selectedService = this.services[0]._id;
					this.getSlots(this.services[0]);
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},

		async getSlots() {
			this.loadingSlots = true;
			this.showSlots = false;

			try {
				const slotsResponse = await this.$store.dispatch("getAvailableSlots", {
					serviceId: this.booking.service,
					dateString: this.selectedDate,
				});

				// Ensure the original slot is included if editing and not in the response
				if (this.isEditing) {
					const originalSlot = {
						start: this.booking.start,
						end: this.booking.end,
						available: null,
						// Include any other relevant information for the slot
					};

					// Check if the original slot is not already in the response
					const originalSlotExists = slotsResponse.some((slot) => slot.start === originalSlot.start && slot.end === originalSlot.end);

					if (!originalSlotExists) {
						// Prepend or append the original slot to the availableSlots array
						// depending on your needs (e.g., sorting by start time)
						this.availableSlots = [originalSlot, ...slotsResponse];
					} else {
						this.availableSlots = slotsResponse;
					}
				} else {
					this.availableSlots = slotsResponse;
				}

				this.showSlots = true;

				// Automatically select the original slot if editing
				if (this.isEditing) {
					this.selectedSlot = this.availableSlots.find((slot) => slot.start === this.booking.start);
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingSlots = false;
			}
		},

		selectSlot(slot) {
			this.selectedSlot = slot;
			this.getAvailableDevices();
		},

		async getAvailableDevices() {
			this.loadingDevices = true;

			try {
				const response = await this.$store.dispatch("getAvailableDevices", {
					start: this.fullStartDate,
					end: this.fullEndDate,
					...(this.isEditing && { excludedBookingId: this.booking._id }),
				});

				let devicesWithDetails = response.available.map((deviceId) => this.deviceFromId(deviceId));

				// Sort the devices by carNumber
				devicesWithDetails.sort((a, b) => {
					if (Number(a.carNumber) < Number(b.carNumber)) return -1;
					if (Number(a.carNumber) > Number(b.carNumber)) return 1;
					return 0;
				});

				this.availableDevices = {
					...response,
					available: devicesWithDetails,
				};

				if (this.isEditing) {
					let device = this.availableDevices.available.find((device) => device._id === this.booking.device);

					if (!device) {
						this.selectedDevice = response.recommended;
					} else {
						this.selectedDevice = device._id;
					}
				} else {
					this.selectedDevice = response.recommended;
				}
			} catch (err) {
				console.log(err);
				this.$toast.error(err.error.message);
			} finally {
				this.loadingDevices = false;
			}
		},

		async getMembers(query) {
			this.loadingMembers = true;

			try {
				await this.$store.dispatch("getMembers", query);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingMembers = false;
			}
		},

		async createBooking() {
			if (this.$refs.form.validate() === false) return;

			let booking = {
				...this.booking,
				start: this.fullStartDate,
				end: this.fullEndDate,
			};

			this.loading = true;

			try {
				if (this.isEditing) {
					await this.$store.dispatch("updateBooking", booking);
					this.$toast.success("Booking updated successfully");
				} else {
					await this.$store.dispatch("createBooking", booking);
					this.$toast.success("Booking created successfully");
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
				this.close();
			}
		},

		async deleteBooking() {
			const res = await this.$refs.confirm.open("Delete Booking?", "", "error", "mdi-trash-can-outline");

			if (res !== true) return;

			try {
				await this.$store.dispatch("deleteBooking", this.booking._id);
				this.close();

				this.$toast.success("Booking deleted");
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},

		scrollToSelectedSlot() {
			if (this.selectedSlot && this.availableSlots.length > 0) {
				const index = this.availableSlots.findIndex((slot) => slot === this.selectedSlot);

				if (index !== -1) {
					const itemHeight = 50; // The height of each item
					const position = index * itemHeight;
					this.$refs.slots.$el.scrollTop = position;
				}
			}
		},

		close() {
			this.setDefaultBookingValues();
			this.dialog = false;
		},
	},
};
</script>

<style lang="scss">
.v-otp-input {
	transform: scale(0.9);
}

.booking-slot-list::-webkit-scrollbar {
	background-color: #e2e2e2; /* or add it to the track */
}

/* Add a thumb */
.booking-slot-list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	height: 28px;
	background: #406dfa;
}
</style>
