import Vue from "vue";

export const getSites = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get("/sites", {
			params: query,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const createSite = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post("/sites", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateSite = async (payload) => {
	const { id, body } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.patch(`/sites/${id}`, body);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteSite = async (id) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.delete(`/sites/${id}`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
