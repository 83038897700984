<template>
	<v-card class="rounded-lg" outlined>
		<v-list-item>
			<v-list-item-action>
				<v-img max-width="125" max-height="125" src="/IOTeeCart.png" alt="Logo" />
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title class="font-weight-bold title accent--text">
					{{ $t("carDetail.car") }}
					{{ device.carNumber }}
				</v-list-item-title>
				<v-list-item-subtitle class="accent--text">
					{{ site.publicProfile.name }}
				</v-list-item-subtitle>
				<v-list-item-subtitle>
					<span class="text-center caption font-weight-bold">
						<v-icon color="success">
							{{ batteryDisplay(device).icon }}
						</v-icon>
						{{ batteryDisplay(device).text }}
					</span>
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-action v-if="!isHomePage && !isSessionPage">
				<v-btn text @click="returnHome">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
			</v-list-item-action>
		</v-list-item>
	</v-card>
</template>

<script>
export default {
	props: {
		device: {
			type: Object,
			required: true,
		},
		site: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isHomePage() {
			const homePath = `/ride/${this.$route.params.id}`;
			return this.$route.path === homePath;
		},

		isSessionPage() {
			return this.$route.path.includes("/session/");
		},
	},
	methods: {
		returnHome() {
			if (!this.isHomePage) {
				this.$router.push({
					path: `/ride/${this.$route.params.id}`,
				});
			}
		},
	},
};
</script>
