<template>
	<v-navigation-drawer
		:value="sideBarOpen"
		@input="setDeviceSidebar"
		disable-resize-watcher
		touchless
		temporary
		right
		width="500px"
		class="elevation-14 gp-full-height"
		style="position: fixed; top: 0px; right: 0px; z-index: 4"
		:style="$vuetify.breakpoint.smAndUp && 'right: 0px;'"
	>
		<v-container class="bg" style="height: 100%" v-if="selectedDevice">
			<v-row>
				<v-col>
					<v-row class="accent" dark style="position: sticky; top: 0; z-index: 1000; border-bottom: 3px solid #406dfa !important">
						<v-col>
							<v-row>
								<v-system-bar color="accent" dark flat height="44" class="px-4" style="width: 100%; border-bottom: 3px solid #406dfa !important">
									<span class="subtitle-1 font-weight-bold white--text"> Car {{ selectedDevice.carNumber }} </span>
									<v-divider inset vertical class="mx-3"></v-divider>
									<span class="text-center font-weight-bold">
										<v-icon :color="batteryDisplay(selectedDevice).color">
											{{ batteryDisplay(selectedDevice).icon }}
										</v-icon>
										{{ $vuetify.breakpoint.smAndUp ? batteryDisplay(selectedDevice).text : "" }}
									</span>
									<span class="text-center ml-3 font-weight-bold">
										<v-icon v-if="isOnline(selectedDevice)" color="success">
											{{ signalDisplay(selectedDevice).icon }}
										</v-icon>
										<v-icon v-else color="error"> mdi-wifi-strength-off-outline </v-icon>
									</span>
									<span class="text-center ml-3 font-weight-bold">
										<v-icon color="success"> mdi-speedometer </v-icon>
										{{ selectedDevice.gpsSpeed ? deviceSpeed(selectedDevice.gpsSpeed) : "Uknown" }}
									</span>

									<span class="text-center ml-3 font-weight-bold">
										<v-icon :color="`${lockStatus(selectedDevice.lockStatus).color}`">
											{{ lockStatus(selectedDevice.lockStatus).icon }}
										</v-icon>
										{{ $vuetify.breakpoint.smAndUp ? (selectedDevice.lockStatus ? $t("general.locked") : $t("general.unlocked")) : "" }}
									</span>
									<span class="text-center ml-3 font-weight-bold" v-if="selectedDevice.key === '1'">
										<v-icon size="20" color="success"> mdi-key </v-icon>
									</span>
									<v-spacer></v-spacer>
									<v-btn @click="setDeviceSidebar(false)" icon color="white">
										<v-icon class="ma-0" color="white">mdi-arrow-right</v-icon>
									</v-btn>
								</v-system-bar>
							</v-row>
						</v-col>
					</v-row>
					<v-row style="position: sticky; top: 47px; z-index: 1000">
						<v-tabs v-model="tabs" centered grow color="accent">
							<v-tab>
								<v-icon small left>mdi-golf-cart</v-icon>
								{{ $t("carModal.info") }}
							</v-tab>
							<v-tab>
								<v-icon small left>mdi-cog-outline</v-icon>
								{{ $t("carModal.settings") }}
							</v-tab>
						</v-tabs>
					</v-row>
					<v-row class="bg">
						<v-col :style="$vuetify.breakpoint.smAndDown && 'margin-bottom: 48px; !important'">
							<v-tabs-items v-model="tabs">
								<v-tab-item>
									<car-tab :car="selectedDevice" :selectedSite="selectedSite" />
								</v-tab-item>
								<v-tab-item>
									<car-settings :car="selectedDevice" />
								</v-tab-item>
							</v-tabs-items>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
		<confirm ref="confirm" />
	</v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CarSettings from "@/components/fleet/carmodal/CarSettings";
import CarTab from "@/components/fleet/carmodal/CarTab";
import Confirm from "@/components/Confirm";

export default {
	components: { Confirm, CarSettings, CarTab },
	data() {
		return {
			tabs: null,
			items: ["Info", "Settings"],
		};
	},
	computed: {
		...mapState({
			selectedDeviceId: (state) => state.devices.selectedDeviceId,
			cars: (state) => state.devices.devices,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		...mapGetters(["devicesWithRideAndBookings"]),

		selectedDevice() {
			return this.devicesWithRideAndBookings.find((car) => car._id === this.selectedDeviceId);
		},

		sideBarOpen() {
			return this.selectedDevice ? true : false;
		},
	},
	methods: {
		setDeviceSidebar(e) {
			if (e === false) {
				this.$store.commit("setSelectedDeviceId", null);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	display: none !important;
}
</style>
