import Vue from "vue";

export const login = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/login", payload);

		return data.token;
	} catch (err) {
		throw err.response.data;
	}
};

export const getUser = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/auth");

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateUser = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch("/auth", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const registerUser = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/register", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const forgotPassword = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/forgot-password", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const resetPassword = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/auth/reset-password", payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
