import Vue from "vue";

export default {
	async addUnlockCode(payload) {
		const unlockCode = await Vue.prototype.$ioteeApi.post(
			"/unlockCodes",
			payload
		);

		return unlockCode;
	},

	async updateUnlockCode(payload) {
		const unlockCode = await Vue.prototype.$ioteeApi.patch(
			`/unlockCodes/${payload.id}`,
			payload.body
		);

		return unlockCode;
	},

	async deleteUnlockCode(payload) {
		const unlockCode = await Vue.prototype.$ioteeApi.post(
			`/unlockCodes/${payload.id}/delete`,
			payload.body
		);

		return unlockCode;
	},

	async publishUnlockCodes(payload) {
		const unlockCode = await Vue.prototype.$ioteeApi.post(
			`/unlockCodes/publish-unlock-codes`,
			payload
		);

		return unlockCode;
	},
};
