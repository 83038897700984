<template>
	<div>
		<apexchart :height="chartOptions.chart.height" :options="chartOptions" :series="seriesData"></apexchart>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	props: {
		rides: {
			type: Array,
			required: true,
		},
		services: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapGetters(["priceFromIdAndServiceId"]),

		formattedCategories() {
			// Map service names as categories, ensuring all services are shown
			return this.services.map((service) => service.name);
		},

		seriesData() {
			// Step 1: Flatten and collect unique price names across all services
			const uniquePrices = this.services.reduce((acc, service) => {
				service.prices.forEach((price) => {
					if (!acc.includes(price.name)) {
						acc.push(price.name);
					}
				});
				return acc;
			}, []);

			// Include 'Unknown' for any prices not specified or not matching the services' prices
			uniquePrices.push("Unknown");

			// Step 2: Initialize the series data structure based on unique prices
			const series = uniquePrices.map((priceName) => ({
				name: priceName,
				data: Array(this.services.length).fill(0), // Initialize counts for each service
			}));

			// Step 3: Count rides for each price in each service
			this.rides.forEach((ride) => {
				// Determine the price name of the ride, falling back to 'Unknown' if necessary

				const priceName = this.priceFromIdAndServiceId(ride.price, ride.service)?.name || "Unknown";

				console.log("price", priceName);
				const serviceIndex = this.services.findIndex((service) => service._id === ride.service);
				console.log("serviceIndex", serviceIndex);
				const priceIndex = series.findIndex((price) => price.name === priceName);

				if (serviceIndex !== -1 && priceIndex !== -1) {
					// Increment the count for the matched price and service
					series[priceIndex].data[serviceIndex] += 1;
				} else if (priceIndex !== -1) {
					// If the service is not found, consider the price as 'Unknown'
					series[series.findIndex((price) => price.name === "Unknown")].data[serviceIndex] += 1;
				}
			});

			return series;
		},

		chartOptions() {
			return {
				chart: {
					height: 250,
					type: "bar",
					stacked: true,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					dropShadow: {
						enabled: true,
						top: 4,
						left: 0,
						blur: 2,
						opacity: 0.1,
					},
				},
				legend: { show: true, position: "top", fontSize: "10px", markers: { radius: 2, width: 8, height: 8 } },
				plotOptions: {
					bar: {
						horizontal: true,
						borderRadius: 4,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: (val) => {
						return parseInt(val) === val ? val : "";
					},
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					categories: this.formattedCategories,
					labels: {
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
						formatter: (val) => {
							return parseInt(val) === val ? val : "";
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					crosshairs: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						showDuplicates: false,
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
					},
				},
				// colors: ["#406DFA"],
				grid: {
					show: false,
				},
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex, w }) {
						const priceName = w.config.series[seriesIndex].name;
						const value = series[seriesIndex][dataPointIndex];
						return `<div class="pa-2 font-weight-bold accent--text">
          <span>${priceName}: ${value} Rounds</span>
        </div>`;
					},
					followCursor: true,
				},
			};
		},
	},
};
</script>
