<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-title>
					<v-icon small left>mdi-golf-cart</v-icon>
					{{ $t("carModal.vehicle") }} Settings
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>{{ $t("carModal.vehicle") }}</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="form" v-model="form" lazy-validation>
					<v-select
						:items="cartTypes"
						v-model="cartType"
						:label="$t('carDetail.cartType')"
						hide-details="auto"
						item-value="model"
						outlined
						dense
						return-object
						:rules="rules"
						class="mb-3"
					>
						<template slot="selection" slot-scope="data">
							{{ data.item.model }}
							<v-chip
								x-small
								class="padlessBtn font-weight-bold ml-2"
								label
								:color="data.item.color ? data.item.color : 'warning darken-2'"
							>
								{{ data.item.brand }}
							</v-chip>
						</template>
						<template slot="item" slot-scope="data">
							{{ data.item.model }}
							<v-chip
								x-small
								class="padlessBtn font-weight-bold ml-2"
								label
								:color="data.item.color ? data.item.color : 'warning darken-2'"
							>
								{{ data.item.brand }}
							</v-chip>
						</template>
					</v-select>
					<v-select
						:items="batteryTypes"
						v-model="batteryType"
						:label="$t('carDetail.battType')"
						hide-details="auto"
						outlined
						dense
						class="mb-3"
						:rules="rules"
					>
					</v-select>
					<v-text-field
						:label="$t('carDetail.cartNotes')"
						hide-details="auto"
						v-model="notes"
						outlined
						dense
						clearable
						class="mb-3"
					>
					</v-text-field>
					<v-spacer></v-spacer>
					<v-btn @click="submit()" :loading="loading" block class="primary">
						{{ $t("general.save") }}
					</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			loading: false,
			value: null,
			form: true,
			cartTypes: [
				// Golf
				{ header: "Golf" },
				{ model: "Tempo", brand: "Club Car" },
				{ model: "Precedent", brand: "Club Car" },
				{ model: "Onward", brand: "Club Car" },
				// Transport
				{ header: "Transport" },
				{ model: "Villager 6", brand: "Club Car" },
				{ model: "Villager 8", brand: "Club Car" },
				{ model: "Transporter", brand: "Club Car" },
				{ model: "Tempo 2+2", brand: "Club Car" },
				// Utility
				{ header: "Utility" },
				{ model: "Carryall 100", brand: "Club Car" },
				{ model: "Carryall 300", brand: "Club Car" },
				{ model: "Carryall 500", brand: "Club Car" },
				{ model: "Carryall 502", brand: "Club Car" },
				{ model: "Carryall 550", brand: "Club Car" },
				{ model: "Carryall 700", brand: "Club Car" },
				{ model: "Carryall 1500", brand: "Club Car" },
				{ model: "Carryall 1700", brand: "Club Car" },
				// Other
				{ header: "Other" },
				{ model: "Club Car", brand: "Club Car" },
				{ model: "EZ-GO", brand: "EZ-GO" },
				{ model: "Yamaha", brand: "Yamaha" },
				{ model: "None", brand: "None" },
			],
			batteryTypes: ["FLA", "AGM", "Li-Ion", "Gas", "Unknown"],
			cartType: null,
			batteryType: null,
			notes: null,
			rules: [(v) => !!v || this.$i18n.t("rules.required")],
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		submit() {
			if (this.$refs.form.validate()) {
				const vehicleInfo = {
					cart: this.cartType,
					power: this.batteryType,
					notes: this.notes,
				};

				this.$emit("updateVehicleInfo", vehicleInfo);
				this.dialog = false;
			}
		},

		reset() {
			this.value = null;
			this.$refs.form.resetValidation();
		},
	},
};
</script>
