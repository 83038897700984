<template>
	<v-dialog v-model="dialog" width="350" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-show="driver.code" v-bind="attrs" v-on="on" color="purple" small class="font-weight-bold white--text" :disabled="!hasRole('user')">
				<v-icon small left color="purple'"> mdi-ticket </v-icon>
				{{ creditsButtonText }}
			</v-btn>
		</template>

		<v-card class="elevation-24">
			<v-card-title class="title accent white--text" style="border-bottom: 3px solid #406dfa !important">
				<span> {{ $t("virtualKeys.credits.credits") }}: </span>
				<span class="ml-2 text-truncate subtitle-1" style="max-width: 140px">
					{{ driver.name }}
				</span>
				<v-spacer></v-spacer>
				<v-btn @click="dialog = false" icon small>
					<v-icon color="white">mdi-close-circle-outline</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="px-2">
				<v-form ref="form" v-model="form" lazy-validation @submit.prevent="save">
					<v-list-item v-if="!unlimitedCredits">
						<v-list-item-content class="pb-0">
							<v-list-item-title class="font-weight-bold accent--text"> {{ $t("virtualKeys.credits.credits") }}: </v-list-item-title>
							<v-list-item-subtitle class="pt-1 pb-3">
								<v-text-field
									prepend-inner-icon="mdi-ticket"
									placeholder="Credits"
									hide-details="auto"
									v-model="driverCredits"
									:rules="codeRules"
									outlined
									dense
									number
									class="mb-3 rounded-0 unlockCodeInput"
									type="number"
								>
									<template v-slot:prepend>
										<v-btn class="rounded-l rounded-r-0 padlessBtn" height="40" color="primary" depressed @click="driverCredits -= 1" :disabled="driverCredits === 0">
											<v-icon>mdi-minus</v-icon>
										</v-btn>
									</template>
									<template v-slot:append-outer>
										<v-btn class="rounded-r rounded-l-0 padlessBtn" height="40" color="primary" depressed @click="driverCredits += 1">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
								</v-text-field>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-list-item>
						<v-list-item-content class="pt-0 pb-0">
							<v-list-item-subtitle class="pt-0 pb-3">
								<v-checkbox v-model="unlimitedCredits" :hint="creditsHint" persistent-hint color="accent">
									<template v-slot:label>
										<div>
											<span class="font-weight-bold accent--text">
												{{ $t("virtualKeys.credits.unlimited") }}
											</span>
										</div>
									</template>
								</v-checkbox>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-form>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn v-if="editing" outlined color="error" class="padlessBtn" @click="deleteCode()" :loading="loadingDelete" :disabled="loadingDelete || !valid">
					<v-icon small>mdi-trash-can-outline</v-icon>
				</v-btn>
				<v-btn color="primary" @click="save" :loading="loading" :disabled="loading || !valid">
					{{ $t("general.save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		driver: {
			required: true,
		},
		cars: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			loading: false,
			loadingDelete: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			codeRules: [(v) => /^-?\d+\.?\d*$/.test(v) || this.$i18n.t("rules.numbersOnly"), (v) => !!`${v}` || this.$i18n.t("rules.required")],
			driverCredits: this.driver.credits,
			unlimitedCredits: false,
			form: true,
			editing: false,
			valid: true,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}

			if (val) {
				this.driverCredits = this.driver.credits;
			}
		},

		unlimitedCredits(val) {
			if (!val) {
				if (this.driver.credits > 0) {
					this.driverCredits = this.driver.credits;
				} else {
					this.driverCredits = 0;
				}
			} else {
				this.driverCredits = -1;
			}
		},

		driverCredits: {
			immediate: true,
			handler(val) {
				if (val < 0) {
					return (this.unlimitedCredits = true);
				}

				this.unlimitedCredits = false;
			},
		},
	},
	computed: {
		creditsButtonText() {
			if (this.driver.credits < 0) {
				return this.$i18n.t("virtualKeys.credits.unlimited");
			}

			return this.driver.credits;
		},

		creditsHint() {
			return this.$i18n.t("virtualKeys.credits.hint");
		},
	},
	methods: {
		async save() {
			if (this.$refs.form.validate()) {
				this.loading = true;

				try {
					await this.$store.dispatch("updateMember", {
						_id: this.driver._id,
						credits: this.driverCredits,
					});

					const message = this.$i18n.t("virtualKeys.credits.updated");

					this.$toast.success(message);

					this.reset();
				} catch (err) {
					const message = err.error.message;
					this.$toast.error(message);
				} finally {
					this.loading = false;
				}
			}
		},

		reset() {
			this.driverCredits = this.driver.credits;
			this.dialog = false;
			this.$refs.form.resetValidation();
		},
	},
};
</script>

<style>
.unlockCodeInput .v-input__append-outer,
.v-input__prepend-outer {
	margin: 0 !important;
}
</style>
