<template>
	<v-dialog v-model="dialog" max-width="500px">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-if="!isEditMode" small class="mr-2" color="primary" v-bind="attrs" v-on="on"> Add Client </v-btn>
			<v-btn v-else color="accent" class="elevation-2 padlessBtn" outlined small dark v-bind="attrs" v-on="on"> Edit </v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span v-if="isEditMode">Edit Client</span>
				<span v-else>Add Client</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field v-model="editedItem.name" class="mb-4" outlined hide-details dense label="Name" :rules="[rules.required]"></v-text-field>
					<v-text-field v-model="editedItem.apiKey" outlined hide-details dense label="API Key" :rules="[rules.required]">
						<template v-slot:append>
							<v-btn color="primary" class="padlessBtn" depressed small @click="randomUuid()">
								<v-icon small>mdi-reload</v-icon>
							</v-btn>
						</template>
					</v-text-field>
				</v-form>
			</v-card-text>
			<v-card-actions class="mx-4 pb-4">
				<v-spacer></v-spacer>
				<v-btn color="primary" outlined @click="closeDialog">
					{{ $t("general.cancel") }}
				</v-btn>
				<v-btn color="primary" depressed @click="saveClient" :disabled="!valid">
					{{ $t("general.save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import Confirm from "@/components/Confirm.vue";
import { v4 as uuidv4 } from "uuid";

export default {
	components: {
		Confirm,
	},
	props: {
		client: {
			type: Object,
			default: () => null,
		},
	},
	data() {
		return {
			dialog: false,
			editedItem: {},
			valid: false,
			rules: {
				required: (value) => !!value || this.$t("general.required"),
			},
		};
	},
	computed: {
		isEditMode() {
			return this.client !== null;
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal) {
				// Dialog is opened
				this.initializeEditedItem();
			} else {
				// Dialog is closed, reset the editedItem
				this.editedItem = this.getInitialClientData();
			}
		},
	},
	methods: {
		initializeEditedItem() {
			if (this.client) {
				this.editedItem = Object.assign({}, this.client);
			} else {
				this.editedItem = this.getInitialClientData();
			}
		},

		getInitialClientData() {
			return {
				name: "",
				apiKey: uuidv4(),
			};
		},

		randomUuid() {
			this.editedItem.apiKey = uuidv4();
		},

		async saveClient() {
			if (this.$refs.form.validate()) {
				try {
					if (this.isEditMode) {
						if (this.client.apiKey !== this.editedItem.apiKey) {
							const res = await this.$refs.confirm.open("WARNING", "Are you sure you want to change this API Key?", "error", "mdi-exclamation-thick");

							if (res === false) {
								return;
							}
						}

						await this.$store.dispatch("admin/updateClient", { id: this.editedItem._id, body: this.editedItem });
					} else {
						await this.$store.dispatch("admin/createClient", this.editedItem);
					}
					this.closeDialog();
				} catch (err) {
					this.$toast.error(err.error.message);
				}
			}
		},

		closeDialog() {
			this.dialog = false;
			this.$nextTick(() => {
				this.$refs.form.resetValidation();
			});
		},
	},
};
</script>
