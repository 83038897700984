import Vue from "vue";
import Router from "@/router";

export default {
	state: {
		storedRideSessionId: null, // Localstorage session ID if exists

		device: null, // Device data
		site: null, // Reduced site data object
		booking: null, // Current booking if exists
		ride: null, // Current ride if exists
	},

	mutations: {
		SET_RIDE_SESSION_ID(state, payload) {
			state.storedRideSessionId = payload;
		},

		// * Bulk write the device, site, booking, ride data
		SET_RIDE_DATA(state, { device, site, booking, ride }) {
			state.device = device;
			state.site = site;
			state.booking = booking;
			state.ride = ride;
		},

		// * Listen to emitted server 'Device' event
		SOCKET_SESSION(state, updates) {
			state.device = {
				...state.device,
				...updates,
			};
		},

		SET_DEVICE(state, payload) {
			state.device = payload;
		},

		SET_SITE(state, payload) {
			state.site = payload;
		},

		SET_BOOKING(state, payload) {
			state.booking = payload;
		},

		SET_RIDE(state, payload) {
			state.ride = payload;
		},
	},

	actions: {
		// * Saves session id to users localStorage
		setRideSession({ commit }, payload) {
			localStorage.setItem("rideSession", payload);

			commit("SET_RIDE_SESSION_ID", payload);
		},

		// * Removes the locally stored rideSession id
		removeRideSession({ state, commit }) {
			localStorage.removeItem("rideSession");

			commit("SET_RIDE_SESSION_ID", null);

			if (Router.currentRoute.name === "RideSession") {
				Router.push({
					name: "RideSelection",
					params: {
						id: state.device._id,
					},
				});
			}
		},

		//* Set the state equal to the session ID in localStorage
		loadRideSession({ commit }) {
			const rideSession = localStorage.getItem("rideSession");

			commit("SET_RIDE_SESSION_ID", rideSession || null);
		},

		/**
		 * * Populate the site, device, booking & ride state
		 * @param {string} deviceId
		 * @returns
		 */
		async getRideData({ commit }, deviceId) {
			const query = `/ride/${deviceId}`;

			try {
				const { data } = await Vue.prototype.$ioteeApi.get(query);

				commit("SET_RIDE_DATA", data);
				return data;
			} catch (err) {
				throw new Error(err);
			}
		},

		/**
		 * * Check if the user has a session id stored in localStorage
		 * * Remove the ID if it doesnt match the current session
		 */
		async checkForLocalRideSession(context) {
			const { state, dispatch } = context;

			await dispatch("loadRideSession");

			// * If there is no locally stored ID, return
			if (!state.storedRideSessionId) return dispatch("removeRideSession");

			// * If the ride session ID === locally stored ID, redirect to session
			if (state.ride && state.storedRideSessionId === state.ride.session) {
				if (Router.currentRoute.name !== "RideSession") {
					Router.push({
						name: "RideSession",
						params: {
							id: state.device._id,
						},
					});
				}
			}
			// * Else, remove the session ID from localStorage
			else {
				dispatch("removeRideSession");
			}

			return;
		},

		// async handleRideStatus({}) {},

		/**
		 * * Polls the api to check if the ride has started.
		 * * If a ride is found, resolve
		 * * If no ride is found after the defined 'maxRetries', reject
		 * @param {number} maxRetries Number of retries of the interval
		 * @param {string} deviceId  Device ID for the api call
		 * @returns
		 */
		async pollRide({ state, dispatch }, { maxRetries, deviceId }) {
			return new Promise((resolve, reject) => {
				let retries = 0;

				// * Poll the server every 1 second
				const interval = setInterval(async () => {
					console.log("Retries: ", `${retries}/${maxRetries}`);

					try {
						await dispatch("getRideData", deviceId);
					} catch (err) {
						clearInterval(interval);
						reject();
					}

					// * Resolve if a ride is populated
					if (state.ride) {
						clearInterval(interval);
						resolve();
					}
					// * Reject if more than 5 failed retries
					else if (retries >= maxRetries) {
						clearInterval(interval);
						reject();
					}
					// * Add 1 to the retry count
					else {
						retries += 1;
					}
				}, 2000);
			});
		},
	},
};
