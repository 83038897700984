import Vue from "vue";
import * as zonesService from "@/services/zones.service";

export default {
	state: {
		zones: [],
		loading: {
			fetch: false,
			save: false,
			delete: false,
		},
	},

	mutations: {
		setZones(state, payload) {
			state.zones = payload;
		},

		setLoading(state, { type, status }) {
			state.loading[type] = status;
		},
	},

	actions: {
		async getSiteZones({ commit }, query) {
			commit("setLoading", { type: "fetch", status: true });

			try {
				const { data: zones } = await zonesService.getSiteZones(query);
				commit("setZones", zones);
			} catch (err) {
				commit("setZones", []);
			} finally {
				commit("setLoading", { type: "fetch", status: false });
			}
		},

		async createZone({ dispatch, commit }, payload) {
			commit("setLoading", { type: "save", status: true });

			try {
				await zonesService.createZone(payload);
				await dispatch("getSiteZones");
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", { type: "save", status: false });
			}
		},

		async updateZone({ dispatch, commit }, zone) {
			commit("setLoading", { type: "save", status: true });

			try {
				await zonesService.updateZone(zone);
				await dispatch("getSiteZones");
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", { type: "save", status: false });
			}
		},

		async deleteZone({ dispatch, commit }, zoneId) {
			commit("setLoading", { type: "delete", status: true });

			try {
				await zonesService.deleteZone(zoneId);
				await dispatch("getSiteZones");
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", { type: "delete", status: false });
			}
		},

		async publishZones({ commit }, siteId) {
			commit("setLoading", { type: "save", status: true });

			try {
				await zonesService.publishZones(siteId);
				Vue.$toast.success("Zones published successfully");
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", { type: "save", status: false });
			}
		},
	},
};
