<template>
	<div class="gp-full-height">
		<v-toolbar dense class="mapToolbar rounded-xl elevation-12" color="accent">
			<v-tooltip content-class="tooltip-menu" bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn small rounded :outlined="!showCars" @click="showCars = !showCars" color="primary" dark v-bind="attrs" v-on="on">
						<v-icon small dark>mdi-golf-cart</v-icon>
					</v-btn>
				</template>
				<span class="font-weight-bold accent--text">
					{{ $t("mapPage.toggleCarBtn") }}
				</span>
			</v-tooltip>

			<v-btn
				v-if="hasRole('user')"
				:loading="rideHistoryLoading"
				small
				rounded
				:outlined="!showHistoryMenu"
				color="primary"
				class="ml-3"
				@click="showHistoryMenu = !showHistoryMenu"
			>
				History
			</v-btn>

			<v-btn
				v-if="hasRole('user')"
				:loading="zonesLoading.fetch"
				small
				rounded
				:outlined="!showZoneToolbar"
				color="primary"
				class="ml-3"
				@click="showZoneToolbar = !showZoneToolbar"
			>
				Zones
			</v-btn>
		</v-toolbar>

		<zone-editor v-if="map" :showZoneToolbar="showZoneToolbar" :map="map" />
		<history-modal v-if="map" :showHistoryMenu="showHistoryMenu" :map="map" />

		<v-map class="mapComponent" style="z-index: 1" :zoom="zoom" :center="center" ref="map" :options="{ zoomControl: false }" @ready="setMap()">
			<v-tilelayer :url="tileUrl" :options="{ maxZoom: 19 }" />

			<!-- ? ________________ MAP CONTROLS___________________ -->
			<v-control style="margin-top: 12px" position="topright">
				<v-tooltip content-class="tooltip-menu" left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" small @click="recenterMap()" class="mapBtn" color="accent">
							<v-icon color="primary" small>mdi-navigation</v-icon>
						</v-btn>
					</template>
					<span class="accent--text font-weight-bold"> Recenter map </span>
				</v-tooltip>
			</v-control>

			<v-control style="margin-top: 12px" position="topright">
				<v-tooltip content-class="tooltip-menu" left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" small @click="satellite = !satellite" class="mapBtn" :color="satellite ? 'accent' : 'primary'">
							<v-icon :color="satellite ? 'primary' : 'white'" small>mdi-map</v-icon>
						</v-btn>
					</template>
					<span class="accent--text font-weight-bold"> Toggle Sattelite </span>
				</v-tooltip>
			</v-control>

			<v-control style="margin-top: 12px" position="topright">
				<v-tooltip content-class="tooltip-menu" left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" small @click="clusteringEnabled = !clusteringEnabled" class="mapBtn" :color="clusteringEnabled ? 'primary' : 'accent'">
							<v-icon :color="clusteringEnabled ? 'white' : 'primary'" small>
								{{ clusteringEnabled ? "mdi-circle-multiple" : "mdi-circle-outline" }}
							</v-icon>
						</v-btn>
					</template>
					<span class="accent--text font-weight-bold"> Toggle Clustering </span>
				</v-tooltip>
			</v-control>

			<v-marker-cluster v-if="clusteringEnabled && showCars" :options="clusterOptions" :key="selectedDeviceId">
				<v-marker
					v-for="l in devices"
					:key="`${l._id}-${l.lockStatus}-${l.inUse}`"
					:lat-lng="latLong(l.gpsCoordinates)"
					:options="{
						riseOnHover: true,
						pmIgnore: true,
					}"
					:z-index-offset="l._id === selectedDeviceId ? 100 : 0"
					@click="showCarDetail(l._id)"
				>
					<l-icon>
						<car-marker
							:carLockStatus="l.lockStatus"
							:carNumber="l.carNumber"
							:inUse="l.inUse"
							:online="l.onlineStatus"
							:gpsSpeed="l.gpsSpeed"
							:carSelected="l._id === selectedDeviceId"
							:carIcon="l.carIcon"
						/>
					</l-icon>
				</v-marker>
			</v-marker-cluster>

			<moving-marker
				v-else-if="showCars"
				v-for="l in devices"
				:key="`${l._id}-${l.lockStatus}-${l.inUse}`"
				:lat-lng="latLong(l.gpsCoordinates)"
				:options="{
					riseOnHover: true,
					pmIgnore: true,
				}"
				:duration="2000"
				:z-index-offset="l._id === selectedDeviceId ? 100 : 0"
				@click="showCarDetail(l._id)"
			>
				<l-icon>
					<car-marker
						:carLockStatus="l.lockStatus"
						:carNumber="l.carNumber"
						:inUse="l.inUse"
						:online="l.onlineStatus"
						:gpsSpeed="l.gpsSpeed"
						:carSelected="l._id === selectedDeviceId"
						:carIcon="l.carIcon"
					/>
				</l-icon>
			</moving-marker>
		</v-map>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as Vue2Leaflet from "vue2-leaflet";
import L from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import CarMarker from "@/components/map/CarMarker.vue";
import { antPath } from "leaflet-ant-path";
import MovingMarker from "@/components/map/MovingMarker.vue";
import ZoneEditor from "./ZoneEditor.vue";
import HistoryModal from "./HistoryModal.vue";

export default {
	components: {
		"v-map": Vue2Leaflet.LMap,
		"v-tilelayer": Vue2Leaflet.LTileLayer,
		"v-marker": Vue2Leaflet.LMarker,
		"l-icon": Vue2Leaflet.LIcon,
		"v-control": Vue2Leaflet.LControl,
		"v-marker-cluster": Vue2LeafletMarkerCluster,
		CarMarker,
		MovingMarker,
		ZoneEditor,
		HistoryModal,
	},
	data() {
		return {
			// map options
			center: null,
			zoom: 17,
			map: null,

			// map layers
			trailGroup: L.layerGroup(),
			historyGroup: null,

			// map controls
			clusteringEnabled: false,
			showCars: true,
			satellite: true,
			showZoneToolbar: false,
			showHistoryMenu: false,
		};
	},
	watch: {
		clusteringEnabled(newVal) {
			localStorage.clusteringEnabled = JSON.stringify(newVal);
		},

		selectedCar: {
			handler(newCar, oldCar) {
				// Return early if there's no newCar selected
				if (!newCar) {
					this.hideTrails();
					return;
				}

				// Determine if the relevant properties of the car have changed
				const isDifferentCar = !oldCar || newCar._id !== oldCar._id;
				const hasCoordinatesChanged = oldCar && newCar.gpsCoordinates !== oldCar.gpsCoordinates;
				const hasRideEnded = oldCar && oldCar.ride && !newCar.ride;

				// Update the map center if it's a different car or the coordinates have changed
				if (isDifferentCar || hasCoordinatesChanged) {
					const coords = this.latLong(newCar.gpsCoordinates);
					this.updateMapCenter(coords, ...(isDifferentCar ? [19] : [])); // Zoom in if it's a different car
				}

				// Show or hide trails based on the ride status
				// This logic is only relevant if it's a different car or the coordinates have changed
				if (isDifferentCar || hasCoordinatesChanged) {
					if (newCar.ride) {
						this.showTrails(); // Assume showTrails method checks and hides existing trails if necessary
					} else {
						this.hideTrails();
					}
				}

				if (hasRideEnded) {
					this.hideTrails();
				}
			},
			deep: true,
		},
	},
	created() {
		this.center = this.latLong(this.selectedSite.centerLatLong);

		if (localStorage.clusteringEnabled) {
			this.clusteringEnabled = JSON.parse(localStorage.clusteringEnabled);
		}
	},
	mounted() {
		if (this.selectedCar) {
			this.center = this.latLong(this.selectedCar.gpsCoordinates);
			this.$refs.map.mapObject.setView(this.center, 19);
		} else {
			this.recenterMap();
		}
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			selectedRide: (state) => state.rides.selectedRide,
			selectedDeviceId: (state) => state.devices.selectedDeviceId,
			devices: (state) => state.devices.devices,
			zonesLoading: (state) => state.zones.loading,
			rideHistoryLoading: (state) => state.rides.rideHistoryLoading,
		}),

		...mapGetters(["devicesWithRide"]),

		tileUrl() {
			if (this.satellite) return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";

			return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
		},

		selectedCar() {
			return this.devicesWithRide.find((car) => car._id === this.selectedDeviceId);
		},

		clusterOptions() {
			return {
				disableClusteringAtZoom: 19,
				maxClusterRadius: 70,
				spiderfyOnMaxZoom: false,
				spiderLegPolylineOptions: {
					weight: 0,
				},
				iconCreateFunction(cluster) {
					const childCount = cluster.getChildCount();

					let c = " marker-cluster-";
					if (childCount < 10) {
						c += "small";
					} else if (childCount < 100) {
						c += "medium";
					} else {
						c += "large";
					}

					return new L.DivIcon({
						html: "<div class='font-weight-bold'><span>" + childCount + "</span></div>",
						className: "marker-cluster" + c,
						iconSize: new L.Point(40, 40),
					});
				},
			};
		},
	},
	methods: {
		setMap() {
			this.map = this.$refs.map.mapObject;
		},

		showCarDetail(id) {
			this.$store.commit("setSelectedDeviceId", id);
		},

		latLong(coordinates) {
			const [lat, long] = coordinates.split(",");

			return L.latLng(lat, long);
		},

		updateMapCenter(coords, zoomLevel) {
			this.$refs.map.mapObject.setView(coords, zoomLevel);
			this.center = coords;
		},

		recenterMap() {
			const coords = this.latLong(this.selectedSite.centerLatLong);
			this.updateMapCenter(coords, this.zoom);
		},

		async showTrails() {
			// First, remove the existing trails from the map to avoid accumulation.
			if (this.trailGroup) {
				this.trailGroup.clearLayers();
			}

			const options = {
				color: "#7998fb",
				pulseColor: "#406dfa",
				delay: 4000,
				opacity: 1,
				pmIgnore: true,
			};

			if (this.selectedCar.ride) {
				// Assuming this.selectedCar.ride.history.coordinates is an array of [lat, lng] coordinates.
				await this.$store.dispatch("rides/getRide", this.selectedCar.ride._id);

				// Add the new trail to the cleared trailGroup.
				this.trailGroup.addLayer(antPath(this.selectedRide.history.coordinates, options));
				this.trailGroup.addTo(this.$refs.map.mapObject); // Ensure the trailGroup is added to the map.
			}
		},

		hideTrails() {
			this.trailGroup.clearLayers();
			this.trailGroup?.removeFrom(this.$refs.map.mapObject);
		},
	},
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.mapComponent {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
}

.mapToolbar {
	position: absolute !important;
	margin-left: auto;
	margin-right: auto;
	right: 0;
	left: 0;
	top: 8px;
	z-index: 1000;
	width: max-content;
}

.tooltip-menu {
	background-color: #ffffff !important;
	opacity: 1;
	-webkit-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	-moz-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
}

.pill {
	display: inline-flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 25px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.marker-cluster-small {
	background-color: #233d89;
	color: #ffffff;
}
.marker-cluster-medium {
	background-color: #233d89;
	color: #ffffff;
}
.marker-cluster-large {
	background-color: #233d89;
	color: #ffffff;
}

.marker-cluster-small div {
	background-color: #406dfa;
	color: #ffffff;
}
.marker-cluster-medium div {
	background-color: #406dfa;
	color: #ffffff;
}
.marker-cluster-large div {
	background-color: #406dfa;
	color: #ffffff;
}
</style>
