<template>
  <v-container fill-height>
    <v-row class="justify-center align-center">
      <v-col cols="8" class="mx-auto text-center">
        <v-progress-circular
          :size="150"
          :width="7"
          color="primary"
          indeterminate
        >
          <v-img
            class="mx-2"
            src="../../public/Logo.png"
            max-height="60"
            max-width="60"
            contain
          ></v-img
        ></v-progress-circular>
      </v-col>
      <v-col v-if="msg" cols="12" class="text-center">
        <span class="accent--text font-weight-bold">{{ msg }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    msg: {
      default: null,
    },
  },
};
</script>
