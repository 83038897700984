<template>
	<v-container fluid v-resize="onResize">
		<v-row>
			<v-col cols="12" class="px-0">
				<v-data-table
					class="admin-fleet-table"
					:headers="headers"
					:items="invitedUsers"
					:search="search"
					item-key="_id"
					sort-by="_id"
					:mobile-breakpoint="0"
					:height="$vuetify.breakpoint.xs ? windowSize.y - 240 : windowSize.y - 200"
					fixed-header
					:page.sync="page"
					:header-props="{
						'sort-icon': 'mdi-menu-down',
					}"
					:items-per-page="itemsPerPage.val"
					hide-default-footer
					@page-count="pageCount = $event"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title class="accent--text">
								Invites
								<v-chip label class="primary font-weight-bold">
									{{ invitedUsers.length }}
								</v-chip>
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<new-user-modal @updated="getInvitations()"></new-user-modal>
							<v-btn small class="primary mr-2 padlessBtn" depressed :loading="refreshLoading" @click="refresh">
								<v-icon small>mdi-refresh</v-icon>
							</v-btn>
							<v-text-field v-model="search" append-icon="mdi-magnify" label="Search Users" single-line outlined dense hide-details style="max-width: 300px"></v-text-field>
						</v-toolbar>
					</template>

					<template v-slot:item.token="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-chip label small @click.stop="copyText(invitationLink(item))" v-bind="attrs" v-on="on">
									{{ invitationLink(item) }}
								</v-chip>
							</template>
							<span>Copy Link</span>
						</v-tooltip>
					</template>

					<template v-slot:item.sentAt="{ item }">
						<v-chip v-if="item.sentAt" small label color="primary" class="font-weight-bold">
							{{ emailLastSent(item.sentAt) }}
						</v-chip>
					</template>

					<template v-slot:item.sites="{ item }">
						<div v-if="item.role === 'superadmin'">
							<v-chip x-small label class="mr-2 font-weight-bold" color="success"> All Sites </v-chip>
						</div>
						<div v-else>
							<v-chip v-for="site in item.meta.sites" :key="site" x-small label class="mr-2 font-weight-bold" color="accent">
								{{ computedSite(site) }}
							</v-chip>
						</div>
					</template>

					<template v-slot:item.edit="{ item }">
						<div class="no-wrap">
							<div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn small class="mr-2 padlessBtn" color="primary" dark depressed v-bind="attrs" v-on="on" @click="resendInvite(item._id)">
											<v-icon small>mdi-undo</v-icon>
										</v-btn>
									</template>
									<span>Send Invite</span>
								</v-tooltip>

								<v-btn small class="padlessBtn" color="error" @click="deleteInvite(item._id)">
									<v-icon small depressed color="white"> mdi-trash-can-outline </v-icon>
								</v-btn>
							</div>
						</div>
					</template>
				</v-data-table>

				<confirm ref="confirm" />
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%" :style="$vuetify.breakpoint.xs ? `bottom: 56px;` : 'bottom: 0;'">
			<v-toolbar flat dense class="mx-3 rounded-t-lg">
				<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

				<v-select
					class="perPage"
					v-model="itemsPerPage"
					:items="items"
					item-text="text"
					item-value="val"
					label="Select"
					hide-details
					return-object
					single-line
					dense
					outlined
				></v-select>
			</v-toolbar>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import NewUserModal from "@/components/admin/users/NewUserModal.vue";
import Confirm from "@/components/Confirm";

import { format } from "date-fns";

export default {
	components: {
		NewUserModal,
		Confirm,
	},
	data() {
		return {
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
			page: 1,
			pageCount: 0,
			windowSize: {
				x: 0,
				y: 0,
			},
			search: "",
			tooltip: false,
			headers: [
				{ text: "Email", value: "email" },
				{ text: "Link", value: "token" },
				{ text: "Last Sent", value: "sentAt" },
				{ text: "Sites", value: "sites" },
				{ text: "Role", value: "meta.role" },
				{
					text: "Actions",
					value: "edit",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
			],
			refreshLoading: false,
		};
	},
	mounted() {
		this.getInvitations();
	},
	watch: {
		page() {
			this.selected = [];
		},

		itemsPerPage(newVal) {
			localStorage.carsPerPage = JSON.stringify(newVal);
		},
	},
	computed: {
		...mapState({
			sites: (state) => state.admin.sites.sites,
			invitedUsers: (state) => state.admin.users.invitedUsers,
		}),
	},
	methods: {
		invitationLink(item) {
			const { token, email } = item;

			return `${process.env.VUE_APP_BASE_APP_URL}/login/register?token=${token}&email=${email}`;
		},

		async copyText(text) {
			await navigator.clipboard.writeText(text);

			const message = "Copied to clipboard";
			this.$toast.info(message);
		},

		emailLastSent(sent) {
			return format(new Date(sent), "dd/MM/yyyy HH:mm");
		},

		async getInvitations() {
			try {
				await this.$store.dispatch(`admin/getInvitedUsers`);
			} catch (err) {
				const message = err.error.message;
				this.$toast.error(message);
			}
		},

		computedSite(id) {
			const site = this.sites.find((site) => site._id === id);

			if (site) {
				return site.name;
			}
		},

		async deleteInvite(id) {
			const res = await this.$refs.confirm.open(`Delete Invite?`, "", "error", "mdi-trash-can-outline");

			if (res !== true) return;

			try {
				await this.$store.dispatch(`admin/deleteInvitedUser`, id);

				this.$toast.success("Invite Deleted");
			} catch (err) {
				const message = err.response.data.message;
				this.$toast.error(message);
			} finally {
				this.refresh();
			}
		},

		async resendInvite(id) {
			const res = await this.$refs.confirm.open(`Resend Invite?`, "", "warning", "mdi-forward");

			if (res === true) {
				try {
					await this.$ioteeApi.post(`/auth/admin/invites/${id}/resend`);

					this.$toast.success("Invite Resent");
				} catch (err) {
					const message = err.response.data.message;
					this.$toast.error(message);
				} finally {
					this.refresh();
				}
			}
		},

		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		async refresh() {
			this.refreshLoading = true;

			try {
				this.getInvitations();

				this.refreshLoading = false;
			} catch (err) {
				const message = err;
				this.$toast.error(message);
			}
		},
	},
};
</script>
