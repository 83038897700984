<template>
  <v-container fluid class="fill-height pa-0">
    <v-row align="center" justify="center" class="fill-height" no-gutters>
      <!-- Left content -->
      <v-col cols="12" md="6" class="hidden-sm-and-down loginBg">
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-col class="white--text text-center">
              <span class="display-2 font-weight-bold">
                {{ $t("loginPage.title") }}
              </span>
              <br />
              <span class="subtitle-1 font-weight-bold">
                {{ $t("loginPage.subtitle") }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <!-- Right content -->
      <v-col cols="12" md="6" style="height: 100%">
        <v-container fill-height fluid>
          <v-row>
            <v-col cols="12">
              <v-img
                class="mx-auto mb-14"
                src="../../../public/Logo.png"
                max-height="90"
                max-width="90"
              ></v-img>
            </v-col>

            <router-view></router-view>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <locale-select />
  </v-container>
</template>

<script>
import LocaleSelect from "@/components/localeSelect";

export default {
  components: {
    LocaleSelect,
  },
};
</script>

<style lang="scss">
.loginBg {
  background-image: url("../../../public/Background.png") !important;
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
