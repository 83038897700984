<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<span v-if="fab">
				<v-btn v-bind="attrs" v-on="on" fab elevation="0" :color="lockStatus(device.lockStatus, bookings, currentBooking).color" :disabled="btnDisabled">
					<v-icon>
						{{ lockStatus(device.lockStatus, bookings, currentBooking).icon }}
					</v-icon>
				</v-btn>
				<p class="caption font-weight-bold">
					{{ deviceLocked ? $t("general.unlock") : $t("general.lock") }}
				</p>
			</span>

			<v-chip
				v-else
				class="font-weight-bold mr-2 elevation-2"
				:color="lockStatus(device.lockStatus, bookings, currentBooking).color"
				dark
				small
				label
				:disabled="btnDisabled"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon small>{{ lockStatus(device.lockStatus, bookings, currentBooking).icon }}</v-icon>
			</v-chip>
		</template>

		<!-- For Unlocked Car -->
		<v-card v-if="!deviceLocked" outlined class="rounded-xl elevation-24 text-center">
			<v-icon class="text-center mt-2" left> mdi-lock-outline </v-icon>
			<v-card-text class="title font-weight-bold text-center mt-2">
				<span>{{ this.$i18n.t("general.lock") }}</span>
			</v-card-text>

			<v-card-text class="font-weight-bold text-center">
				<v-chip label color="accent" outlined>
					{{ `${this.$i18n.t("carDetail.car")} ${device.carNumber}` }}
				</v-chip>
			</v-card-text>

			<v-card-text>
				<v-btn block rounded color="error" @click="lockDevice()" :loading="loading">
					{{ $t("general.yes") }}
				</v-btn>
				<v-btn block rounded outlined color="accent" class="mt-3" @click="dialog = false">
					{{ $t("general.no") }}
				</v-btn>
			</v-card-text>
		</v-card>

		<v-card v-else-if="currentBooking && currentBooking.bookingType === 'cart_maintenance'" outlined class="rounded-xl elevation-24 text-center">
			<v-icon class="text-center mt-2" left> mdi-wrench </v-icon>
			<v-card-text class="title font-weight-bold text-center mt-2"> Maintenance </v-card-text>
			<v-card-text class="font-weight-bold text-center">
				<v-chip label color="accent" outlined>
					{{ `${this.$i18n.t("carDetail.car")} ${device.carNumber}` }}
				</v-chip>
			</v-card-text>
			<v-card-text class="subtitle-2 font-weight-bold text-center"> This cart is blocked for maintenance. You can still unlock it. </v-card-text>

			<v-card-text>
				<v-btn block rounded color="primary" class="font-weight-bold" @click="unlockMaintenance" :loading="loading">
					{{ $t("general.unlock") }}
				</v-btn>
			</v-card-text>
		</v-card>

		<v-card v-else-if="bookings && !showForm" outlined class="rounded-xl elevation-24 text-center">
			<v-icon class="text-center mt-2" left> mdi-calendar-alert </v-icon>
			<v-card-text class="title font-weight-bold text-center mt-2"> Upcoming Bookings </v-card-text>

			<v-card-text class="font-weight-bold text-center">
				<v-chip label color="accent" outlined>
					{{ `${this.$i18n.t("carDetail.car")} ${device.carNumber}` }}
				</v-chip>
			</v-card-text>

			<v-card-text>
				<v-list nav two-line>
					<v-list-item-group v-model="selectedBooking" mandatory>
						<v-list-item
							v-for="(booking, i) in bookings"
							:key="i"
							:value="booking"
							active-class="primary--text font-weight-bold"
							:disabled="bookings.length === 1"
							class="text-left"
						>
							<template v-slot:default="{ active }">
								<v-list-item-avatar>
									<v-icon class="grey lighten-1"> mdi-calendar </v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>
										{{ booking.name }}
									</v-list-item-title>
									<v-list-item-title>
										{{ translatedFormattedDate(booking.start, "HH:mm") }} -
										{{ translatedFormattedDate(booking.end, "HH:mm") }}
									</v-list-item-title>
									<v-list-item-title class="caption" v-if="!booking.isBookingNow">
										Available from
										{{ startWithBookingOffset(booking.start) }}
									</v-list-item-title>
								</v-list-item-content>

								<v-list-item-action>
									<v-checkbox :input-value="active" color="primary"></v-checkbox>
								</v-list-item-action>
							</template>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-card-text>
			<v-card-text>
				<v-btn block rounded color="primary" class="font-weight-bold" @click="unlockWithBooking" :loading="loading">
					{{ $t("general.unlock") }}
				</v-btn>

				<v-btn @click="showForm = true" block rounded text color="accent" class="mt-3 text-decoration-underline"> {{ $t("bookings.unlockWithoutBooking") }}... </v-btn>
			</v-card-text>
		</v-card>

		<v-card v-else-if="showForm || !bookings" outlined class="rounded-xl elevation-24 text-center">
			<v-icon class="text-center mt-2" left> mdi-lock-open-outline </v-icon>
			<v-card-text class="title font-weight-bold text-center mt-2">
				<span>{{ this.$i18n.t("general.unlock") }}</span>
			</v-card-text>

			<v-card-text class="font-weight-bold text-center">
				<v-chip label color="accent" outlined>
					{{ `${this.$i18n.t("carDetail.car")} ${device.carNumber}` }}
				</v-chip>
			</v-card-text>

			<v-form @submit.prevent="toggleLock">
				<v-card-text class="pt-0">
					<v-list-item class="text-left">
						<v-list-item-content class="pa-0">
							<v-list-item-title class="font-weight-bold caption">
								{{ $t("carDetail.driverName") }}: <span class="font-weight-light" style="font-size: 8px !important">(optional)</span>
							</v-list-item-title>
							<v-list-item-subtitle class="pt-1">
								<v-combobox
									v-model="selectedDriver"
									:items="members"
									@update:search-input="membersSearch = $event"
									prepend-inner-icon="mdi-account"
									color="primary"
									item-text="name"
									:return-object="true"
									:menu-props="{ offsetY: true }"
									outlined
									dense
									hide-details="auto"
									hide-no-data
									:loading="loadingMembers"
									clearable
									ref="driverNameInput"
								>
								</v-combobox>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-list-item class="text-left mt-2">
						<v-list-item-content class="pa-0">
							<v-list-item-title class="font-weight-bold caption"
								>{{ $t("services.service") }}: <span class="font-weight-light" style="font-size: 8px !important">(optional)</span>
							</v-list-item-title>
							<v-list-item-subtitle class="pt-1">
								<v-select
									v-model="selectedService"
									item-text="name"
									return-object
									:items="services"
									:placeholder="$t('services.serviceSelect')"
									:menu-props="{ offsetY: true }"
									outlined
									dense
									single-line
									hide-details
									class="mb-1"
									clearable
								>
									<template v-slot:append-item>
										<v-list-item>
											<v-list-item-content>
												<v-btn small block color="primary" to="/settings/booking?addService=true">
													<v-icon small left>mdi-plus</v-icon>
													{{ $t("services.addService") }}
												</v-btn>
											</v-list-item-content>
										</v-list-item>
									</template>
								</v-select>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-list-item class="text-left mt-2" v-if="selectedService && selectedService.prices.length > 0">
						<v-list-item-content class="pa-0">
							<v-list-item-title class="font-weight-bold caption">
								{{ $t("services.price") }}: <span class="font-weight-light" style="font-size: 8px !important">(optional)</span>
							</v-list-item-title>
							<v-list-item-subtitle class="pt-1">
								<v-select
									v-model="selectedPrice"
									item-text="name"
									return-object
									:items="selectedService.prices"
									:placeholder="$t('services.priceSelect')"
									:menu-props="{ offsetY: true }"
									outlined
									dense
									single-line
									hide-details
									class="mb-1"
									clearable
								>
								</v-select>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-card-text>
				<v-card-text>
					<v-btn block rounded color="primary" class="font-weight-bold" @click="unlockDevice" :loading="loading">
						{{ $t("general.unlock") }}
					</v-btn>

					<v-btn v-if="bookings" @click="showForm = false" block rounded text color="accent" class="mt-3 text-decoration-underline">
						{{ $t("bookings.unlockWithBooking") }}...
					</v-btn>
				</v-card-text>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { subMinutes } from "date-fns";

export default {
	props: {
		fab: {
			type: Boolean,
			default: false,
		},
		device: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showForm: false,
			dialog: false,
			selectedBooking: null,
			loading: false,
			loadingMembers: false,
			selectedDriver: "",
			tags: [],
			membersSearch: "",
			selectedService: null,
			selectedPrice: null,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.close();
			}

			if (this.bookings) {
				this.selectedBooking = this.currentBooking || this.bookings[0];
			}
		},

		membersSearch(val) {
			setTimeout(() => {
				if (val === this.membersSearch) {
					this.getMembers({ name: val });
				}
			}, 700);
		},
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			services: (state) => state.sites.services,
			user: (state) => state.auth.user,
			members: (state) => state.members.members,
		}),

		...mapGetters(["futureAndCurrentBookings"]),

		btnDisabled() {
			return !this.isOnline(this.device) || !this.hasRole("basic") || this.device.key === "1" || !this.isKeylessSite();
		},

		bookings() {
			return this.futureAndCurrentBookings[this.device._id];
		},

		deviceLocked() {
			return this.device.lockStatus ? 1 : 0;
		},

		driverName() {
			return typeof this.selectedDriver === "object" ? this.selectedDriver.name : this.selectedDriver;
		},

		driverID() {
			return typeof this.selectedDriver === "object" ? this.selectedDriver._id : null;
		},

		driverEmail() {
			return typeof this.selectedDriver === "object" ? this.selectedDriver.email : null;
		},

		lockIcon() {
			return this.device.bookings ? "mdi-calendar-lock-open" : "mdi-lock-open";
		},

		currentBooking() {
			return this.bookings?.find((booking) => booking.isBookingNow);
		},
	},
	methods: {
		startWithBookingOffset(start) {
			const offsetStart = subMinutes(new Date(start), this.selectedSite.bookingOffset);
			return this.translatedFormattedDate(offsetStart, "HH:mm");
		},

		close() {
			this.showForm = false;
			this.selectedBooking = null;
			this.driversList = null;
			this.selectedDriver = "";
			this.selectedService = null;
			this.selectedPrice = null;
			this.tags = [];
		},

		async getMembers(query) {
			this.loadingMembers = true;

			try {
				await this.$store.dispatch("getMembers", query);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingMembers = false;
			}
		},

		async unlockWithBooking() {
			this.loading = true;
			try {
				await this.$store.dispatch("unlockWithBooking", this.selectedBooking._id);

				const message = this.$t("general.commandSent");
				this.$toast.success(message);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.dialog = false;
				this.loading = false;
			}
		},

		async lockDevice() {
			this.loading = true;

			try {
				await this.$store.dispatch("lockDevice", this.device._id);

				const message = this.$t("general.commandSent");
				this.$toast.success(message);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
				this.dialog = false;
			}
		},

		async unlockMaintenance() {
			this.loading = true;

			const rideData = {
				device: this.device._id,
				site: this.device.siteID,
			};

			try {
				await this.$store.dispatch("unlockDevice", {
					id: this.device._id,
					body: rideData,
				});

				const message = this.$t("general.commandSent");

				this.$toast.success(message);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
				this.dialog = false;
			}
		},

		async unlockDevice() {
			this.loading = true;

			this.$refs.driverNameInput.blur();

			this.$nextTick(async () => {
				const rideData = {
					device: this.device._id,
					site: this.device.siteID,
					driver: this.driverID || null,
					name: this.driverName || null,
					email: this.driverEmail || null,
					unlockedBy: this.user?.email,
					service: this.selectedService?._id || null,
					price: this.selectedPrice?._id || null,
				};

				console.log(rideData);

				try {
					await this.$store.dispatch("unlockDevice", {
						id: this.device._id,
						body: rideData,
					});

					const message = this.$t("general.commandSent");

					this.$toast.success(message);
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.loading = false;
					this.dialog = false;
				}
			});
		},
	},
};
</script>
