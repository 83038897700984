<template>
	<v-dialog v-model="dialog" max-width="290" width="290" persistent>
		<v-card outlined class="rounded-xl elevation-24 text-center">
			<v-icon class="text-center mt-2" left>{{ icon }}</v-icon>
			<v-card-text v-if="title" class="title font-weight-bold text-center mt-2">
				<span>{{ title }}</span>
			</v-card-text>
			<v-card-text v-if="message" class="font-weight-bold text-center">
				{{ message }}
			</v-card-text>
			<v-card-text v-if="note" class="font-weight-regular text-center">
				{{ note }}
			</v-card-text>
			<v-card-text>
				<v-hover v-slot="{ hover }">
					<v-btn block rounded :color="color" class="mt-3" :elevation="hover ? 12 : 0" @click="yes">
						{{ yesTextTranslated }}
					</v-btn>
				</v-hover>
				<v-hover v-slot="{ hover }">
					<v-btn block rounded outlined color="accent" class="mt-3" :elevation="hover ? 12 : 0" @click="no">
						{{ noTextTranslated }}
					</v-btn>
				</v-hover>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		yesText: {
			type: String,
			default: "general.yes", // Use a default key
		},
		noText: {
			type: String,
			default: "general.no", // Use a default key
		},
	},
	data() {
		return {
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			color: null,
			icon: null,
			note: null,
		};
	},
	computed: {
		yesTextTranslated() {
			return this.$t(this.yesText);
		},
		noTextTranslated() {
			return this.$t(this.noText);
		},
	},
	methods: {
		open(title, message, color, icon, note) {
			this.dialog = true;
			this.title = title;
			this.message = message;
			this.color = color;
			this.icon = icon;
			note ? (this.note = note) : (this.note = null);
			return new Promise((resolve, reject) => {
				this.resolve = resolve;
				this.reject = reject;
			});
		},
		yes() {
			this.resolve(true);
			this.dialog = false;
		},
		no() {
			this.resolve(false);
			this.dialog = false;
		},
	},
};
</script>
