<template>
	<v-app class="gp-full-height">
		<app-layout>
			<router-view />
		</app-layout>
	</v-app>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import { mapState } from "vuex";
import debounce from "lodash/debounce";

export default {
	name: "App",
	metaInfo: {
		title: "IOTee",
		meta: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
			},
		],
	},
	components: {
		AppLayout,
	},
	data() {
		return {
			toastID: null,
		};
	},
	mounted() {
		this.setViewHeight();

		const debouncedSetHeight = debounce(this.setViewHeight, 50);

		window.addEventListener("resize", debouncedSetHeight);

		this.$once("destroyed", () => {
			window.removeEventListener("resize", debouncedSetHeight);
		});
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),
	},
	created() {
		const locale = localStorage.getItem("locale");

		if (locale) {
			this.$i18n.locale = locale;
		} else if (navigator.language) {
			this.$i18n.locale = navigator.language.substring(0, 2);
		}
	},
	sockets: {
		connect() {
			console.log("✅ Socket.io Connected");

			this.$socket.client.emit("join:site", this.selectedSite._id);

			this.$socket.client.emit("get:site:devices", this.selectedSite._id);
			this.$socket.client.emit("get:site:queue", this.selectedSite._id);
			this.$socket.client.emit("get:site:activerides", this.selectedSite._id);

			this.$toast.dismiss(this.toastID);

			this.toastID = null;
		},

		disconnect() {
			console.log("❌ Socket.io Disconnected");

			if (!this.toastID) {
				this.toastID = this.$toast.warning("Disconnected. Trying to reconnect...", {
					timeout: false,
					closeOnClick: false,
					closeButton: false,
					draggable: false,
				});
			}
		},
	},
	methods: {
		setViewHeight() {
			const vh = window.innerHeight * 0.01;
			const vw = window.innerWidth * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
			document.documentElement.style.setProperty("--vw", `${vw}px`);
		},
	},
};
</script>

<style>
.mainHeight {
	/* If you need to support browser without CSS var support (<= IE11) */
	/* height: 100%; */

	height: calc(100vh - var(--vh-offset, 48px)) !important;
}

.gp-full-height {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
}

.fleet-table.v-data-table td {
	border-bottom: none !important;
}

.admin-fleet-table.v-data-table td {
	border-right: thin solid rgba(0, 0, 0, 0.12) !important;
}

.bg {
	background-color: #f5f7fe !important;
}

.v-btn {
	text-transform: capitalize !important;
}

.v-btn:focus::before {
	opacity: 0 !important;
}

.v-card:focus::before {
	opacity: 0 !important;
}

.v-dialog {
	z-index: 1002 !important;
}

.padlessBtn {
	padding-left: 9px !important;
	padding-right: 9px !important;
	min-width: 0 !important;
}
</style>

<style scoped lang="scss">
/**
    Overwrite vuetify's default v-application--wrap min-height: 100vh;
    Due to the many problems with vh on mobile devices.
   */
.gp-full-height ::v-deep .v-application--wrap {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
}

// ::-webkit-scrollbar {
//   display: none;
// }
</style>
