<template>
	<v-container fluid class="bg" style="height: 100%">
		<v-tabs class="tabs" show-arrows>
			<v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route">
				{{ tab.name }}
			</v-tab>
		</v-tabs>
		<router-view></router-view>
		<device-sidebar />
	</v-container>
</template>

<script>
import DeviceSidebar from "@/components/admin/sidebar/DeviceSidebar";

export default {
	components: {
		DeviceSidebar,
	},
	data() {
		return {
			tabs: [
				{ id: 4, name: "Dashboard", route: `/admin/dashboard` },
				{ id: 1, name: "Device Manager", route: `/admin/devices` },
				{ id: 2, name: "User Manager", route: `/admin/users` },
				{ id: 3, name: "Invites Manager", route: `/admin/invites` },
				{ id: 5, name: "Site Manager", route: `/admin/sites` },
				{ id: 6, name: "Clients", route: `/admin/clients` },
				{ id: 7, name: "Activity Viewer", route: `/admin/activity` },
			],
			client: null,
		};
	},
	async beforeDestroy() {
		this.$store.commit("setDeviceSidebar", false);
	},
};
</script>
