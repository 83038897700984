import Vue from "vue";

Vue.mixin({
	data() {
		return {
			statusData: {
				pending: {
					color: "warning",
					icon: "mdi-progress-download",
				},
				upToDate: {
					color: "primary",
					icon: "mdi-check-circle-outline",
				},
			},
			updateTypes: {
				lock: "Lock Status",
				ds: "Device Settings",
				ss: "Site Settings",
				code: "Keypad/RFID Codes",
			},
		};
	},
	methods: {
		updateStatus(deviceUpdates) {
			const hasUpdates = deviceUpdates && deviceUpdates.length;
			const status = hasUpdates ? this.statusData.pending : this.statusData.upToDate;
			return {
				...status,
				text: this.$i18n.t(hasUpdates ? "fleetPage.table.pending" : "fleetPage.table.upToDate"),
			};
		},

		updateMessageText(deviceUpdates) {
			const hasUpdates = deviceUpdates && deviceUpdates.length;
			if (!hasUpdates) return this.$i18n.t("fleetPage.table.upToDate");

			const names = deviceUpdates.map((update) => this.updateTypes[update.type] || "");
			const str = names.join(", ");
			return `${this.$i18n.t("fleetPage.table.pending")}: ${str}`;
		},
	},
});
