<template>
	<v-container fluid class="bg pa-0 gp-full-height">
		<v-navigation-drawer v-model="drawer" app left class="accent lighten-1" :style="$vuetify.breakpoint.smAndUp && `margin-left: 56px`">
			<v-list nav color="transparent" dark>
				<v-subheader class="white--text font-weight-bold title my-2">
					Reports
					<v-spacer></v-spacer>
					<v-btn :href="helpURL" target="blank" dark icon>
						<v-icon>mdi-help-box</v-icon>
					</v-btn>
				</v-subheader>

				<v-list-item-group>
					<v-list-item class="white--text" to="/charts/usage" exact>
						<v-list-item-avatar>
							<v-icon>mdi-golf-cart</v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title class="font-weight-bold"> {{ $t("usage.title") }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item class="white--text" to="/charts/revenue" exact>
						<v-list-item-avatar>
							<v-icon>mdi-cash</v-icon>
						</v-list-item-avatar>

						<v-list-item-content>
							<v-list-item-title class="font-weight-bold"> Revenue </v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<v-toolbar flat color="white">
			<v-btn @click="drawer = !drawer" small icon class="mr-4 ml-2"><v-icon color="accent">mdi-menu</v-icon></v-btn>
			<span class="text-h5 font-weight-bold accent--text">
				{{ title }}
			</span>
			<v-spacer></v-spacer>

			<v-dialog ref="dialog" v-model="dateMenu" :persistent="!dateRange[1]" width="290px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn small label="Date" color="primary" class="font-weight-bold" depressed v-bind="attrs" v-on="on">
						<v-icon left>mdi-calendar-month-outline</v-icon>
						{{ formattedFirstDay }}
						<v-icon small class="mx-2">mdi-arrow-right</v-icon>
						{{ formattedLastDay }}
					</v-btn>
				</template>
				<v-date-picker v-model="dateRange" range color="accent" show-current :locale="$i18n.locale" scrollable no-title @change="dateMenu = false" />
			</v-dialog>
		</v-toolbar>
		<router-view :dateRange="dateRangeISO"></router-view>
	</v-container>
</template>

<script>
import { format, startOfMonth, endOfMonth, startOfDay, endOfDay } from "date-fns";
import { de, da } from "date-fns/locale";

const locales = { de, da };

export default {
	data() {
		return {
			drawer: true,
			dateMenu: false,
			dateRange: [format(startOfMonth(new Date()), "yyyy-MM-dd"), format(endOfMonth(new Date()), "yyyy-MM-dd")],
		};
	},
	computed: {
		title() {
			return this.$route.meta.title;
		},

		dateRangeISO() {
			return {
				start: this.dateRange[0] ? startOfDay(new Date(this.dateRange[0])).toISOString() : null,
				end: this.dateRange[1] ? endOfDay(new Date(this.dateRange[1])).toISOString() : null,
			};
		},

		formattedFirstDay() {
			if (this.dateRange[0]) {
				return format(new Date(this.dateRange[0]), "dd MMMM", {
					locale: locales[this.$i18n.locale],
				});
			}

			return "";
		},

		formattedLastDay() {
			if (this.dateRange[1]) {
				return format(new Date(this.dateRange[1]), "dd MMMM", {
					locale: locales[this.$i18n.locale],
				});
			}

			return "?";
		},

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/article/erste-schritte-mit-berichten-1s4m36f/";

				default:
					return "https://help.iotee.eu/en/article/getting-started-with-reports-1u9rqx9/";
			}
		},
	},
};
</script>
