<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<v-card outlined>
					<v-card-title>
						<span class="subtitle-1 font-weight-bold d-flex align-center">
							<v-icon left color="accent">mdi-golf-cart</v-icon>
							{{ selectedDevice ? `Cart ${selectedDevice.carNumber}` : $t("general.selectCart") }}
						</span>
						<v-spacer></v-spacer>
						<v-slide-x-transition>
							<v-btn v-if="selectedDevice" small icon @click="selectedDevice = null">
								<v-icon>mdi-close-circle</v-icon>
							</v-btn>
						</v-slide-x-transition>
					</v-card-title>

					<v-expand-transition v-if="devices.length">
						<v-card-text v-show="!selectedDevice">
							<v-item-group v-model="selectedDevice" active-class="primary lighten-4 primary--text" class="mt-4">
								<v-item :value="item" v-for="(item, index) in sortedDevices" :key="index">
									<template v-slot="{ active, toggle }">
										<v-card :elevation="active ? '8' : '0'" :outlined="!active" :style="active && 'border: 2px solid #3F6DFA !important'" class="rounded-lg mb-4" @click="toggle">
											<v-list-item>
												<v-list-item-action>
													<v-checkbox :input-value="active" color="primary accent-4" on-icon="mdi-circle-slice-8" off-icon="mdi-circle-outline"></v-checkbox>
												</v-list-item-action>

												<v-list-item-content>
													<v-list-item-title class="font-weight-bold"> Cart {{ item.carNumber }} </v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-card>
									</template>
								</v-item>
							</v-item-group>
						</v-card-text>
					</v-expand-transition>
				</v-card>

				<v-card v-if="device && device.session" outlined class="mt-4">
					<v-card-text class="text-center">
						<div class="subtitle-1 font-weight-bold success--text">
							<v-icon class="blink" color="success" left> mdi-check-circle </v-icon>
							{{ $t("s2r.cartUnlocked") }}
						</div>
					</v-card-text>
					<v-card-text>
						<v-btn color="success" class="font-weight-bold rounded-lg" block @click="toggleLockDevice" :loading="loadingLock"> {{ $t("s2r.lockCart") }} </v-btn>
					</v-card-text>
				</v-card>

				<v-card outlined class="mt-4" v-else-if="device">
					<v-card-text>
						<v-btn color="success" class="font-weight-bold rounded-lg" block @click="toggleLockDevice" :loading="loadingLock">
							{{ $t("s2r.unlockCart") }}
						</v-btn>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			loading: true,
			loadingLock: false,
			selectedDevice: null,
		};
	},
	watch: {
		selectedDevice(val) {
			if (val) {
				this.getDevice();
			} else {
				this.$store.commit("guest/devices/setDevice", null);
			}
		},
	},
	async mounted() {
		await this.getVirtualKey();
		this.getSiteDevices();
	},
	computed: {
		...mapState({
			site: (state) => state.guest.sites.site,
			virtualKey: (state) => state.guest.sites.virtualKey,
			devices: (state) => state.guest.devices.devices,
			device: (state) => state.guest.devices.device,
		}),

		sortedDevices() {
			return this.sortDevices(this.devices);
		},
	},
	methods: {
		async getVirtualKey() {
			if (this.virtualKey && this.virtualKey._id === this.$route.params.virtualKeyId) return;

			this.loadingVirtualKey = true;
			try {
				await this.$store.dispatch("guest/sites/getVirtualKey", {
					siteId: this.site._id,
					query: {
						_id: this.$route.params.virtualKeyId,
						master: true,
					},
				});
			} catch (err) {
				this.$toast.error("An error occurred");
				this.clearVirtualKey();
			} finally {
				this.loadingVirtualKey = false;
			}
		},

		clearVirtualKey() {
			this.$store.commit("guest/sites/setVirtualKey", null);
			this.$router.push({ name: "guest.site", params: { id: this.site._id } });
		},

		sortDevices(devices) {
			return devices.sort((a, b) => a.carNumber - b.carNumber);
		},

		async getDevice() {
			try {
				await this.$store.dispatch("guest/devices/getDevice", this.selectedDevice._id);
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred while fetching device details");
			}
		},

		async getSiteDevices() {
			this.loading = true;

			try {
				await this.$store.dispatch("guest/devices/getDevices", this.site._id);
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loading = false;
			}
		},

		async toggleLockDevice() {
			this.loadingLock = true;
			try {
				const lockStatus = this.device.lockStatus === 0 ? 1 : 0;

				await this.$store.dispatch("guest/devices/toggleLockWithMasterKey", { siteId: this.site._id, deviceId: this.device._id, virtualKeyId: this.virtualKey._id, lockStatus });
				await this.pollDeviceForLockStatus(lockStatus);

				if (lockStatus === 0) {
					this.$toast.success("Cart unlocked successfully");
				} else {
					this.$toast.success("Cart locked successfully");
				}
			} catch (err) {
				console.log(err);
				this.$toast.error(err.error.message || "An error occurred while unlocking cart");
			} finally {
				this.loadingLock = false;
			}
		},

		async pollDeviceForLockStatus(lockStatus) {
			try {
				let attempts = 0;
				const maxAttempts = 10; // Maximum number of attempts
				let response = false; // Flag to check if device is response

				while (attempts < maxAttempts && !response) {
					await this.getDevice(); // Attempt to get device status
					if (this.device.lockStatus === lockStatus) {
						response = true; // Device is response
						break; // Exit loop
					}

					await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
					attempts++; // Increment the attempt counter
				}

				if (!response) {
					if (lockStatus === 0) {
						throw new Error("Cart could not be unlocked");
					} else {
						throw new Error("Cart could not be locked");
					}
				}
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred while unlocking cart");
			}
		},
	},
};
</script>
