import jwt_decode from "jwt-decode";
import { getUnixTime } from "date-fns";

// store in localStorage
const store = (key, value) => {
  return localStorage.setItem(key, value);
};

// read from localstorage
const read = (key) => {
  const token = localStorage.getItem(key);

  if (!token) {
    console.log("No token");
    return null;
  }

  try {
    return jwt_decode(token);
  } catch (err) {
    return null;
  }
};

// check if expired
const isExpired = (token) => {
  const timeNow = getUnixTime(new Date());

  return timeNow > token.exp;
};

// reset localstorage
const clear = () => {
  localStorage.clear();
  return null;
};

export { clear, read, store, isExpired };
