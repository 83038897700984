<template>
	<v-dialog v-model="dialog" width="500">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" class="mr-2 elevation-2" small outlined color="accent">
				<v-icon small left>mdi-plus-circle-outline</v-icon>
				Invite User
			</v-btn>
		</template>

		<v-card class="elevation-24">
			<v-card-text class="title font-weight-bold text-center">
				<span>Add User</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field label="Email" hide-details="auto" v-model="email" :rules="[rules.required, rules.email]" outlined dense class="mb-3"></v-text-field>
					<v-select v-model="role" :items="items" :rules="[rules.required]" dense label="Role" outlined></v-select>
					<site-select :model="sites" @siteID="siteChange" :multiple="true" :rules="[rules.required]" />
					<v-autocomplete
						label="Language"
						hide-details="auto"
						v-model="lang"
						:items="countriesList"
						:rules="[rules.required]"
						item-text="language.name"
						item-value="language.code"
						outlined
						dense
						class="mb-3 mt-6"
					></v-autocomplete>
					<v-hover v-slot="{ hover }">
						<v-btn :loading="loading" :disabled="loading" block @click="addUser()" color="primary" class="mt-6" :elevation="hover ? 12 : 0"> Add </v-btn>
					</v-hover>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import SiteSelect from "@/components/SitesPicker";
import countriesArray from "@/mixins/Countries";

export default {
	components: {
		SiteSelect,
	},
	data() {
		return {
			valid: true,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				email: (v) => /.+@.+\..+/.test(v) || this.$i18n.t("rules.emailValid"),
			},
			dialog: false,
			loading: false,
			email: "",
			role: "user",
			sites: null,
			items: ["guest", "basic", "user", "pro", "service", "admin", "superadmin"],
			showPass: false,
			countriesList: countriesArray.slice(0, 2),
			lang: "en",
		};
	},
	methods: {
		siteChange(value) {
			this.sites = value;
		},

		async addUser() {
			if (this.$refs.form.validate()) {
				try {
					this.loading = true;

					const payload = {
						email: this.formatEmail(this.email),
						role: this.role,
						lang: this.lang,
						sites: this.sites,
					};

					await this.$store.dispatch("admin/inviteUser", payload);

					this.$toast.success("User added successfully");
					this.dialog = false;
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.$emit("updated");
					this.resetForm();
				}
			}
		},

		resetForm() {
			this.email = "";
			this.password = "";
			this.role = "user";
			this.sites = [];
			this.sendEmail = false;
			this.loading = false;

			this.$refs.form.resetValidation();
		},
	},
};
</script>
