import Vue from "vue";

export const uploadFirmware = async (file, onUploadProgress) => {
	const formData = new FormData();
	formData.append("file", file);

	return Vue.prototype.$ioteeStorageApi.post("/firmware", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress,
	});
};

export const listFirmware = async () => {
	const response = await Vue.prototype.$ioteeStorageApi.get("/firmware");
	return response.data;
};

export const deleteFirmware = async (fileName) => {
	await Vue.prototype.$ioteeStorageApi.delete(`/firmware/${fileName}`);
};

export const downloadFirmware = async (fileName) => {
	await Vue.prototype.$ioteeStorageApi.get(`/firmware/${fileName}`, {
		responseType: "blob",
	});
};
