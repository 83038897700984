<template>
	<v-bottom-sheet
		v-model="sheet"
		close-on-click-outside
		content-class="rounded-t-xl"
		max-width="500px"
		width="100%"
	>
		<stripe-checkout
			ref="checkoutRef"
			:pk="publishableKey"
			:stripeAccount="stripeAccount"
			:session-id="sessionId"
		/>

		<v-sheet class="rounded-t-xl" v-if="selectedRate">
			<v-card-text>
				<v-expansion-panels v-model="isOpen" flat>
					<v-expansion-panel>
						<v-expansion-panel-header class="px-2 pt-0" disable-icon-rotate>
							<span v-if="isCheckoutDataStored">
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">
											{{ form.firstName }} {{ form.lastName }}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{ form.email }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</span>
							<span v-else>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">
											{{ $t("s2r.payment.personalDetails") }}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</span>
							<template v-slot:actions>
								<v-btn
									v-if="isCheckoutDataStored"
									text
									small
									class="font-weight-bold primary--text"
								>
									<v-icon small color="primary" left>mdi-pencil</v-icon>
									{{ $t("general.edit") }}
								</v-btn>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-form ref="form" v-model="valid" lazy-validation>
								<span class="subtitle-2">{{ $t("ride.checkout.first") }}</span>
								<v-text-field
									v-model="form.firstName"
									hide-details
									type="text"
									:rules="[rules.required]"
									dense
									outlined
									class="mb-2"
								></v-text-field>

								<span class="subtitle-2">{{ $t("ride.checkout.last") }}</span>
								<v-text-field
									v-model="form.lastName"
									hide-details
									:rules="[rules.required]"
									dense
									outlined
									type="text"
									class="mb-2"
								></v-text-field>

								<span class="subtitle-2">Email</span>
								<v-text-field
									v-model="form.email"
									hide-details
									:rules="[rules.required, rules.emailValid]"
									dense
									outlined
									type="text"
									class="mb-2"
								></v-text-field>
							</v-form>

							<v-list-item class="px-0">
								<v-list-item-action>
									<v-checkbox v-model="saveDetails"></v-checkbox>
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-subtitle class="text-wrap caption">
										{{ $t("s2r.payment.saveDetails") }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>

				<v-list-item class="mb-4 grey rounded-lg">
					<v-list-item-content>
						<v-list-item-title class="font-weight-bold">
							{{ selectedRate.name }}
						</v-list-item-title>
						<v-list-item-subtitle
							v-if="selectedRate.desc"
							class="font-weight-bold"
						>
							{{ selectedRate.desc }}
						</v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action class="font-weight-bold">
						{{ formattedPrice(selectedRate.amount, site.currency) }}
						<span class="font-weight-regular caption">
							+ {{ $t("s2r.payment.unlockFeeCalc") }}
						</span>
					</v-list-item-action>
				</v-list-item>

				<v-list-item class="my-4">
					<v-list-item-action>
						<v-checkbox v-model="form.tcCheckbox"></v-checkbox>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-subtitle class="text-wrap caption">
							{{ $t("ride.checkout.acceptTerms") }}
							<a target="_blank" href="https://www.iotee.eu/terms" @click.stop>
								{{ $t("ride.checkout.terms") }}
							</a>
							&
							<a
								target="_blank"
								href="https://www.iotee.eu/datenschutz"
								@click.stop
							>
								{{ $t("ride.checkout.privacy") }}
							</a>
							<v-spacer></v-spacer>
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<v-btn
					block
					class="rounded-lg font-weight-bold d-flex align-center"
					color="primary"
					depressed
					:disabled="!valid || !form.tcCheckbox"
					:loading="loading"
					@click="submitForm"
				>
					<span class="text-center" style="flex-grow: 1">
						{{ $t("s2r.payment.continuePayment") }}
					</span>
					<v-icon class="ml-auto">mdi-credit-card-fast-outline</v-icon>
				</v-btn>
			</v-card-text>
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		selectedRate: Object,
		site: Object,
		device: Object,
	},
	components: {
		StripeCheckout,
	},
	data() {
		this.publishableKey = process.env.VUE_APP_STRIPE_PK;
		this.stripeAccount = this.site.stripeAccount;
		return {
			form: {
				firstName: null,
				lastName: null,
				email: null,
				tcCheckbox: false,
			},
			valid: true,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				emailValid: (value) =>
					/.+@.+\..+/.test(value) || this.$i18n.t("rules.emailValid"),
			},
			saveDetails: false,
			isOpen: 0,
			sessionId: null,
			loading: false,
		};
	},
	watch: {
		saveDetails(val) {
			if (val) {
				this.saveDetailsToLocalStorage();
			}
		},

		form: {
			deep: true,
			handler() {
				if (this.saveDetails) {
					this.saveDetailsToLocalStorage();
				}
			},
		},

		sheet(val) {
			if (val) {
				this.initialiseForm();
			}
		},
	},
	computed: {
		sheet: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("update:value", val);
			},
		},

		isCheckoutDataStored() {
			return !!this.form.firstName && !!this.form.lastName && !!this.form.email;
		},

		fullName() {
			return `${this.form.firstName} ${this.form.lastName}`;
		},

		isBooking() {
			return !!this.$route.query.bookingId;
		},
	},
	mounted() {},
	methods: {
		initialiseForm() {
			this.isOpen = 0;

			setTimeout(() => {
				if (localStorage.getItem("checkoutData")) {
					const data = JSON.parse(localStorage.getItem("checkoutData"));
					this.form.firstName = data.firstName;
					this.form.lastName = data.lastName;
					this.form.email = data.email;
					this.saveDetails = true;

					// checkout data found, keep panel closed
					this.isOpen = null;
				} else {
					// no checkout data found, keep panel open
					this.isOpen = 0;
				}
			}, 100);
		},

		clearForm() {
			this.form.firstName = "";
			this.form.lastName = "";
			this.form.email = "";
			this.form.tcCheckbox = false;
		},

		saveDetailsToLocalStorage() {
			const data = {
				firstName: this.form.firstName,
				lastName: this.form.lastName,
				email: this.form.email,
			};

			localStorage.setItem("checkoutData", JSON.stringify(data));
		},

		removeDetailsFromLocalStorage() {
			localStorage.removeItem("checkoutData");
		},

		async submitForm() {
			this.loading = true;

			try {
				if (this.$refs.form.validate()) {
					if (!this.saveDetails) {
						this.removeDetailsFromLocalStorage();
					}

					await this.getSessionId();
					this.$refs.checkoutRef.redirectToCheckout();
				} else {
					this.isOpen = 0;
				}
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},

		async getSessionId() {
			try {
				const body = {
					siteId: this.site.id,
					rateId: this.selectedRate._id,
					email: this.form.email,
					name: this.fullName,
					deviceId: this.device.id,
					success_url: `${process.env.VUE_APP_BASE_APP_URL}/ride/${this.$route.params.id}/session`,
					cancel_url: `${process.env.VUE_APP_BASE_APP_URL}/ride/${this.$route.params.id}/prices`,
					...(this.isBooking && { bookingId: this.$route.query.bookingId }),
				};

				const { data } = await this.$ioteeApiV2.post(
					`/stripe/generate-checkout-session`,
					body
				);

				if (!data) return;

				this.sessionId = data.id;
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
