<template>
	<div>
		<v-card v-if="loading" class="rounded-lg" outlined>
			<v-card-text class="text-center">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-card-text>
		</v-card>

		<not-found v-else-if="!virtualKey" :title="$t('s2r.virtualKey.notFound.title')" :subtitle="$t('s2r.virtualKey.notFound.subtitle')" />

		<div v-else>
			<h3 class="mb-4 ml-2">{{ $t("s2r.virtualKey.yourKey") }}</h3>
			<v-card class="rounded-lg" outlined>
				<v-list>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="accent"> mdi-account </v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="accent--text font-weight-bold">
								{{ virtualKey.driver?.name }}
							</v-list-item-title>
							<v-list-item-subtitle class="text-wrap">
								{{ virtualKey.driver?.email }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="accent"> mdi-ticket-confirmation-outline</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="accent--text font-weight-bold">
								{{ $t("s2r.virtualKey.aCredits") }}
							</v-list-item-title>
							<v-list-item-subtitle class="text-wrap">
								{{ availableCredits }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon color="accent"> mdi-golf-cart</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="accent--text font-weight-bold"> {{ $t("s2r.virtualKey.validFor") }}: </v-list-item-title>
							<div class="mt-2">
								<v-chip v-if="virtualKey.allDevices" label small color="primary" class="font-weight-bold">
									<v-icon small left color="white">mdi-golf-cart</v-icon>
									{{ $t("s2r.virtualKey.allCarts") }}
								</v-chip>
								<v-chip-group v-else column>
									<v-chip v-for="car in virtualKey.devices" :key="car" label small color="primary" class="font-weight-bold">
										<v-icon small left color="white">mdi-golf-cart</v-icon>
										{{ car }}
									</v-chip>
								</v-chip-group>
							</div>
						</v-list-item-content>
					</v-list-item>

					<v-list-item>
						<v-list-item-icon>
							<v-icon :color="codeEnabled ? 'success' : 'error'">
								{{ codeEnabled ? "mdi-check-circle-outline" : "mdi-close-circle-outline" }}
							</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="accent--text font-weight-bold">
								{{ $t("s2r.virtualKey.status") }}
							</v-list-item-title>
							<v-list-item-subtitle class="text-wrap">
								{{ codeEnabled ? $t("general.enabled") : $t("general.disabled") }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>

					<v-list-item v-if="virtualKey.expires">
						<v-list-item-icon>
							<v-icon color="accent"> mdi-clock-alert-outline</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="accent--text font-weight-bold">
								{{ $t("s2r.virtualKey.expires") }}
							</v-list-item-title>
							<v-list-item-subtitle class="text-wrap">
								{{ translatedFormattedDate(virtualKey.expires, "EEE do MMM yyy, HH:mm") }}
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-card>

			<v-card outlined class="mt-4 rounded-lg">
				<v-list>
					<v-list-item>
						<v-list-item-icon>
							<v-icon color="accent"> mdi-information-outline</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="accent--text font-weight-bold text-wrap">
								{{ infoMessage }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>

				<v-card-text class="pt-0">
					<v-btn :loading="loadingUnlock" :disabled="!canUnlock" color="purple" class="font-weight-bold rounded-lg white--text" block @click="unlockDevice">
						{{ $t("s2r.unlockCart") }}! 🔓
					</v-btn>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import NotFound from "../../components/scanToRide/NotFound.vue";
import DevicesService from "@/services/devicesService";

export default {
	props: {
		site: {
			type: Object,
			required: true,
		},
		device: {
			type: Object,
			required: true,
		},
		status: {
			type: String,
			required: true,
		},
		virtualKey: {
			type: Object,
			required: false,
		},
	},
	components: {
		NotFound,
	},
	data() {
		return {
			loadingUnlock: false,
			loading: true,
		};
	},
	computed: {
		codeEnabled() {
			return this.virtualKey.enabled;
		},

		unlimitedCredits() {
			return this.virtualKey.driver.credits === -1;
		},

		availableCredits() {
			return this.unlimitedCredits ? "Unlimited" : this.virtualKey.driver.credits;
		},

		hasAvailableCredits() {
			return this.unlimitedCredits || this.virtualKey.driver.credits > 0;
		},

		canAccessCar() {
			if (this.virtualKey.allDevices) {
				return true;
			} else {
				return this.virtualKey.devices.includes(Number(this.device.carNumber));
			}
		},

		canUnlock() {
			// if status is booked and the user is not a master, return false
			if ((this.status === "BOOKED" && !this.virtualKey.master) || (this.status === "SITE_CLOSED" && !this.virtualKey.master)) {
				return false;
			}
			// other conditions
			return this.hasAvailableCredits && this.codeEnabled && this.canAccessCar;
		},

		infoMessage() {
			// if status is booked and the user is not a master, show the relevant message
			if (this.status === "BOOKED" && !this.virtualKey.master) {
				return this.$t("s2r.virtualKey.statusMsg.booked");
			} else if (this.status === "SITE_CLOSED" && !this.virtualKey.master) {
				return this.$t("s2r.virtualKey.statusMsg.closed");
			} else if (!this.hasAvailableCredits) {
				return this.$t("s2r.virtualKey.statusMsg.noCreds");
			} else if (!this.codeEnabled) {
				return this.$t("s2r.virtualKey.statusMsg.disabled");
			} else if (!this.canAccessCar) {
				return this.$t("s2r.virtualKey.statusMsg.access", {
					car: this.device.carNumber,
				});
			} else if (this.hasAvailableCredits && !this.unlimitedCredits) {
				return this.$t("s2r.virtualKey.statusMsg.deduct");
			} else {
				return this.$t("s2r.virtualKey.statusMsg.unlimited");
			}
		},
	},
	mounted() {
		this.getVirtualKey();
	},
	methods: {
		async getVirtualKey() {
			try {
				console.log(this.$route.params.virtualKey);
				await this.$store.dispatch("guest/sites/getVirtualKey", {
					id: this.site._id,
					code: this.$route.params.code,
				});
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
				this.$store.commit("guest/sites/setVirtualKey", null);
			} finally {
				this.loading = false;
			}
		},

		async unlockDevice() {
			this.loadingUnlock = true;

			try {
				const rideData = {
					type: "code",
					device: this.device.id,
					carNumber: this.device.carNumber,
					siteID: this.site.id,
					email: this.virtualKey.driver?.email,
					code: this.virtualKey.code,
					driver: this.virtualKey.driver?.id,
					name: this.virtualKey.driver?.name || null,
					deductCredits: true,
				};

				const {
					data: { session },
				} = await DevicesService.unlockDevice({
					deviceId: this.device.id,
					rideData,
				});

				this.$router.push({
					name: "RideSession",
					params: {
						id: this.device.id,
						sessionId: session,
					},
				});
			} catch (err) {
				console.log(err);
			} finally {
				this.loadingUnlock = false;
			}
		},
	},
};
</script>
