<template>
	<v-col cols="12">
		<v-row>
			<v-col class="white--text text-center mx-auto mt-n14" cols="8" lg="6" md="8" sm="6">
				<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="signIn">
					<v-text-field :label="$t('loginPage.email')" v-model="email" type="email" :rules="[rules.required, rules.email]" required outlined dense color="primary" />
					<v-text-field
						id="password"
						:label="$t('loginPage.password')"
						v-model="password"
						:rules="[rules.required]"
						hide-details="auto"
						outlined
						dense
						color="primary"
						:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
						:type="showPass ? 'text' : 'password'"
						@click:append="showPass = !showPass"
					/>
					<v-divider class="my-6"></v-divider>
					<v-hover v-slot="{ hover }">
						<v-btn block :loading="btnLoading" :disabled="valid === false || btnLoading" type="submit" class="primary" :elevation="hover ? 12 : 2">
							{{ $t("loginPage.logInButton") }}
						</v-btn>
					</v-hover>
				</v-form>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="caption accent--text text-center mx-auto" cols="8" lg="6" md="8" sm="6">
				<v-btn text color="primary" @click="resetPassword">
					{{ $t("loginPage.forgotPass") }}
				</v-btn>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	data() {
		return {
			email: "",
			password: "",
			valid: true,
			showPass: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				email: (v) => /.+@.+\..+/.test(v) || this.$i18n.t("rules.emailValid"),
			},
			btnLoading: false,
		};
	},
	mounted() {
		if (this.$route.query.email) {
			this.email = this.$route.query.email;
		}
	},
	methods: {
		async signIn() {
			if (this.$refs.form.validate()) {
				this.btnLoading = true;

				try {
					const user = {
						email: this.formatEmail(this.email),
						password: this.password,
					};

					await this.$store.dispatch("login", user);
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.btnLoading = false;
				}
			}
		},

		resetPassword() {
			this.$router.push({
				path: `/login/resetPassword`,
			});
		},
	},
};
</script>
