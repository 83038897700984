<template>
	<page-loading v-if="loading" />

	<v-container v-else fluid>
		<v-row v-if="ready" class="mb-14">
			<v-col xs="12" sm="8" md="4" class="mx-auto">
				<v-row>
					<v-col>
						<cart-header :site="site" :device="device" />
					</v-col>
				</v-row>

				<div>
					<v-card class="rounded-lg mt-4" outlined>
						<v-card-title>
							<h3 class="ml-2 mt-2">
								<v-icon large color="primary">mdi-calendar-month</v-icon>
								{{ $t("s2r.booked.yourBooking") }}
							</h3>
						</v-card-title>

						<v-card class="primary--text mt-2" flat>
							<div class="pa-4">
								<v-row class="text-center">
									<v-col cols="3">
										<p class="mb-2 title primary--text font-weight-bold">{{ translatedFormattedDate(booking.start, "HH:mm") }}</p>

										<v-chip label color="primary" class="white--text font-weight-bold" small>
											{{ $t("general.start") }}
										</v-chip>
									</v-col>
									<v-col cols="6" class="px-0">
										<div class="dotted-timeline">
											<div class="timeline-icon">
												<v-icon small color="primary "> mdi-record-circle-outline </v-icon>
											</div>
											<div class="timeline-line"></div>
											<div class="timeline-icon">
												<v-icon small color="primary "> mdi-golf-cart </v-icon>
											</div>
											<div class="timeline-line"></div>
											<div class="timeline-icon">
												<v-icon small color="primary "> mdi-record-circle-outline </v-icon>
											</div>
										</div>
										<div class="caption font-weight-bold primary--text mt-2">
											{{ formatDurationToTime(booking.start, booking.end) }}
										</div>
									</v-col>
									<v-col cols="3">
										<p class="mb-2 title primary--text font-weight-bold">{{ translatedFormattedDate(booking.end, "HH:mm") }}</p>
										<v-chip label color="primary" class="white--text font-weight-bold" small>
											{{ $t("general.end") }}
										</v-chip>
									</v-col>
								</v-row>
							</div>
						</v-card>

						<v-list>
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent"> mdi-account </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="accent--text font-weight-bold">
										{{ booking.name }}
									</v-list-item-title>
									<v-list-item-subtitle class="text-wrap">
										{{ booking.email }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-list>
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent"> mdi-calendar</v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="accent--text font-weight-bold">
										{{ translatedFormattedDate(booking.start, "EE do MMM") }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-card-text v-if="booking.note">
							<v-alert outlined color="purple darken-1" prominent dense border="left" icon="mdi-message-fast-outline">
								<strong>{{ $t("s2r.booked.message") }}:</strong>
								<br />
								{{ booking.note }}
							</v-alert>
						</v-card-text>
					</v-card>

					<v-card v-if="isBookingExpired" outlined class="mt-4">
						<v-card-text>
							<v-alert outlined color="error" class="mb-0" prominent dense border="left" icon="mdi-alert-circle-outline">
								<strong>{{ $t("bookings.bookingExpired") }}:</strong>
								<br />
								{{ $t("bookings.bookingExpiredMessage") }}
							</v-alert>
						</v-card-text>
					</v-card>

					<v-card v-else-if="device.session" outlined class="mt-4">
						<v-card-text class="text-center">
							<div class="subtitle-1 font-weight-bold success--text">
								<v-icon class="blink" color="success" left> mdi-check-circle </v-icon>
								{{ $t("bookings.bookingActive") }}
							</div>
						</v-card-text>
						<v-card-text>
							<v-btn color="success" class="font-weight-bold rounded-lg" block @click="toggleLockDevice" :loading="loadingLock">{{ $t("s2r.lockCart") }}</v-btn>
						</v-card-text>
					</v-card>

					<v-card outlined class="mt-4" v-else-if="booking.prePaid">
						<v-list>
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent"> mdi-clock </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="accent--text font-weight-bold"> {{ $t("s2r.booked.accessFrom") }}: </v-list-item-title>
									<v-list-item-title class="title font-weight-bold text-wrap">
										{{ bookingStartWithOffset }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-card-text>
							<v-btn :disabled="!isBookingActive" color="success" class="font-weight-bold rounded-lg" block @click="toggleLockDevice" :loading="loadingLock">
								{{ !showCountdown ? `${$t("s2r.unlockCart")}! 🔓` : countdown }}
							</v-btn>
						</v-card-text>
					</v-card>

					<v-card outlined class="mt-4" v-else>
						<v-list>
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent"> mdi-cash </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="accent--text font-weight-bold">
										{{ $t("s2r.booked.requiresPayment.title") }}
									</v-list-item-title>
									<v-list-item-subtitle class="mt-2 font-weight-bold text-wrap">
										{{ $t("s2r.booked.requiresPayment.subtitle") }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>

					<v-card class="rounded-lg mt-4" flat v-if="!site.bookingIntegration && !device.session && !isBookingExpired">
						<v-card-text>
							<v-btn block text class="rounded-lg font-weight-bold d-flex align-center" color="error" depressed @click="changeDevice">
								<v-icon small left class="mr-2">mdi-wrench-outline</v-icon>
								{{ $t("bookings.changeCart.issue") }}
							</v-btn>
						</v-card-text>
					</v-card>
				</div>
			</v-col>
		</v-row>

		<v-row v-else-if="!booking">
			<v-col xs="12" sm="8" md="4" class="mx-auto text-center">
				<v-alert outlined color="error" prominent dense border="left" icon="mdi-alert-circle-outline">
					<strong>{{ $t("s2r.booked.notFound.title") }}</strong>
					<br />
					{{ $t("s2r.booked.notFound.title") }}
				</v-alert>
			</v-col>
		</v-row>
		<confirm ref="confirm" :yesText="$t('bookings.changeCart.title')" />
		<change-cart v-if="ready" ref="changeCart" :currentDevice="device" :service="selectedService" :booking="booking" @updateBooking="updateBooking" />
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import { subMinutes } from "date-fns";

import CartHeader from "@/components/guest/booking/CartHeader";
import ChangeCart from "@/components/guest/booking/ChangeCart";

import Confirm from "@/components/Confirm";

import PageLoading from "@/components/PageLoading";

export default {
	metaInfo: {
		title: "IOTee",
		meta: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
			},
		],
	},
	components: {
		PageLoading,
		CartHeader,
		Confirm,
		ChangeCart,
	},
	data() {
		return {
			ready: false,
			loading: true,
			loadingLock: false,

			countdown: "",
			interval: null,
			showCountdown: true,
			// isBookingActive: false,
		};
	},
	computed: {
		...mapState({
			booking: (state) => state.guest.bookings.booking,
			device: (state) => state.guest.devices.device,
			site: (state) => state.guest.sites.site,
		}),

		bookingId() {
			return this.$route.params.id;
		},

		selectedService() {
			return this.site?.services?.find((service) => service.id === this.booking?.service);
		},

		selectedPrice() {
			return this.selectedService?.prices?.find((price) => price.id === this.booking?.price);
		},

		bookingDuration() {
			return this.formatDurationToTime(this.booking.start, this.booking.end);
		},

		bookingStartWithOffset() {
			const start = subMinutes(new Date(this.booking.start), this.site.bookingOffset);

			return this.translatedFormattedDate(start, "HH:mm");
		},

		isBookingActive() {
			// if the current time is within the booking start and end time (with offset), then booking is active
			const now = new Date();
			const start = subMinutes(new Date(this.booking.start), this.site.bookingOffset);
			const end = new Date(this.booking.end);

			return now >= start && now <= end;
		},

		isBookingExpired() {
			const now = new Date();
			const end = new Date(this.booking.end);

			return now > end;
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		async initialize() {
			await this.getBooking();
			await this.getDevice();
			await this.getSite();

			this.ready = true;
		},

		async getBooking() {
			try {
				await this.$store.dispatch("guest/bookings/getBooking", this.bookingId);
				this.startCountdown();
				this.loading = false;
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred while fetching booking details");
			} finally {
				this.loading = false;
			}
		},

		async getDevice() {
			try {
				await this.$store.dispatch("guest/devices/getDevice", this.booking.device);
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred while fetching device details");
			}
		},

		async getSite() {
			this.loading = true;

			try {
				await this.$store.dispatch("guest/sites/getSite", this.device.siteID);
			} catch (err) {
				if (err.error && err.error.message) {
					this.$toast.error(err.error.message);
				} else {
					this.$toast.error("An error occurred while fetching site details");
				}
			} finally {
				this.loading = false;
			}
		},

		async changeDevice() {
			const res = await this.$refs.confirm.open(this.$t("bookings.changeCart.confirm.title"), this.$t("bookings.changeCart.confirm.subtitle"), "error", "mdi-wrench-outline");

			if (res) {
				this.$refs.changeCart.open();
			}
		},

		async updateBooking(body) {
			this.loading = true;
			try {
				await this.$store.dispatch("guest/bookings/updateBooking", { bookingId: this.bookingId, body });
				location.reload();
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred while updating booking details");
			} finally {
				// this.$router.replace({ name: "guest.booking", params: { id: this.bookingId } }).catch(() => {});
				this.$toast.success("Booking details updated successfully");
			}
		},

		async toggleLockDevice() {
			this.loadingLock = true;
			try {
				const lockStatus = this.device.lockStatus === 0 ? 1 : 0;

				await this.$store.dispatch("guest/bookings/toggleLockWithBooking", { siteId: this.booking.site, bookingId: this.bookingId, lockStatus });
				await this.pollDeviceForLockStatus(lockStatus);

				if (lockStatus === 0) {
					this.$toast.success("Cart unlocked successfully");
				} else {
					this.$toast.success("Cart locked successfully");
				}
			} catch (err) {
				const { error } = err;

				if (error.message === "Device already locked") {
					this.$toast.error("Cart is already locked");
				} else if (error.message === "Device already unlocked") {
					this.$toast.error("Cart is already unlocked");
				} else {
					this.$toast.error(err.error.message || "An error occurred while unlocking cart");
				}

				this.getDevice();
			} finally {
				this.loadingLock = false;
			}
		},

		async pollDeviceForLockStatus(lockStatus) {
			try {
				let attempts = 0;
				const maxAttempts = 10; // Maximum number of attempts
				let response = false; // Flag to check if device is response

				while (attempts < maxAttempts && !response) {
					await this.getDevice(); // Attempt to get device status
					if (this.device.lockStatus === lockStatus) {
						response = true; // Device is response
						break; // Exit loop
					}

					await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
					attempts++; // Increment the attempt counter
				}

				if (!response) {
					if (lockStatus === 0) {
						throw new Error("Cart could not be unlocked");
					} else {
						throw new Error("Cart could not be locked");
					}
				}
			} catch (err) {
				this.$toast.error(err.message || "An error occurred while unlocking cart");
			}
		},

		timUntilBooking() {
			let now = new Date();
			let start = subMinutes(new Date(this.booking.start), this.site.bookingOffset);

			return this.formatDurationToTime(now, start);
		},

		startCountdown() {
			this.interval = setInterval(() => {
				let now = new Date();
				let start = subMinutes(new Date(this.booking.start), this.site.bookingOffset);

				// if time left is 0 or negative, clear interval and reset countdown
				if (now >= start) {
					clearInterval(this.interval);
					this.countdown = "";
					this.showCountdown = false;
				} else {
					this.countdown = this.timUntilBooking();
				}
			}, 1000); // update every second
		},
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
};
</script>

<style lang="scss" scoped>
.dotted-timeline {
	display: flex;

	.timeline-line {
		width: 100%;
		position: relative;
		border-bottom: 1px solid #406dfa;
	}
	.timeline-icon {
		position: relative;
		top: 8px;
	}
}

.paymentChip {
	position: absolute;
	top: -10px;
	right: 0;
	z-index: 100;
}

.blink {
	-webkit-animation: blink 4s infinite both;
	animation: blink 4s infinite both;
}

@-webkit-keyframes blink {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0;
	}
}
@keyframes blink {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0;
	}
}
</style>
