import Vue from "vue";

export const getDevices = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/devices`);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateDevice = async ({ id, body }) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/devices/${id}`, body);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateDevices = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/devices`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getDeviceUpdates = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/devices/updates`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getDeviceBatteryReadings = async (deviceId) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/devices/${deviceId}/battery`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const lockDevice = async (deviceId) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/devices/${deviceId}/lock`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const unlockDevice = async ({ id, body }) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/devices/${id}/unlock`, body);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const lockDevices = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/devices/lock`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const unlockDevices = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/devices/unlock`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
