<template>
	<div>
		<v-speed-dial
			v-model="fab"
			fixed
			bottom
			left
			:style="$vuetify.breakpoint.xs ? 'left: 10px' : 'left: 70px'"
			transition="slide-y-reverse-transition"
		>
			<template v-slot:activator>
				<v-btn v-model="fab" color="primary" dark small fab>
					<v-icon v-if="fab"> mdi-close </v-icon>
					<v-icon v-else> mdi-information-outline </v-icon>
				</v-btn>
			</template>
			<v-btn fab dark small color="primary" @click="snackbar = !snackbar">
				<v-icon>mdi-lock-clock</v-icon>
			</v-btn>
		</v-speed-dial>

		<v-snackbar
			v-model="snackbar"
			:timeout="-1"
			:value="true"
			bottom
			left
			:color="lockActive ? 'primary' : 'accent lighten-3'"
			outlined
		>
			<v-icon :color="lockActive ? 'primary' : 'accent lighten-3'" left>
				mdi-lock-clock
			</v-icon>
			<span class="font-weight-bold">
				{{
					lockActive
						? `${$t("fleetPage.banner.schedLockSet")} ${site.lockFrom}`
						: `${$t("fleetPage.banner.schedLockNot")}`
				}}
			</span>

			<template v-slot:action="{ attrs }">
				<v-btn
					:color="lockActive ? 'primary' : 'accent lighten-3'"
					icon
					v-bind="attrs"
					@click="snackbar = !snackbar"
				>
					<v-icon>mdi-close-circle</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	props: {
		site: {},
	},
	data() {
		return {
			snackbar: false,
			fab: false,
		};
	},
	computed: {
		lockActive() {
			return this.site.lockFrom !== "00:00" ? true : false;
		},
	},
};
</script>
