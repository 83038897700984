<template>
	<span>
		<v-btn fab elevation="0" color="primary lighten 3" @click="locateCar">
			<v-icon>mdi-map-marker-radius-outline</v-icon>
		</v-btn>
		<p class="caption font-weight-bold">{{ $t("general.track") }}</p>
	</span>
</template>

<script>
export default {
	props: ["car"],
	methods: {
		async locateCar() {
			await this.$router.push("/");
			this.$store.commit("setSelectedDeviceId", this.car._id);
		},
	},
};
</script>
