<template>
	<v-card class="rounded-lg px-4">
		<v-card-title class="font-weight-bold">{{ $t("settingsPage.bookingSettings.bookingOffset.title") }}</v-card-title>
		<v-card-subtitle>
			{{
				$t("settingsPage.bookingSettings.bookingOffset.subtitle", {
					hours: formattedTime,
				})
			}}
		</v-card-subtitle>

		<v-card-text>
			<v-slider v-model="bookingOffset" :max="120" :min="0" thumb-label="always" :thumb-size="15" :step="5" class="mt-4" hide-details>
				<template v-slot:thumb-label>
					<v-chip class="font-weight-bold" small label color="primary">
						{{ formattedTime }}
					</v-chip>
				</template>
			</v-slider>
		</v-card-text>

		<v-card-actions class="mx-2">
			<v-spacer></v-spacer>
			<v-btn :disabled="!isDirty" text small color="primary" @click="reset">{{ $t("general.cancel") }}</v-btn>
			<v-btn :disabled="!isDirty" small depressed color="primary" @click="updateSite" :loading="loading">{{ $t("general.save") }}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			isDirty: false,
			loading: false,
			bookingOffset: 0,
		};
	},
	watch: {
		bookingOffset(val) {
			if (val !== JSON.parse(JSON.stringify(this.selectedSite.bookingOffset))) {
				this.isDirty = true;
			} else {
				this.isDirty = false;
			}
		},
	},
	mounted() {
		this.reset();
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),

		formattedTimes() {
			return (timeArray) =>
				timeArray.map((time) => {
					const start = new Date(time.start).toLocaleTimeString();
					const end = new Date(time.end).toLocaleTimeString();
					return { start, end, _id: time._id };
				});
		},

		formattedTime() {
			const hours = Math.floor(this.bookingOffset / 60);
			const minutes = this.bookingOffset % 60;
			const unitHour = this.$tc("general.hour", hours);
			const unitMinute = this.$tc("general.minute", minutes);
			if (hours === 0) {
				return `${minutes} ${unitMinute}`;
			} else if (minutes === 0) {
				return `${hours} ${unitHour}`;
			} else {
				return `${hours} ${unitHour} ${minutes} ${unitMinute}`;
			}
		},
	},
	methods: {
		reset() {
			this.bookingOffset = JSON.parse(JSON.stringify(this.selectedSite.bookingOffset));
		},

		async updateSite() {
			this.loading = true;

			const siteSettings = {
				updateData: {
					_id: this.selectedSite._id,
					bookingOffset: this.bookingOffset,
				},
			};

			try {
				await this.$store.dispatch("updateSite", siteSettings);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
				this.reset();
			}
		},
	},
};
</script>
