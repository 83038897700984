<template>
	<v-select
		v-model="selectedCurrency"
		:items="filteredCurrencies"
		placeholder="Select Currency"
		outlined
		dense
		item-value="code"
		@change="save"
		hide-details
		single-line
		style="max-width: 130px; transform: scale(0.71)"
	>
		<template v-slot:prepend-item>
			<div class="sticky-search">
				<v-list-item>
					<v-list-item-content>
						<v-text-field v-model="search" outlined placeholder="Search..." dense hide-details autofocus></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-divider></v-divider>
			</div>
		</template>
		<template v-slot:item="{ item }"> {{ item.symbol }} - {{ item.name }} </template>
		<template v-slot:selection="{ item }">
			<span class="d-inline-block text-truncate"> {{ item.symbol }} - {{ item.name }} </span>
		</template>
	</v-select>
</template>

<script>
import countriesArray from "@/mixins/Countries";

export default {
	name: "CurrencyMenu",
	props: {
		site: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			countriesArray: countriesArray,
			selectedCurrency: null,
			lastSelectedCurrency: null,
			currencyItems: countriesArray.map((country) => country.currency),
			search: "",
		};
	},
	computed: {
		filteredCurrencies() {
			return this.currencyItems.filter((currency) => currency.name.toLowerCase().includes(this.search.toLowerCase()));
		},
	},
	mounted() {
		if (this.site && this.site.currency) {
			const defaultCurrency = this.currencyItems.find((item) => item.code === this.site.currency);
			this.selectedCurrency = defaultCurrency;
			this.lastSelectedCurrency = defaultCurrency;
		}
	},
	watch: {
		selectedCurrency(val) {
			if (!val) {
				this.selectedCurrency = this.lastSelectedCurrency;
			} else {
				this.lastSelectedCurrency = val;
			}
		},
	},
	methods: {
		async save(newCurrency) {
			if (!newCurrency) return;

			try {
				const siteSettings = {
					updateData: {
						_id: this.site._id,
						currency: newCurrency,
					},
				};

				await this.$store.dispatch("updateSite", siteSettings);

				this.$toast.success("Currency updated");
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.search = "";
			}
		},
	},
};
</script>

<style scoped>
.select-container {
	max-width: 200px;
}

.sticky-search {
	position: sticky;
	top: 0;
	z-index: 2;
	background: white;
}
</style>
