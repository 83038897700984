<template>
	<div class="text-center">
		<v-dialog v-model="dialog" width="500">
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon color="primary">mdi-help-circle-outline</v-icon>
				</v-btn>
			</template>

			<v-card>
				<v-card-title v-if="title" class="title accent--text font-weight-bold">
					{{ title }}
				</v-card-title>

				<v-card-text v-if="body">
					{{ body }}
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						class="mb-2 mr-2"
						small
						color="primary"
						@click="dialog = false"
					>
						{{ $t("general.ok") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		title: { default: null },
		body: { default: null },
		icon: { default: "mdi-help-circle-outline" },
	},
	data() {
		return {
			dialog: false,
		};
	},
};
</script>
