import Vue from "vue";
import i18n from "@/i18n";
import * as ridesService from "@/services/rides.service";

export default {
	namespaced: true,
	state: {
		rides: [],
		currentPage: 1,
		totalItems: 0,
		loading: false,
		loadingRide: false,
		itemsPerPage: 10,

		selectedRide: null,
		selectedRideHistory: [],
		activeRides: {},
		carTrail: null,

		rideHistory: [],
		rideHistoryLoading: false,
	},

	mutations: {
		setTotalItems(state, totalItems) {
			state.totalItems = totalItems;
		},

		setItemsPerPage(state, itemsPerPage) {
			state.itemsPerPage = itemsPerPage;
		},

		setCurrentPage(state, page) {
			state.currentPage = page;
		},

		setLoading(state, loading) {
			state.loading = loading;
		},

		setRideLoading(state, loading) {
			state.loadingRide = loading;
		},

		setRides(state, payload) {
			state.rides = payload;
		},

		setSelectedRide(state, payload) {
			state.selectedRide = payload;
		},

		setSelectedRideHistory(state, payload) {
			state.selectedRideHistory = payload;
		},

		setRideHistory(state, payload) {
			state.rideHistory = payload;
		},

		setRideHistoryLoading(state, payload) {
			state.rideHistoryLoading = payload;
		},

		SOCKET_SITE_RIDES(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_SITE_RIDES", payload);
			}

			const ridesBySession = payload.reduce((acc, curr) => {
				if (curr._id) {
					if (!acc[curr._id]) {
						acc[curr._id] = curr;
					} else {
						if (!Array.isArray(acc[curr._id])) {
							acc[curr._id] = [acc[curr._id]];
						}
						acc[curr._id].push(curr);
					}
				}
				return acc;
			}, {});

			state.activeRides = ridesBySession;
		},

		SOCKET_RIDE_UPDATE(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_RIDE_UPDATE", payload);
			}

			const rideId = payload._id;

			switch (true) {
				case payload.deleted:
					Vue.delete(state.activeRides, rideId);
					break;

				case payload.status === 1:
					Vue.set(state.activeRides, rideId, {
						...state.activeRides[rideId],
						...payload,
					});
					break;
			}
		},

		// Set ALL Sites
		setCarTrail(state, payload) {
			state.carTrail = payload;
		},
	},

	actions: {
		async getSiteRides({ commit, state }, queryParams = {}) {
			try {
				commit("setLoading", true); // Set loading to true
				const defaultQuery = {
					page: state.currentPage,
					limit: state.itemsPerPage,
				};

				const query = { ...defaultQuery, ...queryParams };

				const { data, pagination } = await ridesService.getSiteRides(query);

				commit("setRides", data);
				commit("setTotalItems", pagination.totalItems);
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", false); // Set loading to false
			}
		},

		setCurrentPage({ commit, dispatch }, { page, queryParams }) {
			commit("setCurrentPage", page);
			dispatch("getSiteRides", queryParams);
		},

		setItemsPerPage({ commit, dispatch }, { itemsPerPage, queryParams }) {
			commit("setItemsPerPage", itemsPerPage);
			dispatch("getSiteRides", queryParams);
		},

		async getRide({ commit }, rideId) {
			commit("setRideLoading", rideId);
			const ride = await ridesService.getRide(rideId);
			commit("setSelectedRide", ride);

			commit("setRideLoading", false);
		},

		// Get selected trail
		async getCarTrails({ commit }, payload) {
			try {
				const trail = payload;

				const trailObject = {
					points: trail.points,
					carNumber: trail.carNumber,
				};

				commit("setCarTrail", trailObject);

				return trail;
			} catch (error) {
				return error;
			}
		},

		async getRideHistory({ commit }, payload) {
			commit("setRideHistoryLoading", true);

			try {
				const rides = await ridesService.getRideHistory(payload);
				commit("setRideHistory", rides);
			} catch (error) {
				return error;
			} finally {
				commit("setRideHistoryLoading", false);
			}
		},

		async getRidesChartData(_, query) {
			try {
				const data = await ridesService.getRidesChartData(query);
				return data;
			} catch (error) {
				return error;
			}
		},
	},
	getters: {
		getRideData(state, getters, rootState) {
			const ride = state.selectedRide;

			if (!ride) return {};

			const device = rootState.devices.devices.find((device) => device._id === ride.device._id);

			let data = {
				carName: device.carName,
				carNumber: ride.carNumber,
				service: ride.service,
				booking: ride.booking,
				...ride.meta,
			};

			switch (ride.type) {
				case "key":
					data = {
						...data,
						method: "Unlocked using Key",
					};

					break;

				case "keypad":
					data = {
						...data,
						method: "Unlocked using Keypad",
					};

					break;

				case "rfid":
					data = {
						...data,
						method: "Unlocked using RFID",
					};

					break;

				case "manual":
					data = {
						...data,
						method: i18n.t("ridesPage.method.dash"),
					};

					break;

				case "booking":
					data = {
						...data,
						method: i18n.t("ridesPage.method.booking"),
					};

					break;

				default:
					data = {
						...data,
					};

					break;
			}

			return data;
		},

		// Get ride list data
		// get device from devices store (rootstate)
		getRideListData: (state, getters, rootState) => (id) => {
			const ride = state.rides.find((ride) => ride._id === id);

			if (!ride) return {};

			const device = rootState.devices.devices.find((device) => device._id === ride.device._id);

			let data = {
				carName: device.carName,
				carNumber: ride.carNumber,
				service: ride.service,
				booking: ride.booking,
				...ride.meta,
			};

			switch (ride.type) {
				case "key":
					break;

				case "keypad":
					data = {
						...data,
						method: "Unlocked using Keypad",
					};

					break;

				case "rfid":
					data = {
						...data,
						method: "Unlocked using RFID",
					};

					break;

				case "manual":
					data = {
						...data,
						method: i18n.t("ridesPage.method.dash"),
					};

					break;

				case "booking":
					data = {
						...data,
						method: i18n.t("ridesPage.method.booking"),
					};

					break;

				default:
					data = {
						...data,
					};

					break;
			}

			return data;
		},
	},
};
