<template>
	<v-card width="100%" flat>
		<v-card-title class="font-weight-bold">
			{{ $t("userGroups.title") }}
			<v-spacer></v-spacer>
			<v-btn color="primary" small depressed @click="addUserGroup">{{ $t("userGroups.add") }}</v-btn>
		</v-card-title>
		<v-card-subtitle>{{ $t("userGroups.subtitle") }}</v-card-subtitle>
		<v-data-table :headers="headers" :items="userGroupWithDefault" :loading="loading" :hide-default-footer="true">
			<template v-slot:item.actions="{ item }">
				<v-icon small class="mr-2" v-if="item._id" @click="editUserGroup(item)">mdi-pencil</v-icon>
				<v-icon small v-if="item._id" @click="deleteUserGroup(item)" :loading="loadingDelete">mdi-delete</v-icon>
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" max-width="500px">
			<v-card>
				<v-card-title>
					<span v-if="editedIndex === -1">{{ $t("userGroups.add") }}</span>
					<span v-else>{{ $t("userGroups.edit") }}</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid" lazy-validation>
						<v-text-field v-model="editedItem.name" :rules="[rules.required]" outlined hide-details dense :label="$t('userGroups.name')" required></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions class="mx-4 pb-4">
					<v-spacer></v-spacer>
					<v-btn color="primary" outlined @click="closeDialog">
						{{ $t("general.cancel") }}
					</v-btn>
					<v-btn color="primary" depressed @click="saveUserGroup" :loading="loadingSave">
						{{ $t("general.save") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<confirm ref="confirm" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import Confirm from "@/components/Confirm.vue";

export default {
	components: {
		Confirm,
	},
	data() {
		return {
			headers: [
				{ text: "Name", value: "name" },
				{ text: "", align: "right", value: "actions", sortable: false },
			],
			rules: {
				required: (value) => !!value || this.$t("general.required"),
			},
			search: "",
			loading: false,
			loadingSave: false,
			loadingDelete: false,
			pagination: {},
			itemsPerPage: 10,
			dialog: false,
			editedIndex: -1,
			editedItem: {
				name: "",
				description: "",
			},
			valid: false,
		};
	},
	computed: {
		...mapState({
			userGroups: (state) => state.sites.userGroups,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		userGroupWithDefault() {
			return [{ _id: null, name: this.$t("general.memberTypes.guest") }, ...this.userGroups];
		},
	},
	methods: {
		addUserGroup() {
			this.editedIndex = -1;
			this.editedItem = {
				site: this.selectedSite._id,
				name: "",
			};
			this.dialog = true;
		},

		editUserGroup(item) {
			this.editedIndex = this.userGroups.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},

		async saveUserGroup() {
			if (this.$refs.form.validate() === false) {
				return;
			}

			try {
				this.loadingSave = true;
				if (this.editedIndex > -1) {
					await this.$store.dispatch("updateSiteUserGroup", this.editedItem);
				} else {
					await this.$store.dispatch("addSiteUserGroup", this.editedItem);
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingSave = false;
				this.closeDialog();
			}
		},

		async deleteUserGroup(item) {
			const res = await this.$refs.confirm.open(this.$t("userGroups.delete"), this.$t("userGroups.deleteConfirm"), "error", "mdi-exclamation-thick");
			if (res === false) {
				return;
			}

			try {
				this.loadingDelete = true;

				await this.$store.dispatch("deleteSiteUserGroup", item);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingDelete = false;
			}
		},

		closeDialog() {
			this.dialog = false;
			this.$nextTick(() => {
				this.$refs.form.resetValidation();
			});
		},
	},
};
</script>
