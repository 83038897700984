<template>
	<v-dialog v-model="dialog" width="350" persistent>
		<template v-slot:activator="{ on, attrs }">
			<span>
				<v-btn v-bind="attrs" v-on="on" fab elevation="0" :color="keyStatus().color" :outlined="keyStatus().outlined" :disabled="!isKeylessSite()" :loading="loading">
					<v-icon>
						{{ keyStatus().icon }}
					</v-icon>
				</v-btn>
				<p class="caption font-weight-bold mb-0">{{ $t("general.keyMode") }}</p>
				<i class="caption">
					{{ car.key === "0" ? $t("general.disabled") : $t("general.enabled") }}
				</i>
			</span>
		</template>

		<v-card outlined class="rounded-xl elevation-24 text-center">
			<v-icon class="text-center mt-2" left>
				{{ car.key === "0" ? "mdi-key-outline" : "mdi-key-remove" }}
			</v-icon>
			<v-card-text class="title font-weight-bold text-center mt-2">
				<span>
					{{ car.key === "0" ? $t("general.keyMode") : $t("general.keylessMode") }}
				</span>
			</v-card-text>

			<v-card-text class="font-weight-bold text-center">
				{{ car.key === "0" ? $t("carModal.keyModeConfirm") : $t("carModal.keylessModeConfirm") }}
			</v-card-text>
			<v-card-text>
				<v-btn block rounded color="success" class="mt-3" @click="saveKeyless">
					{{ $t("general.yes") }}
				</v-btn>
				<v-btn block rounded outlined color="accent" class="mt-3" @click="no">
					{{ $t("general.no") }}
				</v-btn>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		fab: {
			default: false,
		},
		car: {
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			loading: false,
		};
	},
	computed: {
		keyBtnIcon(icon) {
			return icon === 0 ? "mdi-lock" : "mdi-lock-open-outline";
		},

		keyBtnColor(icon) {
			return icon === 0 ? "error" : "success";
		},
	},
	methods: {
		keyStatus() {
			if (this.car.key === "0") {
				return {
					icon: "mdi-key-outline",
					color: "success",
					outlined: true,
				};
			} else {
				return {
					icon: "mdi-key-outline",
					color: "success",
					outlined: false,
				};
			}
		},

		async saveKeyless() {
			this.loading = true;

			try {
				const payload = {
					id: this.car._id,
					body: {
						updateData: {
							key: String(this.car.key === "1" ? 0 : 1),
						},
						publish: true,
					},
				};

				await this.$store.dispatch("updateDevice", payload);

				this.loading = false;
			} catch (err) {
				const message = err;
				this.$toast.error(message);

				this.loading = false;
			}

			this.dialog = false;
		},

		no() {
			this.dialog = false;
		},
	},
};
</script>
