<template>
	<div v-resize="onResize">
		<v-data-table
			v-model="selectedCars"
			class="rounded-lg fleet-table"
			:headers="headers"
			:items="devices"
			sort-by="carNumber"
			show-select
			item-key="_id"
			:mobile-breakpoint="0"
			:page.sync="page"
			:items-per-page="itemsPerPage.val"
			hide-default-footer
			:header-props="{
				'sort-icon': 'mdi-menu-down',
			}"
			:height="$vuetify.breakpoint.xs ? windowSize.y - 98 - 24 - 72 : windowSize.y - 98 - 24 - 12"
			fixed-header
			:no-data-text="$t('fleetPage.table.noDevicesText')"
			@page-count="pageCount = $event"
			@click:row="openCarSidebar"
		>
			<template v-slot:header.data-table-select="{ props, on }">
				<v-simple-checkbox color="primary" v-if="props.indeterminate" v-ripple v-bind="props" :value="props.indeterminate" v-on="on"></v-simple-checkbox>
				<v-simple-checkbox color="primary" v-if="!props.indeterminate" v-ripple v-bind="props" v-on="on"></v-simple-checkbox>
			</template>
			<template v-slot:item.data-table-select="{ item, isSelected, select }">
				<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
			</template>

			<template v-slot:item.carNumber="{ item }">
				<v-chip outlined label color="accent" class="font-weight-bold">
					{{ item.carNumber }}
				</v-chip>
			</template>
			<template v-slot:item.carName="{ item }">
				<div class="no-wrap">
					<v-chip class="mr-2" dark color="accent" small label>
						<v-icon small>{{ item.carIcon }}</v-icon>
					</v-chip>
					<span v-if="item.ride && item.ride.meta.name" class="font-weight-bold text-wrap mt-1">
						<v-icon small class="mb-1" color="accent"> mdi-account-outline </v-icon>
						{{ item.ride.meta.name }}
					</span>
					<span v-else class="font-weight-bold accent--text">
						{{ item.carName }}
					</span>
				</div>
			</template>

			<template v-slot:item.timeUTC="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							<v-chip v-if="isOnline(item) && item.inUse" class="font-weight-bold" color="#e9c36a" dark small label>
								{{ $t("fleetPage.inUse") }}
							</v-chip>
							<v-chip v-else class="font-weight-bold" :color="`${onlineStatus(item).color}`" dark small label>
								{{ onlineStatus(item).text }}
							</v-chip>
						</span>
					</template>
					<span class="caption">
						{{
							isOnline(item)
								? computedTimeAgo(item.timeUTC)
								: item.disconnectedAt
								? `Disconnected: ${disconnectedAt(item.disconnectedAt)}`
								: `Last seen: ${computedTimeAgo(item.timeUTC)}`
						}}
					</span>
				</v-tooltip>
			</template>

			<template v-slot:item.ride="{ item }">
				<div class="no-wrap" v-if="item.ride">
					<span v-if="item.ride.service">
						<service-chip :serviceId="item.ride.service" :priceId="item.ride.price" size="small" />
					</span>
					<span v-if="item.sessionBooking">
						<v-chip label small color="primary lighten-4" class="mx-2 font-weight-bold mr-2 primary--text elevation-2" @click.stop="viewBooking(item.sessionBooking)">
							<v-icon small left>mdi-calendar</v-icon>
							{{ translatedFormattedDate(item.sessionBooking.start, "HH:mm") }}
							<v-icon x-small class="mx-2">mdi-ray-start-end</v-icon>
							{{ translatedFormattedDate(item.sessionBooking.end, "HH:mm") }}
						</v-chip>
					</span>

					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								<v-chip class="mx-2 primary--text font-weight-bold" color="primary lighten-4" small label>
									<v-icon left small>mdi-speedometer</v-icon>
									{{ deviceSpeed(item.gpsSpeed) }}
								</v-chip>
							</span>
						</template>
						<span class="font-weight-bold">
							{{ $t("mapPage.bottomSheet.speed") }}
						</span>
					</v-tooltip>
				</div>
				<div v-else class="no-wrap">
					<v-chip-group>
						<v-chip
							v-for="(booking, i) in item.bookings"
							class="font-weight-bold elevation-2"
							:class="booking.bookingType === 'cart_maintenance' ? 'white--text' : booking.isBookingNow ? 'white--text' : 'primary--text'"
							:color="booking.bookingType === 'cart_maintenance' ? 'error' : booking.isBookingNow ? 'primary' : 'primary lighten-4'"
							small
							label
							:key="i"
							@click.stop="viewBooking(booking)"
						>
							<div v-if="booking.bookingType === 'cart_maintenance'">
								<v-icon small left>mdi-wrench</v-icon>
								Maintenance
							</div>

							<div v-else>
								<v-icon small left>mdi-calendar</v-icon>
								<v-icon v-if="booking.scheduleUnlock" small left> mdi-timer-outline </v-icon>

								{{ translatedFormattedDate(booking.start, "HH:mm") }}
							</div>
						</v-chip>
					</v-chip-group>
				</div>
			</template>
			<template v-slot:item.items="{ item }">
				<div class="no-wrap">
					<div>
						<fleet-lock-button :device="item" />
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip v-if="!showVoltage" class="mr-2" color="transparent" small label @click.stop="showVoltage = !showVoltage">
										<v-icon :color="batteryDisplay(item).color">
											{{ batteryDisplay(item).icon }}
										</v-icon>
									</v-chip>
									<v-chip v-else small label class="primary--text mr-2" color="primary lighten-4" @click.stop="showVoltage = !showVoltage">
										<v-icon small left>mdi-battery-charging</v-icon>
										{{ `${item.battVoltage}v` }}
									</v-chip>
								</span>
							</template>
							<span class="font-weight-bold">
								{{ batteryDisplay(item).text }}
							</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip :class="`${updateStatus(item.updates).color}--text font-weight-bold mr-2`" :color="`${updateStatus(item.updates).color} lighten-4`" dark small label>
										<v-icon small>
											{{ updateStatus(item.updates).icon }}
										</v-icon>
									</v-chip>
								</span>
							</template>
							<span class="font-weight-bold">
								{{ updateMessageText(item.updates) }}
							</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip v-if="isOnline(item)" class="mr-2 primary--text" color="primary lighten-4" dark small label>
										<v-icon small>
											{{ signalDisplay(item).icon }}
										</v-icon>
									</v-chip>
								</span>
							</template>
							<span class="font-weight-bold">{{ conText(item.conType) }}</span>
						</v-tooltip>
						<note-modal v-if="item.carNote" :car="item" />
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip v-if="showTimer(item)" class="mr-2 primary--text font-weight-bold" color="primary lighten-4" small label>
										<v-icon left small>mdi-lock-clock</v-icon>
										{{ item.lockTimeRemaining }}
									</v-chip>
								</span>
							</template>
							<span class="font-weight-bold"> Mins until Auto Lock </span>
						</v-tooltip>
						<key-icon v-if="item.key === '1'" :keyAlarm="item.keyAlarm" />
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip v-if="!item.inService" class="mr-2 white--text font-weight-bold" color="error" small label>
										<v-icon small>mdi-cancel</v-icon>
									</v-chip>
								</span>
							</template>
							<span class="font-weight-bold"> Out of Service </span>
						</v-tooltip>
					</div>
				</div>
			</template>
		</v-data-table>
		<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%" :style="$vuetify.breakpoint.xs ? `bottom: 56px;` : 'bottom: 0;'">
			<v-toolbar flat dense class="mx-3 rounded-t-lg">
				<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

				<v-select
					class="perPage"
					v-model="itemsPerPage"
					:items="items"
					item-text="text"
					item-value="val"
					label="Select"
					hide-details
					return-object
					single-line
					dense
					outlined
				></v-select>
			</v-toolbar>
		</v-row>
		<booking-view ref="bookingView" />
		<maintenance-modal ref="maintenanceModal" />
	</div>
</template>

<script>
import NoteModal from "@/components/fleet/NoteModal";
import BookingView from "@/components/bookings/BookingView";
import FleetLockButton from "@/components/fleet/LockModal";
import KeyIcon from "@/components/fleet/keyIcon.vue";
import ServiceChip from "@/components/ServiceChip";
import MaintenanceModal from "@/components/bookings/MaintenanceModal.vue";

import { mapState } from "vuex";

export default {
	props: {
		selected: { type: Array },
		devices: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		NoteModal,
		BookingView,
		FleetLockButton,
		KeyIcon,
		ServiceChip,
		MaintenanceModal,
	},
	data() {
		return {
			showVoltage: false,
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
			windowSize: {
				x: 0,
				y: 0,
			},
			page: 1,
			pageCount: 0,
			headers: [
				{
					text: this.$i18n.t("fleetPage.table.number"),
					value: "carNumber",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: this.$i18n.t("fleetPage.table.name"),
					value: "carName",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: this.$i18n.t("fleetPage.table.status"),
					value: "timeUTC",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				// {
				//   text: this.$i18n.t("fleetPage.table.unlockCode"),
				//   value: "lockCode",
				//   align: "center",
				//   class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				// },
				{
					text: "Ride Info & Bookings",
					value: "ride",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: this.$i18n.t("fleetPage.table.info"),
					value: "items",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "",
					value: "edit",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 ",
				},
			],
		};
	},
	watch: {
		page() {
			this.selectedCars = [];
		},

		itemsPerPage(newVal) {
			localStorage.carsPerPage = JSON.stringify(newVal);
		},
	},
	mounted() {
		if (localStorage.carsPerPage) {
			this.itemsPerPage = JSON.parse(localStorage.carsPerPage);
		}
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),

		selectedCars: {
			get() {
				return this.selected;
			},
			set(val) {
				this.$emit("setSelectedCars", val);
			},
		},
	},
	methods: {
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		viewBooking(booking) {
			if (booking.bookingType === "cart_maintenance") {
				this.$refs.maintenanceModal.open(booking);
				return;
			}

			this.$refs.bookingView.open(booking);
		},

		showTimer(item) {
			return Number(item.lockTimeRemaining) > 0 && Number(item.lockTimeRemaining) < Number(this.selectedSite.autoLock) && !item.inUse ? true : false;
		},

		tripDistance(item) {
			let distance = item.ride.endOdometer ? item.ride.endOdometer - item.ride.startOdometer : Number(item.carOdometer) - item.ride.startOdometer;

			return distance.toFixed(1);
		},

		openCarSidebar(payload) {
			if (this.hasRole("user")) {
				this.$store.commit("setSelectedDeviceId", payload._id);
			}
		},
	},
};
</script>

<style lang="scss">
.no-wrap {
	white-space: nowrap !important;
}

.perPage {
	width: 90px;
	transform: scale(0.8);
	position: absolute;
	right: 5px;
	bottom: 5px;
}
</style>
