import * as membersService from "@/services/members.service";

export default {
	state: {
		members: [],
		membersPagination: {
			currentPage: 1,
			totalPages: 0,
			totalItems: 0,
			itemsPerPage: 50,
			nextPage: null,
			prevPage: null,
		},
	},

	mutations: {
		setMembers(state, payload) {
			state.members = payload;
		},

		setMembersPagination(state, payload) {
			state.membersPagination = payload;
		},

		setMembersCurrentPage(state, payload) {
			state.membersPagination.currentPage = payload;
		},

		setMembersItemsPerPage(state, payload) {
			state.membersPagination.itemsPerPage = payload;
		},
	},

	actions: {
		async getMembers({ commit, state }, payload = {}) {
			const defaultQuery = {
				page: state.membersPagination.currentPage,
				limit: state.membersPagination.itemsPerPage,
				sort: "name:asc",
			};

			// Create a query object by combining defaultQuery with payload
			// It filters out null, undefined, or empty string values
			const query = Object.entries({ ...defaultQuery, ...payload }).reduce((acc, [key, value]) => {
				if (value !== null && value !== undefined && value !== "") {
					acc[key] = value;
				}
				return acc;
			}, {});

			const { data, pagination } = await membersService.getMembers(query);

			commit("setMembers", data);
			commit("setMembersPagination", pagination);

			// Adjust current page if necessary
			if (state.membersPagination.currentPage > pagination.totalPages) {
				commit("setMembersCurrentPage", pagination.totalPages);
			}

			if (pagination.totalPages === 1) {
				commit("setMembersCurrentPage", 1);
			}
		},

		async addMember({ dispatch }, payload) {
			await membersService.addMember(payload);
			await dispatch("getMembers");
		},

		async importMembers({ dispatch }, payload) {
			const members = await membersService.importMembers(payload);
			await dispatch("getMembers");

			return members;
		},

		async updateMember({ dispatch }, payload) {
			await membersService.updateMember(payload);
			await dispatch("getMembers");
		},

		async updateMembers({ dispatch }, payload) {
			await membersService.updateMembers(payload);
			await dispatch("getMembers");
		},

		async linkMember({ dispatch }, payload) {
			const member = await membersService.linkMember(payload);
			await dispatch("getMembers");

			return member;
		},

		async unlinkMember({ dispatch }, payload) {
			const member = await membersService.unlinkMember(payload);
			await dispatch("getMembers");

			return member;
		},

		async linkMembers({ dispatch }, payload) {
			const res = await membersService.linkMembers(payload);
			await dispatch("getMembers");

			return res;
		},

		async unlinkMembers({ dispatch }, payload) {
			const res = await membersService.unlinkMembers(payload);
			await dispatch("getMembers");

			return res;
		},

		async deleteMember({ dispatch }, memberId) {
			await membersService.deleteMember(memberId);
			await dispatch("getMembers");
		},

		async deleteMembers({ dispatch }, ids) {
			await membersService.deleteMembers(ids);
			await dispatch("getMembers");
		},
	},
};
