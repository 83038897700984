<template>
	<v-container>
		<v-list v-if="localOpeningHours.length">
			<v-list-item v-for="(day, index) in localOpeningHours" :key="index">
				<v-list-item-action>
					<v-switch :input-value="day !== null" @change="() => toggleDay(index)" inset />
				</v-list-item-action>

				<v-list-item-content>
					<v-list-item-title class="subtitle-2 font-weight-bold">{{ $t(`general.days.long.${index}`) }}</v-list-item-title>
				</v-list-item-content>

				<v-list-item-content v-if="day">
					<v-row>
						<v-col>
							<time-picker v-model="day.openTime" label="Open" />
						</v-col>
						<v-col>
							<time-picker v-model="day.closeTime" label="Closed" />
						</v-col>
					</v-row>
				</v-list-item-content>

				<v-list-item-action v-else>
					<v-card color="grey" flat>
						<v-card-text class="font-weight-bold subtitle-2 pa-2">
							<v-icon left>mdi-weather-night</v-icon>
							Closed
						</v-card-text>
					</v-card>
				</v-list-item-action>
			</v-list-item>
		</v-list>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="primary" outlined small @click="cancel" :disabled="!isDirty || loading">{{ $t("general.cancel") }}</v-btn>
			<v-btn color="primary" :loading="loading" depressed small @click="save" :disabled="!isDirty"> {{ $t("general.save") }}</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import TimePicker from "@/components/TimePicker.vue";
import cloneDeep from "lodash/cloneDeep";

export default {
	components: {
		TimePicker,
	},
	data() {
		return {
			localOpeningHours: [],
			loading: false,
		};
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),

		isDirty() {
			return JSON.stringify(this.localOpeningHours) !== JSON.stringify(this.selectedSite.openingHours);
		},
	},
	mounted() {
		this.localOpeningHours = this.deepCopyOpeningHours();
	},
	watch: {
		// Watch for changes in the store and update local copy accordingly
		selectedSite: {
			handler() {
				this.localOpeningHours = this.deepCopyOpeningHours();
			},
			deep: true,
		},
	},
	methods: {
		deepCopyOpeningHours() {
			return cloneDeep(this.selectedSite.openingHours);
		},

		toggleDay(index) {
			// If the day is already set, toggle it to null
			if (this.localOpeningHours[index]) {
				this.$set(this.localOpeningHours, index, null);
			} else {
				// If the day is null, toggle it to the default opening hours
				this.$set(this.localOpeningHours, index, { ...(this.selectedSite.openingHours[index] || { openTime: "05:00", closeTime: "20:00" }) });
			}
		},

		async save() {
			this.loading = true;

			try {
				await this.$store.dispatch("updateSite", { updateData: { openingHours: this.localOpeningHours } });
				this.$toast.success("Opening hours updated");
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},

		cancel() {
			// Reset the local copy to the store
			this.localOpeningHours = this.deepCopyOpeningHours();
		},
	},
};
</script>
