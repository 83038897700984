import Vue from "vue";

export const getUsers = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get("/users");

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateUser = async (payload) => {
	const { id, body } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.patch(`/users/${id}`, body);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteUser = async (id) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.delete(`/users/${id}`);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getInvitedUsers = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get("/users/invites");

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const inviteUser = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post("/users/invites", payload);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteInvitedUser = async (id) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.delete(`/users/invites/${id}`);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};
