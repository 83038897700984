import Vue from "vue";
import i18n from "@/i18n";
import * as sitesService from "@/services/sites.service";

export default {
	state: {
		sites: [],
		selectedSite: null,
		userGroups: [],
		services: [],
	},

	mutations: {
		// Set ALL Sites
		setSites(state, payload) {
			state.sites = payload;
		},

		// Selected Site
		setSelectedSite(state, payload) {
			state.selectedSite = payload;
		},

		updateOpeningHours(state, { index, value }) {
			// Vue.set is used for reactivity
			Vue.set(state.selectedSite.openingHours, index, value);
		},

		setUserGroups(state, payload) {
			state.userGroups = payload;
		},

		setServices(state, payload) {
			state.services = payload;
		},
	},

	actions: {
		async getSite({ commit, dispatch }) {
			const site = await sitesService.getSite();
			commit("setSelectedSite", site);
			await dispatch("getSiteUserGroups");
			await dispatch("getSiteServices");
		},

		async getSites({ commit }) {
			const sites = await sitesService.getSites();
			commit("setSites", sites);

			return sites;
		},

		async updateSite({ dispatch }, payload) {
			await sitesService.updateSite(payload);
			await dispatch("getSite");
		},

		// Add New Site with UserId
		async addNewSite({ dispatch }, payload) {
			try {
				await sitesService.handleAddNewSite(payload);
				dispatch("getAllSites");
			} catch (error) {
				return error;
			}
		},

		async deleteSite({ dispatch }, payload) {
			try {
				const res = await sitesService.handleDeleteSite(payload);
				dispatch("getAllSites");
				return res;
			} catch (err) {
				return err;
			}
		},

		async getSiteUserGroups({ commit }) {
			const userGroups = await sitesService.getSiteUserGroups();
			commit("setUserGroups", userGroups);
		},

		async addSiteUserGroup({ dispatch }, payload) {
			await sitesService.addSiteUserGroup(payload);
			await dispatch("getSiteUserGroups");
		},

		async updateSiteUserGroup({ dispatch }, payload) {
			await sitesService.updateSiteUserGroup(payload);
			await dispatch("getSiteUserGroups");
		},

		async deleteSiteUserGroup({ dispatch }, payload) {
			await sitesService.deleteSiteUserGroup(payload);
			await dispatch("getSiteUserGroups");
			await dispatch("getServices");
		},

		async getSiteServices({ commit }) {
			const services = await sitesService.getSiteServices();
			commit("setServices", services);
		},

		async addSiteService({ dispatch }, payload) {
			await sitesService.addSiteService(payload);
			await dispatch("getSiteServices");
		},

		async updateSiteService({ dispatch }, payload) {
			await sitesService.updateSiteService(payload);
			await dispatch("getSiteServices");
		},

		async deleteSiteService({ dispatch }, payload) {
			await sitesService.deleteSiteService(payload);
			await dispatch("getSiteServices");
		},

		async onboardStripeConnectAccount() {
			return await sitesService.onboardStripeConnectAccount();
		},

		async uploadLogo({ dispatch }, payload) {
			await sitesService.uploadLogo(payload);
			await dispatch("getSite");
		},

		async uploadCoverPhoto({ dispatch }, payload) {
			await sitesService.uploadCoverPhoto(payload);
			await dispatch("getSite");
		},
	},
	getters: {
		getUserGroupName: (state) => (id) => {
			const group = state.userGroups.find((userGroup) => userGroup._id === id);

			if (group) {
				return group.name;
			}

			return i18n.t("general.memberTypes.guest");
		},

		getOpeningHours: (state) => (day) => {
			if (state.selectedSite) {
				const hours = state.selectedSite.openingHours[day];

				if (!hours) return { hoursOpen: 0, openTime: 0, closeTime: 0, open: false };
				const { openTime, closeTime } = hours;

				const startDateTime = new Date(`2000-01-01T${openTime}`);
				const endDateTime = new Date(`2000-01-01T${closeTime}`);
				const diff = endDateTime - startDateTime;
				const hoursOpen = Math.floor(diff / (1000 * 60 * 60) + 1);

				return { hoursOpen, openTime, closeTime, open: true };
			}

			return null;
		},

		serviceFromId: (state) => (id) => {
			return state.services.find((service) => service._id === id);
		},

		priceFromIdAndServiceId: (state) => (priceId, serviceId) => {
			const service = state.services.find((service) => service._id === serviceId);

			if (!service) return null;

			return service.prices.find((price) => price._id === priceId);
		},
	},
};
