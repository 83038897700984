<template>
	<div>
		<v-card v-if="loading" class="rounded-lg" outlined>
			<v-card-text class="text-center">
				<v-progress-circular
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-card-text>
		</v-card>

		<not-found
			v-else-if="!rates.length || !site.stripeAccountActive"
			:title="$t('s2r.payment.notFound.title')"
			:subtitle="$t('s2r.payment.notFound.subtitle')"
		/>

		<div v-else>
			<div v-if="specialRates.length">
				<v-card outlined class="rounded-lg mb-4" color="primary lighten-5">
					<div class="pa-4">
						<div class="subtitle-1 font-weight-bold">
							<v-icon left>mdi-star-circle-outline</v-icon>
							{{ $t("s2r.payment.special.title") }}
						</div>
						<div class="mb-4 mt-2 subtitle-2">
							{{ $t("s2r.payment.special.subtitle") }}
						</div>
						<v-item-group
							v-model="selectedSpecialRateValue"
							active-class="primary lighten-4 primary--text"
						>
							<v-item
								v-slot="{ active, toggle }"
								v-for="(rate, i) in specialRates"
								:key="`special-rate-${i}`"
							>
								<v-card
									:elevation="active ? '8' : '0'"
									:outlined="!active"
									:style="active && 'border: 2px solid #3F6DFA !important'"
									class="rounded-lg mb-4"
									@click="selectRate(rate, toggle, i, 'special')"
								>
									<v-list-item :value="i">
										<v-list-item-action>
											<v-checkbox
												:input-value="active"
												color="primary accent-4"
												on-icon="mdi-circle-slice-8"
												off-icon="mdi-circle-outline"
											></v-checkbox>
										</v-list-item-action>

										<v-list-item-content>
											<v-list-item-title class="font-weight-bold">
												{{ rate.name }}
											</v-list-item-title>
											<v-list-item-subtitle v-if="rate.desc" class="caption">
												{{ rate.desc }}
											</v-list-item-subtitle>
										</v-list-item-content>

										<v-list-item-action class="font-weight-bold">
											{{ formattedPrice(rate.amount, site.currency) }}
										</v-list-item-action>
									</v-list-item>
								</v-card>
							</v-item>
						</v-item-group>
					</div>
				</v-card>
			</div>

			<div v-if="dailyRates.length">
				<v-card outlined class="rounded-lg" color="grey">
					<div class="pa-4">
						<div class="subtitle-1 font-weight-bold">
							<v-icon left>mdi-golf</v-icon>
							{{ $t("s2r.payment.daily.title") }}
						</div>
						<div class="mb-4 mt-2 subtitle-2">
							{{ $t("s2r.payment.daily.subtitle") }}
						</div>
						<v-item-group
							v-model="selectedDailyRateValue"
							active-class="primary lighten-4 primary--text"
						>
							<v-item
								v-slot="{ active, toggle }"
								v-for="(rate, i) in dailyRates"
								:key="`daily-rate-${i}`"
							>
								<v-card
									:elevation="active ? '8' : '0'"
									:outlined="!active"
									:style="active && 'border: 2px solid #3F6DFA !important'"
									class="rounded-lg mb-4"
									@click="selectRate(rate, toggle, i, 'daily')"
								>
									<v-list-item :value="i">
										<v-list-item-action>
											<v-checkbox
												:input-value="active"
												color="primary accent-4"
												on-icon="mdi-circle-slice-8"
												off-icon="mdi-circle-outline"
											></v-checkbox>
										</v-list-item-action>

										<v-list-item-content>
											<v-list-item-title class="font-weight-bold">
												{{ rate.name }}
											</v-list-item-title>
											<v-list-item-subtitle v-if="rate.desc" class="caption">
												{{ rate.desc }}
											</v-list-item-subtitle>
										</v-list-item-content>

										<v-list-item-action class="font-weight-bold">
											{{ formattedPrice(rate.amount, site.currency) }}
										</v-list-item-action>
									</v-list-item>
								</v-card>
							</v-item>
						</v-item-group>
					</div>
				</v-card>
			</div>
		</div>

		<rate-bottom-sheet
			:value.sync="sheet"
			:selected-rate="selectedRate"
			:site="site"
			:device="device"
			:status="status"
		/>
	</div>
</template>

<script>
import NotFound from "../../components/scanToRide/NotFound.vue";
import RateBottomSheet from "../../components/scanToRide/RateBottomSheet.vue";

export default {
	components: { NotFound, RateBottomSheet },
	props: ["device", "site", "status"],
	data() {
		return {
			loading: true,
			rates: [],
			selectedRate: null,
			selectedSpecialRateValue: null,
			selectedDailyRateValue: null,
			sheet: false,
			specialRates: [],
			dailyRates: [],
		};
	},
	watch: {
		sheet(val) {
			if (!val) {
				this.clearSelection();
			}
		},
	},
	mounted() {
		if (this.status === "SITE_CLOSED") {
			this.$router.push({
				path: `/ride/${this.$route.params.id}`,
			});
		}

		this.getRates();
	},
	methods: {
		selectRate(rate, toggle, index, category) {
			if (category === "special") {
				this.setSelectedRate(rate, index, "special");
			} else if (category === "daily") {
				this.setSelectedRate(rate, index, "daily");
			}
			this.openBottomSheet();
			toggle();
		},

		setSelectedRate(rate, index, category) {
			if (category === "special") {
				this.selectedRate = this.specialRates[index];
				this.selectedSpecialRateValue = index;
			} else if (category === "daily") {
				this.selectedRate = this.dailyRates[index];
				this.selectedDailyRateValue = index;
			}
		},

		openBottomSheet() {
			this.sheet = true;
		},

		clearSelection() {
			this.selectedRate = null;
			this.selectedSpecialRateValue = null;
			this.selectedDailyRateValue = null;
		},

		async getRates() {
			try {
				const { data } = await this.$ioteeApiV2.get(
					`/sites/${this.site.id}/rates`
				);

				if (!data) return;

				// Separate rates into specialRates and dailyRates
				const specialRates = data.filter((rate) => rate.range.enabled);
				const dailyRates = data.filter((rate) => !rate.range.enabled);

				this.specialRates = specialRates;
				this.dailyRates = dailyRates;

				this.rates = data;
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
