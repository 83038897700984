import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "leaflet/dist/leaflet.css";

// Axios
import axios from "./axios";

// Vue Meta
import VueMeta from "vue-meta";
Vue.use(VueMeta);

// Vue Cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Tranlations
import i18n from "./i18n";

// Mixins
import "@/mixins";

// Apex Charts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

// Time Picker
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
Vue.component("vue-timepicker", VueTimepicker);

import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

// ? HTML Paper
import VueHtmlToPaper from "vue-html-to-paper";
Vue.use(VueHtmlToPaper);

// ?Crisp Chat
import CrispChat from "@dansmaculotte/vue-crisp-chat";

Vue.use(CrispChat, {
	websiteId: "ed238b08-91ea-4642-ac22-f0bf2235be43",
	disabled: true,
	hideOnLoad: false,
});

import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

// ? Leaflet Vue
// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl;
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
	iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
	iconUrl: require("leaflet/dist/images/marker-icon.png"),
	shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.config.productionTip = false;

import socket from "@/socket";
import VueSocketIOExt from "vue-socket.io-extended";
Vue.use(VueSocketIOExt, socket, { store });

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(Toast);

import money from "v-money";
Vue.use(money);
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

new Vue({
	router,
	store,
	vuetify,
	axios,
	i18n,
	async created() {
		await store.dispatch("handlePostLoginRequests");
	},
	render: (h) => h(App),
}).$mount("#app");
