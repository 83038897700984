<template>
	<div>
		<v-menu :close-on-content-click="false" bottom left offset-y min-width="180px" :nudge-right="$vuetify.breakpoint.smAndUp ? '80' : '0'" v-model="menu">
			<template v-slot:activator="{ on: menu }">
				<v-btn :disabled="!selectedCars.length" small color="accent" class="ml-2" depressed outlined v-on="menu">
					Actions
					<v-icon small right>mdi-menu-down</v-icon>
				</v-btn>
			</template>

			<v-list dense nav>
				<v-list-item class="font-weight-bold"> Selected: {{ selectedCars.length }} </v-list-item>
				<v-list-group prepend-icon="mdi-broadcast" no-action>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title>Commands</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item @click="pingDevices(2)" :disabled="loading" class="pl-10">
						<v-list-item-title>
							<v-icon small left>mdi-broadcast</v-icon>
							Ping
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="pingDevices(9, true)" :disabled="loading" class="pl-10">
						<v-list-item-title>
							<v-icon small left>mdi-video-input-antenna</v-icon>
							Restart Modem
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="pingDevices(3, true)" :disabled="loading" class="pl-10">
						<v-list-item-title>
							<v-icon small left>mdi-rotate-left</v-icon>
							Reboot
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="pingDevices(10, true)" :disabled="loading" class="pl-10">
						<v-list-item-title>
							<v-icon small left>mdi-wifi-refresh</v-icon>
							Restart WiFi
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="pingDevices(11, true)" :disabled="loading" class="pl-10">
						<v-list-item-title>
							<v-icon small left>mdi-signal-2g</v-icon>
							Toggle CAT-M1
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="pingDevices(4, true)" :disabled="loading" class="pl-10">
						<v-list-item-title>
							<v-icon small left>mdi-cog-refresh-outline</v-icon>
							Reset
						</v-list-item-title>
					</v-list-item>
					<custom-ping @pingDevice="pingDevices($event)" />
				</v-list-group>

				<multi-car-vehicle-settings @updateVehicleInfo="updateVehicleInfo" />
				<multi-hardware-info @hardwareInfo="updateHardwareInfo" />
				<transfer-server-modal @saveServer="saveServer" />

				<calibrate-battery :selectedCars="selectedCars" @clearSelected="clearSelected" />

				<v-list-item @click="pingDevices(1)" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-golf</v-icon>
						Publish Site Settings
					</v-list-item-title>
				</v-list-item>

				<v-list-item @click="updateCarts()" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-memory</v-icon>
						Publish Device Settings
					</v-list-item-title>
				</v-list-item>
				<v-list-item @click="deleteDevices()" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-delete-alert</v-icon>
						Delete
					</v-list-item-title>
				</v-list-item>

				<site-set :selectedSite="selectedSite" :selectedCars="selectedCars" @clearSelected="clearSelected" />
			</v-list>
		</v-menu>
		<confirm ref="confirm" />
	</div>
</template>

<script>
import Confirm from "@/components/Confirm";
import SiteSet from "@/components/admin/SiteSet";
import CustomPing from "@/components/admin/CustomPing";
import MultiCarVehicleSettings from "@/components/fleet/MultiCarVehicleSettings";
import MultiHardwareInfo from "@/components/admin/MultiHardwareInfo";
import TransferServerModal from "./TransferServerModal.vue";
import CalibrateBattery from "./CalibrateBattery.vue";

export default {
	props: {
		selectedSite: {
			default: null,
		},
		selectedCars: {
			default: null,
		},
	},
	components: {
		Confirm,
		SiteSet,
		CustomPing,
		MultiCarVehicleSettings,
		MultiHardwareInfo,
		TransferServerModal,
		CalibrateBattery,
	},
	data() {
		return {
			loading: false,
			menu: false,
		};
	},
	methods: {
		clearSelected() {
			this.$emit("resetSelected", []);
			this.menu = false;
		},

		async updateCarts() {
			this.loading = true;

			try {
				const settings = {
					ids: this.selectedCars.map((selected) => selected._id),
					updateData: {},
					publish: true,
				};

				await this.$store.dispatch("updateDevices", settings);
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},

		pingMessage(val) {
			switch (val) {
				case 4:
					return "Reset Devices?";

				case 3:
					return "Reboot Devices?";

				case 9:
					return "Restart Modem?";

				case 10:
					return "Restart WiFi?";

				case 11:
					return "Toggle CAT-M1?";

				default:
					return "Are you sure?";
			}
		},

		async pingDevices(val, confirm = false) {
			this.loading = true;

			const devices = this.selectedCars.map((selected) => {
				return selected._id;
			});

			try {
				if (confirm) {
					const res = await this.$refs.confirm.open(this.pingMessage(val), ``, "accent", "mdi-help-circle-outline");

					if (res !== true) {
						this.loading = false;

						return;
					}
				}

				await this.$store.dispatch("admin/pingDevices", {
					ids: devices,
					command: val,
				});

				const message = `Command sent to ${this.selectedCars.length} Car${this.selectedCars.length > 1 ? "s" : ""}`;
				this.$toast.success(message);
			} catch (err) {
				if (err.error) {
					this.$toast.error(err.error.message);
				} else {
					this.$toast.error("Error sending command");
				}
				console.log(err);
			} finally {
				this.loading = false;
				this.clearSelected();
			}
		},

		async deleteDevices() {
			this.loading = true;
			const res = await this.$refs.confirm.open("Delete Cars?", `Are you sure you want to delete selected Cars?`, "error", "mdi-delete-alert");

			if (res === true) {
				try {
					const settings = this.selectedCars.map((selected) => {
						return selected._id;
					});

					await this.$store.dispatch("admin/deleteDevices", settings);
				} catch (err) {
					console.log(err);
				}
			}

			this.loading = false;
			this.clearSelected();
		},

		async updateVehicleInfo(val) {
			const settings = {
				ids: this.selectedCars.map((selected) => selected._id),
				updateData: {
					vehicleInfo: val,
				},
			};

			await this.$store.dispatch("updateDevices", settings);

			this.loading = false;
			this.clearSelected();
		},

		async updateHardwareInfo(val) {
			const settings = {
				ids: this.selectedCars.map((selected) => selected._id),
				updateData: {
					hardwareInfo: val,
				},
			};

			await this.$store.dispatch("updateDevices", settings);

			this.loading = false;
			this.clearSelected();
		},

		async saveServer(val) {
			const res = await this.$refs.confirm.open(`Change Server?`, `Selected devices will appear in ${val.name} staging`, "warning", "mdi-server");

			if (res === true) {
				this.loadingSetServer = true;

				const settings = {
					ids: this.selectedCars.map((selected) => selected._id),
					updateData: {
						server: val.value,
					},
					publish: 1,
				};

				await this.$store.dispatch("updateDevices", settings);

				this.loadingSetServer = false;
			}
		},
	},
};
</script>
