<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-title>
					<v-icon small left>mdi-golf</v-icon>
					Move Site
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>Move Site</span>
			</v-card-text>
			<v-card-text>
				<sites-picker :model="selectedSite._id" @siteID="setSiteID" :chips="false" class="mb-3" />
				<v-btn block rounded @click="saveSite" color="primary" class="mt-3" :loading="loading" :disabled="!siteID"> Save </v-btn>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import SitesPicker from "@/components/SitesPicker";

export default {
	props: ["selectedCars", "selectedSite"],
	components: {
		SitesPicker,
	},
	data() {
		return {
			dialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			siteID: null,
		};
	},
	methods: {
		setSiteID(val) {
			this.siteID = val;
		},

		async saveSite() {
			this.loading = true;

			try {
				const settings = {
					ids: this.selectedCars.map((selected) => selected._id),
					updateData: {
						siteID: this.siteID,
					},
					publish: 1,
				};

				await this.$store.dispatch("updateDevices", settings);
				this.dialog = false;
				this.$emit("clearSelected");
			} catch (err) {
				this.$toast.error(err.message);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
