<template>
	<div>
		<apexchart :height="chartOptions.chart.height" :options="chartOptions" :series="seriesData"></apexchart>
	</div>
</template>

<script>
export default {
	props: {
		rides: {
			type: Array,
			required: true,
		},
		services: {
			type: Array,
			required: true,
		},
	},
	computed: {
		formattedCategories() {
			// Map service names as categories, ensuring all services are shown
			return this.services.map((service) => service.name);
		},

		seriesData() {
			// Initialize a count object with all service IDs set to 0
			const serviceCounts = this.services.reduce((acc, service) => {
				acc[service._id] = 0;
				return acc;
			}, {});

			// Count rides for each service
			this.rides.forEach((ride) => {
				if (ride.service && ride.service in serviceCounts) {
					serviceCounts[ride.service]++;
				}
			});

			// Convert the serviceCounts into an array of counts aligned with the services order
			const data = this.services.map((service) => serviceCounts[service._id]);

			return [
				{
					name: "Rounds",
					data,
				},
			];
		},

		chartOptions() {
			return {
				chart: {
					height: 250,
					type: "bar",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					dropShadow: {
						enabled: true,
						top: 4,
						left: 0,
						blur: 2,
						opacity: 0.1,
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
						horizontal: true,
					},
				},
				dataLabels: {
					enabled: true,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					categories: this.formattedCategories,
					labels: {
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
						formatter: (val) => {
							return parseInt(val) === val ? val : "";
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					crosshairs: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						showDuplicates: false,
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
					},
				},
				colors: ["#406DFA"],
				grid: {
					show: false,
				},
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex }) {
						// vuetify chip with the date and the number of rides
						return `<div class="pa-2 font-weight-bold accent--text">
              <span >${series[seriesIndex][dataPointIndex]} Rounds</span>
            </div>`;
					},
					followCursor: true,
				},
			};
		},
	},
};
</script>
