import Vue from "vue";
import { jwtDecode } from "jwt-decode";

const permissionsHierarchy = ["guest", "basic", "user", "service", "admin", "superadmin"];

export function hasRole(requiredRole) {
	const token = localStorage.user;

	if (!token) {
		console.warn("No token found in localStorage");
		return false;
	}

	let userRole;

	try {
		const decodedToken = jwtDecode(token);
		userRole = decodedToken.role;

		if (!permissionsHierarchy.includes(userRole) || !permissionsHierarchy.includes(requiredRole)) {
			console.warn("Invalid role(s) provided");
			return false;
		}
	} catch (error) {
		console.error("Error decoding token: ", error);
		return false;
	}

	const userIndex = permissionsHierarchy.indexOf(userRole);
	const requiredIndex = permissionsHierarchy.indexOf(requiredRole);

	return userIndex >= requiredIndex;
}

Vue.mixin({
	methods: {
		hasRole,

		formatEmail(email) {
			return email.trim().toLowerCase();
		},
	},
});
