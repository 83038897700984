<template>
	<v-row no-gutters>
		<v-col class="px-8 py-2">
			<p class="title">Delete</p>
			<v-btn :loading="loadingDelete" :disabled="loadingDelete" color="error" class="mr-2" @click="deleteSite()"> Delete Site </v-btn>
		</v-col>
		<confirm ref="confirm" />
	</v-row>
</template>

<script>
import Confirm from "@/components/Confirm";

export default {
	components: {
		Confirm,
	},
	props: {
		site: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loadingDelete: false,
		};
	},
	methods: {
		async deleteSite() {
			const res = await this.$refs.confirm.open(
				`Delete ${this.site.name}?`,
				`Deleting will reset associated devices Site ID to staging and will remove ALL associated data such as Rides, Bookings, Payments. Continue?`,
				"error",
				"mdi-delete-alert"
			);

			if (res === true) {
				this.loadingDelete = true;

				try {
					await this.$store.dispatch("admin/sites/deleteSite", this.site._id);
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.loadingDelete = false;

					if (this.site._id === localStorage.getItem("selectedSiteID")) {
						localStorage.removeItem("selectedSiteID");
						window.location.reload();
					} else {
						this.$emit("cancel");
					}
				}
			}
		},
	},
};
</script>
