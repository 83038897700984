<template>
	<v-card flat>
		<v-card-text class="pt-0">
			<v-expansion-panels accordion flat class="mb-3">
				<!-- GENERAL SETTINGS -->
				<v-expansion-panel>
					<v-expansion-panel-header>
						{{ $t("carModal.general") }}
					</v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<v-form ref="generalForm" v-model="generalForm" lazy-validation>
							<v-text-field :label="$t('carDetail.carName')" hide-details="auto" v-model="defaultItem.carName" outlined dense class="mb-3"></v-text-field>
							<v-text-field
								:label="$t('carDetail.carNumber')"
								hide-details="auto"
								:rules="[rules.numberOnly, rules.required]"
								type="number"
								v-model.number="defaultItem.carNumber"
								outlined
								dense
								class="mb-3"
							></v-text-field>
							<v-select v-model="defaultItem.carIcon" :items="items" hide-details="auto" :label="$t('carDetail.carIcon')" outlined dense class="mb-3">
								<template slot="selection" slot-scope="data">
									<v-icon>{{ data.item }}</v-icon>
								</template>
								<template slot="item" slot-scope="data">
									<v-icon>{{ data.item }}</v-icon>
								</template>
								<template v-slot:append-item v-if="hasRole('admin')">
									<v-divider class="mb-2"></v-divider>
									<v-list-item>
										<v-list-item-avatar>
											<v-icon> {{ defaultItem.carIcon }} </v-icon>
										</v-list-item-avatar>

										<v-list-item-content>
											<v-text-field label="Custom Icon" hide-details="auto" v-model="defaultItem.carIcon" outlined dense class="mb-3"></v-text-field>
										</v-list-item-content>
									</v-list-item>
								</template>
							</v-select>

							<div v-if="!site.bookingIntegration">
								<v-alert v-if="hasMaintenance && maintenanceBooking" color="error" dense>
									<v-list-item>
										<v-list-item-content class="font-weight-bold white--text">
											<v-list-item-title>Maintenance </v-list-item-title>
											<v-list-item-subtitle class="white--text">
												{{ maintenanceDates }}
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-action>
											<v-btn @click="openMaintenanceModal" class="padlessBtn" small depressed>
												<v-icon small>mdi-pencil</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>
								</v-alert>

								<span v-else>
									<v-btn small class="mb-4 font-weight-bold" block color="error" @click="openMaintenanceModal">
										<v-icon small left>mdi-wrench</v-icon>
										Add Maintenance
									</v-btn>
								</span>
							</div>

							<v-checkbox v-else v-model="hasMaintenance" :label="defaultItem.inService ? $t('carModal.inService') : $t('carModal.outService')"></v-checkbox>
							<v-btn @click="saveGeneral()" :loading="loadingGeneral" :disabled="loadingGeneral" class="primary" block>
								{{ $t("general.save") }}
							</v-btn>
						</v-form>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- Vehicle Info -->
				<v-expansion-panel v-if="defaultItem.vehicleInfo">
					<v-expansion-panel-header>
						{{ $t("carModal.vehicle") }}
					</v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<v-form ref="vehicleForm" v-model="vehicleForm" lazy-validation>
							<v-text-field
								:label="$t('carDetail.carSerial')"
								hide-details="auto"
								v-model="defaultItem.carSerialNumber"
								outlined
								dense
								clearable
								class="mb-3 rounded-r-0 unlockCodeInput"
							>
								<template v-slot:append-outer>
									<scanner @scanResult="handleScan" />
								</template>
							</v-text-field>

							<v-select
								:items="cartTypes"
								v-model="defaultItem.vehicleInfo.cart"
								:label="$t('carDetail.cartType')"
								hide-details="auto"
								item-value="model"
								outlined
								dense
								return-object
								class="mb-3"
							>
								<template slot="selection" slot-scope="data">
									{{ data.item.model }}
									<v-chip x-small class="padlessBtn font-weight-bold ml-2" label :color="data.item.color ? data.item.color : 'warning darken-2'">
										{{ data.item.brand }}
									</v-chip>
								</template>
								<template slot="item" slot-scope="data">
									{{ data.item.model }}
									<v-chip x-small class="padlessBtn font-weight-bold ml-2" label :color="data.item.color ? data.item.color : 'warning darken-2'">
										{{ data.item.brand }}
									</v-chip>
								</template>
							</v-select>
							<v-select :items="batteryTypes" v-model="defaultItem.vehicleInfo.power" :label="$t('carDetail.battType')" hide-details="auto" outlined dense class="mb-3"> </v-select>
							<v-text-field :label="$t('carDetail.cartNotes')" hide-details="auto" v-model="defaultItem.vehicleInfo.notes" outlined dense clearable class="mb-3"> </v-text-field>
							<v-spacer></v-spacer>
							<v-hover v-slot="{ hover }">
								<v-btn @click="saveVehicle()" :loading="loadingVehicle" :disabled="loadingVehicle" block class="primary" :elevation="hover ? 12 : 2">
									{{ $t("general.save") }}
								</v-btn>
							</v-hover>
						</v-form>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- CAR NOTES -->
				<v-expansion-panel>
					<v-expansion-panel-header>
						{{ $t("carModal.notes") }}
					</v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<v-form>
							<v-textarea
								rows="3"
								no-resize
								:label="$t('carModal.notesFormText')"
								hide-details="auto"
								v-model="defaultItem.carNote"
								outlined
								clearable
								class="mb-3"
								clear-icon="mdi-close-circle"
							></v-textarea>
							<v-spacer></v-spacer>
							<v-hover v-slot="{ hover }">
								<v-btn @click="saveNote()" :loading="loadingNotes" :disabled="loadingNotes" block class="primary" :elevation="hover ? 12 : 2">
									{{ $t("general.save") }}
								</v-btn>
							</v-hover>
						</v-form>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- Unlock Code -->
				<v-expansion-panel>
					<v-expansion-panel-header>
						{{ $t("carModal.unlockCode") }}
					</v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<v-form ref="unlockForm" v-model="unlockForm" lazy-validation>
							<v-text-field
								prepend-inner-icon="mdi-key-variant"
								placeholder="Unlock Code"
								hide-details="auto"
								type="number"
								v-model="defaultItem.lockCode"
								:rules="codeRules"
								outlined
								dense
								clearable
								class="mb-3"
							></v-text-field>

							<v-hover v-slot="{ hover }">
								<v-btn @click="saveUnlockCode()" :loading="loadingUnlockCode" :disabled="loadingUnlockCode" block class="primary mt-4 mb-3" :elevation="hover ? 12 : 2">
									{{ $t("general.save") }}
								</v-btn>
							</v-hover>
						</v-form>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel v-if="hasRole('admin')">
					<v-expansion-panel-header>
						{{ $t("carModal.admin") }}
					</v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<v-form ref="serverForm" v-model="serverForm" lazy-validation>
							<v-select
								outlined
								dense
								v-model="defaultItem.server"
								:items="serverItems"
								item-text="name"
								item-value="value"
								label="Server"
								single-line
								hide-details
								class="mb-2"
							></v-select>
							<v-hover v-slot="{ hover }">
								<v-btn @click="saveServer()" :loading="loadingSetServer" :disabled="loadingSetServer || !serverForm" block class="primary" :elevation="hover ? 12 : 2">
									Save
								</v-btn>
							</v-hover>
						</v-form>
						<v-divider class="my-3"></v-divider>
						<v-form ref="odometerForm" v-model="odometerForm" lazy-validation>
							<v-text-field
								prepend-inner-icon="mdi-counter"
								label="Odometer Offset"
								hide-details="auto"
								v-model="defaultItem.carOdometerOffset"
								:rules="[rules.required]"
								type="number"
								outlined
								dense
								clearable
								class="mb-3"
							></v-text-field>
							<v-hover v-slot="{ hover }">
								<v-btn
									@click="saveOdometer()"
									:loading="loadingOdometerOffset"
									:disabled="loadingOdometerOffset || !odometerForm"
									block
									class="primary"
									:elevation="hover ? 12 : 2"
								>
									Save
								</v-btn>
							</v-hover>
						</v-form>
						<v-divider class="my-3"></v-divider>
						<v-alert border="left" class="text-left accent--text" dense> Reset Odometer? </v-alert>
						<v-hover v-slot="{ hover }">
							<v-btn @click="pingResetOdometer()" :loading="loadingOdometer" :disabled="loadingOdometer" block class="accent" :elevation="hover ? 12 : 2"> Reset Odometer </v-btn>
						</v-hover>

						<v-checkbox v-model="defaultItem.trackerMode" label="Tracker Mode" @click="saveTrackerMode"></v-checkbox>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-row class="text-center">
				<v-col cols="12 pt-0"> </v-col>
			</v-row>
		</v-card-text>
		<confirm ref="confirm" />
		<maintenance-modal ref="maintenanceModal" :device="car" />
	</v-card>
</template>

<script>
import Confirm from "@/components/Confirm";
import { mapState } from "vuex";

import { differenceInCalendarDays, differenceInCalendarMonths } from "date-fns";

import Scanner from "./Scanner.vue";
import MaintenanceModal from "@/components/bookings/MaintenanceModal.vue";

export default {
	components: {
		Confirm,
		Scanner,
		MaintenanceModal,
	},
	props: ["car"],
	data() {
		return {
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				length: (value) => value.length === 6 || this.$i18n.t("rules.6Digits"),
				numberOnly: (value) => /^\d+$/.test(value) || this.$i18n.t("rules.numbersOnly"),
				maxBatt: (value) => value <= 9999 || "Value should be equal or less than 9999",
				minBatt: (value) => value >= -9999 || "Value should be equal or less than -9999",
			},
			codeRules: [
				(v) => /^-?\d+\.?\d*$/.test(v) || this.$i18n.t("rules.numbersOnly"),
				(v) => !!v || this.$i18n.t("rules.required"),
				(v) => (v && v.length === 6) || this.$i18n.t("rules.6Digits"),
			],

			generalForm: true,
			unlockForm: true,
			odometerForm: true,
			vehicleForm: true,
			serverForm: true,
			items: [
				"mdi-golf-cart",
				"mdi-shield-car",
				"mdi-police-badge",
				"mdi-crown-outline",
				"mdi-star-shooting",
				"mdi-broom",
				"mdi-dog",
				"mdi-tortoise",
				"mdi-alien",
				"mdi-ghost",
				"mdi-pac-man",
			],
			cartTypes: [
				// Golf
				{ header: "Golf" },
				{ model: "Tempo", brand: "Club Car" },
				{ model: "Precedent", brand: "Club Car" },
				{ model: "Onward", brand: "Club Car" },
				// Transport
				{ header: "Transport" },
				{ model: "Villager 6", brand: "Club Car" },
				{ model: "Villager 8", brand: "Club Car" },
				{ model: "Transporter", brand: "Club Car" },
				{ model: "Tempo 2+2", brand: "Club Car" },
				// Utility
				{ header: "Utility" },
				{ model: "Carryall 100", brand: "Club Car" },
				{ model: "Carryall 300", brand: "Club Car" },
				{ model: "Carryall 500", brand: "Club Car" },
				{ model: "Carryall 502", brand: "Club Car" },
				{ model: "Carryall 550", brand: "Club Car" },
				{ model: "Carryall 700", brand: "Club Car" },
				{ model: "Carryall 1500", brand: "Club Car" },
				{ model: "Carryall 1700", brand: "Club Car" },
				{ model: "Urban", brand: "Club Car" },
				// Other
				{ header: "Other" },
				{ model: "Club Car", brand: "Club Car" },
				{ model: "EZ-GO", brand: "EZ-GO" },
				{ model: "Yamaha", brand: "Yamaha" },
				{ model: "None", brand: "None" },
			],
			batteryTypes: ["FLA", "AGM", "Li-Ion", "Gas", "Unknown"],
			serverItems: [
				{ name: "Production", value: "prod" },
				{ name: "Beta", value: "beta" },
			],
			loadingGeneral: false,
			loadingUnlockCode: false,
			loadingNotes: false,
			loadingSetSite: false,
			loadingOdometer: false,
			loadingOdometerOffset: false,
			loadingVehicle: false,
			loadingSetServer: false,
			defaultItem: {},
		};
	},
	computed: {
		...mapState({
			site: (state) => state.sites.selectedSite,
		}),

		hasMaintenance() {
			return this.car?.bookings?.some((booking) => booking.bookingType === "cart_maintenance" && booking.isBookingNow);
		},

		maintenanceBooking() {
			return this.car?.bookings?.find((booking) => booking.bookingType === "cart_maintenance" && booking.isBookingNow);
		},

		maintenanceDates() {
			// Format dates
			const start = new Date(this.maintenanceBooking.start);
			const end = new Date(this.maintenanceBooking.end);
			const startFormatted = this.translatedFormattedDate(start, "do MMM");
			const endFormatted = this.translatedFormattedDate(end, "do MMM");

			// Calculate differences
			const days = differenceInCalendarDays(end, start) + 1;
			const weeks = Math.floor(days / 7);
			const months = differenceInCalendarMonths(end, start) + (end.getDate() >= start.getDate() ? 0 : -1);

			// Determine the appropriate unit to display
			let duration;
			if (months >= 1) {
				duration = this.$tc("general.monthString", months, { count: months });
			} else if (weeks >= 1) {
				duration = this.$tc("general.weekString", weeks, { count: weeks });
			} else {
				duration = this.$tc("general.dayString", days, { count: days });
			}

			// Return the formatted string
			if (startFormatted === endFormatted) {
				return `${startFormatted} (${duration})`;
			}

			return `${startFormatted} - ${endFormatted} (${duration})`;
		},
	},
	mounted() {
		this.defaultItem = Object.assign({}, this.car);
	},
	methods: {
		setSiteID(val) {
			this.defaultItem.siteID = val;
		},

		handleScan(val) {
			this.defaultItem.carSerialNumber = val;
		},

		openMaintenanceModal() {
			if (this.hasMaintenance) {
				this.$refs.maintenanceModal.open(this.maintenanceBooking);
				return;
			}

			this.$refs.maintenanceModal.open();
		},

		async saveGeneral() {
			if (this.$refs.generalForm.validate()) {
				try {
					this.loadingGeneral = true;

					const settings = {
						id: this.defaultItem._id,
						body: {
							updateData: {
								carNumber: this.defaultItem.carNumber,
								carName: this.defaultItem.carName,
								carIcon: this.defaultItem.carIcon,
								inService: this.defaultItem.inService,
							},
						},
					};

					if (this.defaultItem.carNumber != this.car.carNumber) {
						await this.doesCarNumberExist(this.defaultItem.carNumber);
					}

					await this.$store.dispatch("updateDevice", settings);
					this.loadingGeneral = false;
				} catch (err) {
					const message = err.message;
					this.$toast.error(message);

					this.loadingGeneral = false;
				}
			}
		},

		async saveUnlockCode() {
			if (this.$refs.unlockForm.validate()) {
				this.loadingUnlockCode = true;

				const settings = {
					id: this.defaultItem._id,
					body: {
						updateData: {
							lockCode: this.defaultItem.lockCode,
						},
					},
				};

				await this.$store.dispatch("updateDevice", settings);

				this.loadingUnlockCode = false;
			}
		},

		async saveNote() {
			this.loadingNotes = true;

			const settings = {
				id: this.defaultItem._id,
				body: {
					updateData: {
						carNote: this.defaultItem.carNote,
					},
				},
			};

			await this.$store.dispatch("updateDevice", settings);
			this.loadingNotes = false;
		},

		async saveVehicle() {
			if (this.$refs.vehicleForm.validate()) {
				this.loadingVehicle = true;

				const settings = {
					id: this.defaultItem._id,
					body: {
						updateData: {
							carSerialNumber: this.defaultItem.carSerialNumber,
							vehicleInfo: this.defaultItem.vehicleInfo,
						},
						publish: true,
					},
				};

				await this.$store.dispatch("updateDevice", settings);

				this.loadingVehicle = false;
			}
		},

		async saveServer() {
			if (this.$refs.serverForm.validate()) {
				const res = await this.$refs.confirm.open(`Change Server ${this.car.carNumber}?`, `This device will appear in ${this.defaultItem.server} staging`, "warning", "mdi-server");

				if (res === true) {
					this.loadingSetServer = true;

					const settings = {
						id: this.defaultItem._id,
						body: {
							updateData: {
								server: this.defaultItem.server,
							},
							publish: true,
						},
					};

					await this.$store.dispatch("updateDevice", settings);

					this.loadingSetServer = false;
				}
			}
		},

		async saveOdometer() {
			if (this.$refs.odometerForm.validate()) {
				this.loadingOdometerOffset = true;

				const settings = {
					id: this.defaultItem._id,
					body: {
						updateData: {
							carOdometerOffset: this.defaultItem.carOdometerOffset,
						},
					},
				};

				await this.$store.dispatch("updateDevice", settings);

				this.loadingOdometerOffset = false;
			}
		},

		async pingResetOdometer() {
			this.loadingOdometer = true;

			const res = await this.$refs.confirm.open(`Reset Odometer Car ${this.car.carNumber}?`, `This action cannot be undone`, "accent", "mdi-restart");

			if (res === true) {
				try {
					await this.$store.dispatch("admin/pingDevice", {
						id: this.defaultItem._id,
						command: 7,
					});

					const settings = {
						id: this.defaultItem._id,
						body: {
							updateData: {
								carOdometerOffset: "0",
							},
						},
					};

					await this.$store.dispatch("updateDevice", settings);

					this.$toast.success("Odometer reset");
				} catch {
					this.$toast.error("Error resetting odometer");
				} finally {
					this.loadingOdometer = false;
				}
			} else {
				this.loadingOdometer = false;
			}
		},

		async saveTrackerMode() {
			try {
				const settings = {
					id: this.defaultItem._id,
					body: {
						updateData: {
							trackerMode: this.defaultItem.trackerMode,
						},
						publish: true,
					},
				};

				await this.$store.dispatch("updateDevice", settings);
			} catch (err) {
				const message = "Error changing tracker mode";
				this.$toast.success(message);
			}
		},
	},
};
</script>
