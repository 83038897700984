<template>
	<v-dialog v-model="dialog" width="500" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" class="mr-2 elevation-2" small outlined color="accent">
				<v-icon small left>mdi-plus-circle-outline</v-icon>
				New Site
			</v-btn>
		</template>

		<v-card class="elevation-24">
			<v-card-text class="title font-weight-bold text-center">
				<span>Add Site</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-text-field label="Course Name" hide-details="auto" v-model="siteData.name" :rules="[rules.required]" outlined dense class="mb-3"></v-text-field>
					<v-autocomplete
						label="Country"
						hide-details="auto"
						v-model="siteData.country"
						:items="countriesList"
						:rules="[rules.required]"
						item-text="name"
						item-value="name"
						outlined
						dense
						class="mb-3"
					></v-autocomplete>
					<v-text-field label="Center Lat Long" hide-details="auto" v-model="siteData.centerLatLong" :rules="[rules.required, rules.latLong]" outlined dense></v-text-field>

					{{ siteData }}
					<v-btn :loading="loading" block @click="addSite()" color="primary" class="mt-3"> Add </v-btn>
					<v-btn block @click="close" color="primary" outlined class="mt-3"> Close </v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import countriesArray from "@/mixins/Countries";

export default {
	data() {
		return {
			valid: true,
			rules: {
				required: (v) => !!v || "This field is required",
				latLong: (v) => /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/.test(v) || "Input must be in 'lat, long' format",
			},
			countriesList: countriesArray,
			dialog: false,
			loading: false,
			id: null,
			siteData: {
				name: null,
				country: null,
				currency: null,
				centerLatLong: null,
			},
		};
	},
	watch: {
		country(val) {
			this.siteData.currency = val.currency.code;
		},
	},
	computed: {
		country() {
			return this.countriesList.find((country) => country.name === this.siteData.country);
		},
	},
	methods: {
		setDefaultData() {
			this.siteData = {
				name: null,
				country: null,
				currency: null,

				centerLatLong: null,
			};
		},

		close() {
			this.dialog = false;
			this.setDefaultData();
		},

		async addSite() {
			this.loading = true;
			try {
				if (this.$refs.form.validate()) {
					// Format the centerLatLong value
					if (this.siteData.centerLatLong) {
						const regexPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
						if (regexPattern.test(this.siteData.centerLatLong)) {
							const [lat, long] = this.siteData.centerLatLong.split(",").map((n) => parseFloat(n.trim()).toFixed(6));
							this.siteData.centerLatLong = `${lat}, ${long}`;
						}
					}

					await this.$store.dispatch("admin/sites/createSite", this.siteData);
					this.$toast.success("Site added successfully");
					this.close();
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
