<template>
	<page-loading v-if="loading" />

	<v-container v-else fluid>
		<v-row>
			<v-col xs="12" sm="8" md="4" class="mx-auto">
				<v-row>
					<v-col>
						<site-header :site="site" />
					</v-col>
				</v-row>

				<v-row v-if="siteClosed">
					<v-col class="mx-auto">
						<site-closed :site="site" />

						<selection-card icon="mdi-shield" color="accent" title="Master Code" subtitle="I have a master code" class="mt-6 mb-8">
							<bottom-sheet :btnTitle="$t('s2r.virtualKey.btn')" btnColor="accent" :title="$t('s2r.master.subtitle')" :loading="loadingMasterKey" @code-entered="getMasterKey" />
						</selection-card>
					</v-col>
				</v-row>

				<v-row v-else class="mb-14">
					<router-view />
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";

import SelectionCard from "@/components/guest/site/SelectionCard.vue";
import BottomSheet from "@/components/guest/site/BottomSheet";
import SiteClosed from "@/components/guest/site/SiteClosed.vue";

import SiteHeader from "@/components/guest/site/SiteHeader";
import PageLoading from "@/components/PageLoading";

export default {
	metaInfo: {
		title: "IOTee",
		meta: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
			},
		],
	},
	components: {
		PageLoading,
		SiteHeader,
		BottomSheet,
		SelectionCard,
		SiteClosed,
	},
	data() {
		return {
			loading: true,
			loadingMasterKey: false,
		};
	},
	async mounted() {
		await this.getSite();
		await this.getSiteDevices();
	},
	computed: {
		...mapState({
			site: (state) => state.guest.sites.site,
			virtualKey: (state) => state.guest.sites.virtualKey,
		}),

		siteClosed() {
			return !this.isSiteOpen(this.site.openingHours);
		},
	},
	methods: {
		async getSite() {
			this.loading = true;

			try {
				const siteId = this.$route.params.id;

				await this.$store.dispatch("guest/sites/getSite", siteId);
			} catch (err) {
				if (err.error && err.error.message) {
					this.$toast.error(err.error.message);
				} else {
					this.$toast.error("An error occurred while fetching site details");
				}
			} finally {
				this.loading = false;
			}
		},

		async getSiteDevices() {
			this.loading = true;

			try {
				const siteId = this.$route.params.id;

				await this.$store.dispatch("guest/devices/getDevices", siteId);
			} catch (err) {
				if (err.error && err.error.message) {
					this.$toast.error(err.error.message);
				} else {
					this.$toast.error("An error occurred while fetching site devices");
				}
			} finally {
				this.loading = false;
			}
		},

		async getMasterKey(code) {
			this.loadingMasterKey = true;
			try {
				await this.$store.dispatch("guest/sites/getVirtualKey", {
					siteId: this.site._id,
					query: {
						code: code,
						master: true,
					},
				});

				this.$router.push({ name: "guest.masterKey", params: { id: this.site._id, virtualKeyId: this.virtualKey._id } });
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
				this.$store.commit("guest/sites/setVirtualKey", null);
			} finally {
				this.loadingMasterKey = false;
			}
		},
	},
};
</script>
./Index.vue
