import * as usersService from "@/services/admin/users.service";

export default {
	state: {
		users: [],
		invitedUsers: [],
	},

	mutations: {
		setUsers(state, payload) {
			state.users = payload;
		},

		setInvitedUsers(state, payload) {
			state.invitedUsers = payload;
		},
	},

	actions: {
		async getUsers({ commit }) {
			const users = await usersService.getUsers();
			commit("setUsers", users);
		},

		async updateUser({ dispatch }, payload) {
			await usersService.updateUser(payload);
			await dispatch("getUsers");
		},

		async deleteUser({ dispatch }, id) {
			await usersService.deleteUser(id);
			await dispatch("getUsers");
		},

		async getInvitedUsers({ commit }) {
			const invitedUsers = await usersService.getInvitedUsers();
			commit("setInvitedUsers", invitedUsers);
		},

		async inviteUser(_, payload) {
			await usersService.inviteUser(payload);
		},

		async deleteInvitedUser({ dispatch }, id) {
			await usersService.deleteInvitedUser(id);
			dispatch("getInvitedUsers");
		},
	},
};
