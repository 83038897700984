<template>
	<page-loading v-if="pageLoading" :msg="loadingMessage" />
</template>

<script>
import { mapState } from "vuex";

import PageLoading from "@/components/PageLoading";

export default {
	metaInfo: {
		title: "IOTee",
		meta: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
			},
		],
	},
	components: {
		PageLoading,
	},
	data() {
		return {
			pageLoading: true,
			loadingMessage: "Redirecting...",
		};
	},
	computed: {
		...mapState({
			device: (state) => state.guest.devices.device,
		}),
	},
	mounted() {
		this.getDevice();
	},
	methods: {
		async getDevice() {
			const deviceId = this.$route.params.id;

			try {
				await this.$store.dispatch("guest/devices/getDevice", deviceId);

				if (this.device) {
					const { siteID } = this.device;

					this.$router.push({ name: "guest.site", params: { id: siteID } });
				}
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			}
		},
	},
};
</script>
