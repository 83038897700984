<template>
	<div :class="{ pulse: carSelected }" class="pill white--text font-weight-bold" :style="{ backgroundColor: markerColor }">
		<v-icon small :left="!carIcon" :color="lockIcon.color">
			{{ lockIcon.icon }}
		</v-icon>
		<v-icon v-if="carIcon" small class="mx-1" color="white">
			{{ carIcon }}
		</v-icon>
		{{ carNumber }}
	</div>
</template>

<script>
export default {
	props: {
		bgColor: {
			type: String,
			default: "#ffffff",
		},
		carIcon: {
			type: String,
			default: null,
		},
		carSelected: {
			type: Boolean,
			default: false,
		},
		online: {
			type: Boolean,
		},
		carLockStatus: {
			type: Number,
			required: true,
		},
		carNumber: {
			type: String,
			required: true,
		},
		inUse: {
			type: Number,
			required: true,
		},
		gpsSpeed: {
			type: String,
			default: "0.00",
		},
	},
	computed: {
		lockIcon() {
			return {
				icon: this.carLockStatus ? "mdi-lock" : "mdi-lock-open-outline",
				color: this.carLockStatus ? "error" : "success",
			};
		},

		markerColor() {
			const opacity = this.online ? 1 : 0.6;
			if (!this.online) {
				return `rgba(249, 150, 151, ${opacity})`;
			} else if (this.inUse && this.gpsSpeed === "0.00") {
				return `rgba(233, 195, 106, ${opacity})`;
			} else if (this.inUse) {
				return `rgba(255, 183, 0, ${opacity})`;
			} else {
				return `rgba(64, 109, 250, ${opacity})`;
			}
		},
	},
};
</script>

<style scoped>
.pill {
	display: inline-flex;
	align-items: center;
	padding: 6px 14px 6px 10px;
	border-radius: 25px;
}

.pulse {
	/* box-shadow: 0 0 0 0 #406dfa; */
	transform: scale(1);
	animation: pulse 2s infinite;
	border: 5px solid #233d89;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(64, 109, 250, 1);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0px 0px 15px 60px rgba(64, 109, 250, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(64, 109, 250, 0);
	}
}
</style>
