<template>
	<div>
		<stripe-onboard v-if="!stripeOnboarded" />

		<v-row v-if="stripeOnboarded && !selectedSite.bookingIntegration" no-gutters class="accent--text mt-8">
			<v-col>
				<v-expansion-panels>
					<ExpansionPanel :title="$t('settingsPage.publicProfile.title')" :subtitle="$t('settingsPage.publicProfile.subtitle')">
						<public-profile-settings />
					</ExpansionPanel>
				</v-expansion-panels>
			</v-col>
		</v-row>

		<v-row no-gutters class="accent--text mt-8">
			<v-col>
				<v-expansion-panels>
					<ExpansionPanel :title="$t('settingsPage.bookingSettings.openingHours.title')" :subtitle="$t('settingsPage.bookingSettings.openingHours.subtitle')">
						<OpeningHours />
					</ExpansionPanel>
				</v-expansion-panels>
			</v-col>
		</v-row>

		<v-row v-if="stripeOnboarded && !selectedSite.bookingIntegration" no-gutters class="accent--text mt-8">
			<v-col>
				<v-expansion-panels>
					<ExpansionPanel :title="$t('settingsPage.bookingSettings.bookableCarts.title')" :subtitle="$t('settingsPage.bookingSettings.bookableCarts.subtitle')">
						<BookableCarts />
					</ExpansionPanel>
				</v-expansion-panels>
			</v-col>
		</v-row>

		<v-row no-gutters class="accent--text mt-8">
			<v-col>
				<booking-offset-settings />
			</v-col>
		</v-row>

		<v-row no-gutters class="mt-8">
			<v-col>
				<v-card class="rounded-lg">
					<v-card-title class="font-weight-bold mb-2">
						<service-settings />
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>

		<v-row no-gutters class="mt-8">
			<v-col>
				<v-card class="rounded-lg">
					<v-card-title class="font-weight-bold mb-2">
						<user-group-settings />
					</v-card-title>
				</v-card>
			</v-col>
		</v-row>
		<confirm ref="confirm" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import StripeOnboard from "@/components/sitesettings/StripeOnboard.vue";
import Confirm from "@/components/Confirm";
import UserGroupSettings from "@/components/sitesettings/UserGroupSettings.vue";
import ServiceSettings from "@/components/sitesettings/ServiceSettings.vue";
import OpeningHours from "@/components/sitesettings/OpeningHours.vue";
import ExpansionPanel from "@/components/sitesettings/ExpansionPanel.vue";
import PublicProfileSettings from "@/components/sitesettings/PublicProfileSettings.vue";
import BookingOffsetSettings from "@/components/sitesettings/BookingOffsetSettings.vue";
import BookableCarts from "@/components/sitesettings/BookableCarts.vue";

export default {
	components: {
		StripeOnboard,
		Confirm,
		UserGroupSettings,
		ServiceSettings,
		OpeningHours,
		ExpansionPanel,
		PublicProfileSettings,
		BookingOffsetSettings,
		BookableCarts,
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),

		stripeOnboarded() {
			return this.selectedSite.stripeConfig && this.selectedSite.stripeConfig.accountActive && this.selectedSite.stripeConfig.accountId;
		},
	},
};
</script>
