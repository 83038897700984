<template>
	<div>
		<apexchart type="radialBar" height="200" :options="chartOptions" :series="series"></apexchart>
	</div>
</template>

<script>
export default {
	props: {
		devices: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		series() {
			return [this.onlineDevicesPercent, this.offlineDevicesPercent];
		},

		chartOptions() {
			return {
				chart: {
					height: 200,
					type: "radialBar",
				},

				labels: ["Online", "Offline"],
				colors: ["#299D8F", "#F44336"],
				stroke: {
					lineCap: "round",
				},
				plotOptions: {
					radialBar: {
						dataLabels: {
							name: {
								fontSize: "22px",
							},
							value: {
								fontSize: "16px",
							},
							total: {
								show: true,
								label: "Online",
								formatter: () => {
									// By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
									return `${this.onlineDevices.length}/ ${this.devices.length}`;
								},
							},
						},
					},
				},
			};
		},

		onlineDevices() {
			return this.devices.filter((device) => device.onlineStatus);
		},

		offlineDevices() {
			return this.devices.filter((device) => !device.onlineStatus);
		},

		onlineDevicesPercent() {
			return ((this.onlineDevices.length / this.devices.length) * 100).toFixed(0);
		},

		offlineDevicesPercent() {
			return ((this.offlineDevices.length / this.devices.length) * 100).toFixed(0);
		},
	},
};
</script>
