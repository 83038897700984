<template>
	<div v-resize="onResize">
		<v-row class="pa-4">
			<v-col cols="12" md="5" sm="6">
				<v-card rounded="lg" elevation="12" color="primary" dark>
					<v-card-title class="font-weight-bold pb-2">
						Stripe Balance
						<v-spacer></v-spacer>
						<v-select
							v-if="stripeBalances && stripeBalances.available.length > 0"
							hide-details
							outlined
							dense
							style="max-width: 120px; scale: 0.8"
							v-model="selectedBalanceCurrency"
							:items="currencyItems"
							label="Currency"
						></v-select>

						<v-btn small color="primary lighten-4" class="primary--text hidden-sm-and-down" depressed target="_blank" href="https://dashboard.stripe.com/dashboard">
							<v-icon left small>mdi-open-in-new</v-icon>
							Stripe
						</v-btn>
					</v-card-title>

					<v-card-text v-if="loadingBalances">
						<v-skeleton-loader type="text" width="100%" height="24px" class="mb-3"></v-skeleton-loader>
						<v-skeleton-loader type="text" width="100%" height="24px" class="mb-2"></v-skeleton-loader>
						<v-skeleton-loader type="text" width="100%" height="24px"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else-if="stripeBalances" class="font-weight-bold">
						<p class="subtitle-1 font-weight-bold mb-3">
							Total Balance:
							{{ formattedPrice(totalBalance, selectedBalanceCurrency) }}
						</p>
						<p class="mb-2">
							Available to pay out:
							{{ formattedPrice(availableBalance.amount, selectedBalanceCurrency) }}
						</p>
						<p class="mb-0">
							Available soon:
							{{ formattedPrice(pendingBalance.amount, selectedBalanceCurrency) }}
						</p>
					</v-card-text>
					<v-card-text v-else> No balance available</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="4" sm="6">
				<v-card rounded="lg" flat color="primary" dark>
					<v-card-title class="font-weight-bold pb-2" v-if="stripePayouts && stripePayouts.length">
						Stripe Payouts:
						{{ formattedPrice(selectedPayout.amount, selectedPayout.currency) }}
						<v-spacer></v-spacer>
						<v-select
							v-if="stripeBalances && stripeBalances.available.length > 0"
							hide-details
							outlined
							dense
							style="max-width: 125px; scale: 0.8"
							v-model="selectedPayout"
							:items="stripePayouts"
							label="Date"
							item-value="id"
							return-object
						>
							<template v-slot:item="{ item }">
								<v-list-item-content>
									<v-list-item-title>
										{{ formatDateFromUnixTime(item.created, "do MMM yy") }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ formattedPrice(item.amount, item.currency) }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</template>

							<template v-slot:selection="{ item }">
								{{ formatDateFromUnixTime(item.created, "dd.MM.yy") }}
							</template>
						</v-select>

						<v-tooltip bottom v-if="isWithdrawnPayout">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" style="position: absolute; top: -15px; right: 10px">
									<v-chip small label color="grey" class="accent--text elevation-4"> Withdrawn </v-chip>
								</span>
							</template>
							<span class="font-weight-bold">
								Negative funds are withdrawn <br />
								from your bank account.
							</span>
						</v-tooltip>
						<v-chip style="position: absolute; top: -10px; right: 10px" small label color="green" class="elevation-4" v-else> Paid </v-chip>
					</v-card-title>
					<v-card-text v-if="loadingBalances">
						<v-skeleton-loader type="text" width="100%" height="24px" class="mb-3"></v-skeleton-loader>
						<v-skeleton-loader type="text" width="100%" height="24px" class="mb-2"></v-skeleton-loader>
						<v-skeleton-loader type="text" width="100%" height="24px"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else-if="selectedPayout" class="font-weight-bold">
						<p class="mb-2">
							Sent:
							{{ formatDateFromUnixTime(selectedPayout.created) }}
						</p>
						<p>
							Arrived:
							{{ formatDateFromUnixTime(selectedPayout.arrival_date) }}
						</p>
						<p class="mb-0" v-if="selectedPayout.destination.bank_name">
							<v-icon>mdi-piggy-bank-outline</v-icon>
							{{ selectedPayout.destination.bank_name }}
							**{{ selectedPayout.destination.last4 }}
						</p>
					</v-card-text>
					<v-card-title class="font-weight-bold" v-else> No recent payouts </v-card-title>
				</v-card>
			</v-col>
			<v-col cols="6" md="3" class="hidden-sm-and-down">
				<v-row>
					<v-col cols="12">
						<data-card icon="mdi-golf" iconBg="grey" :value="payments.length" text="Payments" />
					</v-col>
					<v-col cols="12">
						<data-card
							color="primary lighten-4"
							textColor="primary"
							icon="mdi-cash"
							iconBg="primary"
							:value="totalRevenue"
							:currency="selectedSite.currency"
							text="Gross Revenue"
						/>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="px-4 mt-0">
			<v-col cols="12">
				<v-data-table
					:loading="loading"
					class="logs-list"
					fixed-header
					:headers="headers"
					:items="payments"
					item-key="id"
					:items-per-page="itemsPerPage"
					hide-default-footer
					:mobile-breakpoint="0"
					loading-text="Fetching payments.."
					:height="$vuetify.breakpoint.xs ? windowSize.y - 282 : windowSize.y - 420"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title class="font-weight-bold accent--text"> Payment History </v-toolbar-title>
							<v-spacer></v-spacer>

							<v-btn-toggle color="primary" class="mr-4" v-model="toggleStatus">
								<v-btn small> All </v-btn>
								<v-btn small> Succeeded </v-btn>
								<v-btn small> Pending </v-btn>
								<v-btn small> Cancelled </v-btn>
								<v-btn small> Failed </v-btn>
							</v-btn-toggle>

							<v-btn :loading="loading" color="primary" small depressed class="padlessBtn" @click="fetchPayments()">
								<v-icon small>mdi-refresh</v-icon>
							</v-btn>

							<!-- <v-btn :disabled="!payments.length" small depressed color="primary" class="ml-2 font-weight-bold" @click="downloadInvoice" :loading="loadingDownload">
								<v-icon left small>mdi-download</v-icon>
								PDF Statement
							</v-btn> -->
						</v-toolbar>
					</template>

					<template v-slot:item.priceDetails.total="{ item }">
						<span class="font-weight-bold text-no-wrap">
							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-chip label small :color="paymentColor(item.status)">
											{{ formattedPrice(item.priceDetails.total, item.priceDetails.currency) }}
										</v-chip>
									</span>
								</template>
								<span class="font-weight-bold text-capitalize">
									{{ item.status }}
								</span>
							</v-tooltip>
						</span>
					</template>

					<template v-slot:item.priceDetails.stripeProcessingFee="{ item }">
						<span class="font-weight-bold text-no-wrap" v-if="item.paymentSucceededAt">
							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										<v-chip label small :color="paymentColor(item.status)">
											{{ formattedPrice(item.priceDetails.total - item.priceDetails.stripeProcessingFee - item.priceDetails.applicationFee, item.priceDetails.currency) }}
										</v-chip>
									</span>
								</template>
								<span class="font-weight-bold text-capitalize">
									Application Fee: {{ formattedPrice(item.priceDetails.applicationFee, item.priceDetails.currency) }}
									<br />
									Stripe Fee:
									{{ formattedPrice(item.priceDetails.stripeProcessingFee, item.priceDetails.currency) }}
								</span>
							</v-tooltip>
						</span>
					</template>

					<template v-slot:item.booking.status="{ item }">
						<v-list-item dense class="pl-0">
							<v-list-item-content>
								<v-list-item-title :class="`${paymentStatus(item.status).color}--text`" class="font-weight-bold text-no-wrap">
									Payment: {{ paymentStatus(item.status).text }}
								</v-list-item-title>
								<v-list-item-subtitle v-if="item.booking" :class="`${bookingStatus(item.booking.status).color}--text`">
									Booking: {{ bookingStatus(item.booking.status).text }}
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>

					<template v-slot:item.booking.device="{ item }">
						<span class="font-weight-bold text-no-wrap" v-if="item.booking && item.booking.device">
							<v-chip :disabled="item.status !== 'succeeded'" label small color="accent lighten-1">
								<v-icon small left>mdi-golf-cart</v-icon>
								{{ deviceFromId(item.booking.device)?.carNumber || "Unknown" }}
							</v-chip>
						</span>
					</template>

					<template v-slot:item.userDetails="{ item }">
						<v-list-item dense class="pl-0">
							<v-list-item-content>
								<v-list-item-title class="font-weight-bold text-no-wrap">{{ item.userDetails.name }}</v-list-item-title>
								<v-list-item-subtitle>{{ item.userDetails.email }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>

					<template v-slot:item.booking="{ item }">
						<v-chip label small v-if="item.booking" :color="bookingStatus(item.booking.status).color" class="caption font-weight-bold text-no-wrap">
							<v-icon small left>mdi-calendar-month</v-icon>
							{{ translatedFormattedDate(item.booking.start, "HH:mm MMM do") }}
						</v-chip>
					</template>

					<template v-slot:item.paymentDescription="{ item }">
						<span class="font-weight-bold text-no-wrap"> {{ item.paymentDescription }}</span>
					</template>

					<template v-slot:item.createdAt="{ item }">
						<v-list-item dense class="pl-0">
							<v-list-item-content>
								<v-list-item-title v-if="item.paymentSucceededAt" class="caption font-weight-bold text-no-wrap green--text">
									Charged: {{ translatedFormattedDate(item.paymentSucceededAt, "HH:mm, dd/MM/yy") }}
								</v-list-item-title>
								<v-list-item-title v-else-if="item.paymentDueDate" class="caption font-weight-bold text-no-wrap warning--text">
									Due: {{ translatedFormattedDate(item.paymentDueDate, "HH:mm, dd/MM/yy") }}
								</v-list-item-title>
								<v-list-item-title v-else-if="item.cancelledAt" class="caption text-no-wrap">
									Cancelled: {{ translatedFormattedDate(item.cancelledAt, "HH:mm, dd/MM/yy") }}
								</v-list-item-title>
								<v-list-item-subtitle class="caption">Created: {{ translatedFormattedDate(item.createdAt, "HH:mm dd/MM/yy") }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>

					<template v-slot:item.edit="{ item }">
						<span class="font-weight-bold text-no-wrap" v-if="item.stripeReceiptUrl">
							<v-tooltip left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small depressed color="primary" class="font-weight-bold padlessBtn" v-bind="attrs" v-on="on" @click="openReceiptUrl(item.stripeReceiptUrl)">
										<v-icon small>mdi-receipt</v-icon>
									</v-btn>
								</template>
								<span>View Receipt</span>
							</v-tooltip>
						</span>
					</template>
				</v-data-table>

				<v-toolbar flat dense class="rounded-0">
					<v-pagination :disabled="currentPage < 1" class="mx-auto" color="primary" v-model="page" :length="totalPages"></v-pagination>

					<v-select class="perPage" v-model="perPage" :items="pageSizes" item-text="text" item-value="val" label="Select" hide-details single-line dense outlined></v-select>
				</v-toolbar>
			</v-col>
		</v-row>
		<confirm ref="confirm" />
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Confirm from "@/components/Confirm.vue";
import DataCard from "@/components/charts/DataCard.vue";

export default {
	components: {
		Confirm,
		DataCard,
	},
	props: {
		dateRange: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loadingBalances: false,
			windowSize: {
				x: 0,
				y: 0,
			},
			toggleStatus: 0,
			pageSizes: [
				{ text: "10", val: 10 },
				{ text: "25", val: 25 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
			],
			headers: [
				{
					text: "Total",
					align: "center",
					value: "priceDetails.total",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
					width: "80px",
				},
				{
					text: "Net",
					align: "center",
					value: "priceDetails.stripeProcessingFee",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
					width: "80px",
				},
				{
					text: "Status",
					value: "booking.status",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
					width: "80px",
				},
				{
					text: "Cart",
					sortable: false,
					align: "center",
					value: "booking.device",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},

				{
					align: "start",
					text: "Customer",
					value: "userDetails",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Booking Date",
					align: "start",
					value: "booking",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Description",
					align: "start",
					value: "paymentDescription",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					align: "start",
					text: "Payment Date",
					value: "createdAt",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "",
					value: "edit",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
					width: "120",
				},
			],
			stripeBalances: null,
			stripePayouts: null,
			selectedBalanceCurrency: null,
			selectedPayout: null,
		};
	},
	watch: {
		dateRange: {
			handler(val) {
				if (val.start && val.end) {
					this.fetchPayments();
				}
			},
			deep: true,
		},

		status() {
			this.fetchPayments();
		},
	},
	computed: {
		...mapState({
			payments: (state) => state.payments.payments,
			currentPage: (state) => state.payments.currentPage,
			itemsPerPage: (state) => state.payments.itemsPerPage,
			totalItems: (state) => state.payments.totalItems,
			totalPages: (state) => Math.ceil(state.payments.totalItems / state.payments.itemsPerPage),
			loading: (state) => state.payments.loading,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		...mapGetters(["deviceFromId"]),

		page: {
			get() {
				return this.currentPage;
			},
			set(value) {
				this.$store.dispatch("setCurrentPage", {
					page: value,
					queryParams: this.prepareQuery(),
				});
			},
		},

		perPage: {
			get() {
				return this.itemsPerPage;
			},
			set(value) {
				this.$store.dispatch("setItemsPerPage", {
					itemsPerPage: value,
					queryParams: this.prepareQuery(),
				});
			},
		},

		status() {
			switch (this.toggleStatus) {
				case 1:
					return "succeeded";
				case 2:
					return "pending";
				case 3:
					return "cancelled";
				case 4:
					return "failed";

				default:
					return null;
			}
		},

		totalRevenue() {
			return this.payments
				.filter((payment) => payment.status === "succeeded")
				.reduce((acc, payment) => acc + (payment.priceDetails.total - payment.priceDetails.stripeProcessingFee - payment.priceDetails.applicationFee), 0);
		},

		totalBalance() {
			let total = 0;

			total = this.availableBalance.amount + this.pendingBalance.amount;

			return total;
		},

		currencyItems() {
			return this.stripeBalances?.available.map(({ currency }) => currency) || [];
		},

		availableBalance() {
			return this.stripeBalances?.available.find((balance) => balance.currency === this.selectedBalanceCurrency) || null;
		},

		pendingBalance() {
			return this.stripeBalances?.pending.find((balance) => balance.currency === this.selectedBalanceCurrency) || null;
		},

		isWithdrawnPayout() {
			if (this.selectedPayout && this.selectedPayout.amount < 0) {
				return true;
			}

			return false;
		},
	},

	created() {
		this.fetchPayments();
		this.fetchAccountBalance();
	},
	methods: {
		prepareQuery() {
			const queryParams = {
				...this.dateRange,
				"priceDetails.method": ["stripe", "none"],
				...(this.status ? { status: this.status } : {}),
			};

			return queryParams;
		},

		paymentColor(status) {
			switch (status) {
				case "succeeded":
					return "success";
				case "pending":
					return "warning";
				case "failed":
					return "error";
				case "cancelled":
					return "grey";

				default:
					return "grey";
			}
		},

		// payment status: succeeded, pending, failed, cancelled
		paymentStatus(status) {
			switch (status) {
				case "succeeded":
					return {
						color: "success",
						text: "Succeeded",
					};
				case "pending":
					return {
						color: "warning",
						text: "Pending",
					};
				case "failed":
					return {
						color: "error",
						text: "Failed",
					};
				case "cancelled":
					return {
						color: "error",
						text: "Cancelled",
					};

				default:
					return {
						color: "grey",
						text: "Unknown",
					};
			}
		},

		//booking status: confirmed, cancelled, payement_pending
		bookingStatus(status) {
			switch (status) {
				case "confirmed":
					return {
						color: "success",
						text: "Confirmed",
					};
				case "cancelled":
					return {
						color: "error",
						text: "Cancelled",
					};
				case "payment_pending":
					return {
						color: "warning",
						text: "Payment Pending",
					};

				default:
					return {
						color: "grey",
						text: "Unknown",
					};
			}
		},

		fetchPayments() {
			this.$store.dispatch("fetchPayments", this.prepareQuery());
		},

		async fetchAccountBalance() {
			this.loadingBalances = true;
			try {
				if (this.selectedSite.stripeConfig.accountId) {
					const { balance, payouts } = await this.$store.dispatch("fetchStripeAccountBalance");

					this.stripeBalances = balance;
					this.stripePayouts = payouts.data;

					if (balance.available.length) {
						this.selectedBalanceCurrency = balance.available[0].currency;
					}

					if (payouts.data.length) {
						this.selectedPayout = payouts.data[0];
					}
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingBalances = false;
			}
		},

		changePage(newPage) {
			this.$store.dispatch("setCurrentPage", newPage);
		},

		openReceiptUrl(url) {
			window.open(url, "_blank");
		},

		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},
	},
};
</script>
