<template>
	<div class="text-center">
		<v-expand-transition>
			<v-toolbar v-if="showHistoryMenu" dense class="historyToolbar rounded-xl elevation-12" color="accent">
				<span class="font-weight-bold white--text mr-3">Type: </span>
				<v-btn @click="historyType = 'polyline'" color="primary" :outlined="historyType !== 'polyline'" rounded small class="mr-1">Trail</v-btn>
				<v-btn @click="historyType = 'heatmap'" color="primary" :outlined="historyType !== 'heatmap'" rounded small class="ml-1">Heatmap</v-btn>
				<!-- v-select for cart numbers in rideHistory -->
				<span class="font-weight-bold white--text ml-3">Cart: </span>
				<v-select
					outlined
					rounded
					:background-color="selectedCart && 'primary'"
					:color="selectedCart ? 'white' : 'primary'"
					dark
					dense
					hide-details
					style="scale: 0.8; max-width: 120px"
					:items="carts"
					item-text="carNumber"
					item-value="value"
					v-model="selectedCart"
					:return-object="false"
					:menu-props="{ offsetY: true, closeOnContentClick: true }"
					clearable
				></v-select>

				<v-dialog ref="dialog" v-model="modal" width="290px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn rounded small v-bind="attrs" v-on="on" color="primary" class="white--text">
							<v-icon left>mdi-calendar</v-icon>
							{{ displayDateRange }}
						</v-btn>
					</template>
					<v-card>
						<v-date-picker v-model="orderedDateRange" @change="saveDateRange()" no-title range scrollable> </v-date-picker>

						<div class="px-4 pb-4">
							<v-btn class="font-weight-bold mb-2" small depressed color="primary" block @click="selectToday()">Today</v-btn>
							<v-btn class="font-weight-bold mb-2" small depressed color="primary" block @click="selectWeek()">Week</v-btn>
							<v-row no-gutters class="d-flex justify-space-between">
								<v-btn class="font-weight-bold mb-2" small depressed color="primary" @click="selectMonth(1)">1 Month</v-btn>
								<v-btn class="font-weight-bold mb-2" small depressed color="primary" @click="selectMonth(3)">3 Months</v-btn>
								<v-btn class="font-weight-bold mb-2" small depressed color="primary" @click="selectMonth(6)">6 Months</v-btn>
							</v-row>
						</div>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</v-expand-transition>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, endOfDay, startOfDay, subDays, subMonths } from "date-fns";
import "leaflet.heat";
import L from "leaflet";

export default {
	props: {
		showHistoryMenu: {
			type: Boolean,
			default: false,
		},
		map: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			menu: false,
			dateRange: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],

			historyType: "polyline",

			trailGroup: null,
			selectedCart: null,

			modal: false,
		};
	},
	watch: {
		showHistoryMenu(newVal) {
			if (newVal) {
				this.selectToday();
			} else {
				if (this.trailGroup) {
					this.hideTrails();
					this.selectedCart = null;
				}
			}
		},

		modal(newVal) {
			if (!newVal) {
				this.cancelModal();
			}
		},

		historyType() {
			if (this.trailGroup) {
				this.hideTrails();
				this.showTrails();
			}
		},

		selectedCart() {
			this.hideTrails();
			this.showTrails();
		},
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			rideHistory: (state) => state.rides.rideHistory,
		}),

		...mapGetters(["deviceFromId"]),

		carts() {
			const ids = Object.keys(this.rideHistory);

			const carts = ids.map((id) => {
				const device = this.deviceFromId(id);
				return {
					carNumber: device.carNumber,
					value: id,
				};
			});

			//sort by car number
			carts.sort((a, b) => a.carNumber - b.carNumber);

			return carts;
		},

		displayDateRange() {
			// Check if either start or end date is undefined
			if (this.dateRange[0] === undefined || this.dateRange[1] === undefined) {
				return null; // Return null if either date is undefined
			}

			// If both dates are defined, format and return the date range string
			return `${format(new Date(this.dateRange[0]), "dd.MM.yy")} - ${format(new Date(this.dateRange[1]), "dd.MM.yy")}`;
		},

		orderedDateRange: {
			get() {
				// Simply return the current dateRange
				return this.dateRange;
			},
			set(newValue) {
				// Check if the newValue is valid and contains two dates
				if (newValue.length === 2 && newValue[0] && newValue[1]) {
					const startDate = new Date(newValue[0]);
					const endDate = new Date(newValue[1]);

					// If the start date is later than the end date, swap them
					if (startDate.getTime() > endDate.getTime()) {
						this.dateRange = [newValue[1], newValue[0]];
					} else {
						this.dateRange = newValue;
					}
				} else {
					// If newValue is not valid, just assign it directly to dateRange
					this.dateRange = newValue;
				}
			},
		},
	},
	methods: {
		saveDateRange() {
			this.modal = false;
			this.getRideHistory();
		},

		selectToday() {
			const today = new Date().toISOString().substr(0, 10);
			this.orderedDateRange = [today, today];

			this.getRideHistory();
			this.modal = false;
		},

		selectWeek() {
			const start = new Date().toISOString().substr(0, 10); // Assuming Monday as the first day of the week
			const end = subDays(new Date(), 7).toISOString().substr(0, 10);
			this.orderedDateRange = [start, end];

			this.getRideHistory();
			this.modal = false;
		},

		selectMonth(count = 1) {
			const start = new Date().toISOString().substr(0, 10);
			const end = subMonths(new Date(), count).toISOString().substr(0, 10);
			this.orderedDateRange = [start, end];

			this.getRideHistory();
			this.modal = false;
		},

		cancelModal() {
			this.modal = false;

			if (this.orderedDateRange[0] === undefined || this.orderedDateRange[1] === undefined) {
				this.selectMonth();
			}
		},

		async getRideHistory() {
			const queryParams = {
				start: startOfDay(new Date(this.orderedDateRange[0])),
				end: endOfDay(new Date(this.orderedDateRange[1])),
			};

			await this.$store.dispatch("rides/getRideHistory", queryParams);

			this.hideTrails();
			this.showTrails();
		},

		showTrails() {
			if (this.historyType === "polyline") {
				this.showPolylineTrails();
			} else if (this.historyType === "heatmap") {
				this.showHeatmap();
			}
		},

		showPolylineTrails() {
			this.trailGroup = L.featureGroup();
			const options = {
				color: "#406dfa",
				weight: 4,
				opacity: 1,
			};

			const highlightOptions = {
				color: "#9A27B0", // color when polyline is hovered over
			};

			const rideKeys = this.selectedCart ? [this.selectedCart] : Object.keys(this.rideHistory);

			rideKeys.forEach((rideKey) => {
				const ride = this.rideHistory[rideKey];
				const polyline = L.polyline(ride.geometry.coordinates, options);

				const device = this.deviceFromId(rideKey);
				polyline.bindTooltip(`Cart ${device.carNumber}`, {
					sticky: true,
					direction: "top",
				});

				polyline
					.on("mouseover", function () {
						this.setStyle(highlightOptions);
						this.bringToFront();
					})
					.on("mouseout", function () {
						this.setStyle(options);
					});

				polyline.on("click", () => {
					this.selectedCart = rideKey;
					this.recenterMap();
				});

				this.trailGroup.addLayer(polyline);
			});

			this.trailGroup.addTo(this.map);
			this.recenterMap();
		},

		showHeatmap() {
			let heatPoints = [];
			const rideKeys = this.selectedCart ? [this.selectedCart] : Object.keys(this.rideHistory);

			// Collecting all coordinates
			rideKeys.forEach((rideKey) => {
				const ride = this.rideHistory[rideKey];
				ride.geometry.coordinates.forEach((coord) => {
					heatPoints.push([coord[0], coord[1], 1]); // lat, lng, intensity
				});
			});

			// Calculate the total number of points
			const totalPoints = heatPoints.length;

			// Dynamically adjust heatmap parameters based on total points
			const { radius, blur } = this.adjustHeatmapParametersBasedOnPoints(totalPoints);

			// Check and clear existing heatmap layer
			if (this.trailGroup) {
				this.map.removeLayer(this.trailGroup);
			}

			// Create and add the heatmap layer with dynamically adjusted parameters
			this.trailGroup = L.heatLayer(heatPoints, { radius: radius, blur: blur }).addTo(this.map);
		},

		adjustHeatmapParametersBasedOnPoints(totalPoints) {
			console.log(totalPoints);

			let radius = 25; // Base radius
			let blur = 15; // Base blur

			// Logarithmic scaling for dynamic adjustment
			const logPoints = Math.log10(totalPoints + 1); // Adding 1 to avoid log(0)

			// Adjust radius and blur based on logarithm of total points
			radius -= logPoints * 2.3; // Example: decrease radius more as points increase
			blur += logPoints * 2.4; // Example: increase blur slightly as points increase

			// Ensure radius and blur stay within reasonable bounds
			radius = Math.max(radius, 5); // Minimum radius
			blur = Math.min(blur, 25); // Maximum blur

			return { radius, blur };
		},

		hideTrails() {
			if (this.trailGroup) {
				this.map.removeLayer(this.trailGroup);
				this.trailGroup = null; // Reset the trail group after removal
			}
		},

		recenterMap() {
			this.map.invalidateSize();

			const bounds = this.trailGroup.getBounds();

			if (bounds.isValid()) {
				this.map.fitBounds(bounds, { padding: [10, 10] });
			}
		},
	},
};
</script>

<style scoped>
.historyToolbar {
	position: absolute !important;
	margin-left: auto;
	margin-right: auto;
	right: 0;
	left: 0;
	top: 60px;
	z-index: 1000;
	width: max-content;
}
</style>
