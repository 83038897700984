<template>
	<v-dialog v-model="dialog" max-width="600px" v-if="publicProfile" persistent scrollable>
		<template v-slot:activator="{ on }">
			<slot name="activator" v-bind="on"></slot>
		</template>
		<v-card>
			<v-card-title class="font-weight-bold px-8"> {{ $t("settingsPage.publicProfile.title") }} </v-card-title>
			<v-card-text style="height: 420px">
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-container>
						<v-row>
							<v-col>
								<v-text-field
									outlined
									dense
									v-model="publicProfile.name"
									:label="$t('settingsPage.publicProfile.displayName')"
									placeholder="IOTee Golf Club"
									:rules="[rules.required]"
									required
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
						<div>
							<v-row>
								<v-col>
									<v-text-field outlined dense v-model="publicProfile.address.street" label="Street, Number or Name" placeholder="1 am Golfplatz" hide-details></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field outlined dense v-model="publicProfile.address.city" label="City" placeholder="Mannheim" hide-details></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field outlined dense v-model="publicProfile.address.postcode" label="Postcode" placeholder="22133" hide-details></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field outlined dense v-model="publicProfile.address.country" label="Country" placeholder="Germany" hide-details></v-text-field>
								</v-col>
							</v-row>
						</div>
						<v-row>
							<v-col>
								<v-textarea
									outlined
									auto-grow
									rows="2"
									row-height="30"
									dense
									v-model="publicProfile.description"
									:label="$t('settingsPage.publicProfile.description')"
									placeholder="A short description of your club"
									hide-details
								></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea
									outlined
									auto-grow
									rows="2"
									row-height="30"
									dense
									v-model="publicProfile.termsAndConditions"
									:label="$t('settingsPage.publicProfile.terms')"
									:placeholder="$t('settingsPage.publicProfile.termsPlaceholder')"
									hide-details
								>
								</v-textarea>
								<v-btn
									v-if="!publicProfile.termsAndConditions"
									class="primary"
									block
									small
									@click="publicProfile.termsAndConditions = termsAndConditions"
									:disabled="publicProfile.termsAndConditions === termsAndConditions"
								>
									{{ $t("settingsPage.publicProfile.termsTemplate") }}
								</v-btn>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field
									outlined
									dense
									v-model="publicProfile.website"
									:rules="[rules.validUrl]"
									label="Website"
									placeholder="https://www.example.io"
									hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="primary" outlined @click="close">{{ $t("general.cancel") }}</v-btn>
				<v-btn color="primary" depressed @click="save" :disabled="!isDirty" :loading="loading"> {{ $t("general.save") }}</v-btn>
			</v-card-actions>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import Confirm from "@/components/Confirm.vue";

export default {
	components: { Confirm },
	props: {
		selectedSite: {
			required: true,
		},
	},
	data() {
		return {
			valid: false,
			dialog: false,
			editing: false,
			isDirty: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$t("rules.required"),
				validUrl: (value) => {
					if (!value) return true;
					const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
					return pattern.test(value) || this.$t("rules.validUrl");
				},
			},
			publicProfile: null,
			termsAndConditions: `Please read and accept the terms and conditions before hiring a golf buggy.

1. The golf buggy must be used only for its intended purpose, which is for transportation on the golf course.

2. The hirer is responsible for any damages caused to the golf buggy during the hire period.

3. The hirer must return the golf buggy in the same condition as it was received.

4. The hirer must follow all safety guidelines and instructions provided by the golf course staff.

5. The golf buggy hire is subject to availability and may be limited during peak times.

6. The hirer must be of legal age and possess a valid driver's license.

7. The golf buggy hire fee is non-refundable once the hire period has started.

8. The golf course reserves the right to refuse or terminate the golf buggy hire at any time.

By accepting these terms and conditions, you acknowledge that you have read and understood them and agree to abide by them during the golf buggy hire period.`,
		};
	},
	watch: {
		publicProfile: {
			handler() {
				if (this.dialog) this.isDirty = true;
			},
			deep: true,
		},

		dialog(val) {
			if (!val) {
				this.resetForm();
			}
		},
	},
	methods: {
		resetForm() {
			this.dialog = false;
			this.editing = false;
			this.isDirty = false;
			this.publicProfile = JSON.parse(JSON.stringify(this.selectedSite.publicProfile));

			this.$nextTick(() => {
				this.$refs.form.resetValidation();
			});
		},

		open() {
			this.dialog = true;

			this.publicProfile = JSON.parse(JSON.stringify(this.selectedSite.publicProfile));

			this.$nextTick(() => {
				this.isDirty = false;
			});
		},

		async close() {
			if (this.isDirty) {
				const res = await this.$refs.confirm.open(this.$t("general.close"), this.$t("general.closeWithoutSave"), "accent", "mdi-help-circle-outline");

				if (res === false) {
					return;
				}
			}
			this.resetForm();
		},

		async save() {
			if (this.$refs.form.validate()) {
				// Prepend "https://" to the website URL if it's missing
				if (this.publicProfile.website && !this.publicProfile.website.startsWith("http://") && !this.publicProfile.website.startsWith("https://")) {
					this.publicProfile.website = `https://${this.publicProfile.website}`;
				}

				const siteSettings = {
					updateData: {
						_id: this.selectedSite._id,
						publicProfile: this.publicProfile,
					},
				};

				this.loading = true;

				try {
					await this.$store.dispatch("updateSite", siteSettings);
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.loading = false;
				}

				this.resetForm();
			}
		},
	},
};
</script>

<style scoped>
.date-selection >>> .vc-date {
	display: none !important;
}
</style>
