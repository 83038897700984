<template>
	<div>
		<v-menu :close-on-content-click="true" bottom left offset-y min-width="180px">
			<template v-slot:activator="{ on: menu }">
				<v-btn :disabled="!selectedCars.length || !hasRole('basic')" small color="primary" depressed outlined v-on="menu">
					{{ $t("fleetPage.actionsMenu.title") }}
					<v-icon small right>mdi-menu-down</v-icon>
				</v-btn>
			</template>

			<v-list dense nav>
				<v-list-item class="font-weight-bold"> {{ $t("general.carts") }}: {{ selectedCars.length }} </v-list-item>
				<v-list-item @click="pingCars(2)" v-if="hasRole('admin')" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-broadcast</v-icon>
						Ping
					</v-list-item-title>
				</v-list-item>
				<v-list-item @click="unlockSelected" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-lock-open-outline</v-icon>
						{{ $t("fleetPage.actionsMenu.unlockSel") }}
					</v-list-item-title>
				</v-list-item>
				<v-list-item @click="lockSelected" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-lock</v-icon>
						{{ $t("fleetPage.actionsMenu.lockSel") }}
					</v-list-item-title>
				</v-list-item>
				<v-list-item @click="saveKeyless(1)" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-key-variant</v-icon>
						{{ $t("general.keyMode") }}
					</v-list-item-title>
				</v-list-item>
				<v-list-item @click="saveKeyless(0)" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-qrcode-scan</v-icon>
						{{ $t("general.keylessMode") }}
					</v-list-item-title>
				</v-list-item>

				<unlock-code @updateUnlockCodes="updateUnlockCodes($event)" />

				<multi-car-vehicle-settings @updateVehicleInfo="updateVehicleInfo" />

				<v-list-item @click="pingReboot()" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-rotate-left</v-icon>
						{{ $t("carModal.reboot") }}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
		<confirm ref="confirm" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import Confirm from "@/components/Confirm";
import UnlockCode from "@/components/fleet/MultiCarUnlockCode";
import MultiCarVehicleSettings from "@/components/fleet/MultiCarVehicleSettings";

export default {
	props: {
		selectedSite: {
			default: null,
		},
		selectedCars: {
			default: null,
		},
	},
	components: {
		Confirm,
		UnlockCode,
		MultiCarVehicleSettings,
	},
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
		}),
	},
	methods: {
		async pingCars(val) {
			this.loading = true;

			try {
				const devices = this.selectedCars.map((selected) => {
					return selected._id;
				});

				await this.$store.dispatch("admin/pingDevices", {
					ids: devices,
					command: val,
				});

				const message = `${this.$t("general.commandSent")}: ${this.selectedCars.length} ${this.selectedCars.length > 1 ? this.$t("general.carts") : this.$t("general.cart")}`;

				this.$toast.success(message);

				this.loading = false;
			} catch (err) {
				console.log(err);
			}

			this.$emit("setSelectedCars", []);
		},

		async pingReboot() {
			const res = await this.$refs.confirm.open(this.$t("carModal.reboot"), this.$t("fleetPage.actionsMenu.rebootConfirm"), "accent", "mdi-restart");

			if (res === true) {
				try {
					const devices = this.selectedCars.map((selected) => {
						return selected._id;
					});

					await this.$store.dispatch("admin/pingDevices", {
						ids: devices,
						command: 3,
					});

					const message = `${this.$t("general.commandSent")}: ${this.selectedCars.length} ${this.selectedCars.length > 1 ? this.$t("general.carts") : this.$t("general.cart")}`;

					this.$toast.success(message);
				} catch (err) {
					console.log(err);
				}
			}

			this.$emit("setSelectedCars", []);
		},

		async saveKeyless(val) {
			this.loadingKeyless = true;

			const res = await this.$refs.confirm.open(
				`${val === 0 ? this.$t("general.keylessMode") : this.$t("general.keyMode")}`,
				`${val === 0 ? this.$t("fleetPage.actionsMenu.keylessModeConfirm") : this.$t("fleetPage.actionsMenu.keyModeConfirm")}`,
				"accent",
				`${val === 0 ? "mdi-qrcode-scan" : "mdi-key-variant"}`
			);

			if (res === true) {
				const settings = {
					ids: this.selectedCars.map((selected) => selected._id),
					updateData: {
						key: String(val),
					},
					publish: true,
				};

				await this.$store.dispatch("updateDevices", settings);

				this.loadingKeyless = false;
			}

			this.$emit("setSelectedCars", []);
		},

		getDevicesToLock(lockStatus) {
			return this.selectedCars.reduce((deviceIds, device) => {
				if (device.key === "0" && this.isOnline(device) && device.lockStatus !== lockStatus) {
					deviceIds.push(device._id);
				}
				return deviceIds;
			}, []);
		},

		async lockSelected() {
			const res = await this.$refs.confirm.open(
				this.$t("general.lock"),
				this.$t("fleetPage.actionsMenu.lockConfirm"),
				"success",
				"mdi-lock-outline",
				`${this.selectedCars.some((e) => e.key === "1") ? this.$t("fleetPage.actionsMenu.lockWarn") : ""}`
			);

			if (res !== true) {
				return;
			}

			try {
				const deviceIds = this.getDevicesToLock(1);

				await this.$store.dispatch("lockDevices", { ids: deviceIds });

				const message = `${this.$t("general.commandSent")}: ${deviceIds.length} ${deviceIds.length > 1 ? this.$t("general.carts") : this.$t("general.cart")}`;

				this.$toast.success(message);
			} catch (err) {
				console.log(err);
			}

			this.$emit("setSelectedCars", []);
		},

		async unlockSelected() {
			const res = await this.$refs.confirm.open(
				this.$t("general.unlock"),
				this.$t("fleetPage.actionsMenu.unlockConfirm"),
				"success",
				"mdi-lock-open-outline",
				`${this.selectedCars.some((e) => e.key === "1") ? this.$t("fleetPage.actionsMenu.unlockWarn") : ""}`
			);

			if (res !== true) {
				return;
			}

			try {
				const deviceIds = this.getDevicesToLock(0);

				await this.$store.dispatch("unlockDevices", { ids: deviceIds, rideData: { unlockedBy: this.user.email } });

				const message = `${this.$t("general.commandSent")}: ${deviceIds.length} ${deviceIds.length > 1 ? this.$t("general.carts") : this.$t("general.cart")}`;

				this.$toast.success(message);
			} catch (err) {
				console.log(err);
			}

			this.$emit("setSelectedCars", []);
		},

		async updateUnlockCodes(val) {
			const settings = {
				ids: this.selectedCars.map((selected) => selected._id),
				updateData: {
					lockCode: val,
				},
			};

			await this.$store.dispatch("updateDevices", settings);

			this.$emit("setSelectedCars", []);
		},

		async updateVehicleInfo(val) {
			const settings = {
				ids: this.selectedCars.map((selected) => selected._id),
				updateData: {
					vehicleInfo: val,
				},
			};

			await this.$store.dispatch("updateDevices", settings);

			this.$emit("setSelectedCars", []);
		},
	},
};
</script>
