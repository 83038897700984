<template>
	<div>
		<v-btn
			@click="openBottomSheet"
			block
			class="rounded-lg font-weight-bold d-flex align-center"
			:color="btnColor"
			dark
			depressed
		>
			<span class="text-center" style="flex-grow: 1">{{ btnTitle }}</span>
			<v-icon class="ml-auto">mdi-chevron-right</v-icon>
		</v-btn>

		<v-bottom-sheet
			v-model="bottomSheetOpen"
			content-class="rounded-t-xl"
			max-width="500px"
			width="100%"
		>
			<v-sheet class="rounded-t-xl text-center">
				<v-card-text
					v-if="title"
					class="accent--text text-wrap font-weight-bold subtitle-2 pb-0"
				>
					{{ title }}
				</v-card-text>
				<v-card-subtitle v-if="subtitle" class="pt-0 caption">
					{{ subtitle }}
				</v-card-subtitle>
				<v-card-text>
					<v-form
						ref="form"
						v-model="valid"
						lazy-validation
						@submit.prevent="submitCode"
					>
						<v-otp-input
							v-model="otpCode"
							:rules="codeRules"
							type="tel"
							length="6"
							:color="btnColor"
						></v-otp-input>

						<v-checkbox
							v-show="type === 'virtualKey'"
							:color="btnColor"
							class="mt-2 ml-4"
							v-model="rememberCode"
						>
							<template v-slot:label>
								<div>
									<span class="font-weight-bold">
										{{ $t("ride.bottomSheet.remember") }}
									</span>
								</div>
							</template>
						</v-checkbox>

						<v-btn
							:color="btnColor"
							class="mt-1 mb-6 rounded-lg white--text font-weight-bold"
							block
							type="submit"
							:disabled="otpCode.length !== 6"
						>
							{{ $t("general.continue") }}
						</v-btn>
					</v-form>
				</v-card-text>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
export default {
	props: {
		btnTitle: { default: "Open Sheet" },
		btnColor: { default: "primary" },
		title: { default: "" },
		subtitle: { default: "" },
		type: { default: "virtualKey" },
	},
	data() {
		return {
			rememberCode: false,
			bottomSheetOpen: false,
			otpCode: "",
			valid: false,
			codeRules: [
				(v) => /^-?\d+\.?\d*$/.test(v) || this.$i18n.t("rules.numbersOnly"),
				(v) => !!v || this.$i18n.t("rules.required"),
				(v) => (v && v.length === 6) || this.$i18n.t("rules.6Digits"),
			],
		};
	},
	watch: {
		bottomSheetOpen(val) {
			if (val === false) {
				this.otpCode = "";
				this.$refs.form.resetValidation();
			}
		},
		rememberCode(newVal, oldVal) {
			if (!newVal && oldVal) {
				localStorage.removeItem("unlockCode");
			}
		},
	},
	methods: {
		submitCode() {
			if (this.$refs.form.validate()) {
				if (this.rememberCode && this.type === "virtualKey") {
					localStorage.setItem("unlockCode", this.otpCode);
				}
				this.$emit("code-entered", this.otpCode);
				this.otpCode = "";
				this.bottomSheetOpen = false;
			}
		},

		openBottomSheet() {
			this.bottomSheetOpen = true;
			if (this.type === "virtualKey") {
				const otpCode = localStorage.getItem("unlockCode");
				if (otpCode) {
					this.otpCode = otpCode;
					this.rememberCode = true;
				}
			}
		},
	},
	mounted() {
		const otpCode = localStorage.getItem("unlockCode");
		if (otpCode && this.type === "virtualKey") {
			this.otpCode = otpCode;
			this.rememberCode = true;
		}
	},
};
</script>
