<template>
	<div>
		<v-card flat>
			<v-card-text>
				<v-row class="text-center">
					<v-col cols="12">
						<span class="text-center font-weight-bold">
							{{ $t("carModal.quickActions") }}
						</span>
					</v-col>
					<v-col class="d-flex flex-no-wrap justify-space-around">
						<lock-toggle-button :fab="true" :device="car" />
						<locate-button :car="car" />
						<key-toggle :car="car" />
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="12 pt-0 text-center">
						<span class="text-center font-weight-bold">
							{{ $t("carModal.carInfo") }}
						</span>
					</v-col>
					<v-col cols="6" class="px-0">
						<v-list class="text-center">
							<v-list-item v-if="car.ride && car.ride.meta && car.ride.meta.name">
								<v-list-item-content>
									<v-list-item-subtitle> Current Driver </v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.ride.meta.name }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.carNumber") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.carNumber }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.carName") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.carName }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.deviceId") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car._id }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
					<v-col cols="6" class="px-0 text-center">
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle> Odometer </v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ odometerTotal(car.carOdometer, car.carOdometerOffset) }}
										km
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carModal.unlockCode") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										<!-- <send-lock-code v-if="car.lockCode" :item="car" /> -->
										{{ car.lockCode }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.lastResponded") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ computedTimeAgo(car.timeUTC) }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
				</v-row>
			</v-card-text>

			<div v-if="batteryReadings.length">
				<v-divider class="my-4 grey py-2"></v-divider>

				<v-card-text class="pt-0">
					<v-row no-gutters>
						<v-col cols="12 pt-0 text-center">
							<span class="subtitle-1 font-weight-bold">Charge Data</span>
							<battery-readings :dataArray="batteryReadings" />
						</v-col>
					</v-row>
				</v-card-text>
			</div>

			<v-divider class="my-4 grey py-2"></v-divider>
			<v-card-text class="pt-0">
				<v-row no-gutters>
					<v-col cols="12 pt-0 text-center">
						<span class="text-center font-weight-bold">
							{{ $t("carModal.vehicle") }}
						</span>
					</v-col>
					<v-col cols="6" class="px-0">
						<v-list class="text-center">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.cartBrand") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										<v-chip small class="padlessBtn font-weight-bold mr-2" label color="warning darken-2">
											{{ car.vehicleInfo.cart.brand }}
										</v-chip>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.cartType") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.vehicleInfo.cart.model }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item v-if="car.vehicleInfo.notes && car.vehicleInfo.notes.length > 0">
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.cartNotes") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.vehicleInfo.notes }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
					<v-col cols="6" class="px-0">
						<v-list class="text-center">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.battType") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.vehicleInfo.power }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>
										{{ $t("carDetail.carSerial") }}
									</v-list-item-subtitle>
									<v-list-item-title class="mt-1 font-weight-medium">
										{{ car.carSerialNumber }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider class="my-4 grey py-2"></v-divider>
			<v-card-text class="pt-0">
				<v-row no-gutters>
					<v-col cols="12 pt-0 text-center">
						<span class="text-center font-weight-bold">
							{{ $t("carModal.qrCode") }}
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon class="ml-1" color="accent" small v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
								</template>
								<span>
									{{ $t("carModal.qrText") }}
								</span>
							</v-tooltip>
						</span>
					</v-col>
					<v-col cols="12 text-center">
						<div id="printSection" :hidden="!qr">
							<qrcode :value="siteQrCode" tag="img"></qrcode>
							<br />
							<a :href="siteQrCode" target="_blank">{{ siteQrCode }}</a>
						</div>
						<div class="mt-3">
							<v-btn small color="primary" :outlined="!qr" @click="qr = !qr" class="mr-1">
								<v-icon small left>mdi-qrcode</v-icon>
								{{ qr ? $t("carModal.hide") : $t("carModal.show") }}
							</v-btn>
							<v-btn small color="primary" class="ml-1" @click="printSection"> <v-icon small left>mdi-printer</v-icon>{{ $t("carModal.print") }} </v-btn>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import qrcode from "@chenfengyuan/vue-qrcode";
import LocateButton from "@/components/LocateButton";
import LockToggleButton from "@/components/fleet/LockModal.vue";
import KeyToggle from "@/components/fleet/KeyToggle.vue";
import { getDeviceBatteryReadings } from "@/services/devices.service";
import BatteryReadings from "@/components/charts/BatteryReadings.vue";

export default {
	components: {
		qrcode,
		LocateButton,
		LockToggleButton,
		KeyToggle,
		BatteryReadings,
	},
	props: {
		car: {
			type: Object,
			required: true,
		},
		selectedSite: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			qr: false,
			batteryReadings: [],
		};
	},
	computed: {
		siteQrCode() {
			return `${process.env.VUE_APP_BASE_APP_URL}/guest/site/${this.selectedSite._id}`;
		},
	},
	mounted() {
		// this.getDeviceBatteryReadings();
	},
	methods: {
		printSection() {
			this.$htmlToPaper("printSection");
		},

		async getDeviceBatteryReadings() {
			try {
				const data = await getDeviceBatteryReadings(this.car._id);

				this.batteryReadings = data;
			} catch (err) {
				console.log(err);
			}
		},
	},
};
</script>
