<template>
	<v-expansion-panel class="rounded-lg">
		<v-expansion-panel-header>
			<template v-slot:default>
				<v-row>
					<v-col class="pa-0">
						<v-card-title class="font-weight-bold mb-2">
							{{ title }}
						</v-card-title>
						<v-card-subtitle v-if="subtitle">
							{{ subtitle }}
						</v-card-subtitle>
					</v-col>
				</v-row>
			</template>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<slot />
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
export default {
	props: {
		title: {
			default: "",
		},
		subtitle: {
			default: null,
		},
	},
};
</script>
