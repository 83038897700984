<template>
	<page-loading v-if="loading" />

	<v-container v-else fluid>
		<v-row>
			<v-col xs="12" sm="8" md="4" class="mx-auto">
				<v-row>
					<v-col>
						<cart-header :device="device" :site="site" />
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<router-view :site="site" :device="device" :status="status" :virtualKey="virtualKey" @getBookings="getBookings" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import { subMinutes, addMinutes } from "date-fns";

import CartHeader from "@/components/scanToRide/CartHeader";
import PageLoading from "@/components/PageLoading";

export default {
	metaInfo: {
		title: "IOTee",
		meta: [
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
			},
		],
	},
	components: {
		PageLoading,
		CartHeader,
	},
	data() {
		return {
			loading: true,
			status: null,
			availableCarts: [],
		};
	},
	mounted() {
		// this.getCartStatus();
		this.getDevice();
	},
	watch: {
		bookings() {
			this.setStatus();
		},
	},
	computed: {
		...mapState({
			bookings: (state) => state.guest.bookings.bookings,
			device: (state) => state.guest.devices.device,
			site: (state) => state.guest.sites.site,
			virtualKey: (state) => state.guest.sites.virtualKey,
		}),
	},
	methods: {
		async getCartStatus() {
			this.loading = true;
			try {
				await this.getDevice();
				await this.getSite();

				this.setStatus();
			} catch (error) {
				this.$toast.error(error.error.message || "An error occurred");
			} finally {
				this.loading = false;
			}
		},

		async getDevice() {
			const deviceId = this.$route.params.id;

			try {
				await this.$store.dispatch("guest/devices/getDevice", deviceId);

				if (this.device) {
					const { siteID } = this.device;

					this.$router.push({ name: "guest.site", params: { id: siteID } });
				}
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			}
		},

		async getSite() {
			const site = this.device.siteID;
			await this.$store.dispatch("guest/sites/getSite", site);
		},

		async getBookings(service) {
			const site = this.device.siteID;
			const device = this.device._id;
			const start = subMinutes(new Date(), this.site.bookingOffset);
			const end = addMinutes(new Date(), service.duration || 0);

			await this.$store.dispatch("guest/bookings/getBookings", { site, query: { device, start, end } });
		},

		setStatus() {
			if (!this.device.onlineStatus) return (this.status = "OFFLINE");
			if (this.device.session || this.device.lockStatus !== 1) return (this.status = "IN_USE");
			if (!this.isSiteOpen(this.site.openingHours)) return (this.status = "SITE_CLOSED");
			if (this.bookings?.length > 0) return (this.status = "BOOKED");

			return (this.status = "AVAILABLE");
		},

		async getAvailableCarts() {},
	},
};
</script>
