import Vue from "vue";

/**
 * * Get a member
 * @param {Object} query - Query object incl. pagination, sort, filter
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const query = {
 *  page: 1,
 *  limit: 10,
 *  sort: "name",
 *  name: "John",
 * };
 */
export const getMembers = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/members`, { params: query });

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

/**
 * * Add a member
 * @param {Object} payload - Member object
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const payload = {
 *   name: "John",
 * };
 */
export const addMember = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const importMembers = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members/import`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

/**
 * * Update a member
 * @param {Object} payload - Member object
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const payload = {
 *    _id: "5f8c5d3d6c7a0e2b7c7c0d9b",
 *    name: "John",
 *  };
 */
export const updateMember = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/members/${payload._id}`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateMembers = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/members`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

/**
 * * Link a member
 * @param {Object} id - Member ID
 * @param {Object} body - email
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const id = "5f8c5d3d6c7a0e2b7c7c0d9b";
 * @example const body = {
 * 	email: "user@email.com",
 * };
 */
export const linkMember = async ({ id, body }) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members/${id}/link`, body);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

/**
 * * Unlink a member
 * @param {Object} id - Member ID
 * @param {Object} body - profileId
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const id = "5f8c5d3d6c7a0e2b7c7c0d9b";
 * @example const body = {
 * 	profileId: "5f8c5d3d6c7a0e2b7c7c0d9b",
 * };
 */
export const unlinkMember = async ({ id, body }) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members/${id}/unlink`, body);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const linkMembers = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members/link`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const unlinkMembers = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members/unlink`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

/**
 * * Delete a member
 * @param {Object} memberId - Member ID
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const memberId = "5f8c5d3d6c7a0e2b7c7c0d9b";
 */
export const deleteMember = async (memberId) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.delete(`/members/${memberId}`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

/**
 * * Delete multiple members
 * @param {Array} ids - Array of member IDs
 * @returns {Object} - Response data
 * @throws {Error} - Error object
 * @example const ids = ["5f8c5d3d6c7a0e2b7c7c0d9b", "5f8c5d3d6c7a0e2b7c7c0d9c"];
 */
export const deleteMembers = async (ids) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/members/delete`, ids);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
