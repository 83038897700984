<template>
	<div>
		<apexchart type="area" :options="chartOptions" :series="chartSeries" />
	</div>
</template>

<script>
import { format } from "date-fns";

export default {
	props: {
		dataArray: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			chartOptions: {
				chart: {
					id: "area-chart",
					type: "line",
					toolbar: {
						show: true,
						tools: {
							download: false,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true,
						},
					},
				},
				annotations: {
					points: [], // Will be updated with annotations
				},
				grid: {
					show: false,
				},
				stroke: {
					curve: "smooth",
				},
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					categories: [], // Will be updated with formatted timestamps
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
					decimalsInFloat: 0,
				},
			},
			chartSeries: [
				{
					name: "Percent (%)",
					data: [], // Will be updated with volts data
				},
				{
					name: "Volts (V)",
					data: [], // Will be updated with percent data
				},
			],
		};
	},
	watch: {
		// Watch for changes in dataArray and process data when it changes
		dataArray: {
			immediate: true,
			handler(newVal) {
				if (newVal && newVal.length > 0) {
					this.processData();
				}
			},
		},
	},

	methods: {
		processData() {
			const voltsData = [];
			const percentData = [];
			const annotations = [];

			this.dataArray.forEach((item) => {
				const formattedTime = format(new Date(item.timestamp), "HH:mm");
				voltsData.push({ x: formattedTime, y: item.volts });
				percentData.push({ x: formattedTime, y: item.percent });

				if (item.charge !== undefined) {
					annotations.push({
						x: formattedTime,
						y: item.volts,
						marker: {
							size: 8,
							fillColor: "#fff",
							strokeColor: item.charge ? "#00E396" : "#FF4560",
							radius: 2,
							cssClass: "apexcharts-custom-class",
						},
						label: {
							offsetY: 0,
							style: {
								color: "#fff",
								background: item.charge ? "#00E396" : "#FF4560",
							},

							text: item.charge ? "On Charge" : "Off Charge",
						},
					});
				}
			});

			this.chartSeries[0].data = percentData;
			this.chartSeries[1].data = voltsData;
			this.chartOptions.annotations.points = annotations;

			console.log(this.chartOptions.annotations.points);
		},
	},
};
</script>
