import Vue from "vue";

export const getDevices = async (siteId) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/devices`, {
			headers: {
				"site-id": siteId,
			},
		});

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getDevice = async (deviceId) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/devices/${deviceId}`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getAvailableDevices = async ({ siteId, query }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/devices/availability`, {
			params: query,
			headers: {
				"site-id": siteId,
			},
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const toggleLockWithMasterKey = async ({ siteId, deviceId, virtualKeyId, lockStatus }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.post(
			`/devices/${deviceId}/toggle-lock`,
			{ lockStatus, virtualKeyId },
			{
				headers: {
					"site-id": siteId,
				},
			}
		);

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};
