<template>
	<div>
		<v-card v-if="loading" class="rounded-lg" outlined>
			<v-card-text class="text-center">
				<v-progress-circular
					indeterminate
					color="primary"
				></v-progress-circular>
			</v-card-text>
		</v-card>

		<not-found
			v-else-if="!ride"
			:title="$t('s2r.session.notFound.title')"
			:subtitle="$t('s2r.session.notFound.subtitle')"
		/>

		<div v-else>
			<v-card class="rounded-lg" outlined v-if="awaitingConfirmation">
				<v-card-text v-if="failedUnlock" class="text-center">
					<p class="title font-weight-bold mb-4">
						{{ $t("s2r.session.failed") }}
					</p>

					<v-btn
						v-if="retryCount < 1"
						block
						rounded-lg
						color="primary"
						@click="tryAgain"
					>
						{{ $t("s2r.session.tryAgain") }}...
					</v-btn>
					<span v-else>
						{{ $t("s2r.session.failed2") }}
					</span>
				</v-card-text>

				<v-card-text v-else class="text-center">
					<v-img class="mx-auto" max-width="300" src="/tpc-sticker.gif"></v-img>
					<span class="font-weight-bold">{{ $t("s2r.session.wait") }}...</span>
				</v-card-text>
			</v-card>

			<div v-if="rideActive">
				<v-card outlined class="rounded-lg mb-4" color="primary lighten-5">
					<div class="pa-4">
						<div class="subtitle-1 font-weight-bold">
							⚡️ {{ $t("s2r.session.enjoy") }}!
						</div>
						<div class="mb-4 mt-2 subtitle-2">
							{{ $t("s2r.session.message") }}! 🔌
						</div>
					</div>
				</v-card>

				<v-card outlined class="rounded-lg mb-4" color="grey ">
					<div class="pa-4">
						<v-list color="transparent">
							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent"> mdi-account </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="accent--text font-weight-bold">
										{{ ride.name || ride.driverName }}
									</v-list-item-title>
									<v-list-item-subtitle class="text-wrap">
										{{ ride.email }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent"> mdi-calendar</v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="accent--text font-weight-bold">
										{{ translatedFormattedDate(ride.start, "EE do MMMM") }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item>
								<v-list-item-icon>
									<v-icon color="accent">mdi-lock-open</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">
										{{ elapsedTime }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item v-if="ride.rideTags.length">
								<v-list-item-content>
									<v-chip-group column>
										<v-chip
											v-for="(item, i) in ride.rideTags"
											:key="i"
											:color="item.color"
											class="font-weight-bold"
											label
										>
											{{ item.text }}
										</v-chip>
									</v-chip-group>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</div>
				</v-card>
			</div>

			<div v-else-if="rideEnded">
				<not-found
					:title="$t('s2r.session.ended.title')"
					:subtitle="$t('s2r.session.ended.subtitle')"
				/>
			</div>
		</div>

		<v-bottom-sheet
			v-model="rideActive"
			hideOverlay
			persistent
			content-class="rounded-t-xl"
			max-width="500px"
			width="100%"
		>
			<v-sheet class="rounded-t-xl text-center">
				<v-card-text>
					<v-btn
						:loading="lockLoading"
						class="rounded-lg font-weight-bold mb-4"
						block
						color="primary"
						@click="endRide"
					>
						{{ $t("s2r.session.end") }} 🔓
					</v-btn>
				</v-card-text>
			</v-sheet>
		</v-bottom-sheet>
		<confirm ref="confirm" />
	</div>
</template>

<script>
import NotFound from "../../components/scanToRide/NotFound.vue";
import DevicesService from "@/services/devicesService";
import Confirm from "@/components/Confirm";

export default {
	props: ["device"],
	components: {
		NotFound,
		Confirm,
	},
	data() {
		return {
			loading: true,
			lockLoading: false,
			ride: null,
			eventSource: null,
			timeoutId: null,
			failedUnlock: false,
			retryCount: 0,
			elapsedTime: null,
			intervalId: null,
		};
	},
	watch: {
		rideActive: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					// Save session ID to localStorage
					localStorage.setItem("sessionID", this.$route.params.sessionId);
				} else {
					// Remove session ID from localStorage
					localStorage.removeItem("sessionID");
				}
			},
		},
	},
	computed: {
		awaitingConfirmation() {
			return !this.ride?.confirmed && this.ride?.status === 1;
		},
		rideActive() {
			return this.ride?.confirmed && this.ride?.status === 1;
		},
		rideEnded() {
			return this.ride?.confirmed && this.ride?.status === 0;
		},
	},
	mounted() {
		this.getRide();
	},
	methods: {
		async getRide() {
			try {
				const { data } = await this.$ioteeApiV2.get(
					`/rides/${this.$route.params.sessionId}`,
					{
						params: {
							expand: ["rate"], // fields to populate
						},
					}
				);

				this.ride = data;

				// If the ride is not confirmed, connect to SSR to receive live updates.
				// Try for 1 minute and disconnect if the ride is confirmed

				this.connect();
				this.timeoutId = setTimeout(() => {
					if (this.awaitingConfirmation) {
						this.failedUnlock = true;
					}
				}, 20000);

				this.calculateElapsedTime();
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},

		connect() {
			this.$socket.client.emit(
				"join-ride-updates",
				this.$route.params.sessionId
			);

			this.$socket.client.on("ride-update", (ride) => {
				this.ride = ride;

				if (this.rideEnded) {
					this.disconnect();
					clearTimeout(this.timeoutId);
				}
			});
		},

		disconnect() {
			this.$socket.client.emit(
				"leave-ride-updates",
				this.$route.params.sessionId
			);
		},

		calculateElapsedTime() {
			const rideStartTime = new Date(this.ride.start);
			this.intervalId = setInterval(() => {
				this.elapsedTime = this.formatDurationToTime(rideStartTime, new Date());
			}, 1000);
		},

		async tryAgain() {
			this.retryCount++;
			this.failedUnlock = false;
			await DevicesService.unlockDevice({
				deviceId: this.device.id,
				sessionId: this.$route.params.sessionId,
			});

			this.getRide();
		},

		async confirmCommand() {
			return await this.$refs.confirm.open(
				this.$t("ride.session.endTitle"),
				this.$t("ride.session.endMessage"),
				"primary",
				"mdi-lock"
			);
		},

		async endRide() {
			this.lockLoading = true;

			try {
				if (await this.confirmCommand()) {
					await this.$ioteeApiV2.post(`/devices/${this.$route.params.id}/lock`);
				}
			} catch (err) {
				console.log(err);
			} finally {
				this.lockLoading = false;
			}
		},
	},
	beforeDestroy() {
		this.disconnect();
		clearInterval(this.intervalId);
	},
};
</script>
