import Vue from "vue";

export const getClients = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.get("/clients");

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const createClient = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.post("/clients", payload);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateClient = async (payload) => {
	const { id, body } = payload;

	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.patch(`/clients/${id}`, body);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteClient = async (id) => {
	try {
		const { data } = await Vue.prototype.$ioteeAdminApi.delete(`/clients/${id}`);

		return data.data;
	} catch (err) {
		throw err.response.data;
	}
};
