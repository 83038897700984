<template>
	<v-dialog v-model="dialog" max-width="500px">
		<v-card>
			<v-card-title>{{ title }} </v-card-title>

			<v-form ref="form" v-model="valid" lazy-validation>
				<v-card-text>
					<div v-if="imagePreview" class="d-flex align-center justify-center">
						<img :src="imagePreview" alt="Preview" :width="type === 'logo' ? 100 : 200" />
					</div>
					<v-file-input
						v-model="selectedFile"
						outlined
						dense
						hide-details
						class="mt-4"
						label="Choose a file"
						accept=".jpg, .png"
						:rules="fileValidationRule"
						@change="(e) => previewFile(e)"
					></v-file-input>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" depressed @click="uploadFile" :disabled="!valid" :loading="loading">Upload</v-btn>
					<v-btn color="primary" outlined @click="close">Cancel</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			valid: false,
			dialog: false,
			selectedFile: null,
			type: null,
			imagePreview: null,
			fileValidationRule: [
				(v) => !!v || "File is required",
				(v) => !v || ["image/jpeg", "image/png"].includes(v.type) || "File must be an image (jpg, png)",
				(v) => !v || v.size <= 2 * 1024 * 1024 || "File size must be less than 2MB",
			],
		};
	},
	computed: {
		title() {
			return this.type === "logo" ? "Upload Logo" : "Upload Cover Photo";
		},
	},
	methods: {
		open(type) {
			this.dialog = true;
			this.type = type;
			this.imagePreview = null;
		},
		close() {
			this.dialog = false;
			this.type = null;
			this.selectedFile = null;
			this.imagePreview = null;
			this.$refs.form.resetValidation();
		},

		previewFile(event) {
			const file = event;
			if (file && file.type.startsWith("image/")) {
				const reader = new FileReader();
				reader.onload = (e) => {
					this.imagePreview = e.target.result;
				};
				reader.readAsDataURL(file);
			} else {
				this.imagePreview = null;
			}
		},

		async uploadFile() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				const formData = new FormData();
				formData.append("image", this.selectedFile);

				try {
					await this.$store.dispatch(this.type === "logo" ? "uploadLogo" : "uploadCoverPhoto", formData);

					this.$toast.success("Image uploaded successfully");
					this.close();
				} catch (err) {
					this.$toast.error("Error uploading image: " + (err.response?.data?.message || err.message));
				} finally {
					this.loading = false;
				}
			}
		},
	},
};
</script>
