<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" small color="purple" dark class="mr-2 padlessBtn" depressed>
				<v-icon small> mdi-magnify </v-icon>
			</v-btn>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>Find Device</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="form" v-model="form" lazy-validation @submit.prevent="search">
					<v-text-field
						autofocus
						placeholder="Device ID"
						label="Device ID"
						hide-details="auto"
						v-model="value"
						:rules="[rules.required]"
						outlined
						rounded
						dense
						clearable
						class="mb-3"
					></v-text-field>
					<v-btn block rounded :loading="loading" @click="search" color="primary" class="mt-3"> Search </v-btn>
				</v-form>
			</v-card-text>
			<v-card-text v-if="device">
				<v-card color="primary lighten-4" flat class="pa-1 rounded-lg">
					<v-simple-table dense>
						<template v-slot:default>
							<tbody>
								<tr>
									<td>Site</td>
									<td>
										<v-btn x-small text @click="selectSite(device.siteID)" class="font-weight-bold" color="primary">
											{{ device.siteID }}
										</v-btn>
									</td>
								</tr>
								<tr>
									<td>Car Number</td>
									<td>
										{{ device.carNumber }}
									</td>
								</tr>
								<tr>
									<td>Device ID</td>
									<td>
										{{ device._id }}
									</td>
								</tr>
								<tr>
									<td>Lock Status</td>
									<td>
										{{ device.lockStatus ? "Locked" : "Unlocked" }}
									</td>
								</tr>
								<tr>
									<td>Lat/Long</td>
									<td>
										{{ device.gpsCoordinates }}
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card>
			</v-card-text>
			<v-card-text class="text-center font-weight-bold title" v-if="notFound"> Device not found </v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			dialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			value: null,
			form: true,
			notFound: false,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	computed: {
		...mapState({
			device: (state) => state.admin.devices.device,
		}),
	},
	methods: {
		async search() {
			if (this.$refs.form.validate()) {
				try {
					this.loading = true;

					await this.$store.dispatch("admin/getDevice", this.value);

					if (this.device) {
						this.notFound = false;
					} else {
						this.notFound = true;
					}
				} catch (err) {
					this.$toast.error(err.error.message || "Error");
				} finally {
					this.loading = false;
				}
			}
		},

		reset() {
			this.value = null;
			this.device = null;
			this.notFound = null;
			this.$refs.form.resetValidation();
		},

		selectSite(siteID) {
			if (this.$refs.form.validate()) {
				localStorage.setItem("selectedSiteID", siteID);
				this.$store.commit("setSelectedDevice", null);
				this.$emit("siteChanged");
				window.location.reload();
			}
		},
	},
};
</script>
