<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<div v-if="site.publicProfile.active">
					<selection-card icon="mdi-credit-card" color="green" :title="$t('s2r.payment.title')" subtitle="Book and pay online" class="mb-6">
						<v-btn block class="rounded-lg font-weight-bold d-flex align-center" color="green" dark depressed @click="goToPayment">
							<span class="text-center" style="flex-grow: 1">
								{{ $t("s2r.payment.continue") }}
							</span>
							<v-icon class="ml-auto">mdi-chevron-right</v-icon>
						</v-btn>
					</selection-card>
				</div>

				<div v-else>
					<selection-card icon="mdi-calendar-month" color="primary" :title="$t('bookings.booking')" :subtitle="$t('s2r.booked.subtitle')" class="mb-6">
						<bottom-sheet
							type="booking"
							:btnTitle="$t('s2r.booked.btn')"
							btnColor="primary"
							:title="$t('s2r.booked.enter')"
							:subtitle="$t('s2r.booked.enterSub')"
							:loading="loadingBooking"
							@code-entered="retrieveBooking"
						/>
					</selection-card>

					<selection-card
						v-if="!site.bookingIntegration"
						icon="mdi-key-variant"
						color="purple"
						:title="$t('s2r.virtualKey.title')"
						:subtitle="$t('s2r.virtualKey.subtitle')"
						class="mb-6"
					>
						<bottom-sheet :btnTitle="$t('s2r.virtualKey.btn')" btnColor="purple" :title="$t('s2r.virtualKey.enter')" :loading="loadingVirtualKey" @code-entered="getVirtualKey" />
					</selection-card>
				</div>

				<selection-card icon="mdi-shield" color="accent" :title="$t('s2r.master.title')" :subtitle="$t('s2r.master.subtitle')">
					<bottom-sheet :btnTitle="$t('s2r.virtualKey.btn')" btnColor="accent" :title="$t('s2r.master.subtitle')" :loading="loadingMasterKey" @code-entered="getMasterKey" />
				</selection-card>
			</v-col>
		</v-row>

		<confirm ref="confirm" yesText="general.continue" noText="general.cancel" />
	</v-container>
</template>

<script>
import { mapState } from "vuex";

import SelectionCard from "@/components/guest/site/SelectionCard.vue";
import BottomSheet from "@/components/guest/site/BottomSheet";

import Confirm from "@/components/Confirm";

export default {
	components: {
		BottomSheet,
		SelectionCard,
		Confirm,
	},
	data() {
		return {
			loading: true,
			loadingVirtualKey: false,
			loadingMasterKey: false,
			loadingBooking: false,
		};
	},
	async mounted() {},
	computed: {
		...mapState({
			site: (state) => state.guest.sites.site,
			virtualKey: (state) => state.guest.sites.virtualKey,
		}),

		bookingAppUrl() {
			return `${process.env.VUE_APP_BOOKING_APP_URL}/clubs/${this.site._id}`;
		},
	},
	methods: {
		async goToPayment() {
			const res = await this.$refs.confirm.open(
				"Pay Online",
				`You will be redirected to our booking app where you can create an account and manage all your future bookings.`,
				"success",
				"mdi-credit-card-outline"
			);

			if (res) {
				// Redirect to payment app
				window.open(this.bookingAppUrl, "_blank");
			}
		},

		async retrieveBooking(code) {
			this.loadingBooking = true;

			try {
				const booking = await this.$store.dispatch("guest/bookings/retrieveBooking", { siteId: this.site._id, params: { code } });

				this.$router.push({ name: "guest.booking", params: { id: booking._id } });
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loadingBooking = false;
			}
		},

		async getVirtualKey(code) {
			this.loadingVirtualKey = true;
			try {
				await this.$store.dispatch("guest/sites/getVirtualKey", {
					siteId: this.site._id,
					query: {
						code: code,
					},
				});

				this.$router.push({ name: "guest.virtualKey", params: { id: this.site._id, virtualKeyId: this.virtualKey._id } });
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
				this.$store.commit("guest/sites/setVirtualKey", null);
			} finally {
				this.loadingVirtualKey = false;
			}
		},

		async getMasterKey(code) {
			this.loadingMasterKey = true;
			try {
				await this.$store.dispatch("guest/sites/getVirtualKey", {
					siteId: this.site._id,
					query: {
						code: code,
						master: true,
					},
				});

				this.$router.push({ name: "guest.masterKey", params: { id: this.site._id, virtualKeyId: this.virtualKey._id } });
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
				this.$store.commit("guest/sites/setVirtualKey", null);
			} finally {
				this.loadingMasterKey = false;
			}
		},
	},
};
</script>
