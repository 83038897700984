<template>
	<div class="calendar-list">
		<v-card v-if="!openingHours.open" flat color="grey">
			<v-card-text class="text-center title font-weight-bold py-12"
				><v-icon left>mdi-calendar-remove</v-icon> {{ $t("settingsPage.bookingSettings.openingHours.closed") }}
			</v-card-text>
		</v-card>

		<v-card v-else-if="!formattedCalendarBookings.length" flat color="grey">
			<v-card-text class="text-center title font-weight-bold py-12"><v-icon left>mdi-calendar-blank</v-icon>{{ $t("bookings.noneFound") }}</v-card-text>
		</v-card>
		<v-calendar
			v-else
			ref="calendar"
			v-model="focusedDay"
			color="primary"
			type="category"
			:categories="categories"
			:category-show-all="showAll"
			:events="formattedCalendarBookings"
			:interval-format="intervalFormat"
			@click:event="viewBooking"
		>
			<template v-slot:category="{ category }">
				<div class="text-center py-1 purple lighten-5">
					<v-chip color="purple lighten-5" label class="purple--text font-weight-bold">
						<v-icon small left>mdi-golf-cart</v-icon>
						{{ carNumber(category) }}
					</v-chip>
				</div>
			</template>
			<template v-slot:event="{ event }">
				<v-col :class="!event.isNow && 'primary--text'">
					<v-row>
						<v-col class="pa-2">
							<span class="font-weight-bold subtitle-2">
								{{ formattedTime(event.start) }}
								<v-icon small :color="!event.isNow ? 'primary' : 'white'">mdi-arrow-right</v-icon>
								{{ formattedTime(event.end) }}
							</span>
						</v-col>
						<v-spacer></v-spacer>
						<span style="position: absolute; right: 6px; top: 6px">
							<v-tooltip bottom content-class="px-2 py-0">
								<template v-slot:activator="{ on, attrs }">
									<v-btn small icon v-bind="attrs" v-on="on" v-if="showEditButton(event)">
										<v-icon small color="white" @click.stop="editEvent(event)">mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span class="caption font-weight-bold">Edit</span>
							</v-tooltip>

							<v-tooltip bottom content-class="px-2 py-0">
								<template v-slot:activator="{ on, attrs }">
									<v-btn small icon dark v-bind="attrs" v-on="on" v-if="showDeleteButton(event)">
										<v-icon small @click.stop="deleteEvent(event._id)">mdi-trash-can</v-icon>
									</v-btn>
								</template>
								<span class="caption font-weight-bold">Delete</span>
							</v-tooltip>
						</span>
					</v-row>

					<v-row class="mt-0">
						<v-col class="px-2 pt-1">
							<span class="font-weight-bold subtitle-2">
								{{ event.booking.name }}
							</span>
						</v-col>
					</v-row>

					<v-row class="mt-0" v-if="event.booking.service">
						<v-col class="px-2 pt-1">
							<service-chip :serviceId="event.booking.service" :priceId="event.booking.price" />
						</v-col>
					</v-row>

					<v-row class="mt-0" v-if="event.booking.scheduleUnlock">
						<v-col class="px-2 pt-1">
							<v-chip label x-small color="primary font-weight-bold">
								<v-icon x-small color="white" left>mdi-timer</v-icon>
								{{ $t("bookings.autoUnlock") }}
							</v-chip>
						</v-col>
					</v-row>
				</v-col>
			</template>
		</v-calendar>

		<confirm ref="confirm" />
		<maintenance-modal ref="maintenanceModal" :currentDay="currentDay" />
		<manage-booking ref="manageBookingModal" :currentDay="currentDay" />
		<booking-view ref="bookingView" />
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Confirm from "@/components/Confirm";
import { format, getDay, differenceInCalendarDays } from "date-fns";
import MaintenanceModal from "@/components/bookings/MaintenanceModal.vue";
import ManageBooking from "@/components/bookings/ManageBooking.vue";
import BookingView from "@/components/bookings/BookingView.vue";
import ServiceChip from "@/components/ServiceChip.vue";

export default {
	props: {
		selectedSite: {
			required: true,
		},
		currentDay: {
			required: true,
			default: "",
		},
		showAll: {
			type: Boolean,
		},
	},
	components: {
		Confirm,
		MaintenanceModal,
		ManageBooking,
		BookingView,
		ServiceChip,
	},
	data() {
		return {
			focusedDay: format(new Date(this.currentDay), "yyyy-MM-dd"),
			events: [],
		};
	},
	watch: {
		currentDay() {
			this.focusedDay = format(new Date(this.currentDay), "yyyy-MM-dd");
		},
	},
	computed: {
		...mapState({
			devices: (state) => state.devices.devices,
		}),

		...mapGetters(["formattedCalendarBookings", "getOpeningHours", "serviceFromId"]),

		categories() {
			return this.devices.map((device) => {
				return device._id;
			});
		},

		openingHours() {
			const dayOfWeek = getDay(new Date(this.currentDay));
			return this.getOpeningHours(dayOfWeek);
		},
	},
	methods: {
		isBookingPast(booking) {
			return new Date(booking.end) < new Date();
		},

		showEditButton(event) {
			if (this.selectedSite.bookingIntegration || event.booking.source === "client") {
				return false;
			}

			if (event.booking.bookingType === "cart_maintenance") {
				return true;
			}

			return !this.isBookingPast(event);
		},

		showDeleteButton(event) {
			if (this.selectedSite.bookingIntegration || event.booking.source === "client") {
				return false;
			}

			return !this.isBookingPast(event);
		},

		editEvent(event) {
			if (event.booking.bookingType === "cart_maintenance") {
				if (this.selectedSite.bookingIntegration) {
					return this.$refs.bookingModal.open(event.booking);
				}

				this.editMaitenance(event.booking);
			} else {
				// if booking.start is in the past, return
				if (this.selectedSite.bookingIntegration) {
					return this.$refs.bookingModal.open(event.booking);
				}

				this.editBooking(event.booking);
			}
		},

		async deleteEvent(id) {
			const res = await this.$refs.confirm.open("Delete Booking?", "", "error", "mdi-trash-can-outline");

			if (res !== true) return;

			try {
				await this.$store.dispatch("deleteBooking", id);
				this.$toast.success("Booking deleted");
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},

		editMaitenance(booking) {
			this.$refs.maintenanceModal.open(booking);
		},

		editBooking(booking) {
			this.$refs.manageBookingModal.open(booking);
		},

		viewBooking(event) {
			const { booking } = event.event;

			this.$refs.bookingView.open(booking);
		},

		carNumber(val) {
			return this.devices.find((device) => device._id === val).carNumber;
		},

		intervalFormat(interval) {
			return interval.time;
		},

		formattedTime(time) {
			const endTime = new Date(time);
			const currentDayStart = new Date(this.currentDay);
			currentDayStart.setHours(0, 0, 0, 0); // Reset time to start of the day

			const dayDifference = differenceInCalendarDays(endTime, currentDayStart);

			if (dayDifference !== 0) {
				// Show day difference for times not on the current day
				return `${format(endTime, "HH:mm")} (${dayDifference > 0 ? "+" : ""}${dayDifference})`;
			}

			return format(endTime, "HH:mm");
		},

		prePaid(prePaid) {
			return {
				text: prePaid ? this.$t("bookings.prePaid") : this.$t("bookings.payAtCart"),
				color: prePaid ? "primary" : "warning",
			};
		},
	},
};
</script>

<style lang="scss">
.v-calendar .v-calendar-daily__head .v-calendar-daily_head-day .v-calendar-daily_head-day-label,
.v-calendar-daily_head-weekday {
	display: none !important;
}

// ::-webkit-scrollbar {
//   display: none !important;
// }
.calendar-list {
	height: calc(var(--vh, 1vh) * 100 - 205px) !important;
	overflow-y: auto;
}

.v-calendar::-webkit-scrollbar {
	width: 15px;
	height: 20px;
	background-color: #aaa; /* or add it to the track */
}

/* Add a thumb */
.v-calendar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #434a5c;
}

.toolbar-bottom-center {
	position: absolute;
	bottom: 44px; /* Adjust as necessary */
	left: 50%; /* Set to 50% to start at the center */
	transform: translateX(-50%); /* Shifts the toolbar left by half its width */
	display: flex;
	justify-content: center; /* Centers the items horizontally within the toolbar */
}
</style>
