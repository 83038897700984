<template>
	<v-dialog v-model="dialog" width="350" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-badge bordered :color="typeFormatting.color" icon="mdi-email-outline" overlap left :value="!!emailSent">
				<v-btn v-if="editedItem._id" small :color="typeFormatting.color" class="mr-2 font-weight-bold" dark v-bind="attrs" v-on="on">
					<v-icon small left>{{ typeFormatting.icon }}</v-icon>
					{{ editedItem.type === "rfid" ? "RFID" : editedItem.code }}
					<v-icon v-if="!hasKeypadEnabled() && editedItem.master" small right>mdi-shield</v-icon>
				</v-btn>
			</v-badge>
			<v-btn v-show="!editedItem._id && availableCodeTypes.length" small class="padlessBtn" color="purple" dark v-bind="attrs" v-on="on">
				<v-icon small>mdi-plus-box-outline</v-icon>
			</v-btn>
		</template>
		<v-card>
			<v-card-title class="title accent white--text" style="border-bottom: 3px solid #406dfa !important">
				{{ formTitle }}
				<v-spacer></v-spacer>
				<v-btn @click="close()" icon small>
					<v-icon color="white">mdi-close-circle-outline</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="pt-6">
				<v-form ref="form" v-model="valid" lazy-validation>
					<v-row>
						<v-col cols="12">
							<v-select outlined dense hide-details="auto" v-model="editedItem.type" :items="availableCodeTypes" label="Type" item-text="name" item-value="value" />
						</v-col>
					</v-row>

					<v-row v-if="editedItem.type !== 'rfid'">
						<v-col cols="12">
							<v-text-field
								prepend-inner-icon="mdi-key-variant"
								placeholder="Unlock Code"
								hide-details="auto"
								v-model="editedItem.code"
								:rules="codeRules"
								outlined
								dense
								clearable
								class="mb-3 rounded-r-0 unlockCodeInput"
								type="number"
							>
								<template v-slot:append-outer>
									<v-btn class="rounded-r rounded-l-0 padlessBtn" height="40" color="primary" depressed @click="editedItem.code = randomCode()">
										<v-icon class="pr-1">mdi-refresh</v-icon>
									</v-btn>
								</template>
							</v-text-field>
						</v-col>
					</v-row>

					<v-row v-else>
						<v-col>
							<scan-RFID :code="editedItem.code" v-model="editedItem.code" :rules="rules.required" />
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox color="accent" v-model="editedItem.allDevices" label="All Carts" class="ma-0" hide-details />
						</v-col>
					</v-row>

					<v-row v-if="!editedItem.allDevices">
						<v-col cols="12">
							<v-autocomplete
								label="Carts"
								placeholder="Select Carts"
								ref="select"
								v-model="editedItem.devices"
								:rules="[(v) => !!(v && v.length) || 'Item is required']"
								clearable
								multiple
								chips
								:items="deviceList"
								:return-object="false"
								item-value="carNumber"
								:menu-props="{ offsetY: true }"
								outlined
								dense
								hide-details
								class="mb-1"
								:no-data-text="$t('bookings.modal.noCarts')"
								:filter="carSearchFilter"
							>
								<template v-slot:prepend-item>
									<v-list-item ripple dense @mousedown.prevent @click="selectAllDevices">
										<v-list-item-action>
											<v-icon :color="editedItem.devices.length > 0 ? 'indigo darken-4' : ''">
												{{ icon }}
											</v-icon>
										</v-list-item-action>
										<v-list-item-content>
											<v-list-item-title class="font-weight-bold"> Select All </v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-divider class="mt-0"></v-divider>
								</template>
								<template v-slot:selection="{ item, index }">
									<v-chip v-if="index === 0" small label color="accent"> {{ $t("carDetail.car") }} {{ item.carNumber }} </v-chip>
									<v-chip v-else-if="index === 1" small label color="accent" outlined class="text-caption ml-2"> (+{{ editedItem.devices.length - 1 }} more) </v-chip>
								</template>
								<template slot="item" slot-scope="{ item }">
									{{ $t("carDetail.car") }} {{ item.carNumber }}

									<span v-if="item.carName" class="ml-2 caption font-weight-bold"> - {{ item.carName }} </span>
								</template>

								<template v-slot:append-item>
									<v-list-item dense @click.stop>
										<v-btn outlined color="primary" block small @click.stop="closeCarSelect">
											{{ $t("general.ok") }}
										</v-btn>
									</v-list-item>
								</template>
							</v-autocomplete>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox color="accent" v-model="editedItem.enabled" label="Code Enabled" class="ma-0" hide-details />
						</v-col>
					</v-row>

					<v-row v-if="!hasKeypadEnabled()">
						<v-col cols="12">
							<v-checkbox color="accent" v-model="editedItem.master" label="Master Code" class="ma-0" hide-details />
						</v-col>
					</v-row>

					<v-row v-if="!hasKeypadEnabled()">
						<v-col cols="12">
							<v-checkbox color="accent" v-model="codeExpires" label="Expires" class="ma-0" hide-details />
						</v-col>
					</v-row>

					<v-row v-if="!hasKeypadEnabled() && codeExpires">
						<v-col cols="12">
							<v-datetime-picker
								dateFormat="dd.MM.yyyy"
								:timeFormat="'- HH:mm'"
								:textFieldProps="{
									prependInnerIcon: 'mdi-calendar-month-outline',
									outlined: true,
									dense: true,
									hideDetails: 'auto',
								}"
								:datePickerProps="{
									locale: $i18n.locale,
									min: todayPicker,
								}"
								:timePickerProps="{
									allowedMinutes: allowedStep,
									format: '24hr',
									scrollable: true,
								}"
								v-model="codeExpires"
							>
								<template v-slot:dateIcon>
									<v-icon>mdi-calendar</v-icon>
								</template>
								<template v-slot:timeIcon>
									<v-icon>mdi-clock</v-icon>
								</template>
							</v-datetime-picker>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-checkbox color="accent" v-model="sendEmail" label="Send Email" class="ma-0" hide-details />
							<p v-if="emailSent" class="caption font-weight-bold mb-1">Email last sent at {{ emailSent }}</p>
							<p v-if="sendEmail" class="caption font-weight-bold">Email will be sent to {{ driver.email }}</p>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<v-divider class="mx-4"></v-divider>

			<v-card-actions class="mt-2">
				<v-btn v-if="editedItem._id" class="padlessBtn" small color="error" @click="deleteCode()">
					<v-icon small>mdi-trash-can</v-icon>
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" small text @click="close"> Cancel </v-btn>
				<v-btn :loading="loading" :disabled="!editedItem.code" color="primary" small @click="save"> Save </v-btn>
			</v-card-actions>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import Confirm from "@/components/Confirm";
import ScanRFID from "@/components/drivers/ScanRFID.vue";
import { mapState } from "vuex";
import { endOfTomorrow, format } from "date-fns";

export default {
	props: {
		code: {
			type: Object,
			default: null,
		},
		driver: {
			type: Object,
			required: true,
		},
	},
	components: {
		Confirm,
		ScanRFID,
	},
	data() {
		return {
			dialog: false,
			editedItem: this.defaultItem(),
			sendEmail: false,

			filteredItemCount: null,
			valid: true,
			loading: false,

			codeRules: [
				(v) => /^-?\d+\.?\d*$/.test(v) || this.$i18n.t("rules.numbersOnly"),
				(v) => !!v || this.$i18n.t("rules.required"),
				(v) => (v && v.length === 6) || this.$i18n.t("rules.6Digits"),
			],
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
		};
	},
	watch: {
		code: {
			immediate: true,
			handler(newVal) {
				this.editedItem = newVal ? JSON.parse(JSON.stringify(newVal)) : this.defaultItem();

				if (!this.editedItem._id) {
					this.sendEmail = true;
				}
			},
		},

		"editedItem.type"(val) {
			if (val === "rfid") {
				this.editedItem.code = null;
			} else {
				this.editedItem.code = this.code?.code || this.randomCode();
			}
		},

		"editedItem.devices": {
			handler(val) {
				if (this.editedItem.allDevices || (val.length > 1 && val.length === this.devices.length)) {
					this.editedItem.allDevices = true;
				} else {
					this.editedItem.allDevices = false;
				}
			},
		},

		dialog(val) {
			if (val && !this.editedItem._id) {
				this.editedItem.type = this.availableCodeTypes[0].value;
			}
		},
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			devices: (state) => state.devices.devices,
		}),

		codeTypes() {
			return [
				...(this.hasKeypadEnabled()
					? [
							{ name: "Keypad", value: "keypad" },
							{ name: "RFID", value: "rfid" },
					  ]
					: [{ name: "Virtual Key", value: "virtualKey" }]),
			];
		},

		emailSent() {
			return this.editedItem.emailSent ? format(new Date(this.editedItem.emailSent), "do MMM, HH:mm") : null;
		},

		availableCodeTypes() {
			// Start with an initial filter based on keypadEnabled status
			let relevantCodeTypes = this.codeTypes.filter((type) => {
				// Assuming 'keypad' and 'rfid' types require keypadEnabled to be true
				if ((type.value === "keypad" || type.value === "rfid") && !this.selectedSite.keypadEnabled) {
					return false;
				}
				return true;
			});

			// Filter out code types already assigned to the driver

			const assignedTypes = new Set(this.driver.code.map((code) => code.type));

			// Ensure the current editing type is included
			if (this.editedItem._id && this.code.type) {
				assignedTypes.delete(this.code.type);
			}

			// Apply the assigned types filter
			return relevantCodeTypes.filter((type) => !assignedTypes.has(type.value));
		},

		deviceList() {
			return this.devices.map((device) => {
				return {
					carNumber: Number(device.carNumber),
					carName: device.carName,
				};
			});
		},

		formTitle() {
			return this.editedItem._id ? "Edit Code" : "New Code";
		},

		typeFormatting() {
			switch (this.editedItem.type) {
				case "rfid":
					return {
						color: "green",
						icon: "mdi-credit-card-wireless-outline",
					};

				case "keypad":
					return {
						color: "orange",
						icon: "mdi-dialpad",
					};

				case "virtualKey":
				default:
					return {
						color: "purple",
						icon: "mdi-key-wireless",
					};
			}
		},

		codeExpires: {
			get() {
				return this.editedItem.expires ? new Date(this.editedItem.expires) : null;
			},
			set(val) {
				if (!val) {
					this.editedItem.expires = null;
				} else if (val === true) {
					if (this.code && this.code.expires) {
						this.editedItem.expires = new Date(this.code.expires);
					} else {
						this.editedItem.expires = endOfTomorrow();
					}
				} else if (val instanceof Date) {
					this.editedItem.expires = val;
				}
			},
		},

		allDevicesSelected() {
			return this.editedItem.devices.length === this.filteredItemCount;
		},

		someDevicesSelected() {
			return this.editedItem.devices.length > 0 && !this.allDevicesSelected;
		},

		icon() {
			if (this.allDevicesSelected) return "mdi-close-box";
			if (this.someDevicesSelected) return "mdi-minus-box";
			return "mdi-checkbox-blank-outline";
		},

		todayPicker() {
			return format(new Date(), "yyyy-MM-dd");
		},
	},
	methods: {
		defaultItem() {
			return {
				type: "virtualKey",
				code: this.randomCode(),
				master: false,
				devices: [],
				allDevices: true,
				enabled: true,
				expires: null,
				driver: this.driver._id,
			};
		},

		async save() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				try {
					if (this.editedItem._id) {
						await this.$store.dispatch("updateCode", { id: this.editedItem._id, body: { data: this.editedItem, sendEmail: this.sendEmail } });
						this.$toast.success("Code updated");
						this.$emit("getMembers");
					} else {
						await this.$store.dispatch("createCode", { data: this.editedItem, sendEmail: this.sendEmail });
						this.$toast.success("Code created");
						this.$emit("getMembers");
					}
				} catch (err) {
					if (err.error) {
						const { status, message } = err.error;

						if (status === 409) {
							this.$toast.error("Code already exists, please use a different code and try again.");
						} else {
							this.$toast.error(message);
						}
					}
				} finally {
					this.loading = false;
					this.close();
				}
			}
		},

		async deleteCode() {
			const res = await this.$refs.confirm.open(`Delete Code?`, "", "accent", "mdi-trash-can-outline");
			if (!res) return;
			try {
				await this.$store.dispatch("deleteCode", this.editedItem._id);
				this.$toast.success("Code deleted");
				this.$emit("getMembers");
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.close();
			}
		},

		close() {
			this.dialog = false;
			// Optionally reset editedItem to default values
			this.editedItem = this.code ? JSON.parse(JSON.stringify(this.code)) : this.defaultItem();
		},

		allowedStep: (m) => m % 1 === 0,

		randomCode() {
			return Math.floor(100000 + Math.random() * 900000).toString();
		},

		carSearchFilter(item, queryText) {
			const carNameMatch = item.carName ? item.carName.toLowerCase().includes(queryText.toLowerCase()) : false;
			const carNumberMatch = item.carNumber.toString().includes(queryText);

			return carNameMatch || carNumberMatch;
		},

		async selectAllDevices() {
			// Await the next tick to make sure the internal state is updated.
			await this.$nextTick();

			// Access the filtered items directly from the v-autocomplete component
			const filteredItems = this.$refs.select.filteredItems;
			this.filteredItemCount = this.$refs.select.filteredItems.length;

			if (this.allDevicesSelected) {
				this.editedItem.devices = [];
			} else {
				this.editedItem.devices = filteredItems.map((car) => car.carNumber);
			}
		},

		closeCarSelect() {
			this.$refs.select.blur();
		},
	},
};
</script>
