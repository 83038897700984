import unlockCodeService from "@/services/unlockCodeService";

export default {
	state: {},

	mutations: {},

	actions: {
		async addUnlockCode(_, payload) {
			await unlockCodeService.addUnlockCode(payload);

			return;
		},

		async updateUnlockCode(_, payload) {
			await unlockCodeService.updateUnlockCode(payload);

			return;
		},

		async deleteUnlockCode(_, payload) {
			await unlockCodeService.deleteUnlockCode(payload);

			return;
		},
	},
};
