<template>
	<div>
		<v-list>
			<v-list-item>
				<v-list-item-content>
					<v-alert prominent :type="selectedSite.publicProfile.active ? 'success' : 'error'" class="font-weight-bold">
						<v-row align="center">
							<v-col class="grow">
								<div class="title">
									{{ selectedSite.publicProfile.active ? "Booking App Enabled" : "Booking App Disabled" }}
								</div>
								<div class="subtitle-2">
									{{ selectedSite.publicProfile.active ? "Your Club will be listed in the booking App" : "Your Club will not be listed in the booking app" }}
								</div>
							</v-col>
							<v-col class="shrink">
								<v-switch v-model="profileActive" color="white" inset></v-switch>
							</v-col>
						</v-row>
					</v-alert>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-alert prominent type="info" class="font-weight-bold">
						<v-row align="center">
							<v-col class="grow"> Download Poster </v-col>
							<v-col class="shrink">
								<v-btn color="white" class="accent--text" @click="downloadPDF">
									Download
									<v-icon right>mdi-download</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-alert>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-card class="pa-2 rounded-lg" color="grey" flat>
						<v-card-text>
							<v-row class="d-flex align-center">
								<v-col class="shrink">
									<v-hover v-slot="{ hover }">
										<div>
											<v-img alt="Logo" width="60" :src="selectedSite.publicProfile.logoUrl">
												<v-expand-transition>
													<div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal text-h2 align-center justify-center" style="height: 100%">
														<v-btn fab small depressed color="grey" @click="uploadImage('logo')">
															<v-icon color="primary">mdi-pencil</v-icon>
														</v-btn>
													</div>
												</v-expand-transition>
											</v-img>
										</div>
									</v-hover>
								</v-col>
								<v-col class="grow">
									<div class="d-flex justify-space-between">
										<span class="title font-weight-bold accent--text">Site Logo</span>
										<v-btn class="ml-4" small color="primary" depressed @click="uploadImage('logo')">Edit</v-btn>
									</div>
								</v-col>
							</v-row>

							<v-row class="d-flex align-center">
								<v-col class="shrink">
									<v-hover v-slot="{ hover }">
										<div>
											<v-img alt="Logo" width="160" :src="selectedSite.publicProfile.coverPhotoUrl">
												<v-expand-transition>
													<div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal text-h2 align-center justify-center" style="height: 100%">
														<v-btn fab small depressed color="grey" @click="uploadImage('cover')">
															<v-icon color="primary">mdi-pencil</v-icon>
														</v-btn>
													</div>
												</v-expand-transition>
											</v-img>
										</div>
									</v-hover>
								</v-col>

								<v-col class="grow">
									<div class="d-flex justify-space-between">
										<span class="title font-weight-bold accent--text">Cover Photo</span>
										<v-btn class="ml-4" small color="primary" depressed @click="uploadImage('cover')">Edit</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-list-item-content>
			</v-list-item>

			<v-list-item>
				<v-list-item-content>
					<v-card class="pa-2 rounded-lg" color="grey" flat>
						<v-card-text>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-subtitle>{{ $t("settingsPage.publicProfile.displayName") }} </v-list-item-subtitle>
									<v-list-item-title>
										{{ selectedSite.publicProfile.name }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="formattedAddress">
								<v-list-item-content>
									<v-list-item-subtitle>{{ $t("settingsPage.publicProfile.address") }}</v-list-item-subtitle>
									<v-list-item-title class="text-wrap" style="white-space: pre-wrap">
										{{ formattedAddress }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="selectedSite.publicProfile.description">
								<v-list-item-content>
									<v-list-item-subtitle>{{ $t("settingsPage.publicProfile.description") }}</v-list-item-subtitle>
									<v-list-item-title>
										{{ selectedSite.publicProfile.description }}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="selectedSite.publicProfile.termsAndConditions">
								<v-list-item-content>
									<v-list-item-subtitle>{{ $t("settingsPage.publicProfile.terms") }}</v-list-item-subtitle>
									<v-alert border="top" color="grey" class="mt-2" style="white-space: pre-line">
										{{ selectedSite.publicProfile.termsAndConditions }}
									</v-alert>
								</v-list-item-content>
							</v-list-item>
							<v-list-item v-if="selectedSite.publicProfile.website">
								<v-list-item-content>
									<v-list-item-subtitle>{{ $t("settingsPage.publicProfile.website") }}</v-list-item-subtitle>
									<v-list-item-title>
										<a :href="`${selectedSite.publicProfile.website}`" target="_blank">
											{{ selectedSite.publicProfile.website }}
										</a>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-card-text>

						<v-card-actions class="mx-2">
							<v-spacer></v-spacer>
							<v-btn small depressed color="primary" @click="openProfileSettings">{{ $t("general.edit") }}</v-btn>
							<public-profile-form ref="profileDialog" :selectedSite="selectedSite" />
						</v-card-actions>
					</v-card>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<image-upload ref="imageDialog" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import ImageUpload from "@/components/sitesettings/ImageUpload.vue";
import PublicProfileForm from "@/components/sitesettings/PublicProfileForm.vue";

import { jsPDF } from "jspdf";
import QRCode from "qrcode";

export default {
	components: {
		PublicProfileForm,
		ImageUpload,
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
		}),

		siteQrCode() {
			return `${process.env.VUE_APP_BASE_APP_URL}/guest/site/${this.selectedSite._id}`;
		},

		formattedAddress() {
			const { street, city, postcode, country } = this.selectedSite.publicProfile.address;
			return [street, city, postcode, country].filter(Boolean).join("\n");
		},

		profileActive: {
			get() {
				return this.selectedSite.publicProfile.active;
			},
			set(value) {
				this.selectedSite.publicProfile.active = value;

				this.save();
			},
		},
	},
	methods: {
		openProfileSettings() {
			this.$refs.profileDialog.open();
		},

		uploadImage(type) {
			this.$refs.imageDialog.open(type);
		},

		async save() {
			const siteSettings = {
				updateData: {
					_id: this.selectedSite._id,
					publicProfile: this.selectedSite.publicProfile,
				},
			};

			try {
				await this.$store.dispatch("updateSite", siteSettings);
				this.$toast.success(this.$t("general.saved"));
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},

		downloadPDF() {
			const pdf = new jsPDF({
				orientation: "p",
				unit: "mm",
				format: "a4",
				putOnlyUsedFonts: true,
			});

			// The dimensions for A4 paper are 210mm x 297mm
			const pageWidth = 210;
			const pageHeight = 297;

			const qrSize = 138; // This is the size of the QR code, you can adjust as needed

			// Calculate positions
			const xPosition = (pageWidth - qrSize) / 2; // Center horizontally
			const yPosition = 120; // 2/3 down the page

			// Add the A4 size background image
			const backgroundImgDataURL = require("../../../public/OnlineBooking.jpg"); // This should be a Data URL
			pdf.addImage(backgroundImgDataURL, "JPEG", 0, 0, pageWidth, pageHeight);

			QRCode.toDataURL(this.siteQrCode, { width: qrSize }, function (err, url) {
				if (err) throw err;

				// Add the QR code to the PDF at the specified coordinates
				pdf.addImage(url, "PNG", xPosition, yPosition, qrSize, qrSize);

				// Save the PDF
				pdf.save("Cart_Booking_Poster_IOTee.pdf");
			});
		},
	},
};
</script>
