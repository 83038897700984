<template>
	<v-row no-gutters>
		<v-col class="px-8 py-2">
			<p class="title">General</p>
			<v-form ref="form1" v-model="valid" lazy-validation>
				<v-row class="px-4 pt-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Site Name</p>
					</v-col>
					<v-col cols="9">
						<v-text-field hide-details="auto" :rules="[rules.required]" v-model="site.name" outlined dense style="max-width: 500px"></v-text-field>
					</v-col>
				</v-row>
				<v-row class="px-4 pt-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Country</p>
					</v-col>
					<v-col cols="9">
						<v-autocomplete
							hide-details="auto"
							v-model="site.country"
							:items="countriesList"
							:rules="[rules.required]"
							item-text="name"
							item-value="name"
							outlined
							dense
							style="max-width: 500px"
							class="mb-3"
						></v-autocomplete>
					</v-col>
				</v-row>
				<v-row class="px-4 pb-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Center Lat/Long</p>
					</v-col>
					<v-col cols="9">
						<v-text-field hide-details="auto" v-model="site.centerLatLong" :rules="[rules.required, rules.latLong]" outlined dense style="max-width: 500px"></v-text-field>
					</v-col>
				</v-row>

				<v-row class="px-4 pb-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Timezone</p>
					</v-col>
					<v-col cols="9">
						<v-autocomplete
							v-model="site.timezone"
							:items="timezones"
							label="Timezone"
							outlined
							dense
							style="max-width: 500px"
							hide-details="auto"
							:rules="[rules.required]"
						></v-autocomplete>
					</v-col>
				</v-row>

				<v-row class="px-4 pb-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Stripe Account</p>
					</v-col>
					<v-col cols="9">
						<v-text-field hide-details="auto" v-model="site.stripeConfig.accountId" clearable outlined dense style="max-width: 500px"></v-text-field>
					</v-col>
				</v-row>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Stripe Revenue Share Method</p>
					</v-col>
					<v-col cols="9">
						<v-select
							v-model="site.stripeConfig.revenueShareMethod"
							:items="selectItems"
							outlined
							dense
							style="max-width: 500px"
							item-text="text"
							:rules="[(v) => !!v || 'Item is required']"
						></v-select>
					</v-col>
				</v-row>

				<v-row class="px-4" v-if="site.stripeConfig.revenueShareMethod === 'percentage'">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Stripe Fee (%)</p>
					</v-col>
					<v-col cols="9">
						<v-text-field
							hide-details="auto"
							v-model="site.stripeConfig.percentage"
							hint="Percentage taken by IOTee"
							persistent-hint
							outlined
							dense
							style="max-width: 500px"
							append-icon="mdi-percent-outline"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="px-4" v-if="site.stripeConfig.revenueShareMethod === 'fixed'">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Stripe Unlock Fee</p>
					</v-col>
					<v-col cols="9">
						<v-text-field
							hide-details="auto"
							v-model="site.stripeConfig.fixedFee"
							persistent-hint
							outlined
							dense
							style="max-width: 500px"
							hint="Fixed rate taken by IOTee: 200 = €2,00 || 2000 = 20kr"
							:suffix="country.currency.code"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Stripe Enabled</p>
					</v-col>
					<v-col cols="9">
						<v-switch inset v-model="site.stripeConfig.accountActive" :label="site.stripeConfig.accountActive ? `Stripe Enabled` : `Stripe Disabled`"></v-switch>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Scan to Ride Toggle</p>
					</v-col>
					<v-col cols="9">
						<v-switch
							inset
							v-model="site.scanToRideToggle"
							:label="site.scanToRideToggle ? `Enabled` : `Disabled`"
							hint="If enabled, customer can toggle Scan To Ride On/Off. If Disabled, Scan-To-Ride will be permanently active"
							persistent-hint
						></v-switch>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Bookings Integration</p>
					</v-col>
					<v-col cols="9">
						<v-switch
							inset
							v-model="site.bookingIntegration"
							:label="site.bookingIntegration ? `Yes` : `No`"
							hint="If yes, bookings can only be managed (added, edited, deleted) by the integrated booking platform (e.g Campo)."
							persistent-hint
						></v-switch>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Members Module</p>
					</v-col>
					<v-col cols="9">
						<v-switch inset v-model="site.membersModule" :label="site.membersModule ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Trail Points</p>
					</v-col>
					<v-col cols="9">
						<v-text-field
							hide-details="auto"
							v-model="site.trailPoints"
							hint="Number of points for a round to be saved. If trail points is less than this number, the ride will be deleted."
							persistent-hint
							outlined
							dense
							type="number"
							style="max-width: 500px"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Minimum Ride Duration</p>
					</v-col>
					<v-col cols="9">
						<v-text-field
							hide-details="auto"
							v-model="site.minRideDuration"
							hint="Minimum time where a ride is saved. In minutes."
							persistent-hint
							outlined
							dense
							type="number"
							style="max-width: 500px"
							suffix="mins"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Keyless Mode</p>
					</v-col>
					<v-col cols="9">
						<v-switch
							inset
							v-model="site.keyless"
							:label="site.keyless ? `Enabled` : `Disabled`"
							hint="If enabled, customer can use Keyless mode. If Disabled, only keys can be used to operate the cars."
							persistent-hint
						></v-switch>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="pt-0">
						<v-btn @click="save()" :loading="loadingGeneral" :disabled="loadingGeneral" class="primary"> Save </v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-col>
	</v-row>
</template>

<script>
import timezones from "@/services/timezones.js";
import countriesArray from "@/mixins/Countries";

export default {
	props: {
		site: {
			required: true,
		},
	},
	data() {
		return {
			rules: {
				required: (v) => !!v || "This field is required",
				latLong: (v) => /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/.test(v) || "Input must be in 'lat, long' format",
			},
			countriesList: countriesArray,
			valid: true,
			loadingGeneral: false,
			selectItems: [
				{ text: "Fixed Percentage (%)", value: "percentage" },
				{ text: "Unlock Fee", value: "fixed" },
			],
		};
	},
	computed: {
		timezones() {
			return timezones.sort(function (a, b) {
				return a.localeCompare(b);
			});
		},

		country() {
			return this.countriesList.find((country) => country.name === this.site.country);
		},
	},
	methods: {
		async save() {
			if (this.$refs.form1.validate()) {
				this.loadingGeneral = true;

				if (this.site.centerLatLong) {
					const regexPattern = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
					if (regexPattern.test(this.site.centerLatLong)) {
						const [lat, long] = this.site.centerLatLong.split(",").map((n) => parseFloat(n.trim()).toFixed(6));
						this.site.centerLatLong = `${lat}, ${long}`;
					}
				}

				const settings = {
					id: this.site._id,
					body: {
						updateData: {
							timezone: this.site.timezone,
							name: this.site.name,
							centerLatLong: this.site.centerLatLong,
							stripeConfig: this.site.stripeConfig,
							scanToRideToggle: this.site.scanToRideToggle,
							membersModule: this.site.membersModule,
							bookingIntegration: this.site.bookingIntegration,
							trailPoints: this.site.trailPoints,
							minRideDuration: this.site.minRideDuration,
							country: this.site.country,
							currency: this.country.currency.code,
							keyless: this.site.keyless,
						},
					},
				};

				console.log(settings);

				try {
					await this.$store.dispatch("admin/sites/updateSite", settings);
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.loadingGeneral = false;
				}
			}
		},
	},
};
</script>
