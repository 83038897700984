<template>
	<div>
		<v-card v-if="site.services.length" class="rounded-lg mb-6" outlined color="green">
			<v-list three-line>
				<v-list-item>
					<v-list-item-avatar size="55" color="green lighten-5">
						<v-icon color="green">mdi-golf</v-icon>
					</v-list-item-avatar>
					<v-list-item-content class="green--text">
						<v-list-item-title class="title font-weight-bold"> Select a Service </v-list-item-title>
						<v-list-item-subtitle> Check for availabilty </v-list-item-subtitle>

						<v-select
							v-model="selectedService"
							:items="site.services"
							item-text="name"
							class="mt-4"
							color="green"
							return-object
							dense
							hide-details
							outlined
							:menu-props="{ offsetY: true }"
						/>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>
		<!-- Cart In Use -->
		<status-component v-if="status === 'IN_USE'" icon="mdi-lock-open" :title="$t('general.unlocked')" :subtitle="$t('s2r.status.inUse')">
			<available-carts :siteId="site._id" />
		</status-component>
		<!-- Cart in Key Mode -->
		<status-component v-if="status === 'KEYMODE'" icon="mdi-key-variant" :title="$t('general.keyMode')" :subtitle="$t('s2r.status.reqKey')">
			<available-carts :siteId="site._id" />
		</status-component>
		<!-- Device is Offline -->
		<status-component
			v-if="status === 'OFFLINE'"
			color="error lighten-3"
			text-color="error"
			icon="mdi-link-variant-off"
			:title="$t('general.offline')"
			:subtitle="$t('s2r.status.offline')"
		>
			<available-carts :siteId="site._id" />
		</status-component>
		<!-- Cart is Out of Service -->
		<status-component
			v-if="status === 'OUT_OF_SERVICE'"
			color="error lighten-3"
			text-color="error"
			icon="mdi-cancel"
			title="Out of Service"
			:subtitle="$t('s2r.status.outOfService')"
		>
			<available-carts :siteId="site.id" />
		</status-component>
		<!-- Cart is Booked -->
		<status-booked v-if="status === 'BOOKED'" :site="site" />
		<!-- Club is Closed -->
		<status-closed v-else-if="status === 'SITE_CLOSED'" :site="site" />
		<!-- Cart is Availbale - show unlocking options -->
		<status-available v-else-if="status === 'AVAILABLE'" :site="site" />
	</div>
</template>

<script>
import AvailableCarts from "@/components/scanToRide/AvailableCarts.vue";
import StatusComponent from "@/components/scanToRide/statusComponent";
import StatusBooked from "@/components/scanToRide/statusBooked";
import StatusAvailable from "@/components/scanToRide/statusAvailable";
import StatusClosed from "@/components/scanToRide/statusClosed";

export default {
	props: {
		site: {
			type: Object,
			required: true,
		},
		status: {
			type: String,
			required: true,
		},
	},
	components: {
		AvailableCarts,
		StatusComponent,
		StatusBooked,
		StatusAvailable,
		StatusClosed,
	},
	data() {
		return {
			selectedService: null,
		};
	},
	watch: {
		selectedService() {
			this.$emit("getBookings", this.selectedService);
		},
	},
	created() {
		if (this.status === "IN_USE") {
			// Check if session ID exists in localStorage
			const sessionID = localStorage.getItem("sessionID");
			if (sessionID) {
				this.$router.push({
					name: "RideSession",
					params: {
						id: this.$route.params.id,
						sessionId: sessionID,
					},
				});
			}
		}

		if (this.site.services.length) {
			this.selectedService = this.site.services[0];
		}
	},
};
</script>
