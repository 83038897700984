<template>
	<v-card class="rounded-lg" outlined>
		<v-list>
			<v-list-item>
				<v-list-item-icon>
					<v-icon color="error"> mdi-close-circle-outline </v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title class="accent--text font-weight-bold">
						{{ title }}
					</v-list-item-title>
					<v-list-item-subtitle class="text-wrap">
						{{ subtitle }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-card-text>
			<v-btn
				@click="
					$router.push({
						path: `/ride/${$route.params.id}`,
					})
				"
				block
				class="rounded-lg font-weight-bold d-flex align-center"
				color="primary"
			>
				<v-icon class="ml-auto">mdi-chevron-left</v-icon>
				<span class="text-center" style="flex-grow: 1">Back</span>
			</v-btn>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		title: String,
		subtitle: String,
	},
};
</script>
