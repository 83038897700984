import Vue from "vue";

Vue.mixin({
	methods: {
		lockStatus(status, bookings, currentBooking) {
			var data;

			if (status === 0) {
				data = {
					icon: "mdi-lock-open-outline",
					text: this.$i18n.t("general.unlocked"),
					color: "success",
				};
			} else if (currentBooking) {
				if (currentBooking.bookingType === "cart_maintenance") {
					data = {
						icon: "mdi-wrench",
						text: this.$i18n.t("general.maintenance"),
						color: "primary",
					};
				} else {
					data = {
						icon: "mdi-calendar-lock-open",
						text: this.$i18n.t("general.booked"),
						color: "primary",
					};
				}
			} else if (bookings && bookings.length) {
				data = {
					icon: "mdi-calendar-lock-open",
					text: this.$i18n.t("general.booked"),
					color: "primary",
				};
				return data;
			} else {
				data = {
					icon: "mdi-lock",
					text: this.$i18n.t("general.locked"),
					color: "error",
				};
			}

			return data;
		},

		lockBtnIcon(icon) {
			return icon === 0 ? "mdi-lock" : "mdi-lock-open-outline";
		},

		lockBtnColor(icon) {
			return icon === 0 ? "error" : "success";
		},

		getLockDetails(device, currentBooking) {
			if (currentBooking && currentBooking.length) {
				return {
					icon: "mdi-calendar-lock-open",
					color: "primary",
					text: this.$i18n.t("general.booked"),
				};
			}
			if (device.lockStatus === 1) {
				return {
					icon: "mdi-lock",
					color: "error",
					text: this.$i18n.t("general.locked"),
				};
			}
			return {
				icon: "mdi-lock-open-outline",
				color: "success",
				text: this.$i18n.t("general.unlocked"),
			};
		},
	},
});
