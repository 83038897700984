<template>
	<v-dialog v-model="dialog" width="800" persistent>
		<template v-slot:activator="{ on, attrs }">
			<div v-show="$vuetify.breakpoint.mdAndUp">
				<v-btn color="accent" class="mr-2" small v-bind="attrs" v-on="on" :disabled="!hasRole('user')">
					<v-icon small left>mdi-tray-arrow-down</v-icon>
					{{ $t("virtualKeys.import.buttons.importBtn") }}
				</v-btn>
			</div>
		</template>

		<v-card>
			<v-card-title class="subtitle-1 font-weight-bold primary white--text">
				{{ $t("virtualKeys.import.title") }}

				<v-spacer></v-spacer>
				<v-btn icon @click="cancel()">
					<v-icon color="white">mdi-close-circle-outline</v-icon>
				</v-btn>
			</v-card-title>

			<v-stepper v-model="e1" flat class="rounded-0">
				<v-stepper-header class="elevation-0 accent">
					<v-stepper-step :complete="e1 > 1" step="1">
						<span class="white--text subtitle-2 font-weight-bold"> {{ $t("virtualKeys.import.stepOne.step") }} (.csv) </span>
					</v-stepper-step>

					<v-stepper-step :complete="e1 > 2" step="2">
						<span class="white--text subtitle-2 font-weight-bold">
							{{ $t("virtualKeys.import.stepTwo.step") }}
						</span>
					</v-stepper-step>

					<v-stepper-step :complete="e1 > 3" step="3">
						<span class="white--text subtitle-2 font-weight-bold">
							{{ $t("virtualKeys.import.stepThree.step") }}
						</span>
					</v-stepper-step>

					<v-stepper-step step="4">
						<span class="white--text subtitle-2 font-weight-bold">
							{{ $t("virtualKeys.import.stepFour.step") }}
						</span>
					</v-stepper-step>
				</v-stepper-header>

				<v-stepper-items>
					<v-stepper-content step="1" class="pt-0">
						<v-card-text class="px-0 text-center">
							<p class="title font-weight-bold">
								{{ $t("virtualKeys.import.stepOne.title") }}
							</p>
							<p class="subtitle-2">
								{{ $t("virtualKeys.import.stepOne.subtitle") }}
							</p>
						</v-card-text>
						<v-card-text class="text-center">
							<v-file-input
								v-model="file"
								dense
								outlined
								placeholder=".csv File"
								accept=".csv"
								prepend-icon="mdi-microsoft-excel"
								@change="parseFile()"
								style="max-width: 400px"
								class="mx-auto"
							></v-file-input>
						</v-card-text>
					</v-stepper-content>

					<v-stepper-content step="2" class="pt-0">
						<v-card-text class="px-0 text-center">
							<p class="title font-weight-bold">
								{{ $t("virtualKeys.import.stepTwo.title") }}
							</p>
							<p class="subtitle-2">
								{{ $t("virtualKeys.import.stepTwo.subtitle") }}
								<br />
								<span class="caption">*{{ $t("rules.required") }}</span>
							</p>
						</v-card-text>
						<v-card-text>
							<v-form ref="form" v-model="valid" lazy-validation>
								<v-row v-for="(field, key) in mappedFields" :key="key" no-gutters>
									<v-col cols="3">
										<p class="subtitle-1 font-weight-bold accent--text">
											{{ field.title }}
										</p>
									</v-col>

									<v-col cols="9">
										<v-select v-model="field.map" :items="fieldsFromFile" :rules="field.rules" label="Select" persistent-hint return-object single-line outlined dense></v-select>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click="e1 = 1">
								{{ $t("virtualKeys.import.buttons.backBtn") }}
							</v-btn>
							<v-btn color="primary" @click="mapFields()" class="ml-2" :disabled="!valid">
								{{ $t("virtualKeys.import.buttons.continueBtn") }}
							</v-btn>
						</v-card-actions>
					</v-stepper-content>

					<v-stepper-content step="3" class="pt-0">
						<v-card-text class="px-0 text-center">
							<p class="title font-weight-bold">
								{{ $t("virtualKeys.import.stepThree.title") }}
							</p>

							<v-card flat v-if="invalidMembers.length > 0">
								<v-alert icon="mdi-information-outline" color="error lighten-3" class="subtitle-2 mb-0">
									{{ $t("virtualKeys.import.stepThree.messages.missing") }}
								</v-alert>
								<v-simple-table dense fixed-header height="220px" class="text-left">
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">Name</th>
												<th class="text-left">Email</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(member, key) in invalidMembers" :key="key">
												<td>{{ member.name }}</td>
												<td>{{ member.email }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>

							<v-card flat v-else>
								<v-alert icon="mdi-information-outline" color="green lighten-3" class="subtitle-2 accent--text mb-0">
									{{ $t("virtualKeys.import.stepThree.messages.success") }}
								</v-alert>
								<v-simple-table dense fixed-header height="220px">
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">Name</th>
												<th class="text-left">Email</th>
												<th class="text-left" v-if="!hasKeypadEnabled()">Member Number</th>
												<th class="text-left" v-if="hasKeypadEnabled()">Company</th>
												<th class="text-left" v-if="hasKeypadEnabled()">Phone</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(member, key) in members" :key="key">
												<td>{{ member.name }}</td>
												<td>{{ member.email }}</td>
												<td v-if="!hasKeypadEnabled()">{{ member.memberNumber }}</td>
												<td v-if="hasKeypadEnabled()">{{ member.company }}</td>
												<td v-if="hasKeypadEnabled()">{{ member.phone }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn text @click="e1 = 2"> Back </v-btn>
							<v-btn color="primary" @click="importMembers()" class="ml-2">
								{{ $t("virtualKeys.import.buttons.importBtn") }}
							</v-btn>
						</v-card-actions>
					</v-stepper-content>

					<v-stepper-content step="4" class="pt-0">
						<v-card-text v-if="loadingImport" class="text-center">
							<v-progress-circular indeterminate width="8" size="120" color="primary"></v-progress-circular>
						</v-card-text>

						<v-card-text v-else class="text-center">
							<p class="title font-weight-bold">
								{{ $t("virtualKeys.import.stepFour.title") }}
							</p>
							<p class="subtitle-2">
								{{ $t("virtualKeys.import.stepFour.subtitle") }}
							</p>

							<div v-if="duplicateMessage">
								<v-alert icon="mdi-information-outline" color="warning lighten-3" class="caption font-weight-bold accent--text mb-0">
									{{ $t("virtualKeys.import.stepFour.messages.exists") }}
								</v-alert>
								<v-simple-table dense fixed-header height="220px">
									<template v-slot:default>
										<thead>
											<tr>
												<th class="text-left">Name</th>
												<th class="text-left">Email</th>
												<th class="text-left" v-if="!hasKeypadEnabled()">Member Number</th>
												<th class="text-left" v-if="hasKeypadEnabled()">Company</th>
												<th class="text-left" v-if="hasKeypadEnabled()">Phone</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(error, key) in duplicateMessage" :key="key">
												<td>{{ error.name }}</td>
												<td>{{ error.email }}</td>
												<td v-if="!hasKeypadEnabled()">{{ error.memberNumber }}</td>
												<td v-if="hasKeypadEnabled()">{{ error.company }}</td>
												<td v-if="hasKeypadEnabled()">{{ error.phone }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</div>

							<v-icon v-else size="120" color="success"> mdi-check-circle-outline </v-icon>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" @click="finish()" class="ml-2">
								{{ $t("virtualKeys.import.buttons.finishBtn") }}
							</v-btn>
						</v-card-actions>
					</v-stepper-content>
				</v-stepper-items>
			</v-stepper>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import Papa from "papaparse";

import Confirm from "@/components/Confirm";

export default {
	props: {
		selectedSite: {
			type: Object,
			required: true,
		},
	},
	components: {
		Confirm,
	},
	data() {
		return {
			dialog: false,
			e1: 1,
			file: null,
			hasHeaders: true,
			valid: false,
			fieldsFromFile: [],
			content: [],
			parsed: false,
			members: [],
			invalidMembers: [],
			loadingImport: false,
			duplicateMessage: null,
			mappedFields: this.initializeFields(),
		};
	},
	watch: {
		e1(val) {
			if (val === 1) {
				this.resetForm();
			}

			if (val === 2) {
				this.invalidMembers = [];
			}
		},
	},
	methods: {
		initializeFields() {
			return [
				{
					title: `${this.$i18n.t("virtualKeys.fields.name")}*`,
					val: "name",
					map: null,
					rules: [(value) => !!value || this.$i18n.t("rules.required")],
				},
				{
					title: `${this.$i18n.t("virtualKeys.fields.email")}*`,
					val: "email",
					map: null,
					rules: [(value) => !!value || this.$i18n.t("rules.required")],
				},
				...(this.hasKeypadEnabled()
					? [
							{
								title: `Company`,
								val: "company",
								map: null,
								rules: [],
							},
							{
								title: `Phone`,
								val: "phone",
								map: null,
								rules: [],
							},
					  ]
					: [
							{
								title: `${this.$i18n.t("virtualKeys.fields.memberNumber")}`,
								val: "memberNumber",
								map: null,
								rules: [],
							},
					  ]),
			];
		},

		parseFile() {
			Papa.parse(this.file, {
				header: true,
				encoding: "ISO-8859-1",
				skipEmptyLines: true,
				complete: function (results) {
					this.content = results;
					this.fieldsFromFile = results.meta.fields;
					this.parsed = true;
				}.bind(this),
			});

			this.e1 = 2;
		},

		async mapFields() {
			if (this.$refs.form.validate()) {
				let members = [];

				for (const row of this.content.data) {
					let member = {};

					for (const field of this.mappedFields) {
						const oldField = field.map;
						const newField = field.val;

						member[newField] = row[oldField];
					}

					members.push(member);
				}

				members = members.reduce((a, member) => {
					if (this.validateEmail(member.email)) {
						a.push(member);
					} else {
						this.invalidMembers.push(member);
					}

					return a;
				}, []);

				this.members = members;
				this.e1 = 3;
			}
		},

		validateEmail(email) {
			var re = /^[^\s@]+@[^\s@]+$/;

			if (re.test(email)) {
				return true;
			} else {
				return false;
			}
		},

		async importMembers() {
			this.e1 = 4;
			this.loadingImport = true;

			const members = this.members.map((member) => {
				return {
					...member,
					rfidTag: null,
					site: this.selectedSite._id,
				};
			});

			try {
				const res = await this.$store.dispatch("importMembers", members);

				if (res.duplicateMembers.length > 0) {
					this.duplicateMessage = res.duplicateMembers;
				}

				const message = this.$i18n.t("virtualKeys.import.responseMessages.complete");
				this.$toast.success(message);

				this.loadingImport = false;
			} catch (err) {
				const message = err.error.message;
				this.$toast.error(message);
			} finally {
				this.loadingImport = false;
			}
		},

		async cancel() {
			const res = await this.$refs.confirm.open("Cancel?", `${this.$i18n.t("virtualKeys.import.responseMessages.cancel")}`, "accent", "mdi-close-circle-outline");

			if (res === true) {
				this.resetForm();
				this.e1 = 1;
				this.dialog = false;
			}
		},

		finish() {
			this.resetForm();
			this.dialog = false;
			this.e1 = 1;
		},

		resetForm() {
			this.members = [];
			this.invalidMembers = [];
			this.duplicateMessage = null;
			this.file = null;

			this.mappedFields = this.initializeFields();
		},
	},
};
</script>
