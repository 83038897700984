<template>
	<v-dialog v-model="dialog" width="500" scrollable :fullscreen="$vuetify.breakpoint.smAndDown" persistent>
		<v-card>
			<v-card-title class="title accent white--text" style="border-bottom: 3px solid #406dfa !important">
				{{ isEditing ? "Edit Maintenance" : "Add Maintenance" }}
			</v-card-title>
			<v-btn @click="dialog = false" icon small style="position: absolute; right: 15px; top: 15px; z-index: 5">
				<v-icon color="white">mdi-close-circle-outline</v-icon>
			</v-btn>

			<v-card-text style="max-height: 700px" class="px-2">
				<v-form ref="form" v-model="valid" autocomplete="off" class="pa-0">
					<v-list class="pt-0">
						<v-list-item>
							<v-list-item-content class="pl-0 pb-0">
								<v-col cols="6" class="px-0 mb-0">
									<v-list-item-title class="font-weight-bold accent--text mb-2">Start: </v-list-item-title>
									<v-menu ref="dateSelect" v-model="startDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												:value="startDate"
												label="Start Date"
												single-line
												prepend-inner-icon="mdi-calendar-month-outline"
												outlined
												dense
												readonly
												v-bind="attrs"
												v-on="on"
												hide-details
											></v-text-field>
										</template>
										<v-date-picker v-model="booking.start" :min="minDateSelect" no-title scrollable />
									</v-menu>
								</v-col>

								<v-col cols="6" class="pr-0">
									<v-list-item-title class="font-weight-bold accent--text mb-2">End: </v-list-item-title>
									<v-menu ref="dateSelect" v-model="endDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												:value="endDate"
												label="End Date"
												single-line
												prepend-inner-icon="mdi-calendar-month-outline"
												outlined
												dense
												readonly
												v-bind="attrs"
												v-on="on"
												hide-details
											></v-text-field>
										</template>
										<v-date-picker v-model="booking.end" :min="booking.start" no-title scrollable />
									</v-menu>
								</v-col>
							</v-list-item-content>
						</v-list-item>

						<v-list-item class="mt-4">
							<v-list-item-content class="pa-0">
								<v-list-item-title class="font-weight-bold accent--text"> Cart: </v-list-item-title>
								<v-list-item-subtitle>
									<v-select
										:loading="loadingDevices"
										v-model="booking.device"
										:items="devices"
										:label="$t('carDetail.carNumber')"
										:menu-props="{ offsetY: true }"
										:rules="[rules.required]"
										prepend-inner-icon="mdi-golf-cart"
										item-text="carNumber"
										item-value="_id"
										outlined
										dense
										single-line
										hide-details
										:return-object="false"
										class="mb-1"
										:no-data-text="$t('bookings.modal.noCarts')"
										@input="getAvailableDevices"
									>
										<template slot="selection" slot-scope="{ item }"> {{ $t("carDetail.car") }} {{ item.carNumber }} </template>
										<template slot="item" slot-scope="{ item }"> {{ $t("carDetail.car") }} {{ item.carNumber }} </template>
									</v-select>

									<v-alert v-if="!availableDevice" type="info" class="text-wrap subtitle-2 font-weight-bold mt-4">
										Bookings exist for this cart between {{ startDate }} and {{ endDate }}. You must first cancel any existing bookings.
									</v-alert>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-form>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-actions>
				<v-btn color="error" class="padlessBtn" v-if="isEditing" @click="deleteBooking" depressed>
					<v-icon left>mdi-delete</v-icon>
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn @click="close" text color="primary">
					{{ $t("general.cancel") }}
				</v-btn>
				<v-btn color="primary" @click="createBooking" :loading="loading" :disabled="!valid || !availableDevice">
					{{ $t("general.save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, parseISO, startOfDay, endOfDay } from "date-fns";
import Confirm from "../Confirm.vue";

export default {
	props: {
		// default new Date
		currentDay: {
			type: String,
			default: format(new Date(), "yyyy/MM/dd HH:mm"),
		},
		device: {
			type: Object,
			default: null,
		},
	},
	components: { Confirm },
	data() {
		return {
			dateSelectMenu: false,
			selectedDevice: null,

			startDateMenu: false,
			endDateMenu: false,

			booking: {
				start: format(new Date(), "yyyy-MM-dd"),
				end: format(new Date(), "yyyy-MM-dd"),
				device: null,
				bookingType: "cart_maintenance",
				name: "Maintenance",
			},

			loading: false,
			loadingDevices: false,

			availableDevice: true,
			availableDevices: {
				available: [],
				recommended: null,
			},

			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				emailValid: (value) => !value || /.+@.+\..+/.test(value) || this.$i18n.t("rules.emailValid"),
				min: (value) => (value && value >= 0.5) || this.$i18n.t("rules.minValue"),
				length: (value) => (value && value.length === 6) || this.$i18n.t("rules.6Digits"),
				numberOnly: (value) => /^\d+$/.test(value) || this.$i18n.t("rules.numbersOnly"),
			},

			valid: true,
			dialog: false,

			isEditing: false,
		};
	},
	watch: {
		dialog(val) {
			if (val) {
				this.booking.start = format(parseISO(new Date(this.currentDay).toISOString()), "yyyy-MM-dd");
			} else {
				this.close();
			}
		},

		"booking.start"(val) {
			// if value is greater than end date then set end date to start date
			if (val > this.booking.end) {
				this.booking.end = val;
			}

			if (this.booking.device) {
				this.getAvailableDevices();
			}
		},

		"booking.end"() {
			if (this.booking.device) {
				this.getAvailableDevices();
			}
		},
	},
	computed: {
		...mapState({
			devices: (state) => state.devices.devices,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		...mapGetters(["devicesCount", "deviceFromId"]),

		startDate() {
			return this.translatedFormattedDate(parseISO(this.booking.start), "EE, do MMM yyyy");
		},

		endDate() {
			return this.translatedFormattedDate(parseISO(this.booking.end), "EE, do MMM yyyy");
		},

		minDateSelect() {
			return format(new Date(), "yyyy-MM-dd");
		},
	},
	methods: {
		defaultBooking() {
			return {
				start: format(new Date(), "yyyy-MM-dd"),
				end: format(new Date(), "yyyy-MM-dd"),
				device: null,
				bookingType: "cart_maintenance",
				name: "Maintenance",
			};
		},

		open(booking) {
			this.dialog = true;

			if (booking) {
				this.isEditing = true;

				this.booking = {
					...booking,
					start: format(parseISO(booking.start), "yyyy-MM-dd"),
					end: format(parseISO(booking.end), "yyyy-MM-dd"),
				};
			}

			if (this.device) {
				this.booking.device = this.device._id;
				this.getAvailableDevices();
			}
		},

		async getAvailableDevices() {
			this.loading = true;

			try {
				this.availableDevices = await this.$store.dispatch("getAvailableDevices", {
					start: startOfDay(new Date(this.booking.start)).toISOString(),
					end: endOfDay(new Date(this.booking.end)).toISOString(),
					...(this.isEditing && { excludedBookingId: this.booking._id }),
				});

				if (this.booking.device) {
					let device = this.availableDevices.available.find((device) => device === this.booking.device);

					if (!device) {
						this.availableDevice = false;
					} else {
						this.availableDevice = true;
					}
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},

		async createBooking() {
			if (this.$refs.form.validate() === false) return;

			let newBooking = {
				...this.booking,
				start: startOfDay(new Date(this.booking.start)).toISOString(),
				end: endOfDay(new Date(this.booking.end)).toISOString(),
			};

			this.loading = true;

			try {
				if (this.isEditing) {
					await this.$store.dispatch("updateBooking", newBooking);
					this.$toast.success("Booking updated successfully");
				} else {
					await this.$store.dispatch("createBooking", newBooking);
					this.$toast.success("Booking created successfully");
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
				this.close();
			}
		},

		async deleteBooking() {
			const res = await this.$refs.confirm.open("Delete Booking?", "", "error", "mdi-trash-can-outline");

			if (res !== true) return;

			try {
				await this.$store.dispatch("deleteBooking", this.booking._id);
				this.close();

				this.$toast.success("Booking deleted");
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},

		close() {
			this.dialog = false;
			this.isEditing = false;
			this.booking = this.defaultBooking();
			this.$refs.form.resetValidation();
		},
	},
};
</script>

<style lang="scss">
.v-otp-input {
	transform: scale(0.9);
}

.booking-slot-list::-webkit-scrollbar {
	background-color: #e2e2e2; /* or add it to the track */
}

/* Add a thumb */
.booking-slot-list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	height: 28px;
	background: #406dfa;
}
</style>
