<template>
	<div>
		<v-row class="mt-1">
			<v-col>
				<v-toolbar flat dense>
					<v-toolbar-title class="title"> Viewing: {{ selectedSite ? selectedSite.name : "All Sites" }} </v-toolbar-title>
					<v-spacer></v-spacer>
					<v-autocomplete
						v-model="selectedSite"
						dense
						outlined
						label="Select Site"
						clearable
						:items="sites"
						item-text="name"
						return-object
						hide-details
						style="max-width: 200px; scale: 0.8"
					>
						<template v-slot:prepend-item>
							<div v-if="selectedSite">
								<v-list-item ripple @mousedown.prevent @click="selectedSite = null">
									<v-list-item-content>
										<v-list-item-title> Select All </v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</div>
						</template>
					</v-autocomplete>
					<v-btn class="mr-1 padlessBtn" color="primary" depressed small @click="getDevices" :loading="loadingDevices">
						<v-icon small> mdi-refresh </v-icon>
					</v-btn>
				</v-toolbar>
			</v-col>
		</v-row>
		<v-row class="px-4 mt-2" v-if="devices.length && sites.length">
			<v-col cols="12">
				<v-row>
					<v-col cols="12">
						<v-row>
							<v-col cols="12" md="3">
								<device-stats :devices="devicesWithSite" />
							</v-col>
							<v-col cols="12" md="3">
								<lock-status :devices="devicesWithSite" />
							</v-col>
							<v-col cols="12" md="3">
								<data-card color="red lighten-4" textColor="red" icon="mdi-access-point-remove" iconBg="red" :value="offlineDevices.length" text="Offline Devices" />
							</v-col>

							<v-col cols="12" md="3">
								<data-card color="red lighten-4" textColor="red" icon="mdi-access-point-remove" iconBg="red" :value="offlineDevices.length" text="Offline Devices" />
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" md="4">
						<v-card flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<v-icon small color="accent" left> mdi-access-point-remove </v-icon>
								Recently Disconnected
							</v-card-title>
							<v-card-subtitle> Devices that have been disconnected for less than 24 hours </v-card-subtitle>
							<v-card-text>
								<v-data-table :headers="recentlyDisonnectedHeaders" :items="recentlyDisconnectedDevices" :items-per-page="10" :mobile-breakpoint="0">
									<template v-slot:item.site="{ item }">
										<v-chip x-small label color="accent lighten-2" class="caption font-weight-bold" @click="selectSite(item.site._id)">
											{{ item.site.name }}
										</v-chip>
									</template>

									<template v-slot:item.disconnectedAt="{ item }">
										<span class="caption">
											{{ duration(item.disconnectedAt) }}
										</span>
									</template>

									<template v-slot:item.onlineStatus="{ item }">
										<v-chip :color="item.onlineStatus ? 'success' : 'error'" small label class="font-weight-bold">
											{{ onlineStatus(item.onlineStatus) }}
										</v-chip>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" md="4">
						<v-card flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<v-icon small color="accent" left> mdi-access-point-check </v-icon>
								Recently Connected
							</v-card-title>
							<v-card-subtitle> Devices that have connected in the last 1 hour </v-card-subtitle>
							<v-card-text>
								<v-data-table :headers="recentlyConnectedHeaders" :items="recentlyConnectedDevices" :items-per-page="10" :mobile-breakpoint="0">
									<template v-slot:item.site="{ item }">
										<v-chip x-small label color="accent lighten-2" class="caption font-weight-bold" @click="selectSite(item.site._id)">
											{{ item.site.name }}
										</v-chip>
									</template>

									<template v-slot:item.connectedAt="{ item }">
										<span class="caption">
											{{ duration(item.connectedAt) }}
										</span>
									</template>

									<template v-slot:item.onlineStatus="{ item }">
										<v-chip :color="item.onlineStatus ? 'success' : 'error'" small label class="font-weight-bold">
											{{ onlineStatus(item.onlineStatus) }}
										</v-chip>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-col>

					<v-col cols="12" md="4">
						<v-card flat class="rounded-lg translucentBackground">
							<v-card-title class="title font-weight-bold accent--text">
								<v-icon small color="accent" left> mdi-lock </v-icon>
								Recently Locked/Unlocked
							</v-card-title>
							<v-card-subtitle> Devices that have had their lock status changed in the last 10 minutes </v-card-subtitle>
							<v-card-text>
								<v-data-table :headers="recentLockUpdateHeaders" :items="recentLockStatusChanged" :items-per-page="20" :mobile-breakpoint="0">
									<template v-slot:item.site="{ item }">
										<v-chip x-small label color="accent lighten-2" class="caption font-weight-bold" @click="selectSite(item.site._id)">
											{{ item.site.name }}
										</v-chip>
									</template>

									<template v-slot:item.lockUpdatedAt="{ item }">
										<span class="caption">
											{{ duration(item.lockUpdatedAt) }}
										</span>
									</template>

									<template v-slot:item.lockStatus="{ item }">
										<v-chip :color="item.lockStatus ? 'success' : 'error'" small label class="font-weight-bold">
											{{ lockStatus(item.lockStatus) }}
										</v-chip>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from "vuex";
import DataCard from "@/components/charts/DataCard.vue";
import { subHours, subMinutes, intlFormatDistance } from "date-fns";

import DeviceStats from "@/components/admin/charts/DeviceStats.vue";
import LockStatus from "@/components/admin/charts/LockStatus.vue";

export default {
	components: {
		DeviceStats,
		LockStatus,
		DataCard,
	},
	data() {
		return {
			recentlyDisonnectedHeaders: [
				{ text: "Car", value: "carNumber" },
				{ text: "Site", value: "site" },
				{ text: "Disconnected", value: "disconnectedAt" },
				{ text: "Status", value: "onlineStatus" },
			],

			recentlyConnectedHeaders: [
				{ text: "Car", value: "carNumber" },
				{ text: "Site", value: "site" },
				{ text: "Connected", value: "connectedAt" },
				{ text: "Status", value: "onlineStatus" },
			],

			recentLockUpdateHeaders: [
				{ text: "Car", value: "carNumber" },
				{ text: "Site", value: "site" },
				{ text: "Updated", value: "lockUpdatedAt" },
				{ text: "Status", value: "lockStatus" },
			],
		};
	},
	watch: {
		selectedSite: {
			handler() {
				this.getDevices();
			},
			deep: true,
		},
	},
	computed: {
		...mapState({
			devices: (state) => state.admin.devices.devices,
			loadingDevices: (state) => state.admin.devices.loading,
			sites: (state) => state.admin.sites.sites,
			site: (state) => state.admin.sites.site,
		}),

		selectedSite: {
			get() {
				return this.site;
			},
			set(value) {
				this.$store.commit("admin/sites/setSite", value);
			},
		},

		devicesWithSite() {
			return this.devices.map((device) => {
				const site = this.sites.find((site) => site._id === device.siteID);
				return {
					...device,
					site: {
						_id: site._id,
						name: site.name,
					},
				};
			});
		},

		onlineDevices() {
			return this.devices.filter((device) => device.onlineStatus);
		},

		offlineDevices() {
			return this.devices.filter((device) => !device.onlineStatus);
		},

		recentlyDisconnectedDevices() {
			// filter devices that have been disconnected for less than 1 day
			const devices = this.devicesWithSite.filter((device) => new Date(device.disconnectedAt) >= subHours(new Date(), 24) && !device.onlineStatus);

			return devices.sort((a, b) => new Date(b.disconnectedAt) - new Date(a.disconnectedAt));
		},

		recentlyConnectedDevices() {
			// filter devices which have been connected for less than 1 hour
			const devices = this.devicesWithSite.filter((device) => new Date(device.connectedAt) >= subHours(new Date(), 1) && device.onlineStatus);

			return devices.sort((a, b) => new Date(b.connectedAt) - new Date(a.connectedAt));
		},

		recentLockStatusChanged() {
			// filter devices which have lockUpdatedAt in the past 10 minutes
			const devices = this.devicesWithSite.filter((device) => new Date(device.lockUpdatedAt) >= subMinutes(new Date(), 10));

			return devices.sort((a, b) => new Date(b.lockUpdatedAt) - new Date(a.lockUpdatedAt));
		},
	},
	async created() {
		await this.getDevices();
		await this.getSites();
	},
	methods: {
		selectSite(id) {
			const site = this.sites.find((site) => site._id === id);

			this.selectedSite = site;
		},

		duration(date) {
			return intlFormatDistance(new Date(date), new Date(), { numeric: "always" });
		},

		onlineStatus(status) {
			return status ? "Online" : "Offline";
		},

		lockStatus(status) {
			return status ? "Locked" : "Unlocked";
		},

		async getDevices() {
			await this.$store.dispatch("admin/getDevices", {
				siteID: this.selectedSite ? this.selectedSite._id : null,
				fields: ["_id", "carNumber", "siteID", "lockStatus", "onlineStatus", "disconnectedAt", "connectedAt", "lockUpdatedAt"],
			});
		},

		async getSites() {
			await this.$store.dispatch("admin/sites/getSites");
		},
	},
};
</script>
