<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on" class="pl-10">
				<v-list-item-title>
					<v-icon small left>mdi-broadcast</v-icon>
					Custom Ping
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>Custom Ping</span>
			</v-card-text>
			<v-card-text>
				<v-form
					ref="form"
					v-model="form"
					lazy-validation
					@submit.prevent="ping"
				>
					<v-text-field
						autofocus
						prepend-inner-icon="mdi-broadcast"
						placeholder="Ping Command"
						label="Ping Command"
						hide-details="auto"
						v-model="value"
						:rules="[rules.required]"
						type="number"
						outlined
						rounded
						dense
						clearable
						class="mb-3"
					></v-text-field>
					<v-btn block rounded @click="ping" color="primary" class="mt-3">
						Send
					</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			value: null,
			form: true,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		ping() {
			if (this.$refs.form.validate()) {
				this.$emit("pingDevice", Number(this.value));
				this.dialog = false;
			}
		},

		reset() {
			this.value = null;
			this.$refs.form.resetValidation();
		},
	},
};
</script>
