<template>
	<div>
		<v-row no-gutters class="accent lighten-1 elevation-8" style="z-index: 200; border-bottom: 3px solid #406dfa !important">
			<v-col>
				<v-row no-gutters class="pa-4">
					<v-col cols="12" class="text-center">
						<span class="title font-weight-bold warning--text">
							{{ filteredCars.length }}
							{{ filteredCars.length === 1 ? $t("general.cart") : $t("general.carts") }}
							<v-chip color="warning" class="elevation-4 ml-2 blink" label small>
								{{ $t("mapPage.carsList.live") }}
							</v-chip>
						</span>
						<v-btn :href="helpURL" target="blank" dark icon style="position: absolute; top: 5px; right: 5px">
							<v-icon>mdi-help-box</v-icon>
						</v-btn>
					</v-col>
					<v-col cols="12" class="mt-4">
						<v-text-field
							v-model="search"
							solo
							flat
							dense
							hide-details
							clearable
							:class="search && 'font-weight-bold'"
							:placeholder="$t('mapPage.carsList.searchBox')"
							:background-color="search ? 'white' : 'rgba(255, 255, 255, 0.5)'"
						>
							<template slot="append">
								<v-btn @click="filterSelect = !filterSelect" small color="white" class="padlessBtn" :depressed="filterSelect" :class="filters !== 'all' && 'warning '">
									<v-icon small :color="filters !== 'all' ? 'white' : 'primary'"> mdi-filter-variant </v-icon>
								</v-btn>
							</template>
						</v-text-field>
					</v-col>
					<v-col cols="12" v-if="filterSelect" class="mt-4">
						<v-chip-group v-model="filters" mandatory column active-class="warning ">
							<v-chip value="all" label small class="font-weight-bold elevation-4"> All </v-chip>
							<v-chip value="locked" label small class="font-weight-bold elevation-4">
								{{ $t("general.locked") }}
							</v-chip>
							<v-chip value="unlocked" label small class="font-weight-bold elevation-4">
								{{ $t("general.unlocked") }}
							</v-chip>
							<v-chip value="reserved" label small class="font-weight-bold elevation-4">
								{{ $t("mapPage.carsList.reserved") }}
							</v-chip>
							<v-chip value="inUse" label small class="font-weight-bold elevation-4">
								{{ $t("mapPage.carsList.inUse") }}
							</v-chip>
							<v-chip value="stored" label small class="font-weight-bold elevation-4">
								{{ $t("mapPage.carsList.stored") }}
							</v-chip>
						</v-chip-group>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<div class="cars-list">
			<v-row class="mt-2" no-gutters ref="list">
				<v-col v-for="item in filteredCars" :key="item._id" :ref="`${item._id}`" cols="12" class="px-4 py-2">
					<car-card @cardClick="selectCar(item)" :item="item" />
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CarCard from "@/components/fleet/CarCard";

export default {
	components: {
		CarCard,
	},
	data() {
		return {
			filterSelect: false,
			filters: "all",
			search: "",
		};
	},
	watch: {
		// Watch selected device and scroll to view
		selectedDeviceId: {
			immediate: true,
			handler(newId) {
				// Wait for Vue's next rendering cycle before scrolling to the element
				this.$nextTick(() => {
					if (newId && this.$refs[newId] && this.$refs[newId][0]) {
						this.$refs[newId][0].scrollIntoView({ behavior: "smooth" });
					}
				});
			},
		},
	},

	computed: {
		...mapState({
			selectedDeviceId: (state) => state.devices.selectedDeviceId,
		}),

		...mapGetters(["devicesWithUpdatesBookingsRide"]),

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/article/erste-schritte-mit-der-echtzeitkarte-ilm9ls/";

				default:
					return "https://help.iotee.eu/en/article/getting-started-with-the-real-time-map-1d0jhqv/";
			}
		},

		filteredCars() {
			let results;

			switch (this.filters) {
				case "inUse":
					results = this.devicesWithUpdatesBookingsRide.filter((car) => car.inUse === 1);

					break;
				case "stored":
					results = this.devicesWithUpdatesBookingsRide.filter((car) => car.inUse === 0);

					break;

				case "locked":
					results = this.devicesWithUpdatesBookingsRide.filter((car) => car.lockStatus === 1);

					break;

				case "unlocked":
					results = this.devicesWithUpdatesBookingsRide.filter((car) => car.lockStatus === 0);

					break;

				case "reserved":
					results = this.devicesWithUpdatesBookingsRide.filter((car) => car.bookings?.length);

					break;
				default:
					results = this.devicesWithUpdatesBookingsRide;
			}

			if (this.search) {
				const keys = ["carName", "carNumber", "ride.meta.name"];
				const lowSearch = this.search.toLowerCase();

				results = results.filter((car) =>
					keys.some((key) => {
						const value = this.getValueByPath(car, key);
						return String(value).toLowerCase().includes(lowSearch);
					})
				);
			}

			results = results.sort((a, b) => Number(a.carNumber) - Number(b.carNumber));

			return results;
		},
	},
	methods: {
		getValueByPath(obj, path) {
			return path.split(".").reduce((nestedObj, key) => {
				return nestedObj && nestedObj[key];
			}, obj);
		},

		selectCar(item) {
			if (this.selectedDeviceId === item._id) {
				this.$store.commit("setSelectedDeviceId", null);
			} else {
				this.$store.commit("setSelectedDeviceId", item._id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	display: none !important;
}
.cars-list {
	height: calc(var(--vh, 1vh) * 100 - 121px) !important;
	overflow-y: auto;
}

.blink {
	-webkit-animation: blink 6s infinite both;
	animation: blink 6s infinite both;
}

@-webkit-keyframes blink {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0;
	}
}
@keyframes blink {
	0%,
	50%,
	100% {
		opacity: 1;
	}
	25%,
	75% {
		opacity: 0;
	}
}
</style>
