import devices from "./devices.store";
import bookings from "./bookings.store";
import sites from "./sites.store";

export default {
	namespaced: true,
	modules: {
		devices,
		bookings,
		sites,
	},
};
