<template>
	<v-card v-if="selectedDeviceId" class="bottom-sheet rounded-xl" color="accent" dark>
		<v-btn @click="closeSheet()" icon small style="position: absolute; right: 10px; top: 8px; z-index: 5">
			<v-icon>mdi-close-circle-outline</v-icon>
		</v-btn>
		<v-card-text class="px-1 py-1">
			<v-list-item three-line>
				<v-list-item-content>
					<v-list-item-title class="title font-weight-bold"> Car {{ selectedDevice.carNumber }} </v-list-item-title>

					<v-list-item-subtitle v-if="selectedDevice.ride && selectedDevice.ride.meta && selectedDevice.ride.meta.name" class="font-weight-bold text-wrap mt-1">
						{{ selectedDevice.ride.meta.name }}
					</v-list-item-subtitle>
					<v-list-item-subtitle v-if="selectedDevice.carName" class="caption font-weight-bold text-trunicate">
						{{ selectedDevice.carName }}
					</v-list-item-subtitle>
					<v-list-item-subtitle class="caption text-wrap">
						{{ computedTimeAgo(selectedDevice.timeUTC) }}
					</v-list-item-subtitle>
				</v-list-item-content>

				<div class="mr-5">
					<v-chip v-if="hasRole('admin')" @click="pingCar" small class="mr-2 font-weight-bold" color="primary" label> Ping </v-chip>
					<lock-button :device="selectedDevice" />
				</div>
			</v-list-item>
		</v-card-text>

		<v-card class="d-flex justify-space-between pa-6 rounded-xl" flat color="accent lighten-1">
			<div v-if="hasRole('admin')" style="position: absolute; top: -10px; left: 10px">
				<v-chip x-small class="mr-2" color="primary"> CSQ: {{ selectedDevice.gprsSignal }} </v-chip>
				<v-chip x-small color="primary"> RSSI: {{ selectedDevice.wifiSignal }} </v-chip>
			</div>
			<div class="text-center ml-3 font-weight-bold">
				<v-icon :color="batteryDisplay(selectedDevice).color">
					{{ batteryDisplay(selectedDevice).icon }}
				</v-icon>
				<br />
				<span class="font-weight-light caption">
					{{ $t("mapPage.bottomSheet.charge") }}
				</span>
				<br />
				{{ batteryDisplay(selectedDevice).text }}
			</div>

			<div v-if="selectedDevice.ride" class="text-center ml-3 font-weight-bold">
				<v-icon color="success">mdi-map-marker-distance</v-icon>
				<br />
				<span class="font-weight-light caption">
					{{ $t("mapPage.bottomSheet.distance") }}
				</span>
				<br />
				{{ tripDistance }} km
			</div>

			<div class="text-center ml-3 font-weight-bold">
				<v-icon color="success"> mdi-speedometer </v-icon>
				<br />
				<span class="font-weight-light caption">
					{{ $t("mapPage.bottomSheet.speed") }}
				</span>
				<br />
				{{ selectedDevice.gpsSpeed ? deviceSpeed(selectedDevice.gpsSpeed) : "Uknown" }}
			</div>

			<div class="text-center ml-3 font-weight-bold">
				<v-icon :color="`${lockStatus(selectedDevice.lockStatus).color}`">
					{{ lockStatus(selectedDevice.lockStatus).icon }}
				</v-icon>
				<br />
				<span class="font-weight-light caption">{{ $t("general.lock") }}</span>
				<br />
				{{ selectedDevice.lockStatus ? $t("general.locked") : $t("general.unlocked") }}
			</div>

			<div class="text-center ml-3 font-weight-bold" v-if="selectedDevice.key === '1'">
				<v-icon color="success"> mdi-key </v-icon>
				<br />
				<span class="font-weight-light caption">{{ $t("general.mode") }}</span>
				<br />
				{{ $t("general.key") }}
			</div>
		</v-card>
	</v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import LockButton from "@/components/fleet/LockModal.vue";

export default {
	components: {
		LockButton,
	},
	data() {
		return {
			tabs: null,
			items: ["Info", "Settings"],
		};
	},
	computed: {
		...mapState({
			selectedDeviceId: (state) => state.devices.selectedDeviceId,
		}),

		...mapGetters(["devicesWithRide"]),

		selectedDevice() {
			return this.devicesWithRide.find((car) => car._id === this.selectedDeviceId);
		},

		tripDistance() {
			// early return if no ride info
			if (!this.selectedDevice.ride) return 0;

			let { endOdometer, startOdometer } = this.selectedDevice.ride;
			let carOdometer = Number(this.selectedDevice.carOdometer);

			let distance = endOdometer ? endOdometer - startOdometer : carOdometer - startOdometer;

			return distance.toFixed(1);
		},
	},
	methods: {
		closeSheet() {
			this.$store.commit("setSelectedDeviceId", null);
		},

		async pingCar() {
			this.loading = true;

			try {
				await this.$store.dispatch("admin/pingDevice", {
					id: this.selectedDevice._id,
					command: 2,
				});

				const message = `Command sent to Car ${this.selectedDevice.carNumber}`;
				this.$toast.success(message);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.bottom-sheet {
	position: absolute;
	z-index: 5;
	bottom: 10px;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: max-content;
}
</style>
