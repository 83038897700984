import * as devicesService from "@/services/admin/devices.service";

export default {
	state: {
		deviceLogs: [],
		systemLogs: [],
		logFiles: [],
		devices: [],
		device: null,
		loading: false,
	},

	mutations: {
		setDeviceLogs(state, payload) {
			state.deviceLogs = payload;
		},

		setDevices(state, payload) {
			state.devices = payload;
		},

		setLoading(state, payload) {
			state.loading = payload;
		},

		setDevice(state, payload) {
			state.device = payload;
		},

		setDeviceSystemLogs(state, payload) {
			state.systemLogs = payload;
		},

		setLogFiles(state, payload) {
			state.logFiles = payload;
		},
	},

	actions: {
		async getDevice({ commit }, payload) {
			const response = await devicesService.getDevice(payload);
			commit("setDevice", response);
		},

		async getDevices({ commit }, payload) {
			commit("setLoading", true);
			const response = await devicesService.getDevices(payload);
			commit("setDevices", response);
			commit("setLoading", false);
		},

		async getDeviceLogs({ commit }, payload) {
			const response = await devicesService.getDeviceLogs(payload);
			commit("setDeviceLogs", response);
		},

		async pingDevice(_, payload) {
			await devicesService.pingDevice(payload);
		},

		async pingDevices(_, payload) {
			await devicesService.pingDevices(payload);
		},

		async deleteDevices({ dispatch }, payload) {
			await devicesService.deleteDevices(payload);
			await dispatch("getDevices", null, { root: true });
		},

		async updateDevicesFirmware(_, payload) {
			await devicesService.updateDevicesFirmware(payload);
		},

		async calibrateDeviceBattery(_, payload) {
			await devicesService.calibrateDeviceBattery(payload);
		},

		async calibrateDevicesBattery(_, payload) {
			await devicesService.calibrateDevicesBattery(payload);
		},

		async getDeviceSystemLogs({ commit }, payload) {
			const response = await devicesService.getDeviceSystemLogs(payload);
			commit("setDeviceSystemLogs", response);
		},

		async getLogFiles({ commit }, payload) {
			const response = await devicesService.getLogFiles(payload);
			commit("setLogFiles", response);
		},
	},
};
