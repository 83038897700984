import Vue from "vue";

export const getPayments = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/payments", {
			params: query,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getStripeAccountBalance = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/payments/balance");

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
