<template>
	<v-dialog v-model="dialog" max-width="600px" v-if="priceItem" persistent>
		<template v-slot:activator="{ on }">
			<slot name="activator" v-bind="on"></slot>
		</template>

		<v-card>
			<v-form ref="form" v-model="valid" lazy-validation>
				<v-card-title class="font-weight-bold px-8">
					{{ editing ? $t("services.editPrice") : $t("services.addPrice") }}
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<user-group-select v-model="priceItem.userGroup" :key="priceItem._id" />
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-text-field
									outlined
									dense
									v-model="priceItem.name"
									:label="$t('services.general.name')"
									:placeholder="$t('general.memberTypes.guest')"
									:rules="[rules.required]"
									required
									hide-details
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-text-field
									v-model="priceItem.amount"
									v-money="moneyFormat"
									:prefix="currencyDisplay(selectedSite.currency)"
									outlined
									dense
									:label="$t('services.general.price')"
									:key="priceItem._id"
									:rules="[rules.required, rules.number, rules.validMinimumPrice]"
									required
									hide-details="auto"
									:hint="
										$t('rules.minPrice', {
											currency: this.currencyDisplay(this.selectedSite.currency),
											amount: this.getMinimumPrice(this.selectedSite.currency),
										})
									"
								/>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<v-text-field
									v-model="priceItem.credits"
									prepend-inner-icon="mdi-ticket-confirmation-outline"
									outlined
									dense
									type="number"
									:label="$t('services.general.priceInCredits')"
									:key="priceItem._id"
									:rules="[rules.credits, rules.minimumCredits]"
									hide-details="auto"
									:hint="$t('services.general.creditsHint')"
									clearable
								/>
							</v-col>
						</v-row>

						<v-row class="date-selection">
							<v-col class="grey rounded-lg mb-2 mx-2">
								<date-select :existingSchedules="priceItem.availableTimes" @update:schedules="handleScheduleUpdate" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="primary" outlined @click="close">{{ $t("general.cancel") }}</v-btn>
					<v-btn color="primary" depressed @click="save"> {{ $t("general.save") }}</v-btn>
				</v-card-actions>
			</v-form>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Confirm from "@/components/Confirm.vue";
import UserGroupSelect from "@/components/drivers/UserGroupSelect.vue";
import DateSelect from "@/components/sitesettings/DateSelect.vue";

export default {
	components: { Confirm, UserGroupSelect, DateSelect },
	props: {
		selectedSite: {
			required: true,
		},
	},
	data() {
		return {
			valid: false,
			dialog: false,
			editing: false,
			priceItem: null,
			isDirty: false,
			rules: {
				required: (value) => !!value || this.$t("rules.required"),
				number: (value) => !isNaN(parseFloat(value)) || this.$t("rules.numbersOnly"),
				credits: (value) => value === undefined || value === null || value === "" || !isNaN(parseFloat(value)) || this.$t("rules.numbersOnly"),
				validMinimumPrice: (value) => {
					if (typeof value !== "string") {
						return false;
					}
					const minPrice = this.getMinimumPrice(this.selectedSite.currency);
					const formattedValue = parseFloat(value.replace(",", "."));
					return (
						formattedValue === 0 ||
						formattedValue >= minPrice ||
						this.$t("rules.minPrice", {
							currency: this.currencyDisplay(this.selectedSite.currency),
							amount: minPrice,
						})
					);
				},

				// minimum of 1 credit
				minimumCredits: (value) => value === null || value === "" || parseFloat(value) >= 0 || this.$t("rules.minCredits"),
			},
			moneyFormat: {
				decimal: ",",
				thousands: ".",
				precision: 2,
			},
		};
	},
	watch: {
		priceItem: {
			handler() {
				if (this.dialog) this.isDirty = true;
			},
			deep: true,
		},

		// Watcher for priceItem.userGroup to update priceItem.name
		"priceItem.userGroup": {
			handler(newVal) {
				if (!this.editing) {
					this.priceItem.name = this.getUserGroupName(newVal);
				}
			},
			immediate: true,
		},

		dialog(val) {
			if (!val) {
				this.resetForm();
			}
		},
	},
	computed: {
		...mapGetters({
			getUserGroupName: "getUserGroupName",
		}),
	},
	methods: {
		generateTempId() {
			return `temp-${Date.now()}`;
		},

		toggleAvailableTimes(e) {
			if (e) {
				this.priceItem.availableTimes.push({
					day: 0,
					startTime: "00:00",
					endTime: "00:00",
				});
			} else {
				this.priceItem.availableTimes = [];
			}
		},

		setEmptyPriceObject() {
			return {
				_id: this.generateTempId(),
				name: "",
				amount: 0,
				credits: null,
				availableTimes: [],
				userGroup: null,
			};
		},

		resetForm() {
			this.dialog = false;
			this.editing = false;
			this.isDirty = false;

			this.$nextTick(() => {
				this.priceItem = null;
				this.$refs.form.resetValidation();
			});
		},

		open(price) {
			this.dialog = true;

			if (price) {
				this.editing = true;
				this.priceItem = { ...price };
			} else {
				this.priceItem = this.setEmptyPriceObject();
			}

			this.$nextTick(() => {
				this.isDirty = false;
			});
		},

		async close() {
			if (this.isDirty) {
				const res = await this.$refs.confirm.open(this.$t("general.close"), this.$t("general.closeWithoutSave"), "accent", "mdi-help-circle-outline");

				if (res === false) {
					return; // Stop if the user does not confirm
				}
			}
			this.resetForm();
		},

		save() {
			if (this.$refs.form.validate()) {
				const price = {
					...this.priceItem,
					amount: parseFloat(this.priceItem.amount.replace(",", ".")) * 100,
				};

				this.$emit("save", { ...price, editing: this.editing });
				this.resetForm();
			}
		},

		handleScheduleUpdate(newSchedules) {
			this.$set(this.priceItem, "availableTimes", newSchedules);

			console.log(this.priceItem);
			console.log(newSchedules);
		},
	},
};
</script>

<style scoped>
.date-selection >>> .vc-date {
	display: none !important;
}
</style>
