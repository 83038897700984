import Vue from "vue";

export const getBookings = async ({ siteId, query }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get("/bookings", {
			params: query,
			headers: {
				"site-id": siteId,
			},
		});

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};

export const getBooking = async (bookingId) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/bookings/${bookingId}`);

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};

export const createBooking = async ({ siteId, body }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.post("/bookings", body, {
			headers: {
				"site-id": siteId,
			},
		});

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};

export const updateBooking = async ({ bookingId, body }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.patch(`/bookings/${bookingId}`, body);

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};

export const toggleLockWithBooking = async ({ siteId, bookingId, lockStatus }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.post(
			`/bookings/${bookingId}/toggle-lock`,
			{ lockStatus },
			{
				headers: {
					"site-id": siteId,
				},
			}
		);

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};

export const retrieveBooking = async ({ siteId, params }) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/bookings/retrieve`, {
			params,
			headers: {
				"site-id": siteId,
			},
		});

		return data;
	} catch (err) {
		console.log(err);
		throw err.response.data;
	}
};
