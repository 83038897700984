<template>
	<div class="text-center">
		<v-menu v-model="menu" :close-on-content-click="false" offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="padlessBtn mr-2" color="primary" depressed small v-bind="attrs" v-on="on">
					<v-icon small>mdi-filter</v-icon>
				</v-btn>
			</template>

			<v-card class="rounded-lg" width="320">
				<v-card-title class="pb-0">
					<h2 class="subtitle-1 font-weight-bold">Search Cars</h2>
					<v-spacer></v-spacer>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon color="primary" v-on="on">mdi-information-outline</v-icon>
						</template>
						<span class="font-weight-bold">Search by Car Number, Car Name, Car Serial Number, Vehicle Model, Driver Name</span>
					</v-tooltip>
				</v-card-title>
				<v-card-text class="py-2">
					<v-text-field v-model="searchTerm" label="Search" outlined dense clearable hide-details></v-text-field>
				</v-card-text>
			</v-card>
		</v-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {
			menu: false,
			searchTerm: null,
		};
	},
	watch: {
		searchTerm(newVal) {
			this.$store.commit("setDeviceSearch", newVal);
		},
	},
};
</script>
