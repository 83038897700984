<template>
	<v-container class="bg" style="height: 100%" fluid>
		<v-row>
			<v-col>
				<v-row class="accent elevation-4" dark style="position: sticky; top: 0; border-bottom: 3px solid #406dfa !important">
					<v-col>
						<v-row class="mb-2">
							<v-col cols="4">
								<span class="title font-weight-bold white--text">
									{{ $t("bookings.bookings") }}
								</span>
							</v-col>

							<v-spacer></v-spacer>

							<v-col cols="8" class="text-right">
								<span v-if="!selectedSite.bookingIntegration">
									<v-btn small class="mr-2" color="error" @click="openMaintenanceModal" :disabled="isPast">
										<v-icon small left>mdi-wrench</v-icon>
										Maintenance
									</v-btn>
									<v-btn small color="primary" @click="openBookingModal" :disabled="isPast">
										<v-icon small left>mdi-plus</v-icon>
										{{ $t("bookings.modal.title") }}
									</v-btn>
								</span>

								<v-tooltip bottom v-else>
									<template v-slot:activator="{ on, attrs }">
										<v-chip outlined color="white" v-bind="attrs" v-on="on">
											{{ $t("bookings.integrated") }}
										</v-chip>
									</template>
									<span>
										{{ $t("bookings.intMessage") }}
									</span>
								</v-tooltip>

								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs" v-on="on" @click="showAll = !showAll" class="padlessBtn ml-4" icon dark>
											<v-icon>
												{{ showAll ? "mdi-calendar-collapse-horizontal" : "mdi-calendar-expand-horizontal" }}
											</v-icon>
										</v-btn>
									</template>
									<span class="font-weight-bold">
										{{ showAll ? "Show only reserved cars" : "Show all cars" }}
									</span>
								</v-tooltip>

								<v-btn :href="helpURL" target="blank" dark icon class="ml-2" v-show="$vuetify.breakpoint.smAndUp">
									<v-icon>mdi-help-box</v-icon>
								</v-btn>

								<v-btn
									@click="
										$router.push({
											path: '/fleet',
										})
									"
									icon
									color="white"
									class="ml-2"
								>
									<v-icon color="white"> mdi-close </v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-card class="rounded-lg mb-4" flat>
							<v-toolbar flat>
								<v-btn fab text small color="accent" @click="prev">
									<v-icon small> mdi-chevron-left </v-icon>
								</v-btn>
								<v-menu v-model="startMenu" :close-on-content-click="false" transition="scale-transition" offset-y :nudge-left="155" min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-btn text color="accent" v-bind="attrs" v-on="on" class="padlessBtn px-0">
											<v-icon>mdi-calendar-month-outline</v-icon>
										</v-btn>
									</template>
									<v-date-picker :locale="$i18n.locale" no-title color="accent" v-model="selectedDay" @input="startMenu = false"></v-date-picker>
								</v-menu>
								<v-btn fab text small color="accent" @click="next">
									<v-icon small> mdi-chevron-right </v-icon>
								</v-btn>

								<v-spacer></v-spacer>

								<v-btn
									:text="currentDayFormatted === todayFormatted"
									:outlined="currentDayFormatted !== todayFormatted"
									style="background: white"
									:disabled="currentDayFormatted === todayFormatted"
									class="mx-2"
									:class="currentDayFormatted !== todayFormatted && 'elevation-4'"
									small
									color="accent darken-2"
									@click="setToday"
								>
									{{ $t("date.today") }}
								</v-btn>
								<v-btn
									:text="currentDayFormatted === tomorrowFormatted"
									:outlined="currentDayFormatted !== tomorrowFormatted"
									style="background: white"
									:disabled="currentDayFormatted === tomorrowFormatted"
									class="mr-4"
									:class="currentDayFormatted !== tomorrowFormatted && 'elevation-4'"
									small
									color="accent darken-2"
									@click="setTomorrow"
								>
									{{ $t("date.tomorrow") }}
								</v-btn>
							</v-toolbar>
							<v-card-text>
								<v-row class="px-4 pb-2 font-weight-bold accent--text">
									{{ dateTitleText }}&nbsp;
									<v-chip label small color="purple" dark class="font-weight-bold hidden-xs-only ml-2" v-if="currentDayFormatted === todayFormatted">
										{{ $t("date.today") }}
									</v-chip>

									<v-spacer></v-spacer>
									<v-chip :outlined="!bookings" small label :color="bookings ? `primary` : `accent`"> {{ $t("bookings.bookings") }}: {{ bookings.length }} </v-chip>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row class="bg">
					<v-col class="pa-0 calendar-list" cols="12">
						<calendar :currentDay="currentDay" :selectedSite="selectedSite" :showAll="showAll" @getCalendarBookings="getBookings" />
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<maintenance-modal ref="maintenanceModal" :currentDay="currentDay" />
		<manage-booking ref="bookingModal" :currentDay="currentDay" />
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import { format, addDays } from "date-fns";
import { de, da } from "date-fns/locale";
import Calendar from "@/components/bookings/Calendar.vue";
import MaintenanceModal from "@/components/bookings/MaintenanceModal.vue";
import ManageBooking from "@/components/bookings/ManageBooking.vue";

const locales = { de, da };

export default {
	components: {
		Calendar,
		MaintenanceModal,
		ManageBooking,
	},
	data() {
		return {
			calendarView: true,
			today: format(new Date(), "yyyy/MM/dd HH:mm"),
			tomorrow: format(addDays(new Date(), 1), "yyyy/MM/dd HH:mm"),
			currentDay: format(new Date(), "yyyy/MM/dd HH:mm"),
			startMenu: false,
			editModal: false,
			selectedEvent: null,
			fullScreen: true,
			showAll: false,
			loading: false,
			windowSize: {
				x: 0,
				y: 0,
			},
		};
	},
	watch: {
		currentDay: {
			handler(val) {
				this.$store.commit("setDate", new Date(val));
				this.getBookings();
			},
			immediate: true,
		},

		showAll(newVal) {
			localStorage.showAllBookingCategories = JSON.stringify(newVal);
		},
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			bookings: (state) => state.bookings.calendarBookings,
		}),

		selectedDay: {
			get() {
				return format(new Date(this.currentDay), "yyyy-MM-dd");
			},
			set(val) {
				this.setDate(val);
			},
		},

		isPast() {
			// is this.currentDay in the past? round to start of day
			return new Date(this.currentDay) < new Date(format(new Date(), "yyyy/MM/dd"));
		},

		todayFormatted() {
			return this.formatDateTime(this.today, "yyyy-MM-dd");
		},

		tomorrowFormatted() {
			return this.formatDateTime(this.tomorrow, "yyyy-MM-dd");
		},

		currentDayFormatted() {
			return this.formatDateTime(this.currentDay, "yyyy-MM-dd");
		},

		dateTitleText() {
			return this.formatDateTime(this.currentDay, "EEEE, dd/MM/yyyy", {
				locale: locales[this.$i18n.locale],
			});
		},

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/article/erste-schritte-mit-buchungen-1um96js/";

				default:
					return "https://help.iotee.eu/en/article/getting-started-with-bookings-1yvk4pn/";
			}
		},
	},
	mounted() {
		if (localStorage.fullScreenBookings) {
			this.fullScreen = JSON.parse(localStorage.fullScreenBookings);
		}

		if (localStorage.showAllBookingCategories) {
			this.showAll = JSON.parse(localStorage.showAllBookingCategories);
		}
	},
	methods: {
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		openMaintenanceModal() {
			this.$refs.maintenanceModal.open();
		},

		openBookingModal() {
			this.$refs.bookingModal.open();
		},

		async getBookings() {
			console.log("getBookings");
			this.loading = true;
			try {
				await this.$store.dispatch("getCalendarBookings");
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},

		formatDateTime(dateInput, formatType) {
			return format(new Date(dateInput), formatType);
		},

		adjustDay(days) {
			this.currentDay = this.formatDateTime(addDays(new Date(this.currentDay), days), "yyyy/MM/dd HH:mm");
		},

		setDate(val) {
			this.currentDay = format(new Date(val), "yyyy/MM/dd HH:mm");
		},

		setToday() {
			this.currentDay = format(new Date(), "yyyy/MM/dd HH:mm");
		},

		setTomorrow() {
			this.currentDay = format(addDays(new Date(), 1), "yyyy/MM/dd HH:mm");
		},

		prev() {
			this.adjustDay(-1);
		},

		next() {
			this.adjustDay(1);
		},
	},
};
</script>

<style lang="scss" scoped>
// ::-webkit-scrollbar {
//   display: none !important;
// }
.calendar-list {
	height: calc(var(--vh, 1vh) * 100 - 205px) !important;
	overflow-y: auto;
}
</style>
