<template>
	<span>
		<v-chip v-if="!device.endpointID" class="mr-4 orange--text elevation-2" color="orange lighten-4" dark label small @click.stop="addEndpoint" :disabled="loading">
			<v-icon small left>mdi-sim-outline</v-icon>
			Register SIM
		</v-chip>

		<v-menu v-else :close-on-content-click="false" bottom left offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="mr-4 elevation-1 padlessBtn" color="success" :loading="loading" dark small label v-bind="attrs" v-on="on" @click.stop="getEndpoint()">
					<v-icon small>mdi-sim-outline</v-icon>
				</v-btn>
			</template>

			<v-list dense nav>
				<div v-if="endpoint">
					<v-list-item two-line :class="`${connColor} white--text`">
						<v-row class="px-2 font-weight-bold subtitle-2 px-4">
							{{ connTitle }}
							<br />
							{{ connCarrier }}
							<v-spacer></v-spacer>
							{{ connStatus }}
						</v-row>
					</v-list-item>
					<v-list-item :disabled="loading">
						<v-list-item-title class="px-2">
							<v-switch v-model="statusSwitch" @change="toggleEndpoint" inset dense label="Status" :loading="loadingStatus"></v-switch>
						</v-list-item-title>
					</v-list-item>

					<v-list-item @click="resetEndpoint" :disabled="loading">
						<v-list-item-title>
							<v-icon small left>mdi-rotate-left</v-icon>
							Reset
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="updateEndpoint" :disabled="loading">
						<v-list-item-title>
							<v-icon small left>mdi-sim-alert-outline</v-icon>
							Update
						</v-list-item-title>
					</v-list-item>
					<v-list-item @click="removeEndpoint" :disabled="loading">
						<v-list-item-title>
							<v-icon small left>mdi-sim-off-outline</v-icon>
							De-Register
						</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-title>
							{{ productionDate }}
						</v-list-item-title>
					</v-list-item>
				</div>
				<div v-else>
					<v-list-item :disabled="loading">
						<v-list-item-title class="px-2"> Loading... </v-list-item-title>
					</v-list-item>
				</div>
			</v-list>
		</v-menu>
		<confirm ref="confirm" />
	</span>
</template>

<script>
import Confirm from "@/components/Confirm";
import { format } from "date-fns";

export default {
	props: {
		device: {
			type: Object,
			required: true,
		},
	},
	components: {
		Confirm,
	},
	data() {
		return {
			ready: false,
			dialog: false,
			loading: false,

			endpoint: null,
			connectivity: null,
			sim: null,

			loadingStatus: false,
		};
	},
	computed: {
		connStatus() {
			return this.connectivity && this.connectivity.pdp_context ? this.connectivity.pdp_context.rat_type.description : "";
		},
		connCarrier() {
			return this.connectivity && this.connectivity.location ? this.connectivity.location.operator.name : null;
		},
		connTitle() {
			if (this.endpoint && this.endpoint.status.id === 0) {
				return this.connectivity.status.description;
			} else {
				return "Disabled";
			}
		},
		connColor() {
			if (this.endpoint && this.endpoint.status.id === 0) {
				switch (this.connectivity.status.description) {
					case "ONLINE":
						return "success";
					case "ATTACHED":
						return "warning";
					case "OFFLINE":
						return "error";
					case "BLOCKED":
						return "grey";
					default:
						return "grey";
				}
			} else {
				return "error";
			}
		},
		productionDate() {
			const produced = this.sim && this.sim.production_date ? this.sim.production_date : "";

			return format(new Date(produced), "MM/yy") || "N/A";
		},
		statusColor() {
			return this.endpoint && this.endpoint.status.id === 0 ? "success" : "error";
		},
		statusSwitch: {
			get() {
				return this.endpoint && this.endpoint.status.id === 0 ? true : false;
			},
			set(val) {
				this.endpoint.status.id = val;
			},
		},
	},
	methods: {
		async getEndpoint() {
			this.loading = true;
			if (this.device.endpointID) {
				try {
					const { data: endpoint } = await this.$emnifyApi.get(this.device.endpointID);
					const { data: connectivity } = await this.$emnifyApi.get(`${this.device.endpointID}/connectivity`);
					const { data: sim } = await this.$emnifyApi.get(`/sim/${endpoint.sim.id}`);

					this.endpoint = endpoint;
					this.connectivity = connectivity;
					this.sim = sim;

					this.ready = true;
					this.loading = false;
				} catch (err) {
					const message = err.response.status;
					this.$toast.success(message);

					if (err.response.status === 404) {
						const settings = {
							id: this.device._id,
							body: {
								updateData: {
									endpointID: null,
								},
							},
						};

						await this.$store.dispatch("updateDevice", settings);
					}

					this.loading = false;
					this.ready = true;
				}
			} else {
				this.endpoint = null;
				this.loading = false;
				this.ready = true;
			}
		},

		async addEndpoint() {
			this.loading = true;

			const res = await this.$refs.confirm.open("Register SIM?", "", "success", "mdi-sim-outline");

			if (res === true) {
				try {
					const ccid = this.device.gprsCCID.slice(0, -1);

					let sim = await this.$emnifyApi.get(`/sim?q=iccid:${ccid}`);

					if (!sim.data.length) {
						throw "SIM Card not found";
					}

					sim = sim.data[0].id;

					const tp = 423838;
					const sp = 437227;

					const device = {
						name: this.device._id,
						tags: this.device.siteID.name,
						status: {
							id: 0,
						},
						sim: {
							id: sim,
							activate: true,
						},
						service_profile: {
							id: sp,
						},
						tariff_profile: {
							id: tp,
						},
					};

					const { headers } = await this.$emnifyApi.post("/endpoint", device);
					const endpoint = headers.location;

					const settings = {
						id: this.device._id,
						body: {
							updateData: {
								endpointID: endpoint,
							},
						},
					};

					await this.$store.dispatch("updateDevice", settings);

					this.loading = false;
				} catch (err) {
					const message = err;
					this.$toast.error(message);

					console.log(err);
					this.loading = false;
				}
			} else {
				this.loading = false;
			}
		},

		async toggleEndpoint(val) {
			this.loadingStatus = true;

			const endpointStatus = val ? 0 : 1;
			const simStatus = val ? 1 : 2;

			try {
				await this.$emnifyApi.patch(this.device.endpointID, {
					status: {
						id: endpointStatus,
					},
				});

				await this.$emnifyApi.patch(`/sim/${this.endpoint.sim.id}`, {
					status: {
						id: simStatus,
					},
				});

				this.getEndpoint();

				this.loadingStatus = false;
			} catch (err) {
				const message = err;
				this.$toast.error(message);

				this.loadingStatus = false;
			}
		},

		async removeEndpoint() {
			this.loading = true;

			const res = await this.$refs.confirm.open("De-Register SIM?", ``, "warning", "mdi-sim-outline");

			if (res === true) {
				try {
					await this.$emnifyApi.patch(this.device.endpointID, {
						status: {
							id: 1,
						},
						sim: {
							id: null,
							activate: false,
							status: {
								id: 2,
							},
						},
					});
					await this.$emnifyApi.delete(this.device.endpointID);

					const settings = {
						id: this.device._id,
						body: {
							updateData: {
								endpointID: null,
							},
						},
					};

					await this.$store.dispatch("updateDevice", settings);

					this.loading = false;
				} catch (err) {
					const message = err;
					this.$toast.error(message);

					this.loading = false;
				}
			} else {
				this.loading = false;
			}
		},

		async updateEndpoint() {
			this.loading = true;

			const res = await this.$refs.confirm.open("Update SIM?", ``, "warning", "mdi-sim-outline");

			if (res === true) {
				try {
					await this.$emnifyApi.patch(this.device.endpointID, {
						name: this.device._id,
						tags: this.device.siteID.name,
					});

					const message = "SIM Updated";
					this.$toast.success(message);

					this.getEndpoint();

					this.loading = false;
				} catch (err) {
					const message = err;
					this.$toast.error(message);

					this.loading = false;
				}
			} else {
				this.loading = false;
			}
		},

		async resetEndpoint() {
			this.loading = true;

			const delay = (time) => new Promise((res) => setTimeout(res, time));

			try {
				await this.$emnifyApi.patch(this.device.endpointID, {
					status: {
						id: 1,
					},
				});

				await delay(2000);

				await this.$emnifyApi.patch(this.device.endpointID, {
					status: {
						id: 0,
					},
				});

				await delay(2000);

				await this.$emnifyApi.patch(`/sim/${this.endpoint.sim.id}`, {
					status: {
						id: 2,
					},
				});

				await delay(2000);

				await this.$emnifyApi.patch(`/sim/${this.endpoint.sim.id}`, {
					status: {
						id: 1,
					},
				});

				await delay(2000);

				this.loading = false;

				const message = "SIM Reset";
				this.$toast.success(message);
			} catch (err) {
				const message = err;
				this.$toast.error(message);

				this.loading = false;
			}
		},
	},
};
</script>
