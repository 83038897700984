import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  options: {
    customProperties: true,
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#406dfa",
        secondary: "#3145d2",
        accent: "#283555",
        error: "#f99697",
        danger: "#ff6b6b",
        warning: "#e9c46a",
        success: "#2a9d8f",
        grey: "#f5f7fe",
      },
    },
  },
});
