import * as firmwareService from "@/services/admin/firmware.service";

export default {
	state: {
		firmware: [],
	},

	mutations: {
		setFirmware(state, firmware) {
			state.firmware = firmware;
		},
	},

	actions: {
		async uploadFirmware({ dispatch }, file) {
			await firmwareService.uploadFirmware(file);
			await dispatch("listFirmware");
		},

		async listFirmware({ commit }) {
			const firmware = await firmwareService.listFirmware();
			commit("setFirmware", firmware);
		},

		async deleteFirmware({ dispatch }, id) {
			await firmwareService.deleteFirmware(id);
			await dispatch("listFirmware");
		},
	},
};
