<template>
	<v-container fluid v-resize="onResize">
		<v-row>
			<v-col cols="12" class="px-0">
				<v-data-table
					class="admin-fleet-table"
					:headers="headers"
					:items="users"
					:search="search"
					item-key="_id"
					sort-by="_id"
					:mobile-breakpoint="0"
					:height="$vuetify.breakpoint.xs ? windowSize.y - 240 : windowSize.y - 200"
					fixed-header
					:page.sync="page"
					:header-props="{
						'sort-icon': 'mdi-menu-down',
					}"
					:items-per-page="itemsPerPage.val"
					hide-default-footer
					@page-count="pageCount = $event"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title class="accent--text">
								Users
								<v-chip label class="primary font-weight-bold">
									{{ users.length }}
								</v-chip>
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn small class="primary mr-2 padlessBtn" depressed :loading="refreshLoading" @click="refresh">
								<v-icon small>mdi-refresh</v-icon>
							</v-btn>
							<v-text-field v-model="search" append-icon="mdi-magnify" label="Search Users" single-line outlined dense hide-details style="max-width: 300px"></v-text-field>
						</v-toolbar>
					</template>
					<template v-slot:item.email="{ item }">
						<span class="font-weight-bold accent--text">
							{{ item.email }}
						</span>
					</template>
					<template v-slot:item._id="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip color="accent lighten-5" dark small label>
										<span class="d-inline-block text-truncate font-weight-bold" style="max-width: 50px">
											{{ item._id }}
										</span>
									</v-chip></span
								>
							</template>
							<span class="caption">{{ item._id }}</span>
						</v-tooltip>
					</template>
					<template v-slot:item.status="{ item }">
						<span class="font-weight-bold">
							<v-chip label small class="font-weight-bold white--text" :color="item.online ? 'green lighten-2' : 'primary lighten-2'">
								{{ item.lastSeen ? translatedFormattedDate(item.lastSeen, "dd/MM/yy - HH:mm") : "Unknown" }}
							</v-chip>
						</span>
					</template>

					<template v-slot:item.sites="{ item }">
						<div v-if="item.role === 'superadmin'">
							<v-chip small label class="mr-2 font-weight-bold" color="success"> All Sites </v-chip>
						</div>
						<div v-else>
							<template v-if="item.sites.length <= 2">
								<v-chip v-for="site in item.sites" :key="site" small label class="mr-2 font-weight-bold" color="accent">
									{{ computedSite(site) }}
								</v-chip>
							</template>
							<template v-else>
								<v-chip @click="showUsersSites(item)" small class="mr-2 font-weight-bold elevation-4" label color="primary" dark depressed>
									{{ item.sites.length }} sites
									<v-icon right small>mdi-open-in-new</v-icon>
								</v-chip>
							</template>
						</div>
					</template>

					<template v-slot:item.role="{ item }">
						<v-chip label small class="font-weight-bold" :color="computedRole(item.role)">
							{{ item.role }}
						</v-chip>
					</template>
					<template v-slot:item.edit="{ item }">
						<div class="no-wrap">
							<div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn small class="mr-2 padlessBtn" color="primary" dark depressed v-bind="attrs" v-on="on" @click="sendResetPasswordLink(item.email)">
											<v-icon small>mdi-undo</v-icon>
										</v-btn>
									</template>
									<span>Send reset password link</span>
								</v-tooltip>

								<edit-users-modal :user="item"></edit-users-modal>
							</div>
						</div>
					</template>
				</v-data-table>

				<confirm ref="confirm" />
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%" :style="$vuetify.breakpoint.xs ? `bottom: 56px;` : 'bottom: 0;'">
			<v-toolbar flat dense class="mx-3 rounded-t-lg">
				<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

				<v-select
					class="perPage"
					v-model="itemsPerPage"
					:items="items"
					item-text="text"
					item-value="val"
					label="Select"
					hide-details
					return-object
					single-line
					dense
					outlined
				></v-select>
			</v-toolbar>
		</v-row>

		<v-dialog v-model="showSites" max-width="500" @click:outside="hideUsersSites">
			<v-card>
				<v-card-title>{{ selectedUser.email }} Sites</v-card-title>
				<v-card-text>
					<v-list>
						<v-list-item v-for="site in selectedUser.sites" :key="site._id">
							<v-list-item-title>{{ computedSite(site) }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" @click="hideUsersSites">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import EditUsersModal from "@/components/admin/users/EditUsersModal.vue";
import Confirm from "@/components/Confirm";

export default {
	components: {
		EditUsersModal,
		Confirm,
	},
	data() {
		return {
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
			page: 1,
			pageCount: 0,
			windowSize: {
				x: 0,
				y: 0,
			},
			search: "",
			tooltip: false,
			headers: [
				{ text: "Email", value: "email" },
				{ text: "User ID", value: "_id" },
				{ text: "Last Seen", value: "status" },
				{ text: "Sites", value: "sites" },
				{ text: "Role", value: "role" },
				{
					text: "Actions",
					value: "edit",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
			],
			refreshLoading: false,
			showSites: false,
			selectedUser: {},
		};
	},
	mounted() {
		this.refresh();

		this.$store.dispatch("admin/getUsers");
	},
	watch: {
		page() {
			this.selected = [];
		},

		itemsPerPage(newVal) {
			localStorage.carsPerPage = JSON.stringify(newVal);
		},
	},
	computed: {
		...mapState({
			users: (state) => state.admin.users.users,
			sites: (state) => state.sites.sites,
		}),
	},
	methods: {
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		computedSite(id) {
			const sites = this.sites.filter((obj) => obj._id === id)[0];
			if (sites) {
				return sites.name;
			}
		},

		showUsersSites(user) {
			this.selectedUser = user;
			this.showSites = true;
		},

		hideUsersSites() {
			this.selectedUser = {};
			this.showSites = false;
		},

		computedRole(role) {
			if (role === "superadmin") {
				return "success darken-3";
			} else if (role === "admin") {
				return "success darken-2";
			} else if (role === "service") {
				return "success darken-1";
			} else if (role === "master") {
				return "warning darken-1";
			} else if (role === "pro") {
				return "info";
			} else if (role === "user") {
				return "info lighten-2";
			}
		},

		async refresh() {
			this.refreshLoading = true;

			try {
				this.$store.dispatch("admin/getUsers");

				this.refreshLoading = false;
			} catch (err) {
				const message = err;
				this.$toast.error(message);
			}
		},

		async sendResetPasswordLink(email) {
			this.btnLoading = true;

			const res = await this.$refs.confirm.open(`Reset password?`, "Send password reset link?", "warning", "mdi-at");

			if (res === true) {
				try {
					const settings = {
						email: this.formatEmail(email),
					};

					await this.$ioteeApi.post(`/reset/requestResetPassword`, settings);

					const message = this.$i18n.t("loginPage.resetlinkSent");
					this.$toast.success(message);

					this.btnLoading = false;
				} catch (err) {
					const message = err.response.data.message;
					this.$toast.error(message);

					this.btnLoading = false;
				}
			}
		},
	},
};
</script>
