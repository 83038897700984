import Vue from "vue";
import axios from "axios";

const emnifyApi = axios.create({
	baseURL: "https://cdn.emnify.net/api/v1",
	timeout: 8000,
	withCredentials: false,
});

const ioteeDashboardApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_DASHBOARD_API,
	timeout: 15000,
	withCredentials: false,
});

const ioteeAdminApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_ADMIN_API,
	timeout: 15000,
	withCredentials: false,
});

const ioteeGuestApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_GUEST_API,
	timeout: 15000,
	withCredentials: false,
	headers: {
		common: {
			"x-api-key": process.env.VUE_APP_MAIN_KEY,
		},
	},
});

const ioteeStorageApi = axios.create({
	baseURL: process.env.VUE_APP_BASE_STORAGE_API,
	timeout: 15000,
	withCredentials: false,
	headers: {
		common: {
			"main-key": process.env.VUE_APP_MAIN_KEY,
		},
	},
});

Vue.prototype.$emnifyApi = emnifyApi;
Vue.prototype.$ioteeDashboardApi = ioteeDashboardApi;
Vue.prototype.$ioteeAdminApi = ioteeAdminApi;
Vue.prototype.$ioteeGuestApi = ioteeGuestApi;
Vue.prototype.$ioteeStorageApi = ioteeStorageApi;

export const setApiHeaders = ({ token, siteId }) => {
	if (token) {
		ioteeDashboardApi.defaults.headers.common["Authorization"] = "Bearer " + token;
		ioteeAdminApi.defaults.headers.common["Authorization"] = "Bearer " + token;
	}

	if (siteId) {
		ioteeDashboardApi.defaults.headers.common["site-id"] = siteId;
	}
};

export default axios;
