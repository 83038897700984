import Vue from "vue";
import countriesArray from "./Countries";

Vue.mixin({
	methods: {
		currenciesList() {
			const currencies = countriesArray.map((country) => {
				return country.currency;
			});

			return currencies;
		},

		currencyDisplay(curr) {
			const symbol = this.currenciesList().find((o) => o.code === curr.toUpperCase());

			return symbol.symbol;
		},

		formattedPrice(price, curr) {
			return `${this.currencyDisplay(curr)} ${(price / 100).toFixed(2)}`;
		},

		getMinimumPrice(currency) {
			switch (currency) {
				case "EUR":
					return 5.0;
				case "GBP":
					return 5.0;
				case "USD":
					return 6.0;
				case "DKK":
					return 38;
				case "CZK":
					return 125.0;
				// Add other currencies as needed
				default:
					return 5.0;
			}
		},
	},
});
