import Vue from "vue";

export const createZone = async (zone) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/zones", zone);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getSiteZones = async () => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/zones`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateZone = async (zone) => {
	try {
		return await Vue.prototype.$ioteeDashboardApi.patch(`/zones/${zone._id}`, zone);
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteZone = async (zoneId) => {
	try {
		return await Vue.prototype.$ioteeDashboardApi.delete(`/zones/${zoneId}`);
	} catch (err) {
		throw err.response.data;
	}
};

export const publishZones = async () => {
	try {
		return await Vue.prototype.$ioteeDashboardApi.post(`/zones/publish`);
	} catch (err) {
		throw err.response.data;
	}
};
