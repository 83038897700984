import Vue from "vue";
import * as bookingsService from "@/services/bookings.service";
import { startOfDay, endOfDay } from "date-fns";

export default {
	state: {
		bookings: [],
		calendarBookings: [],
		date: {
			start: startOfDay(new Date()),
			end: endOfDay(new Date()),
		},
	},

	mutations: {
		setDate(state, newDate) {
			state.date.start = startOfDay(new Date(newDate));
			state.date.end = endOfDay(new Date(newDate));
		},

		setBookings(state, payload) {
			const bookingsByDevice = payload.reduce((acc, curr) => {
				if (curr.device) {
					if (!acc[curr.device]) {
						acc[curr.device] = [];
					}
					acc[curr.device].push(curr);
				}
				return acc;
			}, {});

			state.bookings = bookingsByDevice;
		},

		// Set ALL Sites
		setCalendarBookings(state, payload) {
			state.calendarBookings = payload;
		},

		SOCKET_BOOKING_UPDATE(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_BOOKING_UPDATE", payload);
			}

			const device = payload.device;
			const oldDevice = payload.fullDocumentBeforeChange?.device;

			if (!state.bookings[device]) {
				Vue.set(state.bookings, device, []);
			}

			if (oldDevice && !state.bookings[oldDevice]) {
				Vue.set(state.bookings, oldDevice, []);
			}

			switch (payload.operationType) {
				case "delete": {
					const indexToDelete = state.bookings[device].findIndex((update) => update._id === payload._id);
					if (indexToDelete !== -1) {
						state.bookings[device].splice(indexToDelete, 1);
					}
					break;
				}
				case "insert": {
					state.bookings[device].push(payload);
					break;
				}
				case "update": {
					if (oldDevice && oldDevice !== device) {
						// Remove from old device
						const oldDeviceIndex = state.bookings[oldDevice].findIndex((update) => update._id === payload._id);
						if (oldDeviceIndex !== -1) {
							state.bookings[oldDevice].splice(oldDeviceIndex, 1);
						}
					}

					// Check if status changed to "cancelled"
					if (payload.status === "cancelled") {
						const indexToCancel = state.bookings[device].findIndex((update) => update._id === payload._id);
						if (indexToCancel !== -1) {
							state.bookings[device].splice(indexToCancel, 1);
						}
					} else {
						// Add or update the booking for the new device
						const indexToUpdate = state.bookings[device].findIndex((update) => update._id === payload._id);
						if (indexToUpdate !== -1) {
							Vue.set(state.bookings[device], indexToUpdate, payload);
						} else {
							state.bookings[device].push(payload);
						}
					}
					break;
				}
			}
		},
	},

	actions: {
		async getBookings({ commit }, query) {
			query = { status: { $in: ["confirmed", "payment_pending"] }, ...query };
			const { data: bookings } = await bookingsService.getBookings(query);
			commit("setBookings", bookings);
		},

		async getBooking(_, bookingId) {
			const booking = await bookingsService.getBooking(bookingId);
			return booking;
		},

		async getCalendarBookings({ commit, state }) {
			const query = { start: state.date.start, end: state.date.end, status: { $in: ["confirmed", "payment_pending"] } };
			const { data: bookings } = await bookingsService.getBookings(query);
			commit("setCalendarBookings", bookings);
		},

		async getAvailableDevices(_, query) {
			return await bookingsService.getAvailableDevices(query);
		},

		async getAvailableSlots(_, query) {
			return await bookingsService.getAvailableSlots(query);
		},

		async createBooking({ dispatch }, booking) {
			await bookingsService.createBooking(booking);
			await dispatch("getCalendarBookings");
		},

		async updateBooking({ dispatch }, booking) {
			await bookingsService.updateBooking(booking);
			await dispatch("getCalendarBookings");
		},

		async unlockWithBooking(_, bookingId) {
			await bookingsService.unlockWithBooking(bookingId);
		},

		async deleteBooking({ dispatch }, bookingId) {
			await bookingsService.deleteBooking(bookingId);
			await dispatch("getCalendarBookings");
		},
	},
	getters: {
		futureAndCurrentBookings(state) {
			const now = new Date();

			return Object.entries(state.bookings).reduce((acc, [device, bookings]) => {
				const activeDeviceBookings = bookings
					.map((booking) => {
						const bookingStart = new Date(booking.start);
						const bookingEnd = new Date(booking.end);
						const isBookingNow = bookingStart <= now && now <= bookingEnd;

						return {
							...booking,
							isBookingNow,
						};
					})
					.filter((booking) => {
						const bookingEnd = new Date(booking.end);
						return bookingEnd >= now;
					});

				if (activeDeviceBookings.length) {
					acc[device] = activeDeviceBookings;
				}

				return acc;
			}, {});
		},

		currentBookings(state) {
			const now = new Date(); // Get current date and time once

			return Object.entries(state.bookings).reduce((acc, [device, bookings]) => {
				const activeDeviceBookings = bookings
					.map((booking) => {
						const bookingStart = new Date(booking.start);
						const bookingEnd = new Date(booking.end);

						// Determine if the booking is currently active
						const isBookingNow = bookingStart <= now && now <= bookingEnd;

						return {
							...booking, // Spread the original booking
							isBookingNow, // Add or overwrite isBookingNow
						};
					})
					.filter((booking) => booking.isBookingNow); // Keep only bookings that are currently active

				// Add the filtered bookings to the accumulator if there are any
				if (activeDeviceBookings.length) {
					acc[device] = activeDeviceBookings;
				}

				return acc;
			}, {});
		},

		formattedCalendarBookings(state) {
			return state.calendarBookings.map((booking) => {
				const isWithinCurrentPeriod = (booking) => {
					const now = new Date(); // Get current date and time
					const bookingStart = new Date(booking.start); // Convert booking start time to a Date object
					const bookingEnd = new Date(booking.end); // Convert booking end time to a Date object

					return bookingStart <= now && now <= bookingEnd;
				};

				return {
					_id: booking._id,
					category: booking.device,
					start: new Date(booking.start),
					end: new Date(booking.end),
					timed: true,
					booking,
					color: booking.bookingType === "cart_maintenance" ? "error" : isWithinCurrentPeriod(booking) ? "primary" : "primary lighten-4",
					isNow: isWithinCurrentPeriod(booking),
				};
			});
		},
	},
};
