<template>
	<v-container fluid>
		<v-row class="mt-1">
			<v-toolbar flat dense class="mb-4">
				<v-form ref="form" v-model="form" lazy-validation>
					<v-row>
						<v-select
							v-model="selectedDevice"
							:items="cars"
							item-text="carNumber"
							style="max-width: 100px"
							color="accent"
							item-value="_id"
							label="Car"
							outlined
							hide-details
							dense
							single-line
							return-object
							:rules="[rules.required]"
							@change="getActivity"
						></v-select>
						<v-menu v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-btn label="Date" outlined color="accent" class="ml-4" v-bind="attrs" v-on="on">
									<v-icon small left>mdi-calendar-month-outline</v-icon>
									{{ computedStartDate }}
								</v-btn>
							</template>
							<v-date-picker no-title color="primary" v-model="startDate" @input="startDateMenu = false"></v-date-picker>
						</v-menu>
						<v-menu v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-btn label="Date" outlined color="accent" class="ml-4" v-bind="attrs" v-on="on">
									<v-icon small left>mdi-calendar-month-outline</v-icon>
									{{ computedEndDate }}
								</v-btn>
							</template>
							<v-date-picker no-title color="primary" v-model="endDate" @input="endDateMenu = false"></v-date-picker>
						</v-menu>
						<v-btn @click="getActivity" :loading="searchLoading" class="ml-3" color="primary">
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-row>
				</v-form>
				<v-spacer></v-spacer>
				<v-btn v-if="activities.length" color="error" small class="mr-3" @click="clearActivity">
					<v-icon left>mdi-console</v-icon>
					Clear Log - Car {{ selectedDevice.carNumber }}
				</v-btn>
				<v-switch inset class="mt-5 ml-3" color="accent" v-model="dark" label="Light/Dark"></v-switch>
			</v-toolbar>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-list :color="dark ? 'accent' : 'grey'" dense style="overflow-y: hidden; oveflow-x: scroll">
					<v-virtual-scroll :bench="benched" :items="activities" height="400" item-height="26" :class="dark && 'white--text'" style="min-width: 800px; display: inline-block">
						<template v-slot:default="{ item }">
							<v-list-item :key="item._id" :class="dark && 'white--text'" dense>
								<v-list-item-action :class="dark && 'white--text'">
									<span class="caption">{{ computedDate(item.date) }}</span>
								</v-list-item-action>
								<v-list-item-action>
									<v-chip label x-small class="font-weight-bold mr-4" :color="dark ? 'primary' : 'accent'"> Car {{ item.device.carNumber }} </v-chip>
								</v-list-item-action>

								<v-list-item-content :class="dark && 'white--text'">
									<v-list-item-title class="font-weight-regular">
										<span> MSG: {{ item.message }} ||</span>
										<span> CSQ: {{ item.data ? item.data.csq : "" }} ||</span>
										<span> RSSI: {{ item.data ? item.data.wifiSignal : "" }} || </span>
										<span> Con Type: {{ item.data ? item.data.conType : "" }} </span>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-virtual-scroll>
				</v-list>
			</v-col>
		</v-row>
		<confirm ref="confirm" />
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import Confirm from "@/components/Confirm";

import { format, parseISO } from "date-fns";

export default {
	components: {
		Confirm,
	},
	data() {
		return {
			startDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
			endDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
			search: "",
			selected: [],
			headers: [
				{ text: "Message", value: "message" },
				{ text: "Car Number", value: "carNumber" },
				{ text: "Registered Site", value: "site" },
				{ text: "Date", value: "date" },
			],
			activities: [],
			rules: {
				required: (value) => !!value || "Required.",
			},
			form: false,
			selectedDevice: null,
			searchLoading: false,
			startDateMenu: false,
			endDateMenu: false,
			dark: false,
			benched: 0,
		};
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			cars: (state) => state.devices.devices,
		}),

		computedStartDate() {
			return this.startDate ? format(parseISO(this.startDate), "d/MM/yy") : "";
		},

		computedEndDate() {
			return this.endDate ? format(parseISO(this.endDate), "d/MM/yy") : "";
		},
	},
	mounted() {
		this.getDevices();
	},
	methods: {
		resetSelected(val) {
			this.selected = val;
		},

		async getDevices() {
			this.$store.dispatch("getDevices", this.selectedSite._id);
		},

		async getActivity() {
			const activities = await this.$ioteeApi.get(`/activity`, {
				params: {
					siteID: this.selectedSite._id,
					deviceID: this.selectedDevice._id,
					startDate: this.startDate,
					endDate: this.endDate,
				},
			});

			activities.data.sort(function (a, b) {
				return new Date(b.date) - new Date(a.date);
			});

			this.activities = activities.data;
		},

		async clearActivity() {
			const res = await this.$refs.confirm.open("Delete Activity?", `Are you sure you want to delete the activity for this car?`, "error", "mdi-delete-alert");

			if (res === true) {
				try {
					await this.$ioteeApi.delete(`/activity`, {
						data: {
							deviceID: this.selectedDevice._id,
						},
					});

					this.activities = [];
				} catch (err) {
					console.log(err);
				}
			}
		},

		computedDate(date) {
			return format(new Date(date), "HH:mm:ss - dd/MM/yyyy");
		},
	},
};
</script>
