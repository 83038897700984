<template>
	<div>
		<apexchart type="radialBar" height="200" :options="chartOptions" :series="series"></apexchart>
	</div>
</template>

<script>
export default {
	props: {
		devices: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		series() {
			return [this.lockedDevicesPercent, this.unlockedDevicesPercent];
		},

		chartOptions() {
			return {
				chart: {
					height: 200,
					type: "radialBar",
				},

				labels: ["Locked", "Unlocked"],
				colors: ["#283555", "#299D8F"],
				stroke: {
					lineCap: "round",
				},
				plotOptions: {
					radialBar: {
						dataLabels: {
							name: {
								fontSize: "22px",
							},
							value: {
								fontSize: "16px",
							},
							total: {
								show: true,
								label: "Locked",
								formatter: () => {
									// By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
									return `${this.lockedDevices.length}/ ${this.devices.length}`;
								},
							},
						},
					},
				},
			};
		},

		lockedDevices() {
			return this.devices.filter((device) => device.lockStatus);
		},

		unlockedDevices() {
			return this.devices.filter((device) => !device.lockStatus);
		},

		lockedDevicesPercent() {
			return ((this.lockedDevices.length / this.devices.length) * 100).toFixed(0);
		},

		unlockedDevicesPercent() {
			return ((this.unlockedDevices.length / this.devices.length) * 100).toFixed(0);
		},
	},
};
</script>
