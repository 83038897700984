import Vue from "vue";
import * as sitesService from "@/services/admin/sites.service";

export default {
	namespaced: true,
	state: {
		sites: [],
		site: null,
		loading: false,
	},

	mutations: {
		setSites(state, payload) {
			state.sites = payload;
		},

		setLoading(state, loading) {
			state.loading = loading;
		},

		setSite(state, site) {
			state.site = site;
		},
	},
	actions: {
		async getSites({ commit }) {
			try {
				commit("setLoading", true); // Set loading to true

				const { data } = await sitesService.getSites();

				commit("setSites", data);
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", false); // Set loading to false
			}
		},

		async updateSite({ dispatch }, payload) {
			await sitesService.updateSite(payload);
			await dispatch("getSites");
		},

		async createSite({ dispatch }, payload) {
			await sitesService.createSite(payload);
			await dispatch("getSites");
		},

		async deleteSite({ dispatch }, payload) {
			await sitesService.deleteSite(payload);
			await dispatch("getSites");
		},
	},
};
