import Vue from "vue";

export const findCode = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/codes/find`, {
			params: payload,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const createCode = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/codes`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateCode = async (id, payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/codes/${id}`, payload);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteCode = async (id) => {
	try {
		await Vue.prototype.$ioteeDashboardApi.delete(`/codes/${id}`);
	} catch (err) {
		throw err.response.data;
	}
};

export const sendCodeEmail = async (id) => {
	try {
		await Vue.prototype.$ioteeDashboardApi.post(`/codes/${id}/email`);
	} catch (err) {
		throw err.response.data;
	}
};

export const publishSiteCodes = async () => {
	try {
		return await Vue.prototype.$ioteeDashboardApi.post(`/codes/publish`);
	} catch (err) {
		throw err.response.data;
	}
};
