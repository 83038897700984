<template>
	<v-dialog v-model="dialog" fullscreen lazy>
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" small dark v-bind="attrs" v-on="on">
				Sites Map
				<v-icon right small>mdi-map-marker-circle</v-icon>
			</v-btn>
		</template>

		<v-card>
			<l-map
				v-if="mapReady === true"
				:zoom="zoom"
				:center="center"
				ref="map"
				style="height: 100vh"
			>
				<l-tile-layer :url="url" :options="{ maxZoom: 19 }" />

				<l-layer-group v-if="sites">
					<l-marker
						v-for="site in sites"
						:key="site._id"
						:lat-lng="latLong(site.centerLatLong)"
						:options="{ riseOnHover: true }"
					>
						<l-popup>
							<p class="ma-0 font-weight-bold">
								{{ site.name }}
							</p>
							<p class="ma-0">
								{{ site.country }}
							</p>
						</l-popup>
						<l-icon>
							<v-avatar style="margin-left: -12px" size="35" color="primary">
								<v-icon color="white">mdi-golf-tee</v-icon>
							</v-avatar>
						</l-icon>
					</l-marker>
				</l-layer-group>

				<!-- Recenter Button -->
				<l-control style="margin-top: 12px" position="topright">
					<v-btn
						small
						@click="dialog = false"
						class="mapBtn mb-4"
						color="primary"
					>
						<v-icon color="white" small>mdi-close-circle</v-icon>
					</v-btn>
					<br />
					<v-btn small @click="recenterMap()" class="mapBtn" color="accent">
						<v-icon color="primary" small>mdi-navigation</v-icon>
					</v-btn>
				</l-control>
				<!-- Toggle Map Layer Button -->
				<l-control
					position="topright"
					style="margin-left: 8px; margin-top: 8px"
				>
					<v-btn
						small
						@click="tile = !tile"
						:color="tile ? 'accent' : 'white'"
						class="primary--text padlessBtn"
						:class="tile ? 'primary--text' : 'accent--text'"
					>
						<v-icon small>mdi-map</v-icon>
					</v-btn>
				</l-control>
			</l-map>
		</v-card>
	</v-dialog>
</template>

<script>
// eslint-disable-next-line
import L from "leaflet";
// eslint-disable-next-line
import LDraw from "leaflet-draw";
import "@geoman-io/leaflet-geoman-free";
import {
	LMap,
	LIcon,
	LPopup,
	LTileLayer,
	LLayerGroup,
	LControl,
	LMarker,
} from "vue2-leaflet";
import { latLng } from "leaflet";

export default {
	name: "Map",
	components: {
		LMap,
		LIcon,
		LPopup,
		LTileLayer,
		LControl,
		LMarker,
		LLayerGroup,
	},
	props: {
		sites: {
			default: [],
		},
	},
	data() {
		return {
			dialog: false,
			zoom: 3,
			tile: false,
			map: null,
			mapReady: false,
			center: [47.31322, -1.319482],
		};
	},
	watch: {
		dialog(newVal) {
			setTimeout(() => {
				if (newVal === true) this.mapReady = true;
			}, 10);
		},
	},
	computed: {
		url() {
			if (this.tile === false) {
				return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
			} else {
				return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
			}
		},
	},
	methods: {
		latLong(coordinates) {
			const [lat, long] = coordinates.split(",");

			return latLng(lat, long);
		},

		recenterMap() {
			this.$refs.map.mapObject.invalidateSize();
			this.$refs.map.mapObject.setView(this.center, this.zoom);
		},
	},
};
</script>

<style>
.mapBtn {
	padding-left: 9px !important;
	padding-right: 9px !important;
	min-width: 0 !important;
}
</style>
