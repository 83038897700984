<template>
	<v-card class="rounded-lg" outlined color="white" style="border: 2px dashed #f28478 !important; display: flex; justify-content: center; align-items: center; height: 300px">
		<div class="text-center">
			<div class="mb-2 title font-weight-bold">
				<v-icon left color="accent" large>mdi-credit-card</v-icon>
				{{ $t("pricing.activateTitle") }}
			</div>
			<div class="my-6 px-6 subtitle-2">
				{{ $t("pricing.activateText") }}
			</div>

			<v-btn color="primary" class="text-capitalize" dark @click="onboardStripeConnectAccount" :loading="loading">
				<v-icon left>mdi-qrcode-plus</v-icon>
				{{ $t("pricing.activateBtn") }}
			</v-btn>

			<v-btn :href="helpURL" target="blank" color="primary" depressed class="padlessBtn ml-4">
				<v-icon color="white">mdi-help-box</v-icon>
			</v-btn>
		</div>
	</v-card>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
		};
	},

	computed: {
		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/article/aktivierung-von-stripe-fur-buchungszahlungen-1tvt4b5/";

				default:
					return "https://help.iotee.eu/en/article/activating-stripe-for-booking-payments-1p6urph/";
			}
		},
	},
	methods: {
		async onboardStripeConnectAccount() {
			this.loading = true;
			try {
				const { url } = await this.$store.dispatch("onboardStripeConnectAccount");

				if (url) {
					window.location = url;
				}
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
