<template>
	<div>
		<selection-card icon="mdi-calendar-star" color="primary" :title="$t('s2r.booked.title')" :subtitle="$t('s2r.booked.subtitle')" class="mb-6">
			<bottom-sheet
				type="booking"
				:btnTitle="$t('s2r.booked.btn')"
				btnColor="primary"
				:title="$t('s2r.booked.enter')"
				:subtitle="$t('s2r.booked.enterSub')"
				@code-entered="getBookingId"
			/>
		</selection-card>

		<available-carts :siteId="site.id" />

		<bottom-sheet class="mt-6" :btnTitle="$t('s2r.master.title')" btnColor="accent lighten-3" :title="$t('s2r.master.subtitle')" @code-entered="goToVirtualKey" />
	</div>
</template>

<script>
import AvailableCarts from "@/components/scanToRide/AvailableCarts.vue";
import SelectionCard from "@/components/scanToRide/SelectionCard.vue";
import BottomSheet from "@/components/scanToRide/BottomSheet";

export default {
	props: ["site"],
	components: {
		BottomSheet,
		SelectionCard,
		AvailableCarts,
	},
	data() {
		return {
			sheet: false,
			loading: false,
		};
	},
	mounted() {
		const reservationCode = this.$route.query.reservationCode;

		if (reservationCode) {
			this.getBookingId(reservationCode);
		}
	},
	methods: {
		async getBookingId(code) {
			this.loading = true;
			try {
				const { data } = await this.$ioteeApiV2.get(`/bookings/get-booking-by-code`, {
					params: {
						code,
						deviceId: this.$route.params.id,
					},
				});

				if (!data) {
					const message = this.$t("s2r.booked.notFound.title");
					this.$toast.error(message);
				} else {
					this.$router.push({
						name: "RideBooking",
						params: {
							id: this.$route.params.id,
							bookingId: data.id,
						},
						query: {
							reservationCode: code,
						},
					});
				}
			} catch (err) {
				console.log(err);
			} finally {
				this.loading = false;
			}
		},

		goToVirtualKey(code) {
			this.$router.push({
				name: "RideVirtualKey",
				params: {
					id: this.$route.params.id,
				},
				query: {
					siteId: this.site.id,
					virtualKey: code,
				},
			});
		},
	},
};
</script>
