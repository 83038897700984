import Vue from "vue";

export default {
	handleGetSiteDevices(payload) {
		return Vue.prototype.$ioteeApiV2.get(`/sites/${payload}/devices`);
	},

	handleGetDeviceUpdates(payload) {
		return Vue.prototype.$ioteeApiV2.get(`/sites/${payload}/device-updates`);
	},

	async handleUpdateCars(payload) {
		try {
			const result = await Vue.prototype.$ioteeApi.patch(`/devices`, payload);

			Vue.$toast.success("Device Updated!");

			return result;
		} catch (err) {
			Vue.$toast.error("Error upating devices");

			return err;
		}
	},

	async handleDeleteCar(payload) {
		try {
			const result = await Vue.prototype.$ioteeApi.delete(
				`/devices/${payload}`,
				payload
			);

			Vue.$toast.success("Device deleted!");

			return result;
		} catch (err) {
			Vue.$toast.error("Error deleting device");

			console.error(err);
		}
	},

	async handleDeleteCars(payload) {
		try {
			const result = await Vue.prototype.$ioteeApi.delete(`/devices`, {
				data: payload,
			});

			Vue.$toast.success("Devices deleted!");
			return result;
		} catch (err) {
			Vue.$toast.error("Error deleting devices");

			console.error(err);
		}
	},

	handlePingDevice(payload) {
		return Vue.prototype.$ioteeApi.post(`/devices/ping`, payload);
	},

	async handleUpdateCarsMqtt(payload) {
		try {
			const result = await Vue.prototype.$ioteeApi.patch(
				`/devices/mqtt`,
				payload
			);

			Vue.$toast.success("Device Updated!");
			return result;
		} catch (err) {
			Vue.$toast.error("Error upating device");

			return err;
		}
	},

	async handleCalibrateBattery(payload) {
		try {
			const result = await Vue.prototype.$ioteeApi.patch(
				`/devices/calibrate-battery`,
				payload
			);

			Vue.$toast.success("Battery calibrated");
			return result;
		} catch (err) {
			Vue.$toast.error("Error upating device");

			return err;
		}
	},

	async unlockDevice(payload) {
		return await Vue.prototype.$ioteeApiV2.post(
			`/devices/${payload.deviceId}/unlock`,
			payload
		);
	},

	async lockDevice(deviceId) {
		return await Vue.prototype.$ioteeApiV2.post(`/devices/${deviceId}/lock`);
	},

	async unlockWithBooking(bookingId, code) {
		return await Vue.prototype.$ioteeApiV2.post(
			`/bookings/${bookingId}/unlock`,
			{
				code,
			}
		);
	},
};
