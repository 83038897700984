import Vue from "vue";
import * as paymentsService from "@/services/payments.service";

export default {
	state: {
		payments: [],
		currentPage: 1,
		totalItems: 0,
		loading: false, // Added loading state
		itemsPerPage: 25,
	},
	mutations: {
		setPayments(state, payments) {
			state.payments = payments;
		},

		setTotalItems(state, totalItems) {
			state.totalItems = totalItems;
		},

		setItemsPerPage(state, itemsPerPage) {
			state.itemsPerPage = itemsPerPage;
		},

		setCurrentPage(state, page) {
			state.currentPage = page;
		},

		setLoading(state, loading) {
			state.loading = loading;
		},
	},
	actions: {
		async fetchPayments({ commit, state }, queryParams = {}) {
			try {
				commit("setLoading", true); // Set loading to true

				const defaultQuery = {
					page: state.currentPage,
					limit: state.itemsPerPage,
					sort: "createdAt:desc",
				};

				const query = { ...defaultQuery, ...queryParams };

				const { data, pagination } = await paymentsService.getPayments(query);

				commit("setPayments", data);
				commit("setTotalItems", pagination.totalItems);
			} catch (err) {
				Vue.$toast.error(err.error.message);
			} finally {
				commit("setLoading", false); // Set loading to false
			}
		},

		async fetchStripeAccountBalance() {
			const data = await paymentsService.getStripeAccountBalance();

			return data;
		},

		setCurrentPage({ commit, dispatch }, { page, queryParams }) {
			commit("setCurrentPage", page);
			dispatch("fetchPayments", queryParams);
		},

		setItemsPerPage({ commit, dispatch }, { itemsPerPage, queryParams }) {
			console.log(itemsPerPage);
			commit("setItemsPerPage", itemsPerPage);
			dispatch("fetchPayments", queryParams);
		},
	},
};
