<template>
	<v-card class="rounded-lg" outlined>
		<v-list-item>
			<v-list-item-action>
				<v-img max-width="105" max-height="105" :src="site.publicProfile.logoUrl" alt="Logo" />
			</v-list-item-action>
			<v-list-item-content>
				<v-list-item-title class="font-weight-bold title accent--text">
					{{ site.publicProfile.name }}
				</v-list-item-title>
				<!-- <v-list-item-subtitle class="accent--text">
					{{ site.publicProfile.name }}
				</v-list-item-subtitle> -->
			</v-list-item-content>
			<v-list-item-action v-if="!isHomePage">
				<v-btn text @click="returnHome">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
			</v-list-item-action>
		</v-list-item>
	</v-card>
</template>

<script>
export default {
	props: {
		site: {
			type: Object,
			required: true,
		},
	},
	computed: {
		isHomePage() {
			const homePath = `/guest/site/${this.$route.params.id}`;
			return this.$route.path === homePath;
		},
	},
	methods: {
		returnHome() {
			if (!this.isHomePage) {
				this.$router.push({
					path: `/guest/site/${this.$route.params.id}`,
				});
			}
		},
	},
};
</script>
