<template>
	<div>
		<v-row no-gutters class="mb-4 px-4">
			<h2>{{ $t("settingsPage.generalSettings.title") }}</h2>
			<v-spacer></v-spacer>
			<v-chip label color="primary" class="padlessBtn elevation-8">
				<v-icon>mdi-star-outline</v-icon>
			</v-chip>
		</v-row>
		<v-row no-gutters v-if="defaultItem" class="mb-4">
			<v-col cols="12" class="mb-4">
				<v-card transition="scroll-y-reverse-transition" elevation="1" color="white" class="rounded-lg">
					<v-form ref="generalForm" v-model="generalForm" lazy-validation>
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.generalSettings.lang") }}
									</v-list-item-action>
									<v-list-item-content>
										<v-autocomplete
											hide-details="auto"
											persistent-hint
											:hint="$t('settingsPage.generalSettings.langHint')"
											v-model="defaultItem.lang"
											:items="countriesList"
											:rules="[rules.required]"
											item-text="language.name"
											item-value="language.code"
											outlined
											dense
											class="mb-3"
										></v-autocomplete>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="mt-2">
									<v-spacer></v-spacer>
									<v-btn @click="cancel" small outlined class="mr-2" color="primary"> Cancel </v-btn>
									<v-btn small depressed color="primary" @click="saveGeneral" :loading="loadingGeneral" :disabled="loadingGeneral || !generalForm">
										{{ $t("general.save") }}
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>

		<v-row no-gutters class="mb-4 px-4">
			<h2>{{ $t("settingsPage.wifiSettings.title") }}</h2>
			<v-spacer></v-spacer>
			<v-chip label color="primary" class="padlessBtn elevation-8">
				<v-icon>mdi-wifi-cog</v-icon>
			</v-chip>
		</v-row>
		<v-row no-gutters v-if="defaultItem" class="mb-4">
			<v-col cols="12" class="mb-4">
				<v-card transition="scroll-y-reverse-transition" elevation="1" color="white" class="rounded-lg">
					<v-form ref="wifiForm" v-model="wifiForm" lazy-validation autocomplete="off">
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.wifiSettings.wifi") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-switch class="ml-3" v-model="enableWiFi" inset :label="enableWiFi ? $t('general.enabled') : $t('general.disabled')"></v-switch>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.wifiSettings.ssid") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field
											v-model="defaultItem.wifiSSID"
											:rules="[rules.length, rules.required]"
											type="text"
											hide-details="auto"
											dense
											outlined
											color="primary"
										></v-text-field>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.wifiSettings.password") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field
											v-model="defaultItem.wifiPassword"
											:rules="[rules.length, rules.required]"
											hide-details="auto"
											dense
											outlined
											color="primary"
											:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPass ? 'text' : 'password'"
											@click:append="showPass = !showPass"
										></v-text-field>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="mt-2">
									<v-spacer></v-spacer>
									<v-btn @click="cancel" small outlined class="mr-2" color="primary"> Cancel </v-btn>
									<v-btn small depressed color="primary" @click="saveWifi" :loading="loadingWifi" :disabled="loadingWifi || !wifiForm">
										{{ $t("general.save") }}
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>

		<v-row no-gutters class="mb-4 px-4">
			<h2>{{ $t("settingsPage.lockSettings.title") }}</h2>
			<v-spacer></v-spacer>
			<v-chip label color="primary" class="padlessBtn elevation-8">
				<v-icon>mdi-lock-question</v-icon>
			</v-chip>
		</v-row>
		<v-row no-gutters class="mb-4">
			<v-col class="mb-4">
				<v-card v-if="defaultItem">
					<v-form ref="lockForm" v-model="lockForm" lazy-validation>
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.lockSettings.schedLock") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-switch class="ml-3" v-model="enableLock" inset :label="enableLock ? $t('general.enabled') : $t('general.disabled')"></v-switch>
									</v-list-item-content>
								</v-list-item>

								<v-list-item v-if="enableLock">
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="max-width: 120px">
										{{ $t("settingsPage.lockSettings.schedLockTime") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<vuetify-time-select label="" color="primary" v-model="defaultItem.lockFrom"></vuetify-time-select>
									</v-list-item-content>
								</v-list-item>

								<v-divider class="ma-4 primary lighten-4" />

								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.lockSettings.autoLock") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-switch class="ml-3" v-model="enableAutoLock" inset :label="enableAutoLock ? $t('general.enabled') : $t('general.disabled')"></v-switch>
									</v-list-item-content>
								</v-list-item>

								<v-list-item v-if="enableAutoLock">
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.lockSettings.autoLockTime") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field
											v-model="defaultItem.autoLock"
											:rules="[rules.required]"
											type="number"
											:hint="$t('settingsPage.lockSettings.autoLockHint')"
											hide-details="auto"
											:suffix="$t('general.minutes')"
											outlined
											dense
											class="mt-0"
										></v-text-field>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px"> Round Timeout </v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field
											v-model="defaultItem.roundTimeout"
											:rules="[rules.required]"
											type="number"
											:hint="$t('settingsPage.lockSettings.autoLockHint')"
											:suffix="$t('general.minutes')"
											hide-details="auto"
											outlined
											dense
											class="mt-0"
										></v-text-field>
									</v-list-item-content>
								</v-list-item>

								<v-list-item class="mt-2">
									<v-spacer></v-spacer>
									<v-btn @click="cancel" outlined small class="mr-2" color="primary"> Cancel </v-btn>
									<v-btn small depressed color="primary" @click="saveLock" :loading="loadingLock" :disabled="loadingLock || !lockForm">
										{{ $t("general.save") }}
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>

		<v-row no-gutters class="mb-4 px-4">
			<h2>Device Settings</h2>
			<v-spacer></v-spacer>
			<v-chip label color="primary" class="padlessBtn elevation-8">
				<v-icon>mdi-golf-cart</v-icon>
			</v-chip>
		</v-row>
		<v-row no-gutters class="mb-4">
			<v-col class="mb-4">
				<v-card v-if="defaultItem">
					<v-form ref="deviceForm" v-model="deviceForm" lazy-validation>
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px"> Recovery Mode </v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-switch class="ml-3" v-model="defaultItem.recoveryMode" inset :label="defaultItem.recoveryMode ? $t('general.enabled') : $t('general.disabled')"></v-switch>
									</v-list-item-content>
									<v-list-item-action>
										<help-modal
											title="Recovery Mode"
											body="When enabled, recovery mode can be used to manually change the cart to 'Key Mode'. You can do this by cycling the run/tow switch 3 times, waiting 3 seconds between each cycle."
										/>
									</v-list-item-action>
								</v-list-item>

								<v-list-item class="mt-2">
									<v-spacer></v-spacer>
									<v-btn @click="cancel" outlined small class="mr-2" color="primary"> Cancel </v-btn>
									<v-btn small depressed color="primary" @click="saveDeviceSettings" :loading="loadingDevice">
										{{ $t("general.save") }}
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>

		<v-row no-gutters class="mb-4 px-4">
			<h2>Dashboard Settings</h2>
			<v-spacer></v-spacer>
			<v-chip label color="primary" class="padlessBtn elevation-8">
				<v-icon>mdi-monitor-dashboard</v-icon>
			</v-chip>
		</v-row>
		<v-row no-gutters class="mb-4">
			<v-col class="mb-4">
				<v-card v-if="defaultItem">
					<v-form ref="deviceForm" v-model="deviceForm" lazy-validation>
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px"> Speed Unit </v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-radio-group v-model="speedUnit" mandatory class="ml-3">
											<v-radio label="km/h" value="kph"></v-radio>
											<v-radio label="mph" value="mph"></v-radio>
										</v-radio-group>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState } from "vuex";
import VuetifyTimeSelect from "vuetify-time-select";
import countriesArray from "@/mixins/Countries";
import HelpModal from "@/components/HelpModal.vue";

export default {
	components: {
		VuetifyTimeSelect,
		HelpModal,
	},
	data() {
		return {
			showPass: false,
			loadingGeneral: false,
			loadingWifi: false,
			loadingLock: false,
			loadingAdmin: false,
			loadingDevice: false,
			generalForm: true,
			wifiForm: true,
			lockForm: true,
			autoLockForm: true,
			deviceForm: true,
			rules: {
				length: (value) => value.length <= 33 || this.$i18n.t("rules.length32"),
				length6: (value) => value.length === 6 || "Code must be 6 digits",
				required: (value) => !!value || this.$i18n.t("rules.required"),
				numberOnly: (value) => /^\d+$/.test(value) || "This field only accept numbers",
			},
			wifiInfo: false,
			enableWiFi: false,
			autoLockInfo: false,
			enableLock: true,
			enableAutoLock: true,
			defaultItem: null,
			countriesList: countriesArray.slice(0, 2),
		};
	},
	computed: {
		...mapState({
			cars: (state) => state.device.cars,
			user: (state) => state.auth.user,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		speedUnit: {
			get() {
				return localStorage.getItem("speedUnit") || "kph";
			},
			set(value) {
				localStorage.setItem("speedUnit", value);
			},
		},
	},
	watch: {
		selectedSite() {
			this.defaultItem = Object.assign({}, this.selectedSite);
		},
	},
	mounted() {
		this.defaultItem = Object.assign({}, this.selectedSite);

		this.defaultItem.wifiEnabled === "0" ? (this.enableWiFi = false) : (this.enableWiFi = true);

		this.defaultItem.lockFrom === "00:00" ? (this.enableLock = false) : (this.enableLock = true);

		this.defaultItem.autoLock === "0" ? (this.enableAutoLock = false) : (this.enableAutoLock = true);
	},
	methods: {
		async saveGeneral() {
			if (this.$refs.generalForm.validate()) {
				this.loadingGeneral = true;

				if (!this.enableWiFi) {
					this.defaultItem.wifiEnabled = "0";
				} else {
					this.defaultItem.wifiEnabled = "1";
				}

				const siteSettings = {
					updateData: {
						_id: this.selectedSite._id,
						lang: this.defaultItem.lang,
					},
				};

				await this.$store.dispatch("updateSite", siteSettings);

				this.loadingGeneral = false;
			}
		},

		async saveWifi() {
			if (this.$refs.wifiForm.validate()) {
				this.loadingWifi = true;

				if (!this.enableWiFi) {
					this.defaultItem.wifiEnabled = "0";
				} else {
					this.defaultItem.wifiEnabled = "1";
				}

				const siteSettings = {
					updateData: {
						_id: this.selectedSite._id,
						wifiSSID: this.defaultItem.wifiSSID,
						wifiPassword: this.defaultItem.wifiPassword,
						wifiEnabled: this.defaultItem.wifiEnabled,
					},
					publish: true,
				};

				await this.$store.dispatch("updateSite", siteSettings);

				this.loadingWifi = false;
			}
		},

		async saveLock() {
			if (this.$refs.lockForm.validate()) {
				this.loadingLock = true;

				if (!this.enableLock) {
					this.defaultItem.lockFrom = "00:00";
				}

				if (!this.enableAutoLock) {
					this.defaultItem.autoLock = "0";
				}

				const siteSettings = {
					updateData: {
						_id: this.selectedSite._id,
						lockFrom: this.defaultItem.lockFrom,
						autoLock: this.defaultItem.autoLock,
						roundTimeout: this.defaultItem.roundTimeout,
					},
					publish: true,
				};

				await this.$store.dispatch("updateSite", siteSettings);

				this.loadingLock = false;
			}
		},

		async saveDeviceSettings() {
			if (this.$refs.deviceForm.validate()) {
				this.loadingDevice = true;

				const siteSettings = {
					updateData: {
						_id: this.selectedSite._id,
						recoveryMode: this.defaultItem.recoveryMode,
					},
					publish: true,
				};

				await this.$store.dispatch("updateSite", siteSettings);

				this.loadingDevice = false;
			}
		},

		cancel() {
			this.defaultItem = Object.assign({}, this.selectedSite);
		},
	},
};
</script>

<style lang="scss" scoped>
.pulse {
	border-radius: 10%;
	box-shadow: 0 0 0 0 rgb(64, 109, 250);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgb(64, 109, 250);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 10px 10px rgba(64, 109, 250, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(64, 109, 250, 0);
	}
}
</style>
