import Vue from "vue";
import {
	isToday,
	isYesterday,
	setMinutes,
	setHours,
	format,
	fromUnixTime,
	formatDistance,
	formatDistanceStrict,
	intervalToDuration,
	formatDuration,
	parseISO,
	isWithinInterval,
} from "date-fns";
import store from "@/store";
import { de, da } from "date-fns/locale";
const locales = { de, da };

Vue.mixin({
	methods: {
		async delay(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},

		translatedFormattedDate(date, dateFormat) {
			const res = format(new Date(date), dateFormat, {
				locale: locales[this.$i18n.locale],
			});

			return res;
		},

		formatDurationToTime(start, end, opts = { includeSeconds: false }) {
			const duration = intervalToDuration({
				start: new Date(start),
				end: new Date(end),
			});

			return formatDuration(duration, {
				locale: locales[this.$i18n.locale],
				...opts,
			});
		},

		formatDateFromUnixTime(unixTime, dateFormat = "dd/MM/yyyy") {
			// Using date-fns library for formatting
			return format(fromUnixTime(unixTime), dateFormat);
		},

		timeAgo(time) {
			return formatDistance(new Date(time), new Date(), {
				addSuffix: true,
				locale: locales[this.$i18n.locale],
			});
		},

		computedTimeAgo(time) {
			var result = fromUnixTime(Number(time));
			var results = formatDistance(new Date(result), new Date(), {
				addSuffix: true,
				locale: locales[this.$i18n.locale],
			});

			return results;
		},

		formatFromUTC(time) {
			var result = fromUnixTime(Number(time));
			var results = format(new Date(result), "HH:mm aaa, do MMM yy", {
				locale: locales[this.$i18n.locale],
			});

			return results;
		},

		computedTimeAgoStrict(time) {
			var result = fromUnixTime(Number(time));
			var results = formatDistanceStrict(new Date(result), new Date(), {
				addSuffix: false,
				locale: locales[this.$i18n.locale],
			});

			return results;
		},

		disconnectedAt(time) {
			let start = new Date(time);

			// Check if the date is today
			if (isToday(start)) {
				return `Today at ${format(start, "HH:mm:ss")}`;
			}
			// Check if the date is yesterday
			else if (isYesterday(start)) {
				return `Yesterday at ${format(start, "HH:mm")}`;
			}
			// For other cases, return the date and time
			else {
				return format(start, "dd/MM/yy HH:mm");
			}
		},

		durationSincePingUnix(time) {
			var result = fromUnixTime(Number(time));
			let duration = intervalToDuration({
				start: new Date(result),
				end: new Date(),
			});

			// Initialize the output string with hours, minutes, and seconds
			let output = `${duration.hours < 10 ? "0" + duration.hours : duration.hours}:${duration.minutes < 10 ? "0" + duration.minutes : duration.minutes}:${
				duration.seconds < 10 ? "0" + duration.seconds : duration.seconds
			}`;

			// Prepend days to the output if more than 1
			if (duration.days > 0) {
				output = `${duration.days < 10 ? "0" + duration.days : duration.days}:${output}`;
			}

			// Prepend months to the output if more than 1
			if (duration.months > 0) {
				output = `${duration.months < 10 ? "0" + duration.months : duration.months}:${output}`;
			}

			return output;
		},

		doesCarNumberExist(carNumber) {
			const cars = store.state.devices.devices;
			const carExists = cars.some((car) => Number(car.carNumber) === Number(carNumber));

			if (carExists) {
				throw new Error(`Car number already exists.`);
			}

			return false;
		},

		isKeylessSite() {
			if (this.$store.state.sites.selectedSite) {
				return this.$store.state.sites.selectedSite.keyless;
			}
		},

		hasKeypadEnabled() {
			if (this.$store.state.sites.selectedSite) {
				return this.$store.state.sites.selectedSite.keypadEnabled;
			}
		},

		getTimeStringAsUTC(timeString) {
			const currentDate = new Date();
			const [hours, minutes] = timeString.split(":");
			currentDate.setUTCHours(hours, minutes, 0, 0);
			const utcDateString = currentDate.toISOString().split("T")[0];
			return `${utcDateString}T${hours}:${minutes}:00.000Z`;
		},

		extractTimeFromString(dateTimeString) {
			if (dateTimeString instanceof Date) {
				dateTimeString = dateTimeString.toISOString();
			}
			const timeString = dateTimeString.split("T")[1].split(".")[0];
			return timeString.substr(0, 5);
		},

		combineDateWithTimeString(timeString, date) {
			const [hours, minutes] = timeString.split(":").map(Number);
			return setMinutes(setHours(date, hours), minutes);
		},

		isWithinCurrentPeriod(start, end) {
			const now = new Date();
			const bookingStart = new Date(start);
			const bookingEnd = new Date(end);

			return bookingStart <= now && now <= bookingEnd;
		},

		isSiteOpen(openingHours) {
			const now = new Date();
			const dayOfWeek = now.getDay();
			const hoursToday = openingHours[dayOfWeek];

			// Check if the site is closed today
			if (!hoursToday) {
				return false;
			}

			const { openTime, closeTime } = hoursToday;
			const currentDateStr = now.toISOString().slice(0, 10); // YYYY-MM-DD
			const openDateTime = parseISO(`${currentDateStr}T${openTime}:00`);
			const closeDateTime = parseISO(`${currentDateStr}T${closeTime}:00`);

			if (isWithinInterval(now, { start: openDateTime, end: closeDateTime })) {
				return true;
			} else {
				return false;
			}
		},
	},
});
