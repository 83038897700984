<template>
	<div>
		<v-card flat color="grey" class="rounded-xl pa-3">
			<v-alert class="text-center" border="bottom" type="success" colored-border>
				<span class="title accent--text font-weight-bold text-center pb-0">Available!</span>
				<br />
				<span class="accent--text font-weight-bold text-center pt-2">You can Unlock this cart using the following methods:</span>
			</v-alert>

			<selection-card icon="mdi-key-variant" color="purple" :title="$t('s2r.virtualKey.title')" :subtitle="$t('s2r.virtualKey.subtitle')" class="mb-6">
				<bottom-sheet :btnTitle="$t('s2r.virtualKey.btn')" btnColor="purple" :title="$t('s2r.virtualKey.enter')" @code-entered="goToVirtualKey" />
			</selection-card>

			<selection-card v-if="site.stripeConfig.accountActive" icon="mdi-credit-card" color="primary" :title="$t('s2r.payment.title')" :subtitle="$t('s2r.payment.subtitle')">
				<v-btn block class="rounded-lg font-weight-bold d-flex align-center" color="primary" dark depressed @click="goToPrices">
					<span class="text-center" style="flex-grow: 1">
						{{ $t("s2r.payment.options") }}
					</span>
					<v-icon class="ml-auto">mdi-chevron-right</v-icon>
				</v-btn>
			</selection-card>
		</v-card>

		<confirm ref="confirm" />
	</div>
</template>

<script>
import SelectionCard from "@/components/scanToRide/SelectionCard.vue";
import Confirm from "@/components/Confirm";
import BottomSheet from "@/components/scanToRide/BottomSheet";

export default {
	props: {
		site: {
			type: Object,
			required: true,
		},
	},
	components: {
		SelectionCard,
		Confirm,
		BottomSheet,
	},
	data() {
		return {
			sheet: false,
			loading: false,
			loadingCarts: true,
			availableCarts: [],
		};
	},
	created() {
		// this.getAvailableCarts();
	},
	mounted() {
		const reservationCode = this.$route.query.reservationCode;

		if (reservationCode) {
			this.getBookingId(reservationCode);
		}
	},
	computed: {
		hasCartsAvailable() {
			return this.availableCarts.length ? true : false;
		},
	},
	methods: {
		// async getAvailableCarts() {
		// 	try {
		// 		const { data } = await this.$ioteeApiV2.get(`/bookings/get-available-carts`, {
		// 			params: { siteId: this.site._id },
		// 		});

		// 		this.availableCarts = data.availableCarts.sort((a, b) => a.carNumber - b.carNumber);
		// 	} catch (err) {
		// 		console.log(err);
		// 	} finally {
		// 		this.loadingCarts = false;
		// 	}
		// },

		// async getBookingId(code) {
		// 	this.loading = true;
		// 	try {
		// 		const { data } = await this.$ioteeApiV2.get(`/bookings/get-booking-by-code`, {
		// 			params: {
		// 				code,
		// 				deviceId: this.$route.params.id,
		// 			},
		// 		});

		// 		if (!data) {
		// 			const message = this.$t("s2r.booked.notFound.title");
		// 			this.$toast.error(message);
		// 		} else {
		// 			this.$router.push({
		// 				name: "RideBooking",
		// 				params: {
		// 					id: this.$route.params.id,
		// 					bookingId: data.id,
		// 				},
		// 				query: {
		// 					reservationCode: code,
		// 				},
		// 			});
		// 		}
		// 	} catch (err) {
		// 		console.log(err);
		// 	} finally {
		// 		this.loading = false;
		// 	}
		// },

		// async goToCar(id, cart) {
		// 	const res = await this.$refs.confirm.open(this.$t("s2r.change.title", { cart }), this.$t("s2r.change.subtitle", { cart }), "success", "mdi-golf-cart");

		// 	if (res === true) {
		// 		this.$router.push({
		// 			path: `/ride/${id}`,
		// 		});
		// 	}
		// },

		async goToVirtualKey(code) {
			this.$router.push({
				name: "RideVirtualKey",
				params: {
					id: this.$route.params.id,
					code,
				},
			});
		},

		goToPrices() {
			this.$router.push({
				name: "RidePrices",
				params: {
					id: this.$route.params.id,
				},
			});
		},
	},
};
</script>
