<template>
	<div>
		<v-menu :close-on-content-click="true" bottom left offset-y min-width="180px">
			<template v-slot:activator="{ on: menu }">
				<v-btn :disabled="!selectedMembers.length || !hasRole('user')" small color="accent" class="mr-2" outlined v-on="menu">
					Actions
					<v-icon small right>mdi-menu-down</v-icon>
				</v-btn>
			</template>

			<v-list dense nav>
				<v-list-item class="font-weight-bold"> Selected: {{ selectedMembers.length }} </v-list-item>
				<v-list-item @click="deleteMembers()" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-delete-alert</v-icon>
						Delete
					</v-list-item-title>
				</v-list-item>

				<v-list-item @click="userGroupDialog = !userGroupDialog" :disabled="loading">
					<v-list-item-title>
						<v-icon small left>mdi-account-group</v-icon>
						{{ $t("userGroups.edit") }}
					</v-list-item-title>
				</v-list-item>

				<div v-show="hasRole('admin')" class="primary lighten-4 rounded-lg py-2" style="border: 2px dashed #7694f0 !important">
					<v-list-item @click="linkMembers()" :disabled="loading">
						<v-list-item-title>
							<v-icon small left>mdi-cellphone-check</v-icon>
							Link Members
						</v-list-item-title>
					</v-list-item>

					<v-list-item @click="unlinkMembers()" :disabled="loading">
						<v-list-item-title>
							<v-icon small left>mdi-cellphone-remove</v-icon>
							Unlink Members
						</v-list-item-title>
					</v-list-item>
				</div>
			</v-list>
		</v-menu>
		<confirm ref="confirm" />

		<v-dialog v-model="userGroupDialog" max-width="300" persistent>
			<v-card>
				<v-card-title> {{ $t("userGroups.userGroup") }}: </v-card-title>
				<v-card-text>
					<user-group-select v-model="selectedUserGroup" />
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn small outlined color="primary" @click="cancelUserGroup">{{ $t("general.cancel") }}</v-btn>
					<v-btn small depressed color="primary" @click="saveUserGroup">{{ $t("general.save") }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="loading" width="100px" persistent>
			<v-card width="100" height="100" class="d-flex align-center justify-center">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Confirm from "@/components/Confirm";
import UserGroupSelect from "@/components/drivers/UserGroupSelect";

export default {
	props: {
		selectedSite: {
			default: null,
		},
		selectedMembers: {
			default: null,
		},
	},
	components: {
		Confirm,
		UserGroupSelect,
	},
	data() {
		return {
			loading: false,
			userGroupDialog: false,
			selectedUserGroup: null,
		};
	},
	methods: {
		async deleteMembers() {
			const res = await this.$refs.confirm.open("Delete Members?", `Are you sure you want to delete selected Members?`, "error", "mdi-delete-alert");

			if (res === true) {
				this.loading = true;
				try {
					const ids = this.selectedMembers.map((selected) => selected._id);

					await this.$store.dispatch("deleteMembers", ids);

					const message = "Members deleted";
					this.$toast.success(message);
				} catch (err) {
					this.$toast.success(err.error.message);
				} finally {
					this.loading = false;
					this.$emit("resetSelected", []);
				}
			}

			this.loading = false;
			this.$emit("resetSelected", []);
		},

		async saveUserGroup() {
			this.loading = true;
			try {
				const ids = this.selectedMembers.map((selected) => selected._id);

				await this.$store.dispatch("updateMembers", { ids, updateData: { userGroup: this.selectedUserGroup } });

				const message = "Members updated";
				this.$toast.success(message);
			} catch (err) {
				this.$toast.success(err.error.message);
			} finally {
				this.loading = false;
				this.cancelUserGroup();
				this.$emit("resetSelected", []);
			}
		},

		async cancelUserGroup() {
			this.userGroupDialog = false;
			this.selectedUserGroup = null;
		},

		async linkMembers() {
			this.loading = true;
			try {
				const membersMap = this.selectedMembers.reduce((map, selected) => {
					// Include only if the member does not have an existing profile
					if (!selected.profile || !selected.profile._id) {
						map[selected.email] = selected._id;
					}
					return map;
				}, {});

				// Dispatch the action with the new mapping
				const res = await this.$store.dispatch("linkMembers", membersMap);

				const message = `Members linked: ${res}`;
				this.$toast.success(message);
			} catch (err) {
				this.$toast.error(err.error.message); // changed from success to error
			} finally {
				this.loading = false;
				this.$emit("resetSelected", []);
			}
		},

		async unlinkMembers() {
			const res = await this.$refs.confirm.open("Unlink Members?", `Are you sure you want to unlink selected Members?`, "error", "mdi-account-minus");

			if (res === true) {
				this.loading = true;
				try {
					const membersMap = this.selectedMembers.reduce((map, selected) => {
						// Include only if the member has an existing profile
						if (selected.profile && selected.profile._id) {
							map[selected._id] = selected.profile._id;
						}
						return map;
					}, {});

					await this.$store.dispatch("unlinkMembers", membersMap);

					const message = "Members unlinked";
					this.$toast.success(message);
				} catch (err) {
					this.$toast.success(err.error.message);
				} finally {
					this.loading = false;
					this.$emit("resetSelected", []);
				}
			}

			this.loading = false;
			this.$emit("resetSelected", []);
		},
	},
};
</script>
