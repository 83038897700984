import Vue from "vue";
import * as devicesService from "@/services/devices.service";

export default {
	state: {
		device: null,
		devices: [],
		selectedDevice: null,
		selectedDeviceId: null,
		requestQueue: {},

		filters: {
			search: "",
		},
	},

	mutations: {
		setDevices(state, payload) {
			payload = payload.sort((a, b) => Number(a.carNumber) - Number(b.carNumber));

			state.devices = payload;
		},

		setDeviceSearch(state, payload) {
			state.filters.search = payload;
		},

		setSelectedDevice(state, payload) {
			state.selectedDevice = payload;
		},

		setSelectedDeviceId(state, payload) {
			state.selectedDeviceId = payload;
		},

		SOCKET_SITE_DEVICES(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_SITE_DEVICES", payload);
			}

			payload = payload.sort((a, b) => Number(a.carNumber) - Number(b.carNumber));

			Vue.set(state, "devices", payload);
		},

		SOCKET_DEVICE_UPDATE(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_DEVICE_UPDATE", payload);
			}

			const index = state.devices.findIndex((item) => item._id === payload._id);

			if (index !== -1) {
				Vue.set(state.devices, index, { ...state.devices[index], ...payload });
			}
		},

		SOCKET_REQUEST_QUEUE(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_REQUEST_QUEUE", payload);
			}
			// push the array of devices to requestQueue using the device id as key
			Vue.set(state.requestQueue, payload.device, payload.updates);
		},

		SOCKET_REQUEST_QUEUE_UPDATE(state, payload) {
			if (process.env.VUE_APP_NODE_ENV === "development") {
				console.log("SOCKET_REQUEST_QUEUE_UPDATE", payload);
			}

			const device = payload.device;
			if (!state.requestQueue[device]) {
				Vue.set(state.requestQueue, device, []);
			}

			switch (payload.operationType) {
				case "delete": {
					const indexToDelete = state.requestQueue[device].findIndex((update) => update._id === payload._id);
					if (indexToDelete !== -1) {
						state.requestQueue[device].splice(indexToDelete, 1);
					}
					break;
				}
				case "insert": {
					state.requestQueue[device].push(payload);
					break;
				}
				case "update": {
					const indexToUpdate = state.requestQueue[device].findIndex((update) => update._id === payload._id);
					if (indexToUpdate !== -1) {
						Vue.set(state.requestQueue[device], indexToUpdate, payload);
					} else {
						state.requestQueue[device].push(payload);
					}
					break;
				}
			}
		},
	},

	actions: {
		// Get ALL Devices Assigned to a Site
		async getDevices({ commit }) {
			const devices = await devicesService.getDevices();
			commit("setDevices", devices);
		},

		async updateDevice({ dispatch }, payload) {
			await devicesService.updateDevice(payload);
			await dispatch("getDevices");
		},

		async updateDevices({ dispatch }, payload) {
			await devicesService.updateDevices(payload);
			await dispatch("getDevices");
		},

		async lockDevice({ dispatch }, id) {
			await devicesService.lockDevice(id);
			await dispatch("getDevices");
		},

		async unlockDevice({ dispatch }, payload) {
			await devicesService.unlockDevice(payload);
			await dispatch("getDevices");
		},

		async lockDevices(_, payload) {
			await devicesService.lockDevices(payload);
		},

		async unlockDevices(_, payload) {
			await devicesService.unlockDevices(payload);
		},
	},
	getters: {
		devicesCount(state) {
			return state.devices.length;
		},

		deviceFromId: (state) => (id) => {
			return state.devices.find((device) => device._id === id) || null;
		},

		deviceFromCarNumber: (state) => (carNumber) => {
			return state.devices.find((device) => device.carNumber === carNumber);
		},

		devicesWithUpdatesBookingsRide(state, getters, rootState, rootGetters) {
			return state.devices.map((car) => {
				const carUpdates = state.requestQueue[car._id];
				const deviceBookings = rootGetters.futureAndCurrentBookings[car._id];
				const ride = rootState.rides.activeRides[car.session];
				const sessionBooking = deviceBookings?.find((booking) => booking.session && booking.session === car.session);

				return {
					...car,
					bookings: deviceBookings,
					updates: carUpdates || [],
					ride,
					sessionBooking,
				};
			});
		},

		devicesWithRide(state, getters, rootState) {
			return state.devices.map((car) => {
				const ride = rootState.rides.activeRides[car.session];

				return {
					...car,
					ride,
				};
			});
		},

		devicesWithRideAndBookings(state, getters, rootState, rootGetters) {
			return state.devices.map((car) => {
				const deviceBookings = rootGetters.futureAndCurrentBookings[car._id];
				const ride = rootState.rides.activeRides[car.session];

				return {
					...car,
					bookings: deviceBookings,
					ride,
				};
			});
		},
	},
};
