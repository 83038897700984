import Vue from "vue";

export const getSite = async (siteId) => {
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/sites/${siteId}`, {
			headers: {
				"site-id": siteId,
			},
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getVirtualKey = async (payload) => {
	const { siteId, query } = payload;
	try {
		const { data } = await Vue.prototype.$ioteeGuestApi.get(`/sites/${siteId}/virtual-keys`, {
			params: query,
			headers: {
				"site-id": siteId,
			},
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
