<template>
	<v-container fluid>
		<v-row>
			<v-col v-if="virtualKey" class="pt-0" cols="12">
				<v-card color="purple lighten-5" class="rounded-lg" flat>
					<v-list-item @click="showDetails = !showDetails">
						<v-list-item-content>
							<span class="subtitle-2 font-weight-bold purple--text">
								<v-icon left color="purple">mdi-ticket-confirmation-outline</v-icon>
								Credits:
								<v-chip small label color="purple" class="white--text ml-2 elevation-6">
									{{ availableCredits }}
								</v-chip>
							</span>
						</v-list-item-content>
						<v-list-item-action>
							<v-row class="pr-2">
								<v-btn small icon>
									<v-icon color="purple">{{ showDetails ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
								</v-btn>
								<v-btn @click.stop="clearVirtualKey" small icon class="ml-2">
									<v-icon color="purple">mdi-close-circle</v-icon>
								</v-btn>
							</v-row>
						</v-list-item-action>
					</v-list-item>

					<v-expand-transition>
						<v-card v-show="showDetails" color="purple lighten-5" class="rounded-xl" flat>
							<v-list color="purple lighten-5 pt-0">
								<v-list-item>
									<v-list-item-icon>
										<v-icon color="purple"> mdi-account </v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="purple--text font-weight-bold">
											{{ virtualKey.driver?.name }}
										</v-list-item-title>
										<v-list-item-subtitle class="purple--text text-wrap">
											{{ virtualKey.driver?.email }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon>
										<v-icon color="purple"> mdi-account-group </v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="purple--text font-weight-bold">
											{{ userGroup }}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon>
										<v-icon color="purple"> mdi-ticket-confirmation-outline</v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="purple--text font-weight-bold">
											{{ $t("s2r.virtualKey.aCredits") }}
										</v-list-item-title>
										<v-list-item-subtitle class="purple--text text-wrap">
											{{ availableCredits }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon>
										<v-icon color="purple"> mdi-golf-cart</v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="purple--text font-weight-bold"> {{ $t("s2r.virtualKey.validFor") }}: </v-list-item-title>
										<div class="mt-2">
											<v-chip v-if="virtualKey.allDevices" label small color="primary" class="font-weight-bold">
												<v-icon small left color="white">mdi-golf-cart</v-icon>
												{{ $t("s2r.virtualKey.allCarts") }}
											</v-chip>
											<v-chip-group v-else column>
												<v-chip v-for="car in virtualKey.devices" :key="car" label small color="primary" class="font-weight-bold">
													<v-icon small left color="white">mdi-golf-cart</v-icon>
													{{ car }}
												</v-chip>
											</v-chip-group>
										</div>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-icon>
										<v-icon :color="codeEnabled ? 'success' : 'error'">
											{{ codeEnabled ? "mdi-check-circle-outline" : "mdi-close-circle-outline" }}
										</v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="purple--text font-weight-bold">
											{{ $t("s2r.virtualKey.status") }}
										</v-list-item-title>
										<v-list-item-subtitle class="purple--text text-wrap">
											{{ codeEnabled ? $t("general.enabled") : $t("general.disabled") }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>

								<v-list-item v-if="virtualKey.expires">
									<v-list-item-icon>
										<v-icon color="purple"> mdi-clock-alert-outline</v-icon>
									</v-list-item-icon>

									<v-list-item-content>
										<v-list-item-title class="purple--text font-weight-bold">
											{{ $t("s2r.virtualKey.expires") }}
										</v-list-item-title>
										<v-list-item-subtitle class="purple--text text-wrap">
											{{ translatedFormattedDate(virtualKey.expires, "EEE do MMM yyy, HH:mm") }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card>
					</v-expand-transition>
				</v-card>

				<booking-form :site="site" class="mt-2" :virtualKey="virtualKey" />
			</v-col>
		</v-row>

		<confirm ref="confirm" yesText="general.continue" noText="general.cancel" />
	</v-container>
</template>

<script>
import { mapState } from "vuex";

import BookingForm from "@/components/guest/site/BookingForm.vue";

import Confirm from "@/components/Confirm";

export default {
	components: {
		Confirm,
		BookingForm,
	},
	data() {
		return {
			loading: true,
			loadingVirtualKey: false,
			showDetails: false,
		};
	},
	async mounted() {
		await this.getVirtualKey();
	},
	computed: {
		...mapState({
			virtualKey: (state) => state.guest.sites.virtualKey,
			site: (state) => state.guest.sites.site,
		}),

		codeEnabled() {
			return this.virtualKey.enabled;
		},

		unlimitedCredits() {
			return this.virtualKey.driver.credits === -1;
		},

		availableCredits() {
			return this.unlimitedCredits ? "Unlimited" : this.virtualKey.driver.credits;
		},

		hasAvailableCredits() {
			return this.unlimitedCredits || this.virtualKey.driver.credits > 0;
		},

		userGroup() {
			const group = this.site.userGroups.find((group) => group._id === this.virtualKey.driver.userGroup);

			return group ? group.name : "N/A";
		},

		infoMessage() {
			// if status is booked and the user is not a master, show the relevant message
			if (!this.hasAvailableCredits) {
				return this.$t("s2r.virtualKey.statusMsg.noCreds");
			} else if (!this.codeEnabled) {
				return this.$t("s2r.virtualKey.statusMsg.disabled");
			} else if (this.hasAvailableCredits && !this.unlimitedCredits) {
				return this.$t("s2r.virtualKey.statusMsg.deduct");
			} else {
				return this.$t("s2r.virtualKey.statusMsg.unlimited");
			}
		},
	},
	methods: {
		async getVirtualKey() {
			if (this.virtualKey && this.virtualKey._id === this.$route.params.virtualKeyId) return;

			this.loadingVirtualKey = true;
			try {
				await this.$store.dispatch("guest/sites/getVirtualKey", {
					siteId: this.site._id,
					query: {
						_id: this.$route.params.virtualKeyId,
					},
				});
			} catch (err) {
				this.$toast.error("An error occurred");
				this.clearVirtualKey();
			} finally {
				this.loadingVirtualKey = false;
			}
		},

		clearVirtualKey() {
			this.$store.commit("guest/sites/setVirtualKey", null);
			this.$router.push({ name: "guest.site", params: { id: this.site._id } });
		},
	},
};
</script>
