<template>
	<v-hover v-slot="{ hover }">
		<v-card
			:loading="loadingColor"
			class="rounded-lg"
			flat
			color="white"
			@click="$emit('cardClick')"
			:elevation="isSelectedRide || hover ? 6 : 0"
			:style="isSelectedRide ? 'border: 3px solid #406dfa !important; border-radius: 8px' : ' '"
		>
			<v-card-text class="px-1">
				<v-row align="center" justify="center">
					<v-col cols="5" class="py-0 pr-0">
						<v-list-item two-line>
							<v-list-item-content class="accent--text">
								<v-list-item-title class="font-weight-bold"> {{ $t("general.cart") }} {{ rideData.carNumber }} </v-list-item-title>
								<v-list-item-subtitle v-if="rideData.carName" class="caption font-weight-bold text-wrap font-italic"> {{ rideData.carName }} </v-list-item-subtitle>
								<v-list-item-subtitle class="caption font-weight-bold text-wrap"> {{ rideData.name }} </v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-col>
					<v-col class="text-right pr-4">
						<v-row>
							<v-col>
								<v-chip small color="transparent" class="font-weight-bold">
									{{ computedDate }}
								</v-chip>
								<v-chip small color="transparent" class="font-weight-bold">
									{{ computedTime }}
								</v-chip>
							</v-col>
						</v-row>
						<v-row v-if="rideData.service">
							<v-col class="pt-0 mr-2">
								<service-chip :serviceId="rideData.service" :priceId="rideData.price" size="x-small" />
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-hover>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, parseISO } from "date-fns";
import ServiceChip from "@/components/ServiceChip.vue";

export default {
	props: {
		selectedSite: {
			required: true,
		},
		selectedRide: {
			default: null,
		},
		ride: {
			required: true,
		},
	},
	components: {
		ServiceChip,
	},
	data() {
		return {
			sheet: false,
		};
	},
	computed: {
		...mapState({
			services: (state) => state.sites.services,
			loading: (state) => state.rides.loadingRide,
		}),

		loadingColor() {
			return this.loading === this.ride._id ? "primary" : false;
		},

		...mapGetters({
			getRideListData: "rides/getRideListData",
		}),

		rideData() {
			return this.getRideListData(this.ride._id);
		},

		service() {
			return this.services.find((service) => service._id === this.rideData.service).name;
		},

		isSelectedRide() {
			if (this.selectedRide) {
				return this.ride._id === this.selectedRide._id ? true : false;
			}

			return false;
		},

		computedDate() {
			return format(parseISO(this.ride.start), "dd/MM/yyyy");
		},

		computedTime() {
			return format(parseISO(this.ride.start), "HH:mm");
		},

		roundAmount() {
			return `${this.currencyDisplay(this.selectedSite.currency)} ${this.ride.amount / 100}`;
		},
	},
	methods: {},
};
</script>
