import Vue from "vue";

export const getSiteRides = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/rides`, { params: payload });

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getRide = async (rideId) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/rides/${rideId}`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getRideHistory = async (payload) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/rides/history`, { params: payload });

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getRidesChartData = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get(`/rides/chart`, { params: query });

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
