<template>
	<v-card flat>
		<v-card-text class="pt-0">
			<v-expansion-panels accordion flat>
				<!-- GENERAL SETTINGS -->
				<v-expansion-panel @click="getDeviceLogs('wifi')">
					<v-expansion-panel-header> WiFi Scan </v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<v-row>
							<v-col class="px-0">
								<div class="d-flex justify-space-between pa-4">
									<v-chip small label color="primary" class="font-weight-bold">
										<v-icon small left>mdi-wifi</v-icon>
										{{ device.wifiSSID }} | {{ device.wifiSignal }}
									</v-chip>
									<div>
										<v-btn class="padlessBtn" small outlined color="primary" @click="getDeviceLogs('wifi')" :loading="loadingLogs">
											<v-icon small>mdi-refresh-circle</v-icon>
										</v-btn>
										<v-btn class="padlessBtn ml-2" small depressed color="primary" @click="wifiScan()" :loading="loadingWifi"> Scan </v-btn>
									</div>
								</div>
								<v-simple-table class="font-weight-bold">
									<template v-slot:default>
										<tbody>
											<tr v-for="item in wifiRecords" :key="item._id" @click="selectedLog = item">
												<td>{{ translatedFormattedDate(item.createdAt, "dd/mm/yy HH:mm:ss") }}</td>
												<td>Networks: {{ item.data.Networks.length }}</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel @click="getDeviceLogs('log')">
					<v-expansion-panel-header> Logs </v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<div class="d-flex justify-space-between pa-4">
							<span>Log History:</span>
							<div>
								<v-btn class="padlessBtn" small outlined color="primary" @click="getDeviceLogs('log')" :loading="loadingLogs">
									<v-icon small>mdi-refresh-circle</v-icon>
								</v-btn>
								<v-btn class="padlessBtn ml-2" small depressed color="primary" @click="pingDevice()" :loading="loadingLogFile">
									<v-icon small>mdi-plus-circle-outline</v-icon>
								</v-btn>
							</div>
						</div>
						<v-simple-table class="font-weight-bold">
							<template v-slot:default>
								<tbody>
									<tr v-for="item in logRecords" :key="item._id" @click="selectedLog = item">
										<td>{{ translatedFormattedDate(item.createdAt, "dd/MM/yy HH:mm:ss") }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel @click="getDeviceSystemLogs()">
					<v-expansion-panel-header> System Logs </v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<div class="d-flex justify-space-between pa-4">
							<span>Output: {{ systemLogs.length }}</span>
							<div>
								<download-excel
									class="v-btn v-btn--outlined theme--dark v-size--small accent--text mr-2 hidden-sm-and-down"
									:data="systemLogsRecords"
									:fields="json_fields"
									:worksheet="worksheetName"
									:name="`${worksheetName}.xls`"
									type="xls"
								>
									Export
									<v-icon right small>mdi-download</v-icon>
								</download-excel>
								<v-btn class="padlessBtn" small outlined color="primary" @click="getDeviceSystemLogs()" :loading="loadingSystemLogs">
									<v-icon small>mdi-refresh-circle</v-icon>
								</v-btn>
							</div>
						</div>
						<v-virtual-scroll :bench="10" :items="systemLogsRecords" height="400" item-height="24">
							<template v-slot:default="{ item }">
								<span class="primary--text">{{ translatedFormattedDate(item.time, "dd/MM/yy HH:mm:ss") }}</span> -
								{{ item.body }}
							</template>
						</v-virtual-scroll>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel @click="getLogFiles()">
					<v-expansion-panel-header> Log Files </v-expansion-panel-header>
					<v-expansion-panel-content color="grey" class="pt-4">
						<div class="d-flex justify-space-between pa-4">
							<span>Files: {{ logFiles.length }}</span>
							<div>
								<v-btn class="padlessBtn" small outlined color="primary" @click="getLogFiles()" :loading="loadingLogFiles">
									<v-icon small>mdi-refresh-circle</v-icon>
								</v-btn>
							</div>
						</div>
						<v-simple-table class="font-weight-bold">
							<template v-slot:default>
								<tbody>
									<tr v-for="item in logFiles" :key="item._id">
										<td>{{ logName(item.name) }}</td>
										<td>{{ logDate(logName(item.name)) }}</td>
										<td>
											<v-btn :href="item.downloadUrl" target="_blank" class="font-weight-bold" small depressed text color="primary">
												<v-icon left small>mdi-download</v-icon>
												Download
											</v-btn>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
		<confirm ref="confirm" />
		<log-dialog ref="logDialog" @close="selectedLog = null" />
	</v-card>
</template>

<script>
import Confirm from "@/components/Confirm";
import LogDialog from "@/components/admin/logs/LogDialog";
import { mapState } from "vuex";

export default {
	components: {
		Confirm,
		LogDialog,
	},
	props: {
		device: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loadingWifi: false,
			loadingLogFile: false,
			loadingLogs: false,
			loadingSystemLogs: false,
			loadingLogFiles: false,

			selectedLog: null,

			json_fields: {
				Device: "device",
				Time: {
					field: "time",
					callback: (item) => {
						return `${this.translatedFormattedDate(new Date(item), "dd/MM/yy HH:mm:ss")}`;
					},
				},
				Body: "body",
			},
		};
	},
	watch: {
		selectedLog(val) {
			if (val) {
				this.$refs.logDialog.item = val;
			} else {
				this.$refs.logDialog.item = null;
			}
		},
	},
	computed: {
		...mapState({
			site: (state) => state.sites.selectedSite,
			deviceLogs: (state) => state.admin.devices.deviceLogs,
			systemLogs: (state) => state.admin.devices.systemLogs,
			logFiles: (state) => state.admin.devices.logFiles,
		}),

		wifiRecords() {
			const logs = this.deviceLogs.filter((log) => log.type === "wifi");

			return logs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		},

		logRecords() {
			const logs = this.deviceLogs.filter((log) => log.type === "log");

			return logs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		},

		systemLogsRecords() {
			const logs = this.systemLogs;

			// sort logs by time in descending order

			return logs.sort((a, b) => new Date(b.time) - new Date(a.time));
		},

		worksheetName() {
			return `${this.site.name} - Logs`;
		},
	},
	methods: {
		async wifiScan() {
			this.loadingWifi = true;
			try {
				await this.$store.dispatch("admin/pingDevice", {
					id: this.device._id,
					command: 5,
				});

				// set timout for 10 seconds then get logs
				await new Promise((resolve) => setTimeout(resolve, 10000));
				await this.getDeviceLogs("wifi");
			} catch (err) {
				this.$toast.error(err.error.message || "Error");
			} finally {
				this.loadingWifi = false;
			}
		},

		async pingDevice() {
			this.loadingLogFile = true;
			try {
				await this.$store.dispatch("admin/pingDevice", {
					id: this.device._id,
					command: 6,
				});

				// set timout for 5 seconds then get logs
				await new Promise((resolve) => setTimeout(resolve, 5000));
				await this.getDeviceLogs("log");
			} catch (err) {
				this.$toast.error(err.error.message || "Error");
			} finally {
				this.loadingLogFile = false;
			}
		},

		async getDeviceLogs(type) {
			this.loadingLogs = true;
			try {
				await this.$store.dispatch("admin/getDeviceLogs", {
					id: this.device._id,
					query: {
						type,
						limit: 5,
						sort: "createdAt:desc",
					},
				});
			} catch (err) {
				this.$toast.error(err.error.message || "Error");
			} finally {
				this.loadingLogs = false;
			}
		},

		async getDeviceSystemLogs() {
			this.loadingSystemLogs = true;
			try {
				await this.$store.dispatch("admin/getDeviceSystemLogs", {
					id: this.device._id,
					query: {},
				});
			} catch (err) {
				this.$toast.error(err.error.message || "Error");
			} finally {
				this.loadingSystemLogs = false;
			}
		},

		async getLogFiles() {
			this.loadingLogFiles = true;
			try {
				await this.$store.dispatch("admin/getLogFiles", {
					id: this.device._id,
				});
			} catch (err) {
				this.$toast.error(err.error.message || "Error");
			} finally {
				this.loadingLogFiles = false;
			}
		},

		logName(log) {
			return log.split("/").pop();
		},

		logDate(log) {
			// remove .log extension
			let date = log.split(".")[0];

			date = new Date(Number(date));

			date = date.toLocaleString();

			return date;
		},
	},
};
</script>
