<template>
	<v-dialog v-model="dialog" width="500" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-btn v-bind="attrs" v-on="on" x-small color="primary" class="mr-2">
				<v-icon small> mdi-file-upload-outline </v-icon>
			</v-btn>
		</template>
		<v-form ref="form" v-model="valid" lazy-validation>
			<v-card>
				<v-card-title class="title"> Upload Firmware </v-card-title>

				<v-card-text v-show="!uploaded && !loading">
					<v-file-input
						:rules="rules"
						v-model="upload"
						accept=".bin"
						chips
						outlined
						dense
						color="primary"
						hide-details="auto"
						prepend-icon="mdi-file-upload"
						label="Choose file..."
					></v-file-input>
				</v-card-text>

				<v-card-text v-show="uploaded">
					<v-alert type="success" outlined icon="mdi-check-circle-outline" dense> Firmware uploaded successfully! </v-alert>
				</v-card-text>

				<v-card-text v-show="loading" class="text-center">
					<v-alert type="info" outlined dense>Upload in progress: {{ upload?.name }}</v-alert>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn v-if="uploaded" color="success" depressed outlined @click="close"> Done </v-btn>
					<v-btn v-else :loading="loading" color="primary" depressed outlined @click="uploadFirmware()"> Upload </v-btn>
					<v-btn v-if="!uploaded && !loading" color="primary" :disabled="loading" text @click="close"> Close </v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			upload: null,
			valid: true,
			rules: [(v) => !!v || "Please select a file to upload", (value) => !value || value.size < 5000000 || "File size should be less than 5 MB!"],
			dialog: false,
			loading: false,
			uploaded: false,
		};
	},
	computed: {
		...mapState({
			firmware: (state) => state.admin.firmware.firmware,
		}),
	},
	methods: {
		async uploadFirmware() {
			try {
				this.loading = true;
				await this.$store.dispatch("admin/uploadFirmware", this.upload);
				this.uploaded = true;
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loading = false;
			}
		},

		close() {
			this.upload = null;
			this.uploaded = false;
			this.$refs.form.reset();
			this.dialog = false;
		},
	},
};
</script>
