<template>
	<v-select v-model="selectedTime" :items="times" :label="label" dense outlined hide-details return-object menu-props="auto"></v-select>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: "Time",
		},
		value: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			selectedTime: this.value,
			times: this.generateTimes(),
		};
	},
	watch: {
		value(newValue) {
			this.selectedTime = newValue;
		},
		selectedTime(newTime) {
			this.$emit("input", newTime);
			this.$emit("change", newTime);
		},
	},
	methods: {
		generateTimes() {
			const timesArray = [];
			// Generate times from 00:00 to 23:55 every 5 minutes
			for (let hour = 0; hour < 24; hour++) {
				for (let minute = 0; minute < 60; minute += 5) {
					const time = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
					timesArray.push(time);
				}
			}
			return timesArray;
		},
	},
};
</script>
