<template>
	<v-container fluid v-resize="onResize">
		<v-row>
			<v-col cols="12" class="px-0">
				<v-data-table
					class="admin-fleet-table"
					:headers="headers"
					:items="clients"
					:search="search"
					item-key="_id"
					sort-by="_id"
					:mobile-breakpoint="0"
					:height="$vuetify.breakpoint.xs ? windowSize.y - 240 : windowSize.y - 200"
					fixed-header
					:page.sync="page"
					:header-props="{
						'sort-icon': 'mdi-menu-down',
					}"
					:items-per-page="itemsPerPage.val"
					hide-default-footer
					@page-count="pageCount = $event"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title class="accent--text">
								Clients
								<v-chip label class="primary font-weight-bold">
									{{ clients.length }}
								</v-chip>
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<client-modal />
							<v-btn small class="primary mr-2 padlessBtn" depressed :loading="refreshLoading" @click="refresh">
								<v-icon small>mdi-refresh</v-icon>
							</v-btn>
							<v-text-field v-model="search" append-icon="mdi-magnify" label="Search Clients" single-line outlined dense hide-details style="max-width: 300px"></v-text-field>
						</v-toolbar>
					</template>

					<template v-slot:item.name="{ item }">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="font-weight-bold">{{ item.name }}</v-list-item-title>
								<v-list-item-subtitle class="caption font-weight-bold">{{ item._id }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>

					<template v-slot:item.apiKey="{ item }">
						<span class="font-weight-bold">
							<v-chip label small @click.stop="copyText(item.apiKey)">
								{{ item.apiKey }}
							</v-chip>
						</span>
					</template>

					<template v-slot:item.usage="{ item }">
						<div class="no-wrap">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">{{ item.usage.count }}</v-list-item-title>
									<v-list-item-subtitle>{{ formattedUsageDate(item.usage.date) }} {{ isToday(item.usage.date) ? "- Today" : "" }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</div>
					</template>

					<template v-slot:item.edit="{ item }">
						<div class="no-wrap">
							<div>
								<client-modal :client="item"></client-modal>
								<v-btn color="error" class="elevation-2 padlessBtn ml-2" outlined small dark @click="deleteClient(item._id)">
									<v-icon color="error" small>mdi-delete</v-icon>
								</v-btn>
							</div>
						</div>
					</template>
				</v-data-table>

				<confirm ref="confirm" />
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%" :style="$vuetify.breakpoint.xs ? `bottom: 56px;` : 'bottom: 0;'">
			<v-toolbar flat dense class="mx-3 rounded-t-lg">
				<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

				<v-select
					class="perPage"
					v-model="itemsPerPage"
					:items="items"
					item-text="text"
					item-value="val"
					label="Select"
					hide-details
					return-object
					single-line
					dense
					outlined
				></v-select>
			</v-toolbar>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import ClientModal from "@/components/admin/clients/ClientModal.vue";
import Confirm from "@/components/Confirm";
import { format, isSameDay } from "date-fns";

export default {
	components: {
		ClientModal,
		Confirm,
	},
	data() {
		return {
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
			page: 1,
			pageCount: 0,
			windowSize: {
				x: 0,
				y: 0,
			},
			search: "",
			tooltip: false,
			headers: [
				{ text: "Name", value: "name" },
				{ text: "API Key", value: "apiKey" },
				{ text: "Usage", value: "usage" },
				{
					text: "Actions",
					value: "edit",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
			],
			refreshLoading: false,
		};
	},
	mounted() {
		this.refresh();

		this.$store.dispatch("admin/getClients");
	},
	watch: {
		page() {
			this.selected = [];
		},

		itemsPerPage(newVal) {
			localStorage.carsPerPage = JSON.stringify(newVal);
		},
	},
	computed: {
		...mapState({
			clients: (state) => state.admin.clients.clients,
		}),
	},
	methods: {
		isToday(date) {
			return isSameDay(new Date(), new Date(date));
		},

		formattedUsageDate(date) {
			return format(new Date(date), "dd/MM/yyy, HH:mm");
		},

		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		async copyText(text) {
			await navigator.clipboard.writeText(text);

			const message = "Copied to clipboard";
			this.$toast.info(message);
		},

		async refresh() {
			this.refreshLoading = true;

			try {
				this.$store.dispatch("admin/getClients");

				this.refreshLoading = false;
			} catch (err) {
				const message = err;
				this.$toast.error(message);
			}
		},

		async deleteClient(id) {
			const res = await this.$refs.confirm.open("WARNING", "This client will lose access to the Client API", "error", "mdi-exclamation-thick");

			if (res === false) {
				return;
			}

			try {
				await this.$store.dispatch("admin/deleteClient", id);
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},
	},
};
</script>
