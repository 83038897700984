import users from "./users.store";
import sites from "./sites.store";
import devices from "./devices.store";
import clients from "./clients.store";
import firmware from "./firmware.store";

export default {
	namespaced: true,
	modules: {
		users,
		sites,
		devices,
		clients,
		firmware,
	},
};
