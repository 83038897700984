import Vue from "vue";

Vue.mixin({
	methods: {
		batteryFLA(battVoltage) {
			// Define battery levels and corresponding voltages
			const voltageLevels = [
				{ percentage: 100, voltage: 51.78 },
				{ percentage: 95, voltage: 51.38 },
				{ percentage: 90, voltage: 50.97 },
				{ percentage: 85, voltage: 50.57 },
				{ percentage: 80, voltage: 50.16 },
				{ percentage: 75, voltage: 49.76 },
				{ percentage: 70, voltage: 49.36 },
				{ percentage: 65, voltage: 48.95 },
				{ percentage: 60, voltage: 48.55 },
				{ percentage: 55, voltage: 48.14 },
				{ percentage: 50, voltage: 47.74 },
				{ percentage: 45, voltage: 47.33 },
				{ percentage: 40, voltage: 46.93 },
				{ percentage: 35, voltage: 46.52 },
				{ percentage: 30, voltage: 46.12 },
				{ percentage: 25, voltage: 45.72 },
				{ percentage: 20, voltage: 45.31 },
				{ percentage: 15, voltage: 44.91 },
				{ percentage: 10, voltage: 44.5 },
				{ percentage: 5, voltage: 44.1 },
				{ percentage: 0, voltage: 43.69 },
			];

			// Return null if battVoltage is null
			if (battVoltage === null) {
				return null;
			}

			// Iterate over voltage levels from highest to lowest
			for (let i = 0; i < voltageLevels.length; i++) {
				if (battVoltage >= voltageLevels[i].voltage) {
					return voltageLevels[i].percentage;
				}
			}

			// Default return value
			return 0;
		},

		batteryGas(battVoltage) {
			// Return percentages based on a 12 fla battery
			const voltageLevels = [
				{ percentage: 100, voltage: 12.7 },
				{ percentage: 90, voltage: 12.4 },
				{ percentage: 80, voltage: 12.2 },
				{ percentage: 70, voltage: 12 },
				{ percentage: 60, voltage: 11.9 },
				{ percentage: 50, voltage: 11.8 },
				{ percentage: 40, voltage: 11.7 },
				{ percentage: 30, voltage: 11.6 },
				{ percentage: 20, voltage: 11.5 },
				{ percentage: 10, voltage: 11.4 },
				{ percentage: 0, voltage: 11.3 },
			];

			// Return null if battVoltage is null
			if (battVoltage === null) {
				return null;
			}

			// Iterate over voltage levels from highest to lowest
			for (let i = 0; i < voltageLevels.length; i++) {
				if (battVoltage >= voltageLevels[i].voltage) {
					return voltageLevels[i].percentage;
				}
			}

			// Default return value
			return 0;
		},

		battIcon(battPercent) {
			if (battPercent === null) {
				return "mdi-battery-off-outline mdi-rotate-90";
			} else if (battPercent >= 90) {
				return "mdi-battery mdi-rotate-90";
			} else if (battPercent >= 80) {
				return "mdi-battery-90 mdi-rotate-90";
			} else if (battPercent >= 70) {
				return "mdi-battery-80 mdi-rotate-90";
			} else if (battPercent >= 60) {
				return "mdi-battery-70 mdi-rotate-90";
			} else if (battPercent >= 50) {
				return "mdi-battery-60 mdi-rotate-90";
			} else if (battPercent >= 40) {
				return "mdi-battery-50 mdi-rotate-90";
			} else if (battPercent >= 30) {
				return "mdi-battery-40 mdi-rotate-90";
			} else if (battPercent >= 20) {
				return "mdi-battery-30 mdi-rotate-90";
			} else if (battPercent >= 10) {
				return "mdi-battery-20 mdi-rotate-90";
			} else if (battPercent <= 0) {
				return "mdi-battery-outline mdi-rotate-90";
			} else {
				return "mdi-battery-off-outline mdi-rotate-90";
			}
		},

		batteryDisplay(car) {
			const isOnCharge = car.battCharge;
			const voltage = Number(car.battVoltage);

			let battPercent;

			if (this.vehicle(car).power === "Gas") {
				battPercent = this.batteryGas(voltage);
			} else {
				battPercent = this.batteryFLA(voltage);
			}

			// if (!car.battPercent || car.battPercent === null || car.battPercent === 0 || car.battPercent === "0") {
			// 	battPercent = this.batteryFLA(car.battVoltage);
			// } else {
			// 	battPercent = car.battPercent;
			// }

			if (isOnCharge) {
				return {
					icon: "mdi-battery-charging mdi-rotate-90",
					text: "Charging",
					color: "warning",
				};
			}

			let icon;

			if (this.vehicle(car).power === "Gas") {
				icon = "mdi-gas-station";
			} else if (this.vehicle(car).power === "Li-Ion") {
				icon = "mdi-lightning-bolt-circle";
			} else {
				icon = this.battIcon(battPercent);
			}

			return {
				icon: icon,
				text: this.vehicle(car).power === "Li-Ion" ? "Li-Ion" : voltage ? `${battPercent}%` : "??",
				color: "success",
			};
		},

		vehicle(item) {
			// Check if item.vehicleInfo is defined
			if (!item.vehicleInfo) {
				// Explicit handling when item.vehicleInfo is not defined
				return {
					model: "None", // model is undefined because vehicleInfo is not present
					power: "Unknown", // power is null because vehicleInfo is not present
				};
			}

			// Proceed if item.vehicleInfo is defined
			const model = item.vehicleInfo?.cart?.model || "None";
			const power = item.vehicleInfo?.power || "Unknown";

			return {
				// Return undefined if the model is "None", otherwise return the actual model
				model: model !== "None" ? model : undefined,
				// Return null if the power is "Unknown", otherwise return the actual power
				power: power !== "Unknown" ? power : null,
			};
		},
	},
});
