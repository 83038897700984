<template>
	<div v-resize="onResize">
		<v-card color="grey" flat class="rounded-lg" style="overflow-y: scroll" :height="$vuetify.breakpoint.xs ? windowSize.y - 98 - 24 - 72 : windowSize.y - 98 - 24 - 22">
			<v-card-text>
				<v-data-iterator
					:items="devicesWithUpdatesBookingsRide"
					sort-by="carNumber"
					:page.sync="page"
					:items-per-page="itemsPerPage.val"
					hide-default-footer
					:no-data-text="$t('fleetPage.table.noDevicesText')"
					@page-count="pageCount = $event"
				>
					<template v-slot:default="props">
						<v-row>
							<v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4">
								<car-card :item="item" @cardClick="openCarSidebar(item)" />
							</v-col>
						</v-row>
					</template>
				</v-data-iterator>
			</v-card-text>
		</v-card>
		<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%" :style="$vuetify.breakpoint.xs ? `bottom: 56px;` : 'bottom: 0;'">
			<v-toolbar flat dense class="mx-3 rounded-t-lg">
				<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

				<v-select
					class="perPage"
					v-model="itemsPerPage"
					:items="items"
					item-text="text"
					item-value="val"
					label="Select"
					hide-details
					return-object
					single-line
					dense
					outlined
				></v-select>
			</v-toolbar>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CarCard from "@/components/fleet/CarCard.vue";

export default {
	components: {
		CarCard,
	},
	data() {
		return {
			windowSize: {
				x: 0,
				y: 0,
			},
			page: 1,
			pageCount: 0,
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
		};
	},
	watch: {
		itemsPerPage(newVal) {
			localStorage.carsPerPage = JSON.stringify(newVal);
		},
	},
	mounted() {
		if (localStorage.carsPerPage) {
			this.itemsPerPage = JSON.parse(localStorage.carsPerPage);
		}
	},
	computed: {
		...mapGetters(["devicesWithUpdatesBookingsRide"]),
	},
	methods: {
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		openCarSidebar(payload) {
			if (this.hasRole("user")) {
				this.$store.commit("setSelectedDeviceId", payload._id);
			}
		},
	},
};
</script>
