<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-title>
					<v-icon small left>mdi-battery</v-icon>
					Calibrate Battery
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24 pt-4">
			<v-card-text class="title font-weight-bold text-center">
				<span>Battery Calibration</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="battForm" v-model="valid" lazy-validation @submit.prevent="calibrateBattery">
					<v-text-field
						autofocus
						prepend-inner-icon="mdi-car-battery"
						placeholder="Battery Calibration"
						label="Battery Calibration"
						hide-details="auto"
						v-model="battCalibration"
						:rules="[rules.maxBatt, rules.minBatt, rules.required]"
						type="number"
						outlined
						rounded
						dense
						clearable
						class="mb-3"
					></v-text-field>
					<v-btn block rounded @click="resetBatt" color="warning" class="mt-3"> Reset Battery Calibration </v-btn>
					<v-btn block rounded @click="calibrateBattery" color="primary" class="mt-3" :loading="loading" :disabled="!valid"> Save </v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		selectedCars: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			dialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				maxBatt: (value) => value <= 77.99 || "Value should be equal or less than 77.99",
				minBatt: (value) => value >= 3 || "Value should be greater than or equal to 3",
			},
			battCalibration: null,
			valid: true,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		resetBatt() {
			this.battCalibration = 75;
		},

		async calibrateBattery() {
			if (this.$refs.battForm.validate()) {
				this.loading = true;

				try {
					const devices = this.selectedCars.map((selected) => {
						return selected._id;
					});

					const batt = Number(this.battCalibration);

					await this.$store.dispatch("admin/calibrateDevicesBattery", {
						ids: devices,
						value: batt.toFixed(2),
					});
				} catch (err) {
					this.$toast.error(err.error.message || "Error occurred while calibrating battery");
				} finally {
					this.loading = false;
					this.dialog = false;
				}
			}
		},

		reset() {
			this.battCalibration = null;
			this.$refs.battForm.resetValidation();
			this.$emit("clearSelected", []);
		},
	},
};
</script>
