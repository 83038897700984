<template>
	<div>
		<v-card outlined class="rounded-lg font-weight-bold" :color="color">
			<div class="pa-4">
				<v-list-item>
					<v-list-item-icon>
						<v-icon :color="textColor">{{ icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title
							class="accent--text font-weight-bold"
							:class="`${textColor}--text`"
						>
							{{ title }}
						</v-list-item-title>
						<v-list-item-subtitle
							class="text-wrap"
							:class="`${textColor}--text`"
						>
							{{ subtitle }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</div>
		</v-card>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		color: {
			type: String,
			default: "grey",
		},
		textColor: {
			type: String,
			default: "accent",
		},
		icon: String,
		title: String,
		subtitle: String,
	},
};
</script>
