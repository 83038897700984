import Vue from "vue";

Vue.mixin({
	computed: {},
	methods: {
		doesCarHaveIndividualAssignment(car, site, val) {
			if (val === "lockFrom") {
				return car.lockFrom !== site.lockFrom ? true : false;
			}

			if (val === "wifiSSID") {
				return car.wifiSSID !== "" && car.wifiSSID !== site.wifiSSID ? true : false;
			}

			return;
		},

		deviceSpeed(speed) {
			// if localstorage speed is set to mph, return deviceSpeedMph
			if (localStorage.getItem("speedUnit") === "mph") {
				return this.deviceSpeedMph(speed);
			}

			// if localstorage speed is set to kph, return deviceSpeedKph

			return this.deviceSpeedKph(speed);
		},

		deviceSpeedMph(speed) {
			speed = Number(speed).toFixed(0);

			return `${(speed * 0.621371).toFixed(0)}mph`;
		},

		deviceSpeedKph(speed) {
			speed = Number(speed).toFixed(0);

			return `${speed}kph`;
		},

		odometerTotal(odometer, offset) {
			let value = Number(odometer) + Number(offset);

			return value.toFixed(0);
		},

		firmwareStatusText(update) {
			switch (update) {
				case 1:
					return "Pending update";
				case 2:
					return "Downloading";
				case 3:
					return "Download error";
				case 4:
					return "Download successful";
				default:
					return "Up to date";
			}
		},

		firmwareStatusColor(update) {
			switch (update) {
				case 1:
					return "warning";
				case 2:
					return "success";
				case 3:
					return "danger";
				case 4:
					return "success";
				default:
					return "accent lighten-4";
			}
		},

		firmwareStatusDld(update) {
			switch (update) {
				case 2:
					return true;
				default:
					return false;
			}
		},
	},
});
