<template>
	<div>
		<v-btn v-if="hasKeypadEnabled()" @click="publishCodes" color="purple" class="mr-2 white--text" small :disabled="publishDisabled || !hasRole('user')">
			<v-icon small left>mdi-progress-upload</v-icon>
			Publish to devices
		</v-btn>

		<v-dialog v-model="loading" persistent width="300">
			<v-card color="purple" dark>
				<v-card-text class="text-center subtitle-1 font-weight-bold pt-4"> Publishing codes. Please wait... </v-card-text>
				<v-card-text>
					<v-progress-linear indeterminate color="white" class="mb-0" />
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: {
		selectedSite: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			publishDisabled: false,
			publishDisabledTime: 30000, // 30 seconds
		};
	},
	methods: {
		async publishCodes() {
			this.loading = true;
			this.publishDisabled = true;
			localStorage.setItem("publishDisabled", Date.now().toString());

			try {
				await this.$store.dispatch("publishSiteCodes");
			} catch (err) {
				const message = err.response.data;
				this.$toast.error(message);
			} finally {
				this.loading = false;

				// Set publishDisabled to false after 2 minutes.
				setTimeout(() => {
					this.publishDisabled = false;
					localStorage.removeItem("publishDisabled");
				}, this.publishDisabledTime); // 2 minutes
			}
		},
	},

	created() {
		const publishDisabledTimestamp = localStorage.getItem("publishDisabled");
		if (publishDisabledTimestamp) {
			const diff = Date.now() - Number(publishDisabledTimestamp);
			if (diff < this.publishDisabledTime) {
				this.publishDisabled = true;
				setTimeout(() => {
					this.publishDisabled = false;
					localStorage.removeItem("publishDisabled");
				}, this.publishDisabledTime - diff);
			}
		}
	},
};
</script>
