<template>
	<div>
		<v-expand-transition>
			<v-toolbar v-if="showZoneToolbar" dense class="zoneToolbar rounded-xl elevation-12" color="accent">
				<v-btn v-if="!drawingZone" small rounded :disabled="editedZone && editedZone.geometry ? true : false" color="primary" @click="drawingZone = !drawingZone">
					<v-icon left small>mdi-plus</v-icon>
					{{ $t("zones.add") }}
				</v-btn>
				<v-btn v-else small rounded color="primary" @click="drawingZone = !drawingZone">
					<v-icon left small>mdi-cancel</v-icon>
					{{ $t("general.cancel") }}
				</v-btn>
			</v-toolbar>
		</v-expand-transition>

		<v-slide-x-reverse-transition>
			<v-card v-if="editedZone && editedZone.geometry" class="zoneCard rounded-lg elevation-14">
				<v-card-title class="title accent white--text" style="border-bottom: 3px solid #406dfa !important">
					{{ formTitle }}
				</v-card-title>
				<v-form ref="form" lazy-validation>
					<v-card-text>
						<v-alert class="mb-4" outlined text :color="editedZone.properties.color">{{ zoneTypeDescription }}</v-alert>
						<v-select
							v-model="selectedZoneType"
							outlined
							hide-details
							dense
							:menu-props="{ offsetY: true }"
							:items="zoneTypes"
							item-text="name"
							item-value="val"
							:label="$t('zones.type')"
						></v-select>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-btn v-if="editedZone._id" @click="deleteZone()" :loading="loading.delete" small color="error" class="padlessBtn" depressed>
							<v-icon small> mdi-trash-can </v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn @click="cancel()" :disabled="loading.save" text color="primary" small>{{ $t("general.cancel") }}</v-btn>
						<v-btn @click="save()" :loading="loading.save" color="primary" depressed small>{{ $t("general.save") }}</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-slide-x-reverse-transition>
		<confirm ref="confirm" />
	</div>
</template>

<script>
import Confirm from "@/components/Confirm";
import { mapState } from "vuex";
import L from "leaflet";
import "@geoman-io/leaflet-geoman-free";

export default {
	props: {
		showZoneToolbar: {
			type: Boolean,
			default: false,
		},
		map: {
			type: Object,
			required: true,
			default: null,
		},
	},
	components: {
		Confirm,
	},
	data() {
		return {
			zonesGroup: null,
			editedZonesGroup: null,

			editedZone: null,

			drawingZone: false,
			editingZone: false,
			changesMade: false,

			polylineWeight: 12,
		};
	},
	watch: {
		zones: {
			handler() {
				this.renderZones();
			},
			deep: true,
		},

		drawingZone(val) {
			if (!val) {
				this.map.pm.disableDraw();
			} else {
				this.addZone();
			}
		},

		showZoneToolbar(show) {
			if (show) {
				this.showZones();
			} else {
				this.hideZones();
			}
		},
	},
	mounted() {
		this.editedZone = this.initializeEditedZone();
		this.zonesGroup = L.featureGroup().addTo(this.map);
		this.editedZonesGroup = L.featureGroup().addTo(this.map);
	},
	computed: {
		...mapState({
			zones: (state) => state.zones.zones,
			loading: (state) => state.zones.loading,
		}),

		zoneTypes() {
			return [
				{ name: "Parking", val: "parking", color: "purple" },
				{ name: "Restriction", val: "restriction", color: "red" },
				...(this.hasRole("admin") ? [{ name: "Geofence", val: "geofence", color: "#3388ff" }] : []),
			];
		},

		isDirty() {
			return JSON.stringify(this.editedZone) !== JSON.stringify(this.zones.find((z) => z._id === this.editedZone._id));
		},

		formTitle() {
			return this.editedZone && this.editedZone._id ? "Edit Zone" : "Add Zone";
		},

		zoneTypeDescription() {
			switch (this.editedZone.properties.type) {
				case "geofence":
					return this.$t("zones.typeDesc.geofence");
				case "parking":
					return this.$t("zones.typeDesc.parking");
				case "restriction":
					return this.$t("zones.typeDesc.restriction");
				default:
					return "";
			}
		},

		selectedZoneType: {
			get() {
				return this.editedZone.properties.type;
			},
			set(newValue) {
				const zone = this.zoneTypes.find((z) => z.val === newValue);

				if (zone) {
					this.editedZone.properties.type = zone.val;
					this.editedZone.properties.color = zone.color;
					this.renderEditedZone(this.editedZone);
				}
			},
		},
	},
	methods: {
		initializeEditedZone() {
			const defaultZone = this.zoneTypes[0]; // Default to parking
			return {
				properties: {
					type: defaultZone.val,
					color: defaultZone.color,
				},
				geometry: null,
			};
		},

		addZone() {
			this.editedZonesGroup = L.featureGroup().addTo(this.map);
			// Enable drawing
			this.map.pm.enableDraw("Polygon", {
				allowSelfIntersection: false,
				pathOptions: {
					color: this.editedZone.properties.color,
					fillColor: this.editedZone.properties.color,
					weight: this.polylineWeight,
				},
				templineStyle: {
					color: "#406DFA",
					dashArray: "5, 5",
					weight: this.polylineWeight,
				},
				hintlineStyle: {
					color: "#406DFA",
					dashArray: "5, 5",
					weight: this.polylineWeight,
				},
			});

			// Listen for draw complete event
			this.map.on("pm:create", (e) => {
				this.handleZoneCreation(e);
			});
		},

		handleZoneCreation(e) {
			this.drawingZone = false;
			this.editedZonesGroup.addLayer(e.layer);
			this.editedZone.geometry = e.layer.toGeoJSON().geometry;
		},

		async cancel() {
			if (this.isDirty) {
				const res = await this.$refs.confirm.open(this.$t("general.close"), this.$t("general.closeWithoutSave"), "accent", "mdi-alert");
				if (!res) return;
			}

			this.finishEditing();
		},

		async finishEditing() {
			this.editedZonesGroup.clearLayers();
			this.editedZone = this.initializeEditedZone();
			this.editingZone = false;

			this.renderZones();
		},

		async save() {
			if (this.editedZone._id) {
				await this.$store.dispatch("updateZone", this.editedZone);
				this.$toast.success(this.$t("zones.toast.zoneUpdated"));
			} else {
				await this.$store.dispatch("createZone", this.editedZone);

				this.$toast.success(this.$t("zones.toast.zoneCreated"));
			}

			this.changesMade = true;
			this.finishEditing();
		},

		async showZones() {
			await this.$store.dispatch("getSiteZones");
		},

		renderZones() {
			// Clear previous zones
			this.zonesGroup.clearLayers();

			// deep copy zones to avoid modifying the original array
			const zones = JSON.parse(JSON.stringify(this.zones));

			// Sort zones to ensure geofences are added first
			const sortedZones = zones.sort((a, b) => {
				if (a.properties.type === "geofence") return -1;
				if (b.properties.type === "geofence") return 1;
				return 0;
			});

			// Add new zones
			sortedZones.forEach((zone) => {
				const layer = L.geoJSON(zone.geometry, {
					style: {
						color: zone.properties.color,
						fillColor: zone.properties.color,
						fillOpacity: zone.properties.type === "geofence" ? 0 : 0.4,
						weight: this.polylineWeight,
					},
				});

				layer.on("click", (e) => {
					this.editZone(e, zone);
				});

				// Add layer to map
				layer.addTo(this.zonesGroup);
			});
		},

		renderEditedZone(zone) {
			this.editedZonesGroup.clearLayers();

			const layer = L.geoJSON(zone.geometry, {
				style: {
					color: zone.properties.color,
					fillColor: zone.properties.color,
					fillOpacity: zone.properties.type === "geofence" ? 0 : 0.4,
					weight: this.polylineWeight,
				},
			});

			this.editedZonesGroup.addLayer(layer);

			layer.pm.enable({ allowSelfIntersection: false });

			layer.on("pm:change", (e) => {
				this.editedZone.geometry = e.layer.toGeoJSON().geometry;
			});
		},

		editZone(layer, zone) {
			if (this.editingZone || this.drawingZone) {
				return;
			}

			this.editingZone = true;

			this.editedZone = JSON.parse(JSON.stringify(zone));
			this.zonesGroup.removeLayer(layer.target);

			this.renderEditedZone(zone);
		},

		hideZones() {
			this.zonesGroup.clearLayers();
			this.editedZonesGroup.clearLayers();

			if (this.changesMade) {
				this.publishChanges();
			}
		},

		async deleteZone() {
			const res = await this.$refs.confirm.open(this.$t("zones.delete"), this.$t("zones.deleteConfirm"), "error", "mdi-delete-alert");

			if (!res) return;

			try {
				await this.$store.dispatch("deleteZone", this.editedZone._id);
				this.changesMade = true;

				this.finishEditing();
			} catch (err) {
				this.$toast.error(err.error.message);
			}
		},

		publishChanges() {
			this.$store.dispatch("publishZones");
			this.changesMade = false;
		},
	},
	beforeDestroy() {
		this.hideZones();
	},
};
</script>

<style scoped>
@import "~@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
.zoneToolbar {
	position: absolute !important;
	margin-left: auto;
	margin-right: auto;
	right: 0;
	left: 0;
	top: 60px;
	z-index: 1000;
	width: max-content;
}

.zoneCard {
	position: absolute !important;
	right: 9px;
	top: 8px;
	z-index: 1000;
	width: 300px;
}
</style>
