<template>
	<div>
		<apexchart :height="chartOptions.chart.height" :options="chartOptions" :series="seriesData"></apexchart>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	props: {
		rides: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		...mapState({
			devices: (state) => state.devices.devices,
		}),

		...mapGetters(["deviceFromId"]),

		validRides() {
			return this.rides.filter((ride) => this.getCarNumber(ride));
		},

		categories() {
			// Assuming each device has a carNumber and you want to sort them numerically
			return [...new Set(this.devices.map((device) => device.carNumber))].sort((a, b) => parseInt(a) - parseInt(b));
		},

		seriesData() {
			// Calculate ride counts for each carNumber in categories
			const rideCounts = this.categories.map((carNumber) => {
				// Filter validRides for the current carNumber, which is ensured to be from devices
				return this.validRides.filter((ride) => this.getCarNumber(ride) === carNumber).length;
			});

			return [{ name: "Rounds", type: "bar", data: rideCounts }];
		},

		chartOptions() {
			return {
				chart: {
					height: 250,
					type: "bar",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					dropShadow: {
						enabled: true,
						top: 4,
						left: 0,
						blur: 2,
						opacity: 0.1,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: (val) => {
						return parseInt(val) === val && val > 0 ? val : "";
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 4,
					},
				},
				xaxis: {
					categories: this.categories,
					labels: {
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					crosshairs: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					title: {
						text: "Rounds",
					},
					labels: {
						showDuplicates: false,
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
						formatter: (val) => {
							return parseInt(val) === val ? val : "";
						},
					},
				},
				grid: {
					show: false,
				},
				colors: ["#406DFA", "#9C27B0"],
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex }) {
						// vuetify chip with the date and the number of rides
						return `<div class="pa-2 font-weight-bold accent--text">
              <span >${series[seriesIndex][dataPointIndex]} Rounds</span>
            </div>`;
					},
					followCursor: true,
				},
			};
		},
	},
	methods: {
		getCarNumber(ride) {
			// Directly return ride.meta.carNumber if available
			if (ride.carNumber) {
				return ride.carNumber;
			}
			// Otherwise, use the mapped device's carNumber
			const device = this.deviceFromId(ride.device);
			return device ? device.carNumber : null; // Ensure this method correctly handles missing data
		},
	},
};
</script>
