import * as sitesService from "@/services/guest/sites.service";

export default {
	namespaced: true,
	state: {
		site: null,
		virtualKey: null,
	},
	mutations: {
		setSite(state, site) {
			state.site = site;
		},

		setVirtualKey(state, virtualKey) {
			state.virtualKey = virtualKey;
		},
	},
	actions: {
		async getSite({ commit }, id) {
			const site = await sitesService.getSite(id);
			commit("setSite", site);
		},

		async getVirtualKey({ commit }, payload) {
			const virtualKey = await sitesService.getVirtualKey(payload);
			commit("setVirtualKey", virtualKey);
		},
	},
};
