<template>
	<div>
		<v-row no-gutters class="mb-4 px-4">
			<h2>{{ $t("settingsPage.accountSettings.title") }}</h2>
			<v-spacer></v-spacer>
			<v-chip label color="primary" class="padlessBtn elevation-8">
				<v-icon>mdi-account-cog-outline</v-icon>
			</v-chip>
		</v-row>
		<v-row no-gutters class="accent--text">
			<v-col cols="12" class="rounded-lg">
				<v-card elevation="1" color="white" class="rounded-lg">
					<v-form ref="emailForm" v-model="validEmail" lazy-validation>
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="min-width: 120px">
										{{ $t("settingsPage.accountSettings.email") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field dense :rules="[rules.required, rules.email]" hide-details="auto" outlined color="accent" v-model="defaultItem.email"></v-text-field>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="mt-2">
									<v-spacer></v-spacer>
									<v-btn @click="cancel" small outlined class="mr-2" color="primary"> Cancel </v-btn>
									<v-btn small depressed color="primary" @click="updateEmail" :loading="loading1" :disabled="loading1 || !validEmail">
										{{ $t("general.save") }}
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
					<v-divider class="mx-8 primary lighten-4" />
					<v-form ref="passForm" v-model="validPassword" lazy-validation>
						<v-card-text>
							<v-list>
								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="min-width: 120px">
										{{ $t("settingsPage.accountSettings.newPswd") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field
											v-model="password"
											:rules="[rules.required, rules.passwordMatch, rules.min]"
											hide-details="auto"
											dense
											outlined
											color="accent"
											:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPass ? 'text' : 'password'"
											@click:append="showPass = !showPass"
										></v-text-field>
									</v-list-item-content>
								</v-list-item>

								<v-list-item>
									<v-list-item-action class="font-weight-bold accent--text text-wrap" style="width: 120px">
										{{ $t("settingsPage.accountSettings.confNewPswd") }}
									</v-list-item-action>
									<v-list-item-content class="pa-0">
										<v-text-field
											v-model="confirmPassword"
											:rules="[rules.required, rules.passwordMatch, rules.min]"
											hide-details="auto"
											dense
											outlined
											color="accent"
											:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
											:type="showPass ? 'text' : 'password'"
											@click:append="showPass = !showPass"
										></v-text-field>
									</v-list-item-content>
								</v-list-item>
								<v-list-item class="mt-2">
									<v-spacer></v-spacer>
									<v-btn @click="cancel" small outlined class="mr-2" color="primary"> Cancel </v-btn>
									<v-btn small depressed color="primary" @click="updatePassword" :loading="loading2" :disabled="loading2 || !validPassword">
										{{ $t("general.save") }}
									</v-btn>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-form>
				</v-card>
			</v-col>
		</v-row>

		<confirm ref="confirm" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import Confirm from "@/components/Confirm";

export default {
	components: {
		Confirm,
	},
	data() {
		return {
			showPass: false,
			password: "",
			confirmPassword: "",
			loading1: false,
			loading2: false,
			validEmail: true,
			validPassword: true,
			rules: {
				email: (v) => /.+@.+\..+/.test(v) || this.$i18n.t("rules.emailValid"),
				required: (value) => !!value || this.$i18n.t("rules.required"),
				min: (v) => (v && v.length >= 6) || this.$i18n.t("rules.length8"),
				passwordMatch: (value) => value === this.password || this.$i18n.t("rules.pswdConf"),
			},
			defaultItem: {},
		};
	},
	watch: {
		user() {
			this.defaultItem = Object.assign({}, this.user);
		},
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
		}),
	},
	mounted() {
		this.defaultItem = Object.assign({}, this.user);
	},
	methods: {
		async updateEmail() {
			if (this.$refs.emailForm.validate()) {
				const res = await this.$refs.confirm.open(`${this.$i18n.t("settingsPage.accountSettings.confirmEmail")}`, "", "warning", "mdi-at");

				if (res !== true) {
					return;
				}

				try {
					await this.$store.dispatch("updateUser", { email: this.formatEmail(this.defaultItem.email) });

					const message = "Email updated successfully";
					this.$toast.success(message);
				} catch (err) {
					const message = err.error.message;
					this.$toast.error(message);
				} finally {
					this.loading1 = false;
				}
			}
		},

		async updatePassword() {
			if (this.$refs.passForm.validate()) {
				const res = await this.$refs.confirm.open(`${this.$i18n.t("settingsPage.accountSettings.confirmPswd")}`, "", "warning", "mdi-lock-reset");

				if (res !== true) {
					return;
				}

				this.loading2 = true;

				try {
					await this.$store.dispatch("updateUser", { password: this.confirmPassword });

					const message = "Password updated successfully";
					this.$toast.success(message);
				} catch (err) {
					const message = err.error.message;
					this.$toast.error(message);
				} finally {
					this.loading2 = false;
				}
			}
		},

		cancel() {
			this.defaultItem = Object.assign({}, this.user);
		},

		logout() {
			this.$store.dispatch("logout");
		},
	},
};
</script>
