<template>
	<v-container fluid class="pa-0 bg">
		<v-row no-gutters class="gp-full-height">
			<v-col cols="12" sm="3" lg="2" class="accent lighten-1">
				<v-list nav color="transparent" dark style="position: sticky; top: 0px">
					<v-subheader class="white--text font-weight-bold title my-2">
						Settings
						<v-spacer></v-spacer>
						<!-- <v-btn :href="helpURL" target="blank" dark icon>
							<v-icon>mdi-help-box</v-icon>
						</v-btn> -->
					</v-subheader>

					<v-list-item-group v-model="selectedItem" color="accent">
						<v-list-item class="white--text font-weight-bold" v-for="(item, i) in items" :key="i" :to="item.val">
							<v-list-item-icon>
								<v-icon v-text="item.icon"></v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-list>
			</v-col>

			<v-col cols="12" md="9" class="pa-4 mx-auto" :class="$vuetify.breakpoint.smAndDown && 'pt-14'" style="max-width: 750px">
				<router-view />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			drawer: true,
			selectedItem: 0,
			items: [
				{
					text: this.$t("settingsPage.generalSettings.title"),

					val: "/settings/general",
					icon: "mdi-tune-variant",
				},
				{
					text: this.$t("settingsPage.bookingSettings.title"),
					val: "/settings/booking",
					icon: "mdi-calendar-clock-outline",
				},
				{
					text: this.$t("settingsPage.accountSettings.title"),
					val: "/settings/account",
					icon: "mdi-account-cog-outline",
				},
			],
		};
	},
};
</script>
