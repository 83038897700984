import Vue from "vue";

Vue.mixin({
	methods: {
		deviceStatus(device, ride = null) {
			if (!device.onlineStatus) {
				return {
					title: "Offline",
					message: this.$t("ride.status.offline"),
					icon: "mdi-link-variant-off",
					color: "red",
				};
			}

			if (device.key === "1") {
				return {
					title: "Key Mode",
					message: this.$t("ride.status.reqKey"),
					icon: "mdi-key-variant",
					color: "grey darken-3",
				};
			}

			if (ride && ride.status !== 0) {
				return {
					title: "Unlocked",
					message: this.$t("ride.status.inUse"),
					icon: "mdi-lock-open",
					color: "warning",
				};
			}

			return false;
		},
	},
});
