<template>
	<v-dialog v-model="dialog" scrollable width="350px" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" class="mx-2" depressed dark v-bind="attrs" small v-on="on"> Firmware </v-btn>
		</template>
		<v-card :loading="loadingFiles">
			<v-toolbar flat color="accent" dense>
				<template slot="progress">
					<v-progress-linear color="primary" indeterminate></v-progress-linear>
				</template>

				<span class="subtitle-1 white--text">Firmware Manager</span>
				<v-spacer></v-spacer>
				<upload-modal @refresh="listFiles" />
			</v-toolbar>
			<v-divider></v-divider>

			<v-tabs v-model="tab" background-color="grey" class="accent--text" grow>
				<v-tabs-slider></v-tabs-slider>

				<v-tab href="#wifi"> WiFi </v-tab>

				<v-tab href="#gsm"> GSM </v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item value="wifi">
					<v-card flat>
						<v-card-text class="fileScroller">
							<v-list nav dense>
								<v-list-item v-if="loadingFiles">
									<v-list-item-content>
										<v-progress-circular indeterminate color="primary" />
									</v-list-item-content>
								</v-list-item>

								<v-list-item-group v-model="selectedFile" color="primary" v-else-if="firmware.length > 0">
									<v-list-item v-for="(file, i) in firmware" :key="i" :value="file">
										<v-list-item-content>
											<v-list-item-title v-text="file.name"></v-list-item-title>
										</v-list-item-content>
										<v-list-item-action v-if="selectedFile === file">
											<v-btn x-small color="error" :loading="loadingDelete" text @click="deleteFile(file.name)">
												<v-icon small>mdi-trash-can-outline</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>
								</v-list-item-group>

								<v-list-item v-else>
									<v-list-item-content>
										<v-list-item-title>No files uploaded</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
						<div v-if="selectedFile">
							<v-card-text v-if="selectedFile.downloadUrl">
								<v-divider class="mb-2"></v-divider>
								<span>
									Download:
									<a :href="selectedFile.downloadUrl" target="_blank" class="font-weight-bold">Download</a>
								</span>
							</v-card-text>
							<v-card-text class="pt-0">
								<v-divider class="mb-2"></v-divider>
								<span>
									Selected File:
									<span class="font-weight-bold">{{ selectedFile.name }}</span>
								</span>
							</v-card-text>
						</div>
					</v-card>
				</v-tab-item>

				<v-tab-item value="gsm">
					<v-card flat>
						<v-card-text>
							<v-text-field outlined dense label="File Path" hide-details></v-text-field>
						</v-card-text>

						<v-card-text class="pt-0 fileScroller">
							<v-list nav dense>
								<v-list-item-group color="primary">
									<v-list-item v-for="(path, i) in pathList" :key="i" :value="path" @click="selelctGsmFile(path)">
										<v-list-item-content>
											<v-list-item-title v-text="path"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>

			<v-card-text v-if="selected.length" class="pb-4">
				<v-divider class="mb-4"></v-divider>
				<span>
					Selected Devices:
					<span class="font-weight-bold">{{ selected.length }}</span>
				</span>
			</v-card-text>

			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn v-if="selected.length && selectedFile" color="primary" @click="upload" :loading="loadingUpload" :disabled="!selectedFile.downloadUrl"> Upload </v-btn>
				<v-btn color="accent" text @click="close"> Close </v-btn>
			</v-card-actions>
		</v-card>
		<confirm ref="confirm" />
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";
import UploadModal from "./UploadModal";

import Confirm from "@/components/Confirm";

export default {
	components: {
		Confirm,
		UploadModal,
	},
	props: {
		selectedCars: {
			default: null,
		},
	},
	data() {
		return {
			loadingDelete: false,
			loadingFiles: false,

			dialog: false,
			loadingUpload: false,
			selectedFile: null,
			tab: null,
			selectedPath: null,
			pathList: ["/firmware/SIM7600", "/firmware/SIM7000"],
		};
	},
	watch: {
		dialog(val) {
			if (val) {
				this.listFiles();
			}
		},

		tab() {
			this.selectedFile = null;
		},
	},
	computed: {
		...mapState({
			firmware: (state) => state.admin.firmware.firmware,
		}),

		selected: {
			get() {
				return this.selectedCars;
			},
			set(val) {
				this.$emit("resetSelected", val);
			},
		},
	},
	methods: {
		async selelctGsmFile(path) {
			this.selectedFile = { downloadUrl: path };
		},

		async listFiles() {
			try {
				this.loadingFiles = true;
				await this.$store.dispatch("admin/listFirmware");
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loadingFiles = false;
			}
		},

		async deleteFile(name) {
			try {
				const res = await this.$refs.confirm.open("Delete Firmware?", `Are you sure you want to delete ${name}?`, "error", "mdi-delete-alert");

				this.loadingDelete = true;

				if (res === true) {
					await this.$store.dispatch("admin/deleteFirmware", name);
				}
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loadingDelete = false;
			}
		},

		async upload() {
			try {
				this.loadingUpload = true;

				if (!this.selectedFile.downloadUrl) throw "Invalid download URL";
				if (!this.selected.length) throw "No cars selected";

				const ids = this.selected.map((selected) => {
					return selected._id;
				});

				await this.$store.dispatch("admin/updateDevicesFirmware", { ids, firmware: this.selectedFile.downloadUrl });
			} catch (err) {
				const message = err;
				this.$toast.error(message);
			} finally {
				this.loadingUpload = false;
				this.close();
			}
		},

		close() {
			this.selectedFile = null;
			this.selected = [];
			this.dialog = false;
		},
	},
};
</script>

<style scoped>
.fileScroller {
	overflow-y: auto;
	max-height: 200px;
}
</style>
