<template>
	<v-dialog v-model="dialog" max-width="500px" scrollable>
		<v-card v-if="item">
			<v-card-title>
				<span class="title">{{ translatedFormattedDate(item.createdAt, "dd/mm/yy HH:mm:ss") }}</span>
				<v-spacer></v-spacer>

				<div v-if="item.type === 'log'">
					<v-btn small class="mr-2 padlessBtn" color="primary " depressed @click="$refs.logsList.openAll()">
						<v-icon small>mdi-plus</v-icon>
					</v-btn>
					<v-btn class="padlessBtn" small color="primary" depressed @click="$refs.logsList.closeAll()">
						<v-icon small>mdi-minus</v-icon>
					</v-btn>
				</div>
			</v-card-title>

			<logs-list ref="logsList" v-if="item.type === 'log'" :item="item.data" />

			<div v-if="item.type === 'wifi'">
				<v-simple-table dense>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">SSID</th>
								<th class="text-left">RSSI</th>
								<th class="text-left">Channel</th>
								<th class="text-left">Encrypt</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(network, key) in item.data.Networks" :key="key">
								<td>{{ network.SSID }}</td>
								<td>
									<v-chip :color="wifiIcon(network.RSSI)" small label class="font-weight-bold">
										{{ network.RSSI }}
									</v-chip>
								</td>
								<td>{{ network.Channel }}</td>
								<td>{{ network.Encrypt }}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import LogsList from "@/components/admin/logs/LogsList";

export default {
	components: {
		LogsList,
	},
	data() {
		return {
			dialog: false,
			item: null,
		};
	},
	watch: {
		item(val) {
			if (val) {
				this.dialog = true;
			} else {
				this.dialog = false;
			}
		},

		dialog(val) {
			if (!val) {
				this.$emit("close");
			}
		},
	},
	methods: {
		wifiIcon(wifiSignal) {
			var signal;
			if (wifiSignal) {
				signal = Math.min(Math.max(2 * (Number(wifiSignal) + 100), 0), 100);
			}

			if (signal === null) {
				return "error";
			} else if (signal >= 90) {
				return "success";
			} else if (signal >= 75) {
				return "success";
			} else if (signal >= 50) {
				return "success";
			} else if (signal >= 30) {
				return "warning";
			} else if (signal >= 10) {
				return "error";
			} else if (signal <= 0) {
				return "error";
			} else {
				return "error";
			}
		},
	},
};
</script>
