<template>
	<v-card class="rounded-lg" outlined :color="color">
		<v-list two-line>
			<v-list-item>
				<v-list-item-avatar v-if="icon" size="55" :color="`${color} lighten-5`">
					<v-icon :color="color">{{ icon }}</v-icon>
				</v-list-item-avatar>

				<v-list-item-content :class="`${color}--text`">
					<v-list-item-title v-if="title" class="title font-weight-bold">
						{{ title }}
					</v-list-item-title>
					<v-list-item-subtitle
						v-if="subtitle"
						:class="`${color}--text text-wrap subtitle-1`"
					>
						{{ subtitle }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<div>
			<slot />
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		color: { default: "accent" },
		title: { default: "" },
		subtitle: { default: "" },
		icon: { default: null },
	},
};
</script>
