<template>
	<span>
		<v-chip v-if="service" class="font-weight-bold px-2" color="primary" :small="size === 'small'" :x-small="size === 'x-small'" :large="size === 'large'" label>
			{{ service.name }}
		</v-chip>
		<v-chip class="font-weight-bold ml-2 px-2 white--text" color="purple" :small="size === 'small'" :x-small="size === 'x-small'" :large="size === 'large'" label v-if="price">
			{{ price.name }}
		</v-chip>
	</span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: {
		serviceId: {
			type: String,
			required: false,
			default: null,
		},
		priceId: {
			type: String,
			required: false,
			default: null,
		},
		size: {
			type: String,
			required: false,
			default: "x-small",
		},
	},
	computed: {
		...mapGetters(["serviceFromId", "priceFromIdAndServiceId"]),

		service() {
			return this.serviceId ? this.serviceFromId(this.serviceId) : null;
		},

		price() {
			return this.priceId && this.serviceId ? this.priceFromIdAndServiceId(this.priceId, this.serviceId) : null;
		},
	},
};
</script>
