<template>
	<v-card class="rounded-lg mt-4" outlined>
		<v-card-text v-if="loadingCarts" class="text-center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</v-card-text>

		<v-card-text v-else class="pt-0">
			<v-list>
				<v-list-item>
					<v-list-item-icon>
						<v-icon :color="hasCartsAvailable ? 'success' : 'error'">{{
							hasCartsAvailable
								? "mdi-check-circle-outline"
								: "mdi-close-circle-outline"
						}}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="accent--text font-weight-bold">
							{{ $t("s2r.available.title") }}:
						</v-list-item-title>
						<v-list-item-subtitle v-if="!hasCartsAvailable" class="text-wrap">
							{{ $t("s2r.available.subtitleA") }}
						</v-list-item-subtitle>
						<v-list-item-subtitle v-else class="text-wrap mt-2">
							{{ $t("s2r.available.subtitleB") }}! 🚀
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-expansion-panels flat accordion>
				<v-expansion-panel v-if="hasCartsAvailable">
					<v-expansion-panel-header class="font-weight-bold">
						{{ filteredCarts.length }} Carts
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-chip-group column>
							<v-chip
								v-for="cart in filteredCarts"
								:key="cart.id"
								small
								label
								color="primary"
								class="white--text font-weight-bold mb-4 elevation-4"
								@click="goToCar(cart.id, cart.carNumber)"
							>
								<v-icon small left>mdi-golf-cart</v-icon>
								{{ cart.carNumber }}
							</v-chip>
						</v-chip-group>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
		<confirm ref="confirm" />
	</v-card>
</template>

<script>
import Confirm from "@/components/Confirm";
export default {
	props: {
		siteId: {
			type: String,
			required: true,
		},
	},
	components: {
		Confirm,
	},
	data() {
		return {
			loadingCarts: true,
			availableCarts: [],
		};
	},
	created() {
		this.getAvailableCarts();
	},
	computed: {
		hasCartsAvailable() {
			return this.filteredCarts.length > 0;
		},
		filteredCarts() {
			return this.availableCarts.filter((cart) => !cart.keyMode);
		},
	},
	methods: {
		async getAvailableCarts() {
			try {
				const { data } = await this.$ioteeApiV2.get(
					`/bookings/get-available-carts`,
					{
						params: {
							siteId: this.siteId,
						},
					}
				);

				this.availableCarts = data.availableCarts.sort(
					(a, b) => a.carNumber - b.carNumber
				);
			} catch (err) {
				console.log(err);
			} finally {
				this.loadingCarts = false;
			}
		},

		async goToCar(id, cart) {
			const res = await this.$refs.confirm.open(
				this.$t("s2r.change.title", { cart }),
				this.$t("s2r.change.subtitle", { cart }),
				"success",
				"mdi-golf-cart"
			);

			if (res === true) {
				this.$router.push({
					name: "RideHome",
					params: {
						id,
					},
				});
			}
		},
	},
};
</script>
