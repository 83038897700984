import * as clientsService from "@/services/admin/clients.service";

export default {
	state: {
		clients: [],
		invitedClients: [],
	},

	mutations: {
		setClients(state, payload) {
			state.clients = payload;
		},
	},

	actions: {
		async getClients({ commit }) {
			const clients = await clientsService.getClients();
			commit("setClients", clients);
		},

		async createClient({ dispatch }, payload) {
			await clientsService.createClient(payload);
			await dispatch("getClients");
		},

		async updateClient({ dispatch }, payload) {
			await clientsService.updateClient(payload);
			await dispatch("getClients");
		},

		async deleteClient({ dispatch }, id) {
			await clientsService.deleteClient(id);
			await dispatch("getClients");
		},
	},
};
