<template>
	<div v-resize="onResize">
		<v-data-table
			class="logs-list"
			fixed-header
			:headers="headers"
			:items="logItems"
			item-key="title"
			group-by="id"
			:items-per-page="-1"
			hide-default-footer
			:mobile-breakpoint="0"
			dense
			:height="$vuetify.breakpoint.xs ? windowSize.y - 240 : windowSize.y - 360"
		>
			<template v-slot:group.header="{ items, group, headers, toggle, isOpen }">
				<th :colspan="headers.length" @click.stop="toggle" class="primary lighten-4" style="border-bottom: 1px solid #406dfa !important">
					<v-btn @click.stop="toggle" small icon :ref="group" :data-open="isOpen">
						<v-icon color="accent" small>
							{{ isOpen ? "mdi-minus" : "mdi-plus" }}
						</v-icon>
					</v-btn>
					{{ items[0].category }}
				</th>
			</template>
			<template v-slot:item="{ item }">
				<tr>
					<td>
						{{ item.title }}
					</td>
					<td>{{ item.val }}</td>
				</tr>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { format, fromUnixTime } from "date-fns";

export default {
	props: {
		item: {
			required: true,
		},
	},
	data() {
		return {
			expanded: [],
			windowSize: {
				x: 0,
				y: 0,
			},
			headers: [
				{
					text: "Desc",
					align: "start",
					sortable: false,
					value: "title",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
					width: "200px",
				},
				{
					align: "start",
					text: "Value",
					sortable: false,
					value: "val",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
			],
		};
	},
	mounted() {
		this.closeAll();
	},
	computed: {
		logItems() {
			return [
				// System
				{
					id: 1,
					title: "System Time",
					val: this.formatTimeFromUnix(this.item.a1),
					category: "System",
				},
				{
					id: 1,
					title: "Reset start time",
					val: this.formatTimeFromUnix(this.item.g6),
					category: "System",
				},
				{
					id: 1,
					title: "Firmware version",
					val: this.item.g2,
					category: "System",
				},
				{
					id: 1,
					title: "Last system restart reson",
					val: this.item.j6,
					category: "System",
				},
				{
					id: 1,
					title: "ESPid",
					val: this.item.a2,
					category: "System",
				},
				{
					id: 1,
					title: "siteIDid",
					val: this.item.a3,
					category: "System",
				},
				{
					id: 1,
					title: "Buzzer Enable",
					val: this.yesNo(this.item.f9),
					category: "System",
				},
				{
					id: 1,
					title: "ota status",
					val: this.item.g7,
					category: "System",
				},
				{
					id: 1,
					title: "Use Car Key",
					val: this.yesNo(this.item.f3),
					category: "System",
				},
				{
					id: 1,
					title: "Radio connection",
					val: this.item.b8,
					category: "System",
				},
				{
					id: 1,
					title: "MQTT keep alive time",
					val: this.item.e6,
					category: "System",
				},
				{
					id: 1,
					title: "All Data Ping when locked",
					val: this.item.e7,
					category: "System",
				},
				{
					id: 1,
					title: "Watchdog Timeout",
					val: this.item.e8,
					category: "System",
				},
				// WiFi
				{
					id: 2,
					title: "Wi-Fi on site",
					val: this.yesNo(this.item.b1),
					category: "WiFi",
				},
				{
					id: 2,
					title: "Wi-Fi active",
					val: this.yesNo(this.item.g4),
					category: "WiFi",
				},
				{
					id: 2,
					title: "RSSI",
					val: this.item.b5,
					category: "WiFi",
				},
				{
					id: 2,
					title: "SSID",
					val: this.item.b2,
					category: "WiFi",
				},
				{
					id: 2,
					title: "Password",
					val: this.item.b3,
					category: "WiFi",
				},
				{
					id: 2,
					title: "Local IP",
					val: this.item.b4,
					category: "WiFi",
				},
				{
					id: 2,
					title: "Public IP",
					val: this.item.b9,
					category: "WiFi",
				},
				{
					id: 2,
					title: "Wi-Fi MQTT broker okay",
					val: this.yesNo(this.item.g5),
					category: "WiFi",
				},
				{
					id: 2,
					title: "Wi-Fi Disconnect Threshold",
					val: this.item.b6,
					category: "WiFi",
				},
				{
					id: 2,
					title: "Wi-Fi re-Connect Threshold",
					val: this.item.b7,
					category: "WiFi",
				},
				// Modem and SIM
				{
					id: 3,
					title: "GSM active",
					val: this.yesNo(this.item.c9),
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "csq",
					val: this.item.c7,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "Modem Info",
					val: this.item.c2,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "IMEI",
					val: this.item.c3,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "CCID",
					val: this.item.c4,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "IMSI",
					val: this.item.c5,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "Operator",
					val: this.item.c6,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "SIM apn",
					val: this.item.g3,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "GSM time zone offset",
					val: this.item.h7,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "Network",
					val: this.item.j1,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "Network Status",
					val: this.item.j2,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "mcc",
					val: this.item.j3,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "mns",
					val: this.item.j4,
					category: "Modem and SIM",
				},
				{
					id: 3,
					title: "Network Generation",
					val: this.item.j5,
					category: "Modem and SIM",
				},
				// GPS and Distance
				{
					id: 4,
					title: "Latitude",
					val: this.item.d1,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Longitude",
					val: this.item.d2,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Speed",
					val: this.item.d3,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Altitude",
					val: this.item.d4,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Visible Satellites",
					val: this.item.d5,
					category: "GPS and Distance",
				},

				{
					id: 4,
					title: "Accuracy",
					val: this.item.d7,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Speed Threshold",
					val: this.item.e4,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Track point Distance",
					val: this.item.e5,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Total distance",
					val: this.item.f7,
					category: "GPS and Distance",
				},
				{
					id: 4,
					title: "Stop detect time",
					val: this.item.g1,
					category: "GPS and Distance",
				},
				// Zones
				{
					id: 5,
					title: "In-Use (out of Staging)",
					val: this.item.d8,
					category: "Zones",
				},
				{
					id: 5,
					title: "Parking zone",
					val: this.item.g8,
					category: "Zones",
				},
				{
					id: 5,
					title: "Geofence zone",
					val: this.item.g9,
					category: "Zones",
				},
				{
					id: 5,
					title: "Restricted zone",
					val: this.item.h1,
					category: "Zones",
				},
				// Locking
				{
					id: 6,
					title: "Device Locked",
					val: this.yesNo(this.item.a4),
					category: "Locking",
				},
				{
					id: 6,
					title: "Auto Lock mins remain",
					val: this.item.d9,
					category: "Locking",
				},
				{
					id: 6,
					title: "Sched Locktime",
					val: this.lockFromTime(this.item.e1, this.item.e2),
					category: "Locking",
				},
				{
					id: 6,
					title: "Parking Timeout Mins",
					val: this.item.e3,
					category: "Locking",
				},
				{
					id: 6,
					title: "Parking Lock remain",
					val: this.item.f6,
					category: "Locking",
				},
				{
					id: 6,
					title: "Round Timeout Minutes",
					val: this.item.f4,
					category: "Locking",
				},
				{
					id: 6,
					title: "Round Timeout remain",
					val: this.item.f5,
					category: "Locking",
				},
				// Battery and Charging
				{
					id: 7,
					title: "Car Battery",
					val: this.item.e9,
					category: "Battery and Charging",
				},
				{
					id: 7,
					title: "Battery calibration",
					val: this.item.f1,
					category: "Battery and Charging",
				},
				{
					id: 7,
					title: "Car Battery Charging",
					val: this.yesNo(this.item.f2),
					category: "Battery and Charging",
				},
				// RFID
				{
					id: 8,
					title: "RFIDenabled",
					val: this.item.h3,
					category: "RFID",
				},
				{
					id: 8,
					title: "RFID Code",
					val: this.item.h4,
					category: "RFID",
				},
				{
					id: 8,
					title: "RFID Code SS",
					val: this.item.h5,
					category: "RFID",
				},
				{
					id: 8,
					title: "RFID Code DS",
					val: this.item.h6,
					category: "RFID",
				},

				// {
				//   title: "Modem Name",
				//   val: this.item.c1,
				//   category: "C",
				// },

				// {
				//   title: "Using Satellites",
				//   val: this.item.d6,
				//   category: "D",
				// },

				// {
				//   title: "Trip distance",
				//   val: this.item.f8,
				//   category: "F",
				// },
			];
		},
	},
	methods: {
		lockFromTime(hr, min) {
			return hr && min ? [hr, min].join(":") : "";
		},

		yesNo(val) {
			if (val === "0") return "No";
			if (val === "1") return "Yes";
			return "";
		},

		formatTimeFromUnix(time) {
			return time ? format(fromUnixTime(time), "E do MMMM, H:mm:ss") : "";
		},

		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		closeAll() {
			Object.keys(this.$refs).forEach((k) => {
				if (this.$refs[k] && this.$refs[k].$attrs["data-open"]) {
					this.$refs[k].$el.click();
				}
			});
		},

		openAll() {
			Object.keys(this.$refs).forEach((k) => {
				if (this.$refs[k] && !this.$refs[k].$attrs["data-open"]) {
					this.$refs[k].$el.click();
				}
			});
		},
	},
};
</script>

<style lang="scss">
.logs-list .v-data-table__wrapper::-webkit-scrollbar {
	width: 12px;
	height: 12px;
	background-color: #fff; /* or add it to the track */
}

/* Add a thumb */
.logs-list .v-data-table__wrapper::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #434a5c;
}
</style>
