<template>
	<v-container fluid v-resize="onResize">
		<v-row v-if="cars">
			<v-col cols="12" class="px-0">
				<v-data-table
					class="admin-fleet-table"
					v-model="selected"
					:headers="visibleHeaders"
					:items="cars"
					:search="search"
					single-expand
					show-select
					item-key="_id"
					sort-by="carNumber"
					:mobile-breakpoint="0"
					:height="$vuetify.breakpoint.xs ? windowSize.y - 240 : windowSize.y - 200"
					fixed-header
					:page.sync="page"
					:header-props="{
						'sort-icon': 'mdi-menu-down',
					}"
					:items-per-page="itemsPerPage.val"
					hide-default-footer
					@page-count="pageCount = $event"
					@click:row="openCarSidebar"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-menu offset-y :close-on-content-click="false" max-height="300">
								<template v-slot:activator="{ on, attrs }">
									<v-btn color="primary" class="padlessBtn" depressed small dark v-bind="attrs" v-on="on">
										<v-icon small>mdi-filter-menu</v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item v-for="header in headers" :key="header.value" dense>
										<v-list-item-action>
											<v-checkbox small v-model="columns[header.value]"></v-checkbox>
										</v-list-item-action>
										<v-list-item-title>
											{{ header.text }}
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
							<actions-menu :selectedSite="selectedSite" :selectedCars="selected" @resetSelected="resetSelected" />

							<v-chip label class="font-weight-bold ml-2 accent--text hidden-sm-and-down rounded-r-0" @click.stop="copyText(selectedSite._id)">
								<v-icon left small color="accent">mdi-golf</v-icon>
								{{ selectedSite.name }} - {{ selectedSite._id }}
							</v-chip>
							<v-chip label class="font-weight-bold hidden-sm-and-down rounded-l-0">
								<v-icon left small color="accent">mdi-golf-cart</v-icon>
								{{ cars.length }}
							</v-chip>

							<v-spacer></v-spacer>
							<edit-sites-modal v-if="selectedSite && hasRole('admin')" :site="selectedSite" btnTitle="Edit Site" />
							<firmware-modal v-if="hasRole('superadmin')" :selectedCars="selected" @resetSelected="resetSelected" />
							<download-excel
								class="v-btn v-btn--outlined theme--dark v-size--small accent--text mr-2 hidden-sm-and-down"
								:data="exportCarsList"
								:fields="json_fields"
								:worksheet="worksheetName"
								:name="`${worksheetName}.csv`"
								type="csv"
								:escapeCsv="false"
							>
								Export CSV
								<v-icon right small>mdi-download</v-icon>
							</download-excel>
							<find-device />
							<v-btn small class="primary mr-2 padlessBtn" depressed :loading="refreshLoading" @click="refresh">
								<v-icon small>mdi-refresh</v-icon>
							</v-btn>
							<v-text-field
								class="hidden-sm-and-down"
								v-model="search"
								append-icon="mdi-magnify"
								label="Search Devices"
								single-line
								outlined
								dense
								hide-details
								style="max-width: 150px"
							></v-text-field>
						</v-toolbar>
					</template>
					<template v-slot:header.data-table-select="{ props, on }">
						<v-simple-checkbox color="primary" v-if="props.indeterminate" v-ripple v-bind="props" :value="props.indeterminate" v-on="on"></v-simple-checkbox>
						<v-simple-checkbox color="primary" v-if="!props.indeterminate" v-ripple v-bind="props" v-on="on"></v-simple-checkbox>
					</template>
					<template v-slot:item.data-table-select="{ item, isSelected, select }">
						<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
					</template>
					<template v-slot:item._id="{ item }" v-if="columns._id">
						<span class="font-weight-bold">
							<v-chip label @click.stop="copyText(item._id)">
								{{ item._id }}
							</v-chip>
						</span>
					</template>
					<template v-slot:item.carNumber="{ item }" v-if="columns.carNumber">
						<span class="font-weight-bold">
							{{ item.carNumber }}
						</span>
					</template>
					<template v-slot:item.carName="{ item }">
						<span class="font-weight-bold">
							{{ item.carName }}
						</span>
					</template>
					<template v-slot:item.timeUTC="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip class="font-weight-bold" :color="`${onlineStatus(item).color}`" dark small label>
										{{ durationSincePingUnix(item.timeUTC) }}
									</v-chip>
								</span>
							</template>
							<span class="caption">
								{{
									isOnline(item)
										? computedTimeAgo(item.timeUTC)
										: item.disconnectedAt
										? `Disconnected: ${disconnectedAt(item.disconnectedAt)}`
										: `Last seen: ${computedTimeAgo(item.timeUTC)}`
								}}
							</span>
						</v-tooltip>
					</template>
					<template v-slot:item.uptime="{ item }">
						<span class="font-weight-bold" v-if="item.uptime">
							{{ computedTimeAgoStrict(item.uptime) }}
						</span>
					</template>
					<template v-slot:item.gprsCCID="{ item }">
						<span class="font-weight-bold">
							<v-chip label @click.stop="copyText(item.gprsCCID)">
								{{ item.gprsCCID }}
							</v-chip>
						</span>
					</template>
					<template v-slot:item.gprsCarrier="{ item }">
						<span class="font-weight-bold text-no-wrap">
							{{ item.gprsCarrier }}
						</span>
					</template>
					<template v-slot:item.items="{ item }">
						<div class="no-wrap">
							<div>
								<fleet-lock-button :device="item" />
								<span>
									<v-chip class="mr-2" :class="wifiDisplay(item).textColor" :color="wifiDisplay(item).color" small label>
										<v-icon small left>{{ wifiDisplay(item).icon }}</v-icon>
										{{ wifiDisplay(item).text }}
									</v-chip>
								</span>

								<span>
									<v-chip class="mr-2" :class="gprsDisplay(item).textColor" :color="gprsDisplay(item).color" small label>
										<v-icon small left>{{ gprsDisplay(item).icon }}</v-icon>
										{{ gprsDisplay(item).text }}
									</v-chip>
								</span>

								<span>
									<v-chip class="mr-2 primary--text" color="primary lighten-4" dark small label>
										<v-icon small left>mdi-battery-charging</v-icon>
										{{ item.battVoltage }}
									</v-chip>
								</span>

								<span>
									<v-chip class="mr-2 white--text" :color="item.gpsLock >= 3 ? 'success' : 'error'" dark small label>
										<v-icon small left>mdi-satellite-variant</v-icon>
										{{ item.gpsLock }}
									</v-chip>
								</span>

								<span>
									<v-chip v-if="item.deviceTemp" label small dark class="mr-2 primary--text" color="primary lighten-4">
										<v-icon small left>mdi-thermometer</v-icon>
										{{ item.deviceTemp }}°C
									</v-chip>
								</span>

								<span v-if="item.firmwareStatus === '0' && item.firmware">
									<v-chip class="primary--text font-weight-bold" color="primary lighten-4" small label dark> v{{ item.firmware }} </v-chip>
								</span>

								<span v-if="item.firmwareStatus !== '0'">
									<v-chip :color="firmwareStatusColor(Number(item.firmwareStatus))" small label dark>
										{{ firmwareStatusText(Number(item.firmwareStatus)) }}
										<v-progress-circular v-if="firmwareStatusDld(Number(item.firmwareStatus))" :size="15" :width="2" color="white" indeterminate class="ml-2"></v-progress-circular>
									</v-chip>
								</span>

								<span v-if="item.hardwareInfo">
									<hardware-modal :device="item" />
								</span>
							</div>
						</div>
					</template>
					<template v-slot:item.edit="{ item }">
						<div class="no-wrap">
							<div>
								<manage-sim v-if="connectedEmnify" :device="item" />
								<v-btn icon color="primary" class="ml-2" small @click.stop="soundDevice(item._id)">
									<v-icon>mdi-bullhorn-outline</v-icon>
								</v-btn>
							</div>
						</div>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%" :style="$vuetify.breakpoint.xs ? `bottom: 56px;` : 'bottom: 0;'">
			<v-toolbar flat dense class="mx-3 rounded-t-lg">
				<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

				<v-select
					class="perPage"
					v-model="itemsPerPage"
					:items="items"
					item-text="text"
					item-value="val"
					label="Select"
					hide-details
					return-object
					single-line
					dense
					outlined
				></v-select>
			</v-toolbar>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import FirmwareModal from "@/components/admin/firmwareModal/FirmwareModal";
import FleetLockButton from "@/components/fleet/LockModal";
import EditSitesModal from "@/components/admin/sites/EditSitesModal.vue";
import ManageSim from "@/components/admin/ManageSim.vue";
import ActionsMenu from "@/components/admin/ActionsMenu";
import FindDevice from "@/components/admin/FindDevice";
import HardwareModal from "@/components/admin/HardwareModal.vue";

export default {
	components: {
		FleetLockButton,
		EditSitesModal,
		FirmwareModal,
		ManageSim,
		ActionsMenu,
		FindDevice,
		HardwareModal,
	},
	data() {
		return {
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
			page: 1,
			pageCount: 0,
			windowSize: {
				x: 0,
				y: 0,
			},
			connectedEmnify: false,
			checkbox: false,
			search: "",
			selected: [],
			selectedHeaders: [],
			columns: {
				_id: true,
				carNumber: true,
				carName: true,
				timeUTC: true,
				uptime: true,
				gprsCCID: true,
				gprsCarrier: true,
				items: true,
				edit: true,
			},
			headers: [
				{
					text: "Device ID",
					value: "_id",
					align: "center",

					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Car",
					value: "carNumber",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Car Name",
					value: "carName",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Status",
					value: "timeUTC",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Uptime",
					value: "uptime",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "CCID",
					value: "gprsCCID",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Carrier",
					value: "gprsCarrier",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Info",
					value: "items",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
				{
					text: "Actions",
					value: "edit",
					align: "center",
					class: "font-weight-bold accent--text subtitle-2 text-no-wrap",
				},
			],
			refreshLoading: false,
			json_fields: {
				"Device ID": "_id",
				Site: {
					field: "site",
					callback: () => {
						return `${this.selectedSite.name}`;
					},
				},
				"Car #": "carNumber",
				"Car Serial": "carSerialNumber",
				"Device Model": "hardwareInfo.pcb.rev",
				Modem: "hardwareInfo.modem",
				URL: {
					field: "_id",
					callback: () => {
						return `${process.env.VUE_APP_BASE_APP_URL}/guest/site/${this.selectedSite._id}`;
					},
				},
				URL2: {
					field: "_id",
					callback: (value) => {
						return `${process.env.VUE_APP_BASE_APP_URL}/unlock/${value}`;
					},
				},
			},
		};
	},
	watch: {
		columns: {
			handler: "saveColumns",
			deep: true,
		},

		page() {
			this.selected = [];
		},

		itemsPerPage(newVal) {
			localStorage.carsPerPage = JSON.stringify(newVal);
		},
	},
	created() {
		if (localStorage.getItem("adminColumns")) {
			this.columns = JSON.parse(localStorage.getItem("adminColumns"));
		}
	},
	computed: {
		...mapState({
			cars: (state) => state.devices.devices,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		exportCarsList() {
			return this.selected.length ? this.selected : this.cars;
		},

		visibleHeaders() {
			return this.headers.filter((header) => this.columns[header.value]);
		},

		worksheetName() {
			return `${this.selectedSite.name} - Device List`;
		},
	},
	async mounted() {
		if (localStorage.carsPerPage) {
			this.itemsPerPage = JSON.parse(localStorage.carsPerPage);
		}

		this.refresh();
		this.emnifyAuth();
	},
	methods: {
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		async refresh() {
			this.refreshLoading = true;

			try {
				await this.$store.dispatch("getDevices");

				this.refreshLoading = false;
			} catch (err) {
				const message = err;
				this.$toast.error(message);
			}
		},

		async emnifyAuth() {
			try {
				const payload = {
					application_token: process.env.VUE_APP_EMNIFY_TOKEN,
				};

				const res = await this.$emnifyApi.post("/authenticate", payload);

				const token = res.data.auth_token;

				this.$emnifyApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

				this.connectedEmnify = true;
			} catch (err) {
				this.connectedEmnify = false;
				console.log(err);
			}
		},

		resetSelected(val) {
			this.selected = val;
		},

		async soundDevice(id) {
			try {
				await this.$store.dispatch("admin/pingDevice", {
					id,
					command: 8,
				});
			} catch {
				console.log("Error pinging");
			}
		},

		async copyText(text) {
			await navigator.clipboard.writeText(text);

			const message = "Copied to clipboard";
			this.$toast.info(message);
		},

		saveColumns() {
			localStorage.setItem("adminColumns", JSON.stringify(this.columns));
		},

		deselectCar() {
			this.$store.commit("setSelectedDeviceId", null);
		},

		openCarSidebar(payload) {
			this.$store.commit("setSelectedDeviceId", payload._id);
		},
	},
	beforeDestroy() {
		this.deselectCar();
		this.selected = [];
	},
};
</script>
