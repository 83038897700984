const countriesArray = [
	{
		name: "United Kingdom",
		code: "GB",
		capital: "London",
		region: "EU",
		currency: {
			code: "GBP",
			name: "British pound",
			symbol: "£",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/gbr.svg",
	},
	{
		name: "Germany",
		code: "DE",
		capital: "Berlin",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "de",
			name: "German",
		},
		flag: "https://restcountries.eu/data/deu.svg",
	},
	{
		name: "Spain",
		code: "ES",
		capital: "Madrid",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "es",
			name: "Spanish",
		},
		flag: "https://restcountries.eu/data/esp.svg",
	},
	{
		name: "Denmark",
		code: "DK",
		capital: "Copenhagen",
		region: "EU",
		currency: {
			code: "DKK",
			name: "Danish krone",
			symbol: "kr",
		},
		language: {
			code: "da",
			name: "Danish",
		},
		flag: "https://restcountries.eu/data/dnk.svg",
	},
	{
		name: "Sweden",
		code: "SE",
		capital: "Stockholm",
		region: "EU",
		currency: {
			code: "SEK",
			name: "Swedish krona",
			symbol: "kr",
		},
		language: {
			code: "sv",
			name: "Swedish",
		},
		flag: "https://restcountries.eu/data/swe.svg",
	},
	{
		name: "Czech Republic",
		code: "CZ",
		capital: "Prague",
		region: "EU",
		currency: {
			code: "CZK",
			name: "Czech koruna",
			symbol: "Kč",
		},
		language: {
			code: "cs",
			name: "Czech",
		},
		flag: "https://restcountries.eu/data/cze.svg",
	},
	{
		name: "Australia",
		code: "AU",
		capital: "Canberra",
		region: "OC",
		currency: {
			code: "AUD",
			name: "Australian dollar",
			symbol: "$",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/aus.svg",
	},
	{
		name: "Austria",
		code: "AT",
		capital: "Vienna",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "de",
			name: "German",
		},
		flag: "https://restcountries.eu/data/aut.svg",
	},
	{
		name: "Azerbaijan",
		code: "AZ",
		capital: "Baku",
		region: "AS",
		currency: {
			code: "AZN",
			name: "Azerbaijani manat",
			symbol: null,
		},
		language: {
			code: "az",
			name: "Azerbaijani",
		},
		flag: "https://restcountries.eu/data/aze.svg",
	},
	{
		name: "Bahrain",
		code: "BH",
		capital: "Manama",
		region: "AS",
		currency: {
			code: "BHD",
			name: "Bahraini dinar",
			symbol: ".د.ب",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/bhr.svg",
	},
	{
		name: "Belarus",
		code: "BY",
		capital: "Minsk",
		region: "EU",
		currency: {
			code: "BYN",
			name: "New Belarusian ruble",
			symbol: "Br",
		},
		language: {
			code: "be",
			name: "Belarusian",
		},
		flag: "https://restcountries.eu/data/blr.svg",
	},
	{
		name: "Belgium",
		code: "BE",
		capital: "Brussels",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "nl",
			name: "Dutch",
		},
		flag: "https://restcountries.eu/data/bel.svg",
	},
	{
		name: "Bolivia (Plurinational State of)",
		code: "BO",
		capital: "Sucre",
		region: "SA",
		currency: {
			code: "BOB",
			name: "Bolivian boliviano",
			symbol: "Bs.",
		},
		language: {
			code: "es",
			name: "Spanish",
		},
		flag: "https://restcountries.eu/data/bol.svg",
	},
	{
		name: "Brazil",
		code: "BR",
		capital: "Brasília",
		region: "SA",
		currency: {
			code: "BRL",
			name: "Brazilian real",
			symbol: "R$",
		},
		language: {
			code: "pt",
			name: "Portuguese",
		},
		flag: "https://restcountries.eu/data/bra.svg",
	},
	{
		name: "Brunei Darussalam",
		code: "BN",
		capital: "Bandar Seri Begawan",
		region: "AS",
		currency: {
			code: "BND",
			name: "Brunei dollar",
			symbol: "$",
		},
		language: {
			code: "ms",
			name: "Malay",
		},
		flag: "https://restcountries.eu/data/brn.svg",
	},
	{
		name: "Bulgaria",
		code: "BG",
		capital: "Sofia",
		region: "EU",
		currency: {
			code: "BGN",
			name: "Bulgarian lev",
			symbol: "лв",
		},
		language: {
			code: "bg",
			name: "Bulgarian",
		},
		flag: "https://restcountries.eu/data/bgr.svg",
	},
	{
		name: "Cambodia",
		code: "KH",
		capital: "Phnom Penh",
		region: "AS",
		currency: {
			code: "KHR",
			name: "Cambodian riel",
			symbol: "៛",
		},
		language: {
			code: "km",
			name: "Khmer",
		},
		flag: "https://restcountries.eu/data/khm.svg",
	},
	{
		name: "Canada",
		code: "CA",
		capital: "Ottawa",
		region: "NA",
		currency: {
			code: "CAD",
			name: "Canadian dollar",
			symbol: "$",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/can.svg",
	},
	{
		name: "China",
		code: "CN",
		capital: "Beijing",
		region: "AS",
		currency: {
			code: "CNY",
			name: "Chinese yuan",
			symbol: "¥",
		},
		language: {
			code: "zh",
			name: "Chinese",
		},
		flag: "https://restcountries.eu/data/chn.svg",
	},
	{
		name: "Croatia",
		code: "HR",
		capital: "Zagreb",
		region: "EU",
		currency: {
			code: "HRK",
			name: "Croatian kuna",
			symbol: "kn",
		},
		language: {
			code: "hr",
			name: "Croatian",
		},
		flag: "https://restcountries.eu/data/hrv.svg",
	},
	{
		name: "Cyprus",
		code: "CY",
		capital: "Nicosia",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "tr",
			name: "Turkish",
		},
		flag: "https://restcountries.eu/data/cyp.svg",
	},
	{
		name: "Dominican Republic",
		code: "DO",
		capital: "Santo Domingo",
		region: "NA",
		currency: {
			code: "DOP",
			name: "Dominican peso",
			symbol: "$",
		},
		language: {
			code: "es",
			name: "Spanish",
		},
		flag: "https://restcountries.eu/data/dom.svg",
	},
	{
		name: "Egypt",
		code: "EG",
		capital: "Cairo",
		region: "AF",
		currency: {
			code: "EGP",
			name: "Egyptian pound",
			symbol: "£",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/egy.svg",
	},
	{
		name: "Faroe Islands",
		code: "FO",
		capital: "Tórshavn",
		region: "EU",
		currency: {
			code: "DKK",
			name: "Danish krone",
			symbol: "kr",
		},
		language: {
			code: "fo",
			name: "Faroese",
		},
		flag: "https://restcountries.eu/data/fro.svg",
	},
	{
		name: "Finland",
		code: "FI",
		capital: "Helsinki",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "fi",
			iso639_2: "fin",
			name: "Finnish",
			nativeName: "suomi",
		},
		flag: "https://restcountries.eu/data/fin.svg",
	},
	{
		name: "France",
		code: "FR",
		capital: "Paris",
		region: "EU",
		demonym: "French",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "fr",
			name: "French",
		},
		flag: "https://restcountries.eu/data/fra.svg",
	},
	{
		name: "Georgia",
		code: "GE",
		capital: "Tbilisi",
		region: "AS",
		currency: {
			code: "GEL",
			name: "Georgian Lari",
			symbol: "ლ",
		},
		language: {
			code: "ka",
			name: "Georgian",
		},
		flag: "https://restcountries.eu/data/geo.svg",
	},
	{
		name: "Gibraltar",
		code: "GI",
		capital: "Gibraltar",
		region: "EU",
		currency: {
			code: "GIP",
			name: "Gibraltar pound",
			symbol: "£",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/gib.svg",
	},
	{
		name: "Greece",
		code: "GR",
		capital: "Athens",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "el",
			name: "Greek (modern)",
		},
		flag: "https://restcountries.eu/data/grc.svg",
	},
	{
		name: "Guernsey",
		code: "GG",
		capital: "St. Peter Port",
		region: "EU",
		currency: {
			code: "GBP",
			name: "British pound",
			symbol: "£",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/ggy.svg",
	},
	{
		name: "Hong Kong",
		code: "HK",
		capital: "City of Victoria",
		region: "AS",
		currency: {
			code: "HKD",
			name: "Hong Kong dollar",
			symbol: "$",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/hkg.svg",
	},
	{
		name: "Hungary",
		code: "HU",
		capital: "Budapest",
		region: "EU",
		currency: {
			code: "HUF",
			name: "Hungarian forint",
			symbol: "Ft",
		},
		language: {
			code: "hu",
			name: "Hungarian",
		},
		flag: "https://restcountries.eu/data/hun.svg",
	},
	{
		name: "Iceland",
		code: "IS",
		capital: "Reykjavík",
		region: "EU",
		currency: {
			code: "ISK",
			name: "Icelandic króna",
			symbol: "kr",
		},
		language: {
			code: "is",
			name: "Icelandic",
		},
		flag: "https://restcountries.eu/data/isl.svg",
	},
	{
		name: "India",
		code: "IN",
		capital: "New Delhi",
		region: "AS",
		currency: {
			code: "INR",
			name: "Indian rupee",
			symbol: "₹",
		},
		language: {
			code: "hi",
			name: "Hindi",
		},
		flag: "https://restcountries.eu/data/ind.svg",
	},
	{
		name: "Indonesia",
		code: "ID",
		capital: "Jakarta",
		region: "AS",
		currency: {
			code: "IDR",
			name: "Indonesian rupiah",
			symbol: "Rp",
		},
		language: {
			code: "id",
			name: "Indonesian",
		},
		flag: "https://restcountries.eu/data/idn.svg",
	},
	{
		name: "Ireland",
		code: "IE",
		capital: "Dublin",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "ga",
			name: "Irish",
		},
		flag: "https://restcountries.eu/data/irl.svg",
	},
	{
		name: "Isle of Man",
		code: "IM",
		capital: "Douglas",
		region: "EU",
		currency: {
			code: "GBP",
			name: "British pound",
			symbol: "£",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/imn.svg",
	},
	{
		name: "Israel",
		code: "IL",
		capital: "Jerusalem",
		region: "AS",
		currency: {
			code: "ILS",
			name: "Israeli new shekel",
			symbol: "₪",
		},
		language: {
			code: "he",
			name: "Hebrew (modern)",
		},
		flag: "https://restcountries.eu/data/isr.svg",
	},
	{
		name: "Italy",
		code: "IT",
		capital: "Rome",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "it",
			name: "Italian",
		},
		flag: "https://restcountries.eu/data/ita.svg",
	},
	{
		name: "Japan",
		code: "JP",
		capital: "Tokyo",
		region: "AS",
		currency: {
			code: "JPY",
			name: "Japanese yen",
			symbol: "¥",
		},
		language: {
			code: "ja",
			name: "Japanese",
		},
		flag: "https://restcountries.eu/data/jpn.svg",
	},
	{
		name: "Jersey",
		code: "JE",
		capital: "Saint Helier",
		region: "EU",
		currency: {
			code: "GBP",
			name: "British pound",
			symbol: "£",
		},
		language: {
			code: "en",
			iso639_2: "eng",
			name: "English",
			nativeName: "English",
		},
		flag: "https://restcountries.eu/data/jey.svg",
	},
	{
		name: "Latvia",
		code: "LV",
		capital: "Riga",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "lv",
			name: "Latvian",
		},
		flag: "https://restcountries.eu/data/lva.svg",
	},
	{
		name: "Liechtenstein",
		code: "LI",
		capital: "Vaduz",
		region: "EU",
		currency: {
			code: "CHF",
			name: "Swiss franc",
			symbol: "Fr",
		},
		language: {
			code: "de",
			name: "German",
		},
		flag: "https://restcountries.eu/data/lie.svg",
	},
	{
		name: "Lithuania",
		code: "LT",
		capital: "Vilnius",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "lt",
			name: "Lithuanian",
		},
		flag: "https://restcountries.eu/data/ltu.svg",
	},
	{
		name: "Luxembourg",
		code: "LU",
		capital: "Luxembourg",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "fr",
			name: "French",
		},
		flag: "https://restcountries.eu/data/lux.svg",
	},
	{
		name: "Malaysia",
		code: "MY",
		capital: "Kuala Lumpur",
		region: "AS",
		currency: {
			code: "MYR",
			name: "Malaysian ringgit",
			symbol: "RM",
		},
		language: {
			code: null,
			name: "Malaysian",
		},
		flag: "https://restcountries.eu/data/mys.svg",
	},
	{
		name: "Monaco",
		code: "MC",
		capital: "Monaco",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "fr",
			name: "French",
		},
		flag: "https://restcountries.eu/data/mco.svg",
	},
	{
		name: "Netherlands",
		code: "NL",
		capital: "Amsterdam",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "nl",
			name: "Dutch",
		},
		flag: "https://restcountries.eu/data/nld.svg",
	},
	{
		name: "New Zealand",
		code: "NZ",
		capital: "Wellington",
		region: "OC",
		currency: {
			code: "NZD",
			name: "New Zealand dollar",
			symbol: "$",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/nzl.svg",
	},
	{
		name: "Norway",
		code: "NO",
		capital: "Oslo",
		region: "EU",
		currency: {
			code: "NOK",
			name: "Norwegian krone",
			symbol: "kr",
		},
		language: {
			code: "no",
			name: "Norwegian",
		},
		flag: "https://restcountries.eu/data/nor.svg",
	},
	{
		name: "Oman",
		code: "OM",
		capital: "Muscat",
		region: "AS",
		currency: {
			code: "OMR",
			name: "Omani rial",
			symbol: "ر.ع.",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/omn.svg",
	},
	{
		name: "Philippines",
		code: "PH",
		capital: "Manila",
		region: "AS",
		currency: {
			code: "PHP",
			name: "Philippine peso",
			symbol: "₱",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/phl.svg",
	},
	{
		name: "Poland",
		code: "PL",
		capital: "Warsaw",
		region: "EU",
		currency: {
			code: "PLN",
			name: "Polish złoty",
			symbol: "zł",
		},
		language: {
			code: "pl",
			name: "Polish",
		},
		flag: "https://restcountries.eu/data/pol.svg",
	},
	{
		name: "Portugal",
		code: "PT",
		capital: "Lisbon",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "pt",
			name: "Portuguese",
		},
		flag: "https://restcountries.eu/data/prt.svg",
	},
	{
		name: "Qatar",
		code: "QA",
		capital: "Doha",
		region: "AS",
		currency: {
			code: "QAR",
			name: "Qatari riyal",
			symbol: "ر.ق",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/qat.svg",
	},
	{
		name: "Romania",
		code: "RO",
		capital: "Bucharest",
		region: "EU",
		currency: {
			code: "RON",
			name: "Romanian leu",
			symbol: "lei",
		},
		language: {
			code: "ro",
			name: "Romanian",
		},
		flag: "https://restcountries.eu/data/rou.svg",
	},
	{
		name: "Russian Federation",
		code: "RU",
		capital: "Moscow",
		region: "EU",
		currency: {
			code: "RUB",
			name: "Russian ruble",
			symbol: "₽",
		},
		language: {
			code: "ru",
			name: "Russian",
		},
		flag: "https://restcountries.eu/data/rus.svg",
	},
	{
		name: "Saudi Arabia",
		code: "SA",
		capital: "Riyadh",
		region: "AS",
		currency: {
			code: "SAR",
			name: "Saudi riyal",
			symbol: "ر.س",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/sau.svg",
	},
	{
		name: "Singapore",
		code: "SG",
		capital: "Singapore",
		region: "AS",
		currency: {
			code: "SGD",
			name: "Singapore dollar",
			symbol: "$",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/sgp.svg",
	},
	{
		name: "Slovakia",
		code: "SK",
		capital: "Bratislava",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "sk",
			name: "Slovak",
		},
		flag: "https://restcountries.eu/data/svk.svg",
	},
	{
		name: "Slovenia",
		code: "SI",
		capital: "Ljubljana",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "sl",
			name: "Slovene",
		},
		flag: "https://restcountries.eu/data/svn.svg",
	},
	{
		name: "South Africa",
		code: "ZA",
		capital: "Pretoria",
		region: "AF",
		currency: {
			code: "ZAR",
			name: "South African rand",
			symbol: "R",
		},
		language: {
			code: "en",
			iso639_2: "eng",
			name: "English",
			nativeName: "English",
		},
		flag: "https://restcountries.eu/data/zaf.svg",
	},
	{
		name: "Spain",
		code: "ES",
		capital: "Madrid",
		region: "EU",
		currency: {
			code: "EUR",
			name: "Euro",
			symbol: "€",
		},
		language: {
			code: "es",
			name: "Spanish",
		},
		flag: "https://restcountries.eu/data/esp.svg",
	},
	{
		name: "Svalbard and Jan Mayen",
		code: "SJ",
		capital: "Longyearbyen",
		region: "EU",
		currency: {
			code: "NOK",
			name: "Norwegian krone",
			symbol: "kr",
		},
		language: {
			code: "no",
			name: "Norwegian",
		},
		flag: "https://restcountries.eu/data/sjm.svg",
	},
	{
		name: "Switzerland",
		code: "CH",
		capital: "Bern",
		region: "EU",
		currency: {
			code: "CHF",
			name: "Swiss franc",
			symbol: "Fr",
		},
		language: {
			code: "de",
			name: "German",
		},
		flag: "https://restcountries.eu/data/che.svg",
	},
	{
		name: "Thailand",
		code: "TH",
		capital: "Bangkok",
		region: "AS",
		currency: {
			code: "THB",
			name: "Thai baht",
			symbol: "฿",
		},
		language: {
			code: "th",
			name: "Thai",
		},
		flag: "https://restcountries.eu/data/tha.svg",
	},
	{
		name: "Tunisia",
		code: "TN",
		capital: "Tunis",
		region: "AF",
		currency: {
			code: "TND",
			name: "Tunisian dinar",
			symbol: "د.ت",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/tun.svg",
	},
	{
		name: "Turkey",
		code: "TR",
		capital: "Ankara",
		region: "AS",
		currency: {
			code: "TRY",
			name: "Turkish lira",
			symbol: null,
		},
		language: {
			code: "tr",
			name: "Turkish",
		},
		flag: "https://restcountries.eu/data/tur.svg",
	},
	{
		name: "Ukraine",
		code: "UA",
		capital: "Kiev",
		region: "EU",
		currency: {
			code: "UAH",
			name: "Ukrainian hryvnia",
			symbol: "₴",
		},
		language: {
			code: "uk",
			name: "Ukrainian",
		},
		flag: "https://restcountries.eu/data/ukr.svg",
	},
	{
		name: "United Arab Emirates",
		code: "AE",
		capital: "Abu Dhabi",
		region: "AS",
		currency: {
			code: "AED",
			name: "UAE dirham",
			symbol: "د.إ",
		},
		language: {
			code: "ar",
			name: "Arabic",
		},
		flag: "https://restcountries.eu/data/are.svg",
	},
	{
		name: "United Kingdom",
		code: "GB",
		capital: "London",
		region: "EU",
		currency: {
			code: "GBP",
			name: "British pound",
			symbol: "£",
		},
		language: {
			code: "en",
			name: "English",
		},
		flag: "https://restcountries.eu/data/gbr.svg",
	},
	{
		name: "United States of America",
		code: "US",
		capital: "Washington, D.C.",
		region: "NA",
		currency: {
			code: "USD",
			name: "United States dollar",
			symbol: "$",
		},
		language: {
			code: "en",
			iso639_2: "eng",
			name: "English",
			nativeName: "English",
		},
		flag: "https://restcountries.eu/data/usa.svg",
	},
	{
		name: "Viet Nam",
		code: "VN",
		capital: "Hanoi",
		region: "AS",
		currency: {
			code: "VND",
			name: "Vietnamese đồng",
			symbol: "₫",
		},
		language: {
			code: "vi",
			name: "Vietnamese",
		},
		flag: "https://restcountries.eu/data/vnm.svg",
	},
];

export default countriesArray;
