<template>
	<v-container fluid class="pa-0">
		<v-row no-gutters class="gp-full-height">
			<v-col cols="3" class="hidden-sm-and-down accent lighten-1">
				<cars-list />
			</v-col>
			<v-col cols="12" md="9" style="position: relative">
				<map-component />
				<bottom-sheet />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import BottomSheet from "@/components/fleet/BottomSheet.vue";
import MapComponent from "@/components/map/MapComponent";
import CarsList from "@/components/map/CarsList";

export default {
	name: "Map",
	components: {
		MapComponent,
		BottomSheet,
		CarsList,
	},
	data() {
		return {};
	},
	mounted() {
		this.initData();
	},
	methods: {
		initData() {
			this.$store.dispatch("getBookings");
		},
	},
	beforeDestroy() {
		this.$store.commit("setSelectedDeviceId", null);
	},
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	display: none !important;
}
</style>
