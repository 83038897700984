<template>
	<v-autocomplete
		v-model="siteId"
		:items="sites"
		item-text="name"
		item-value="_id"
		hide-details="auto"
		:rules="[rules.required]"
		outlined
		dense
		:clearable="clearable"
		:small-chips="chips"
		:multiple="multiple"
		label="Site..."
		@change="siteChange()"
		@click="getSites"
	></v-autocomplete>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {
		model: {
			default: null,
		},
		multiple: {
			default: false,
		},
		clearable: {
			default: true,
		},
		chips: {
			default: true,
		},
	},
	data() {
		return {
			newSite: null,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
		};
	},
	computed: {
		...mapState({
			sites: (state) => state.sites.sites,
		}),

		siteId: {
			get() {
				return this.model;
			},
			set(newValue) {
				this.newSite = newValue;
			},
		},
	},
	methods: {
		siteChange() {
			this.$emit("siteID", this.newSite);
		},

		getSites() {
			this.$store.dispatch("getSites");
		},
	},
};
</script>
