import * as codeService from "@/services/code.service";

export default {
	state: {},

	mutations: {},

	actions: {
		async findCode(_, payload) {
			return await codeService.findCode(payload);
		},

		async createCode(_, payload) {
			await codeService.createCode(payload);
		},

		async updateCode(_, { id, body }) {
			await codeService.updateCode(id, body);
		},

		async deleteCode(_, id) {
			await codeService.deleteCode(id);
		},

		async sendCodeEmail(_, id) {
			await codeService.sendCodeEmail(id);

			return;
		},

		async publishSiteCodes() {
			return await codeService.publishSiteCodes();
		},
	},
};
