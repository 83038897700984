<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-list-item v-bind="attrs" v-on="on">
				<v-list-item-title>
					<v-icon small left>mdi-server</v-icon>
					Transfer Server
				</v-list-item-title>
			</v-list-item>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>Transfer Server</span>
			</v-card-text>
			<v-card-text>
				<v-form
					ref="form"
					v-model="form"
					lazy-validation
					@submit.prevent="saveServer"
				>
					<v-select
						outlined
						dense
						v-model="value"
						:items="serverItems"
						:rules="[rules.required]"
						item-text="name"
						item-value="value"
						label="Server"
						single-line
						hide-details
						class="mb-2"
						return-object
					></v-select>
					<v-btn block rounded @click="saveServer" color="primary" class="mt-3">
						Save
					</v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			value: null,
			form: true,
			serverItems: [
				{ name: "Production", value: "prod" },
				{ name: "Beta", value: "beta" },
			],
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	methods: {
		saveServer() {
			if (this.$refs.form.validate()) {
				this.$emit("saveServer", this.value);
				this.dialog = false;
			}
		},

		reset() {
			this.value = null;
			this.$refs.form.resetValidation();
		},
	},
};
</script>
