<template>
	<v-row no-gutters>
		<v-col class="px-8 py-2" v-if="site">
			<p class="title">Configuration</p>
			<v-form ref="adminForm" v-model="adminForm" lazy-validation autocomplete="off">
				<v-row class="pa-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">WiFi</p>
					</v-col>
					<v-col cols="9">
						<v-switch class="mt-0" v-model="enableWiFi" hide-details="auto" inset :label="enableWiFi ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
					<v-col cols="3" v-if="enableWiFi">
						<p class="caption mt-1 accent--text">SSID/Name</p>
					</v-col>
					<v-col cols="9" v-if="enableWiFi">
						<v-text-field
							v-model="site.wifiSSID"
							:rules="[rules.length, rules.required]"
							hide-details="auto"
							dense
							outlined
							color="primary"
							style="max-width: 400px"
						></v-text-field>
					</v-col>
					<v-col cols="3" v-if="enableWiFi">
						<p class="caption mt-1 accent--text">Password</p>
					</v-col>
					<v-col cols="9" v-if="enableWiFi">
						<v-text-field
							v-model="site.wifiPassword"
							:rules="[rules.length, rules.required]"
							hide-details="auto"
							dense
							outlined
							color="primary"
							:append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
							:type="showPass ? 'text' : 'password'"
							@click:append="showPass = !showPass"
							style="max-width: 400px"
						></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Keypad/RFID</p>
					</v-col>
					<v-col cols="9">
						<v-switch inset v-model="keypadEnabled" :label="keypadEnabled ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Zone Buzzer</p>
					</v-col>
					<v-col cols="9">
						<v-switch inset v-model="zoneBuzzerEnabled" :label="zoneBuzzerEnabled ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-4 accent--text">Recovery Mode</p>
					</v-col>
					<v-col cols="9">
						<v-switch inset v-model="site.recoveryMode" :label="site.recoveryMode ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Auto Lock</p>
					</v-col>
					<v-col cols="9">
						<v-switch class="mt-0" v-model="enableAutoLock" hide-details="auto" inset :label="enableAutoLock ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
					<v-col cols="3" v-if="enableAutoLock">
						<p class="caption mt-3 accent--text">Auto Lock Time</p>
					</v-col>
					<v-col cols="9" v-if="enableAutoLock">
						<v-text-field
							v-model="site.autoLock"
							:rules="[rules.required]"
							type="number"
							hint="Time in minutes when a cart will auto lock (in geofence)"
							persistent-hint
							suffix="minutes"
							outlined
							dense
							class="mt-0"
							style="max-width: 200px"
						></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-3 accent--text">Round Timeout</p>
					</v-col>
					<v-col cols="9">
						<v-text-field
							v-model="site.roundTimeout"
							:rules="[rules.required]"
							type="number"
							hint="Time in minutes when a cart will auto lock"
							persistent-hint
							suffix="minutes"
							outlined
							dense
							class="mt-0"
							style="max-width: 200px"
						></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4 pb-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Watchdog Timeout</p>
					</v-col>
					<v-col cols="9">
						<v-text-field v-model="site.wdtTimeout" :rules="[rules.required]" hide-details="auto" suffix="sec" outlined dense class="mt-0" style="max-width: 200px"></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4 pb-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">MQTT Keep Alive</p>
					</v-col>
					<v-col cols="9">
						<v-text-field v-model="site.keepAlive" :rules="[rules.required]" hide-details="auto" suffix="sec" outlined dense class="mt-0" style="max-width: 200px"></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="mb-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">RSSI Connect</p>
					</v-col>
					<v-col cols="6">
						<v-text-field
							v-model="site.thresholdRSSICon"
							:rules="[rules.required]"
							hide-details="auto"
							suffix="rssi"
							outlined
							dense
							class="mt-0"
							style="max-width: 200px"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">RSSI Disconnect</p>
					</v-col>
					<v-col cols="6">
						<v-text-field
							v-model="site.thresholdRSSIDis"
							:rules="[rules.required]"
							hide-details="auto"
							suffix="rssi"
							outlined
							dense
							class="mt-0"
							style="max-width: 200px"
						></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="my-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Speed Threshold</p>
					</v-col>
					<v-col cols="6">
						<v-text-field v-model="site.thresholdSpeed" :rules="[rules.required]" hide-details="auto" outlined dense class="mt-0" style="max-width: 200px"></v-text-field>
					</v-col>
				</v-row>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Ping when locked</p>
					</v-col>
					<v-col cols="6">
						<v-text-field v-model="pingHours" :rules="[rules.required]" hide-details="auto" suffix="hrs" outlined dense class="mt-0" style="max-width: 200px"></v-text-field>
					</v-col>
				</v-row>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Plot Distance</p>
					</v-col>
					<v-col cols="6">
						<v-text-field v-model="site.plotDistance" :rules="[rules.required]" hide-details="auto" outlined dense class="mt-0" style="max-width: 200px"></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="my-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Buzzer</p>
					</v-col>
					<v-col cols="9">
						<v-switch class="mt-0" v-model="enableBuzzer" hide-details="auto" inset :label="enableBuzzer ? `Enabled` : `Disabled`"></v-switch>
					</v-col>
				</v-row>
				<v-divider class="my-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-3 accent--text">Stop Detect Time</p>
					</v-col>
					<v-col cols="9">
						<v-text-field
							v-model="site.stopDetectTime"
							:rules="[rules.required]"
							type="number"
							hint="Threshold for when a cart is considered stopped. Time in seconds"
							persistent-hint
							suffix="sec"
							outlined
							dense
							class="mt-0"
							style="max-width: 200px"
						></v-text-field>
					</v-col>
				</v-row>
				<v-divider class="my-4 primary lighten-4"></v-divider>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-3 accent--text">SIM apn</p>
					</v-col>
					<v-col cols="9">
						<v-select
							outlined
							dense
							v-model="selectedAPN"
							:items="items"
							item-text="name"
							item-value="value"
							label="Select"
							persistent-hint
							single-line
							class="mt-0"
							style="max-width: 200px"
						></v-select>
					</v-col>
					<v-alert border="left" type="error" class="text-left white--text error" dense>
						Caution - Setting the incorrect APN <strong>will</strong> cause the devices to disconnect from cellular. Wifi will be required to recover.
					</v-alert>
				</v-row>
				<v-row class="px-4">
					<v-col cols="3">
						<p class="caption mt-1 accent--text">Modem Mode</p>
					</v-col>
					<v-col cols="9">
						<v-switch class="mt-0" v-model="site.modemManualMode" hide-details="auto" inset :label="site.modemManualMode ? `Manual` : `Automatic`"></v-switch>
					</v-col>
				</v-row>
				<v-divider class="my-4 primary lighten-4"></v-divider>
				<v-row>
					<v-col class="pt-0">
						<v-btn elevation="4" color="primary" @click="saveAdmin" :loading="loadingAdmin" :disabled="loadingAdmin || !adminForm"> Save </v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: {
		site: {
			required: true,
		},
	},
	data() {
		return {
			rules: {
				required: (value) => !!value || "Required.",
				length: (value) => value.length <= 33 || "Cannot be more than 32 characters",
			},
			showPass: false,
			valid: true,
			adminForm: true,
			loadingAdmin: false,
			loadingPush: false,
			enableAutoLock: true,
			keypadEnabled: false,
			enableWiFi: true,
			enableBuzzer: true,
			zoneBuzzerEnabled: false,
			pingHours: null,
			items: [
				{ name: "Emnify - [em]", value: "em" },
				{ name: "Twilio - [super]", value: "super" },
				{ name: "Twilio DE - [de1.super]", value: "de1.super" },
				{ name: "Twilio SG - [sg1.super]", value: "sg1.super" },
				{ name: "Wherever SIM - [wm]", value: "wm" },
			],
		};
	},
	mounted() {
		this.pingHours = this.site.pingSeconds / 60;

		this.site.wifiEnabled === "0" ? (this.enableWiFi = false) : (this.enableWiFi = true);

		this.site.autoLock === "0" ? (this.enableAutoLock = false) : (this.enableAutoLock = true);

		this.site.buzzer === "0" ? (this.enableBuzzer = false) : (this.enableBuzzer = true);

		this.site.keypadEnabled ? (this.keypadEnabled = true) : (this.keypadEnabled = false);

		this.site.zoneBuzzer ? (this.zoneBuzzerEnabled = true) : (this.zoneBuzzerEnabled = false);
	},
	computed: {
		selectedAPN: {
			get() {
				return this.site.simApn;
			},
			set(val) {
				this.site.simApn = val;
			},
		},
	},
	methods: {
		apnName(value) {
			switch (value) {
				case "wm":
					return "Wherever SIM";

				case "em":
					return "Emnify";
			}
		},

		async saveAdmin() {
			if (this.$refs.adminForm.validate()) {
				this.loadingAdmin = true;

				if (!this.enableAutoLock) {
					this.site.autoLock = "0";
				}

				if (!this.enableWiFi) {
					this.site.wifiEnabled = "0";
				} else {
					this.site.wifiEnabled = "1";
				}

				if (!this.keypadEnabled) {
					this.site.keypadEnabled = false;
				} else {
					this.site.keypadEnabled = true;
				}

				if (!this.enableBuzzer) {
					this.site.buzzer = "0";
				} else {
					this.site.buzzer = "1";
				}

				if (!this.zoneBuzzerEnabled) {
					this.site.zoneBuzzer = false;
				} else {
					this.site.zoneBuzzer = true;
				}

				const siteSettings = {
					id: this.site._id,
					body: {
						updateData: {
							wdtTimeout: this.site.wdtTimeout,
							thresholdRSSIDis: this.site.thresholdRSSIDis,
							thresholdRSSICon: this.site.thresholdRSSICon,
							thresholdSpeed: this.site.thresholdSpeed,
							plotDistance: this.site.plotDistance,
							pingSeconds: String(this.pingHours * 60),
							autoLock: this.site.autoLock,
							wifiSSID: this.site.wifiSSID,
							wifiPassword: this.site.wifiPassword,
							wifiEnabled: this.site.wifiEnabled,
							keypadEnabled: this.site.keypadEnabled,
							roundTimeout: this.site.roundTimeout,
							buzzer: this.site.buzzer,
							stopDetectTime: this.site.stopDetectTime,
							simApn: this.site.simApn,
							keepAlive: this.site.keepAlive,
							recoveryMode: this.site.recoveryMode,
							modemManualMode: this.site.modemManualMode,
							zoneBuzzer: this.site.zoneBuzzer,
						},
						publish: true,
					},
				};

				console.log(siteSettings);

				try {
					await this.$store.dispatch("admin/sites/updateSite", siteSettings);
				} catch (err) {
					this.$toast.error(err.error.message);
				} finally {
					this.loadingAdmin = false;
				}
			}
		},
	},
};
</script>
