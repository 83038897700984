<template>
	<v-container fluid class="bg" style="height: 100%">
		<v-row style="position: sticky !important; top: 0px; z-index: 3">
			<v-toolbar flat class="mb-6" height="62px" style="border-bottom: 3px solid #406dfa !important">
				<v-btn
					small
					color="primary"
					class="mr-2"
					@click="
						$router.push({
							path: '/bookings',
						})
					"
				>
					<v-icon :left="$vuetify.breakpoint.smAndUp" small> mdi-calendar-clock </v-icon>
					{{ $vuetify.breakpoint.smAndUp ? $t("bookings.bookings") : "" }}
				</v-btn>

				<v-btn
					small
					color="purple"
					dark
					class="mr-2"
					@click="
						$router.push({
							path: '/virtualKeys',
						})
					"
				>
					<v-icon :left="$vuetify.breakpoint.smAndUp" small> mdi-key-wireless </v-icon>
					{{ $vuetify.breakpoint.smAndUp ? $t("virtualKeys.title") : "" }}
				</v-btn>

				<v-btn-toggle color="primary" class="mr-4" mandatory dense v-model="toggleDisplay">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn active-class="primary" small v-bind="attrs" v-on="on">
								<v-icon :class="!toggleDisplay ? `white--text` : `primary--text`" small> mdi-format-list-bulleted-square </v-icon>
							</v-btn>
						</template>
						<span class="font-weight-bold">
							{{ $t("fleetPage.tableView") }}
						</span>
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn active-class="primary" small v-bind="attrs" v-on="on">
								<v-icon :class="toggleDisplay ? `white--text` : `primary--text`" small>mdi-view-grid-outline</v-icon>
							</v-btn>
						</template>
						<span class="font-weight-bold">
							{{ $t("fleetPage.mobileView") }}
						</span>
					</v-tooltip>
				</v-btn-toggle>
				<v-spacer></v-spacer>
				<fleet-filter />

				<actions-menu :selectedCars="selected" :selectedSite="selectedSite" @setSelectedCars="setSelected" />

				<v-btn :href="helpURL" target="blank" icon class="ml-2" v-show="$vuetify.breakpoint.smAndUp">
					<v-icon color="primary">mdi-help-box</v-icon>
				</v-btn>
			</v-toolbar>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<table-view v-if="toggleDisplay == 0" :devices="filteredDevices" :selected="selected" @setSelectedCars="setSelected" />
				<grid-view v-else />
			</v-col>
		</v-row>
		<fleet-banner :site="selectedSite" />
		<confirm ref="confirm" />
		<device-sidebar />
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableView from "@/components/fleet/TableView.vue";
import GridView from "@/components/fleet/GridView.vue";
import Confirm from "@/components/Confirm";
import ActionsMenu from "@/components/fleet/ActionsMenu.vue";
import FleetBanner from "@/components/fleet/FleetBanner.vue";
import DeviceSidebar from "@/components/fleet/carmodal/CarSidebar";
import FleetFilter from "@/components/fleet/FleetFilter.vue";

export default {
	components: {
		FleetFilter,
		TableView,
		GridView,
		Confirm,
		FleetBanner,
		DeviceSidebar,
		ActionsMenu,
	},
	data() {
		return {
			drawer: false,
			fab: false,
			fab1: false,
			file: null,
			sheet: false,
			timer: "",
			search: "",
			toggleDisplay: 0,
			headers: [
				{ text: "Number", value: "carNumber", align: "center" },
				{ text: "Name", value: "carName", width: "200" },
				{ text: "Status", value: "timeUTC" },
				{ text: "Unlock Code", value: "lockCode" },
				{ text: "Info", value: "items", width: "350" },
				{ text: "", value: "edit", align: "center" },
			],
			selected: [],
		};
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			stats: (state) => state.sites.stats,
			deviceFilters: (state) => state.devices.filters,
		}),

		...mapGetters(["devicesWithUpdatesBookingsRide"]),

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/article/erste-schritte-mit-der-flottentabelle-1x2ejoa/";

				default:
					return "https://help.iotee.eu/en/article/getting-started-with-the-fleet-table-144kihu/";
			}
		},

		filteredDevices() {
			return this.devicesWithUpdatesBookingsRide.filter((device) => {
				if (!this.deviceFilters.search) return true;

				// this.deviceFilters.search filters for the following fields:
				const searchFields = ["carName", "carNumber", "carSerialNumber", "vehicleInfo.notes", "vehicleInfo.cart.model", "ride.meta.name"];

				return searchFields.some((field) => {
					const value = this.getValueByPath(device, field);
					return value && value.toLowerCase().includes(this.deviceFilters.search.toLowerCase());
				});
			});
		},
	},
	mounted() {
		this.initData();

		this.$vuetify.breakpoint.xs ? (this.toggleDisplay = 1) : (this.toggleDisplay = 0);
	},
	methods: {
		getValueByPath(obj, path) {
			return path.split(".").reduce((nestedObj, key) => {
				return nestedObj && nestedObj[key];
			}, obj);
		},

		initData() {
			this.$store.dispatch("getBookings");
		},

		setSelected(val) {
			this.selected = val;
		},

		cancel() {
			this.selected = [];
		},

		resetSelected(val) {
			this.selected = val;
		},

		deselectCar() {
			this.$store.commit("setSelectedDeviceId", null);
		},
	},
	beforeDestroy() {
		this.deselectCar();
	},
};
</script>

<style lang="scss">
.v-data-footer {
	justify-content: center !important;
	.v-data-footer__icons-after .v-icon {
		color: #406dfa !important;
	}
	.v-data-footer__icons-before .v-icon {
		color: #406dfa !important;
	}
}
</style>
