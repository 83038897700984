<template>
	<div class="text-center">
		<v-dialog fullscreen v-model="dialog" width="500" scrollable max-height="100%">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="accent" class="padlessBtn" depressed outlined small dark v-bind="attrs" v-on="on">
					{{ btnTitle }}
				</v-btn>
			</template>

			<v-card class="elevation-24 rounded-0" style="position: relative">
				<v-card-title class="accent white--text">
					Edit {{ site.name }}
					<v-spacer></v-spacer>

					<v-btn icon class="mr-0" color="white" small @click="dialog = false">
						<v-icon>mdi-close-circle-outline</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pa-0" style="height: 100%">
					<v-row no-gutters>
						<v-col cols="12" sm="2" class="pa-0">
							<v-list nav style="position: sticky; top: 0px">
								<v-list-item-group v-model="selectedItem" color="accent">
									<v-list-item v-for="(item, i) in items" :key="i" @click="show = item.text">
										<v-list-item-icon>
											<v-icon v-text="item.icon"></v-icon>
										</v-list-item-icon>

										<v-list-item-content>
											<v-list-item-title v-text="item.text"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-col>

						<v-col cols="12" sm="10" class="pa-0">
							<general-form v-if="show === 'General'" :site="site" />

							<config-form v-if="show === 'Configuration'" :site="site" />

							<delete-form v-if="show === 'Delete'" :site="site" @cancel="dialog = false" />
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import GeneralForm from "@/components/admin/sites/GeneralForm";
import ConfigForm from "@/components/admin/sites/ConfigForm";
import DeleteForm from "@/components/admin/sites/DeleteForm";

export default {
	components: {
		GeneralForm,
		ConfigForm,
		DeleteForm,
	},
	props: {
		btnTitle: {
			default: "Edit",
		},
		site: {
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			selectedItem: 0,
			show: "General",
			items: [
				{ text: "General", icon: "mdi-star-outline" },
				{ text: "Configuration", icon: "mdi-gauge" },
				{ text: "Delete", icon: "mdi-trash-can-outline" },
			],
		};
	},
};
</script>
