<template>
	<v-container fluid v-resize="onResize">
		<v-row class="mt-1" style="overflow-y: hidden; oveflow-x: scroll">
			<v-toolbar flat dense style="min-width: 800px; display: inline-block">
				<sites-map :sites="sites" v-if="sites" />
				<v-spacer></v-spacer>
				<new-site-modal></new-site-modal>
			</v-toolbar>
		</v-row>
		<v-row>
			<v-col cols="12" class="px-0">
				<v-data-table
					class="admin-fleet-table"
					:loading="loading"
					:headers="headers"
					:items="sites"
					:search="search"
					item-key="_id"
					sort-by="_id"
					:mobile-breakpoint="0"
					:height="$vuetify.breakpoint.xs ? windowSize.y - 240 : windowSize.y - 275"
					fixed-header
					:page.sync="page"
					:header-props="{
						'sort-icon': 'mdi-menu-down',
					}"
					:items-per-page="itemsPerPage.val"
					hide-default-footer
					@page-count="pageCount = $event"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title class="accent--text">
								Sites
								<v-chip label class="primary font-weight-bold">
									{{ sites.length }}
								</v-chip>
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-spacer></v-spacer>
							<v-text-field v-model="search" append-icon="mdi-magnify" label="Search Sites" single-line outlined dense hide-details style="max-width: 300px"></v-text-field>
						</v-toolbar>
					</template>
					<template v-slot:item.name="{ item }">
						<span class="font-weight-bold accent--text">
							{{ item.name }}
						</span>
					</template>
					<template v-slot:item._id="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip color="accent lighten-5" dark small label>
										<span class="d-inline-block text-truncate font-weight-bold" style="max-width: 66px">
											{{ item._id }}
										</span>
									</v-chip></span
								>
							</template>
							<span class="caption">{{ item._id }}</span>
						</v-tooltip>
					</template>

					<template v-slot:item.devices="{ item }">
						<span class="font-weight-bold accent--text">
							{{ item.devices }}
						</span>
					</template>

					<template v-slot:item.centerLatLong="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on">
									<v-chip color="primary" class="font-weight-bold" dark small label>
										<v-icon left small color="white">mdi-map-marker-circle</v-icon>
										{{ item.centerLatLong }}
									</v-chip>
								</span>
							</template>
							<span class="caption">{{ item.centerLatLong }}</span>
						</v-tooltip>
					</template>

					<template v-slot:item.publicProfile="{ item }">
						<v-chip v-if="item.stripeConfig.accountId" color="success" class="font-weight-bold mr-2" dark small label>
							{{ item.stripeConfig.accountId }}
						</v-chip>

						<v-chip v-if="item.publicProfile.active" small label color="success">
							<v-icon small>mdi-check-circle</v-icon>
						</v-chip>
					</template>

					<template v-slot:item.currency="{ item }">
						<span class="font-weight-bold accent--text">
							{{ currencyDisplay(item.currency) }}
						</span>
					</template>
					<template v-slot:item.stripeConfig.revenueShareMethod="{ item }">
						<v-chip v-if="item.stripeConfig.revenueShareMethod === 'percentage'" color="primary" class="font-weight-bold mr-2" dark small label>
							{{ item.stripeConfig.percentage }}%
						</v-chip>

						<v-chip v-else color="primary lighten-4" class="font-weight-bold mr-2 primary--text" dark small label>
							{{ currencyDisplay(item.currency) }} {{ item.stripeConfig.fixedFee / 100 }}
						</v-chip>
					</template>

					<template v-slot:item.items="{ item }">
						<v-chip v-if="item.scanToRideToggle" color="primary" class="font-weight-bold mr-2" dark small label> S2R Toggle </v-chip>
						<v-chip v-if="item.keyless" color="primary" class="font-weight-bold mr-2" dark small label> Keyless </v-chip>
						<v-chip v-if="item.keypadEnabled" color="primary" class="font-weight-bold mr-2" dark small label> Keypads/RFID </v-chip>
						<v-chip v-if="item.bookingIntegration" color="accent" class="font-weight-bold mr-2" dark small label> Integrated </v-chip>
					</template>
					<template v-slot:item.edit="{ item }">
						<edit-sites-modal :site="item"></edit-sites-modal>
					</template>
				</v-data-table>

				<v-toolbar flat dense class="rounded-0">
					<v-pagination :disabled="pageCount < 2" class="mx-auto" color="primary" v-model="page" :length="pageCount"></v-pagination>

					<v-select class="perPage" v-model="page" :items="items" item-text="text" item-value="val" label="Select" hide-details single-line dense outlined></v-select>
				</v-toolbar>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import EditSitesModal from "@/components/admin/sites/EditSitesModal.vue";
import NewSiteModal from "@/components/admin/sites/NewSiteModal.vue";
import SitesMap from "@/components/admin/sites/SitesMap.vue";

export default {
	components: {
		NewSiteModal,
		EditSitesModal,
		SitesMap,
	},
	data() {
		return {
			search: "",
			windowSize: {
				x: 0,
				y: 0,
			},
			itemsPerPage: { text: "50", val: 50 },
			items: [
				{ text: "20", val: 20 },
				{ text: "50", val: 50 },
				{ text: "100", val: 100 },
				{ text: "All", val: -1 },
			],
			page: 1,
			pageCount: 0,
			headers: [
				{ text: "Site Name", value: "name" },
				{ text: "Site ID", value: "_id" },
				// { text: "# Devices", value: "devices" },
				{ text: "Country", value: "country" },
				{ text: "Center Lat/Long", value: "centerLatLong" },
				{ text: "Stripe", value: "publicProfile" },
				{ text: "Currency", value: "currency" },
				{ text: "Fee", value: "stripeConfig.revenueShareMethod" },
				{ text: "Modules", value: "items" },
				{ text: "Actions", value: "edit", align: "center" },
			],
		};
	},
	computed: {
		...mapState({
			sites: (state) => state.admin.sites.sites,
			loading: (state) => state.admin.sites.loading,
		}),
	},
	created() {
		this.fetchSites();
	},
	methods: {
		fetchSites() {
			this.$store.dispatch("admin/sites/getSites");
		},

		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},
	},
};
</script>
