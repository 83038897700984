<template>
	<div>
		<apexchart :height="chartOptions.chart.height" :options="chartOptions" :series="seriesData"></apexchart>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
	props: {
		rides: {
			type: Array,
			required: true,
		},
		rideType: {
			type: String,
			default: null,
		},
		selectedCart: {
			type: String,
			default: null,
		},
	},
	data() {
		return {};
	},
	computed: {
		...mapState({
			devices: (state) => state.devices.devices,
			openingHours: (state) => state.sites.selectedSite.openingHours,
		}),

		...mapGetters(["deviceFromId"]),

		validRides() {
			const filter = (ride) => {
				// Check if filtering by specific car number
				const carNumberMatch = this.selectedCart ? ride.carNumber === this.selectedCart : true;

				// Check if filtering by type
				let typeMatch = true; // Default to true if not filtering by type
				if (this.rideType) {
					typeMatch = ride.type === this.rideType;
				} else {
					typeMatch = true;
				}

				return carNumberMatch && typeMatch;
			};

			// Filter rides array based on the dynamically defined filter function
			return this.rides.filter(filter);
		},

		seriesData() {
			const openingHours = this.openingHours;

			// Find the global minimum opening hour and maximum closing hour across all days
			let minOpenHour = 24;
			let maxCloseHour = 0;

			openingHours.forEach((hours) => {
				if (hours !== null) {
					const openHour = parseInt(hours.openTime.split(":")[0], 10);
					const closeHour = parseInt(hours.closeTime.split(":")[0], 10);
					minOpenHour = Math.min(minOpenHour, openHour);
					maxCloseHour = Math.max(maxCloseHour, closeHour);
				}
			});

			// Initialize the heatmap data array based on the min and max hours
			let heatmapData = Array.from({ length: 7 }, () => Array.from({ length: maxCloseHour - minOpenHour }, () => 0));

			// Populate the heatmap data with the ride counts
			this.validRides.forEach((ride) => {
				const start = new Date(ride.start);
				const day = start.getDay();
				const hour = start.getHours();
				const minutes = start.getMinutes();
				const rideTime = hour + minutes / 60;

				// Check if the site is open and the ride falls within opening hours
				const dayOpeningHours = openingHours[day];
				if (dayOpeningHours !== null) {
					const openHour = parseInt(dayOpeningHours.openTime.split(":")[0], 10) + parseInt(dayOpeningHours.openTime.split(":")[1], 10) / 60;
					const closeHour = parseInt(dayOpeningHours.closeTime.split(":")[0], 10) + parseInt(dayOpeningHours.closeTime.split(":")[1], 10) / 60;

					if (rideTime >= openHour && rideTime < closeHour) {
						// Calculate the index for the hour within the heatmap data array
						const index = Math.floor(rideTime) - minOpenHour;
						// Increment the count for the corresponding hour slot
						heatmapData[day][index]++;
					}
				}
			});

			// Convert the heatmap data to the format expected by the heatmap chart
			return heatmapData.map((dayData, dayIndex) => {
				// If the site is closed on this day, return an object indicating no data
				if (openingHours[dayIndex] === null) {
					return {
						name: this.$t(`general.days.short.${dayIndex}`),
						data: [],
					};
				}

				// Create the data entries for the heatmap
				return {
					name: this.$t(`general.days.short.${dayIndex}`),
					data: dayData.map((count, index) => ({
						x: `${(minOpenHour + index).toString().padStart(2, "0")}:00`,
						y: count,
					})),
				};
			});
		},

		chartOptions() {
			return {
				chart: {
					height: 250,
					type: "heatmap",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: true,
					formatter: (val) => {
						return parseInt(val) === val && val > 0 ? val : "";
					},
				},
				legend: {
					show: false,
				},
				plotOptions: {
					heatmap: {
						radius: 4,
						colorScale: {
							ranges: [
								{
									from: 0,
									to: 0,
									color: "#b9c7f6",
								},
							],
						},
					},
				},
				xaxis: {
					labels: {
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					crosshairs: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					labels: {
						showDuplicates: false,
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
						},
					},
				},
				grid: {
					show: false,
				},
				colors: ["#406DFA"],
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex }) {
						// vuetify chip with the date and the number of rides
						return `<div class="pa-2 font-weight-bold accent--text">
              <span >${series[seriesIndex][dataPointIndex]} Rounds</span>
            </div>`;
					},
					followCursor: true,
				},
			};
		},
	},
	methods: {
		getCarNumber(ride) {
			// Directly return ride.meta.carNumber if available
			if (ride.carNumber) {
				return ride.carNumber;
			}
			// Otherwise, use the mapped device's carNumber
			const device = this.deviceFromId(ride.device);
			return device ? device.carNumber : null; // Ensure this method correctly handles missing data
		},
	},
};
</script>

<style>
.color {
	color: #b9c7f6;
}
</style>
