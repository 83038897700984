<template>
	<v-container fluid style="position: relative">
		<v-card class="rounded-lg elevation-18" outlined width="350" style="position: absolute; top: 22px; left: 22px; z-index: 100">
			<v-list-item>
				<v-list-item-action>
					<v-chip class="font-weight-bold title" label color="primary">{{ $t("general.cart") }} {{ rideData.carNumber }} </v-chip>
				</v-list-item-action>

				<v-list-item-content>
					<v-list-item-title class="font-weight-bold" v-if="rideData.name">{{ rideData.name }}</v-list-item-title>
					<v-list-item-subtitle v-if="rideData.carName" class="caption font-weight-bold text-wrap font-italic"> {{ rideData.carName }} </v-list-item-subtitle>
					<v-list-item-subtitle class="caption font-weight-bold" v-if="rideData.method">{{ rideData.method }}</v-list-item-subtitle>
				</v-list-item-content>

				<v-list-item-action>
					<v-btn class="elevation-4" icon :loading="loadingRide" @click="getRide()" small>
						<v-icon color="primary">mdi-refresh</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>

			<v-card-text class="text-center">
				<v-row>
					<v-col cols="3" class="pr-0">
						<p class="mb-2 title accent--text font-weight-bold">
							{{ formattedTime(selectedRide.start) }}
						</p>
						<v-chip label color="primary" class="font-weight-bold" small>
							{{ $t("general.start") }}
						</v-chip>
					</v-col>
					<v-col cols="6" class="px-0">
						<div class="hr-theme-slash-2">
							<div class="hr-icon">
								<v-icon small color="accent lighten-4"> mdi-record-circle-outline </v-icon>
							</div>
							<div class="hr-line"></div>
							<div class="hr-icon">
								<v-icon small color="accent lighten-4"> mdi-golf-cart </v-icon>
							</div>
							<div class="hr-line"></div>
							<div class="hr-icon">
								<v-icon small color="accent lighten-4"> mdi-record-circle-outline </v-icon>
							</div>
						</div>

						<v-chip class="mt-6 white--text pa-3 font-weight-bold elevation-6" label color="primary" small>
							{{ roundDuration }}
						</v-chip>
					</v-col>
					<v-col v-if="selectedRide.status === 0" cols="3" class="pl-0">
						<p class="mb-2 title accent--text font-weight-bold">
							{{ formattedTime(selectedRide.end) }}
						</p>
						<v-chip label color="primary" class="font-weight-bold" small>
							{{ $t("general.end") }}
						</v-chip>
					</v-col>
					<v-col v-else cols="3" class="pl-0">
						<v-chip label color="warning" class="font-weight-bold mt-2 pulse" small>
							{{ $t("ridesPage.active") }}
						</v-chip>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-list-item>
				<v-list-item-content>
					<span class="subtitle-2 font-weight-bold"> Details </span>
				</v-list-item-content>
				<v-list-item-action>
					<v-btn @click="showDetails = !showDetails" small icon>
						<v-icon>{{ showDetails ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>

			<v-expand-transition>
				<v-card flat v-show="showDetails">
					<v-card-text class="pt-0">
						<v-row>
							<v-col cols="12" v-if="rideData.service">
								<span class="subtitle-2">
									<v-icon small left>mdi-golf</v-icon>
									<service-chip :serviceId="rideData.service" :priceId="rideData.price" size="small" />
								</span>
							</v-col>
							<v-col cols="12" v-if="selectedRide.type === 'rfid' || selectedRide.type === 'keypad'">
								<span class="subtitle-2">
									<v-icon small left>
										{{ selectedRide.type === "rfid" ? "mdi-credit-card-chip" : "mdi-dialpad" }}
									</v-icon>
									{{ rideData.unlockCode }}
								</span>
							</v-col>
							<v-col cols="12" v-if="rideData.name">
								<span class="subtitle-2">
									<v-icon small left>mdi-account-circle</v-icon>
									{{ rideData.name }}

									<v-chip v-if="rideData.driver" class="ml-2 font-weight-bold padlessBtn" dark label x-small color="purple">
										<v-icon x-small> mdi-key-wireless </v-icon>
									</v-chip>
								</span>
							</v-col>
							<v-col cols="12" v-if="rideData.email">
								<span class="subtitle-2">
									<v-icon small left>mdi-at</v-icon>
									{{ rideData.email }}
								</span>
							</v-col>
							<v-col cols="12">
								<span class="subtitle-2">
									<v-icon small left>mdi-key-variant</v-icon>
									{{ rideData.method }}
								</span>
							</v-col>
							<v-col cols="12" v-if="rideData.unlockedBy">
								<span class="subtitle-2">
									<v-icon small left>mdi-account-key</v-icon>
									{{ rideData.unlockedBy }}
								</span>
							</v-col>

							<v-col cols="12">
								<span class="subtitle-2">
									<v-icon small left>mdi-map-marker-distance</v-icon>
									{{ rideDistance }} km
								</span>
							</v-col>
							<v-col cols="12" class="pa-0 mt-2">
								<div v-show="hasRole('admin')" class="primary lighten-4 rounded-lg py-2" style="border: 2px dashed #7694f0 !important">
									<v-col class="py-1">
										<span class="subtitle-2">
											<v-icon small left>mdi-vector-polyline</v-icon>
											{{ points.length }} points
										</span>
									</v-col>
									<v-col class="py-1">
										<span class="subtitle-2">
											<v-icon small left>mdi-identifier</v-icon>
											{{ selectedRide._id }}
										</span>
									</v-col>
									<v-col class="py-1">
										<span class="subtitle-2">
											<v-switch v-model="hiResTrail" small class="mt-0" hide-details inset>
												<template slot="label"> <span class="subtitle-2"> Hi-resolution Trail </span> </template>
											</v-switch>
										</span>
									</v-col>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-expand-transition>
		</v-card>

		<v-card class="rounded-lg map-height" flat>
			<ride-map :selectedSite="selectedSite" :selectedRide="selectedRide" :hiResTrail="hiResTrail" />
		</v-card>
		<confirm ref="confirm" />
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, parseISO, intervalToDuration } from "date-fns";
import Confirm from "@/components/Confirm";
import RideMap from "@/components/rides/RideMap";
import ServiceChip from "@/components/ServiceChip.vue";

import polyline from "google-polyline";

export default {
	components: {
		Confirm,
		RideMap,
		ServiceChip,
	},
	data() {
		return {
			defaultLogo: require("../../../public/Cart.png"),
			showDetails: true,
			hiResTrail: false,
		};
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			selectedRide: (state) => state.rides.selectedRide,
			loading: (state) => state.rides.loadingRide,
			services: (state) => state.sites.services,
		}),

		...mapGetters({
			rideData: "rides/getRideData",
		}),

		loadingRide() {
			return this.loading ? true : false;
		},

		service() {
			return this.services.find((service) => service._id === this.rideData.service).name;
		},

		roundDuration() {
			const end = this.selectedRide.end ? new Date(this.selectedRide.end) : new Date();

			const diff = intervalToDuration({
				start: new Date(this.selectedRide.start),
				end,
			});

			let duration = "";

			if (diff.days > 0) {
				duration += `${diff.days}d `;
			}
			if (diff.hours > 0) {
				duration += `${diff.hours}h `;
			}
			if (diff.minutes > 0) {
				duration += `${diff.minutes}m`;
			} else {
				duration = `${diff.seconds}s`;
			}

			return duration;
		},

		rideDistance() {
			if (this.selectedRide.distance) {
				return this.selectedRide.distance;
			} else {
				let distance;

				// Check if endOdometer is defined and is a number
				if (typeof this.selectedRide.endOdometer === "number") {
					distance = this.selectedRide.endOdometer - this.selectedRide.startOdometer;
				} else if (typeof this.selectedRide.device.carOdometer === "number") {
					// Ensure carOdometer is a number
					distance = Number(this.selectedRide.device.carOdometer) - this.selectedRide.startOdometer;
				} else {
					// Return 0 if any value is not a number
					return 0;
				}

				// Check if the computed distance is not a number (NaN)
				if (isNaN(distance)) {
					return 0;
				}

				// If distance is 0, return 0 directly without decimal places
				if (distance === 0) {
					return 0;
				}

				// Fix to 1 decimal place and convert back to a number to remove any trailing zeros
				return Number(distance.toFixed(1));
			}
		},

		points() {
			if (this.selectedRide.polyline) {
				return polyline.decode(this.selectedRide.polyline);
			}

			return this.selectedRide.history && this.selectedRide.history.coordinates ? this.selectedRide.history.coordinates : [];
		},
	},
	methods: {
		formattedTime(time) {
			return format(new Date(time), "HH:mm");
		},

		formattedDate(time) {
			return format(parseISO(time), "dd/MM/yyyy");
		},

		getRide() {
			this.$store.dispatch("rides/getRide", this.selectedRide._id);
		},

		async endRide() {
			this.loading = true;

			const res = await this.$refs.confirm.open(`${this.$i18n.t("ridesModal.confirm.endRideTitle")}`, "", "error", "mdi-stop-circle-outline");

			if (res === true) {
				await this.$ioteeApi.post(`/rides/end`, {
					session: this.selectedRide.session,
				});

				this.$emit("getRides");

				this.loading = false;
			} else {
				this.loading = false;
			}
		},

		async deleteRide() {
			this.loading = true;

			const res = await this.$refs.confirm.open(
				`${this.$i18n.t("ridesModal.confirm.deleteRideTitle")}`,
				`${this.$i18n.t("ridesModal.confirm.deleteRideBody")}`,
				"error",
				"mdi-trash-can-outline"
			);

			if (res === true) {
				await this.$ioteeApi.post(`/rides/delete`, {
					sessionID: this.selectedRide.session,
				});

				this.$emit("deleteRide");
				this.dialog = false;
				this.loading = false;
			} else {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.map-height {
	height: calc(var(--vh, 1vh) * 100 - 26px) !important;
}

.hr-theme-slash-2 {
	display: flex;

	.hr-line {
		width: 100%;
		position: relative;
		border-bottom: 1px solid #c7d6fe;
	}
	.hr-icon {
		position: relative;
		top: 8px;
	}
}

.pulse {
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(233, 196, 106, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(233, 196, 106, 1);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 20px 20px rgba(233, 196, 106, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(233, 196, 106, 0);
	}
}
</style>
