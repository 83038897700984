<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-btn block v-bind="attrs" v-on="on" :color="code ? 'success' : 'primary'">
				<v-icon v-if="code" left color="white"> mdi-check-circle-outline </v-icon>
				<v-icon v-else small left>mdi-smart-card-outline</v-icon>
				{{ code ? "Re-Scan Tag" : "Scan Tag" }}
			</v-btn>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title pt-4 font-weight-bold text-center">
				<span>Scan Tag</span>
			</v-card-text>
			<v-card-text class="caption font-weight-bold text-center">
				<span>Place tag on scanner or enter manually</span>
			</v-card-text>
			<v-card-text>
				<v-form ref="form" v-model="form" lazy-validation @submit.prevent="emitTagID">
					<v-text-field
						autofocus
						prepend-inner-icon="mdi-smart-card-outline"
						placeholder="Tag or Card"
						label="Tag or Card"
						hide-details="auto"
						v-model="value"
						:rules="[rules.required]"
						outlined
						rounded
						dense
						clearable
						class="mb-3"
					></v-text-field>
					<v-btn block rounded @click="emitTagID" :loading="loading" :disabled="!form" color="primary" class="mt-3"> Save </v-btn>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		code: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			dialog: false,
			loading: false,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			value: null,
			form: true,
		};
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.reset();
			}
		},
	},
	computed: {
		hexValue() {
			return Number(this.value);
		},
	},
	methods: {
		async emitTagID() {
			if (this.$refs.form.validate()) {
				this.$emit("input", String(this.hexValue));

				this.reset();
			}
		},

		reset() {
			this.value = null;
			this.dialog = false;
			this.$refs.form.resetValidation();
		},
	},
};
</script>
