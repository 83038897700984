<template>
	<v-container>
		<v-virtual-scroll :items="clonedDevices" height="300" item-height="64">
			<template v-slot:default="{ item }">
				<v-list-item :key="item._id">
					<v-list-item-action>
						<v-chip class="mr-2 font-weight-bold" dark :color="item.bookableOnline ? 'success' : 'accent lighten-5'" label :disabled="!item.bookableOnline">
							<v-icon left>{{ item.carIcon }}</v-icon>

							{{ item.carNumber }}
						</v-chip>
					</v-list-item-action>

					<v-list-item-content>
						<v-list-item-title>{{ item.carName }} </v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<span class="d-flex align-center">
							<v-switch inset v-model="item.bookableOnline" class="pr-4"></v-switch>
							<!-- <v-select style="max-width: 100px" outlined dense hide-details></v-select> -->
						</span>
					</v-list-item-action>
				</v-list-item>

				<v-divider></v-divider>
			</template>
		</v-virtual-scroll>

		<v-card-actions class="mt-4">
			<span class="subtitle-2 font-weight-bold mr-3"> {{ $t("settingsPage.bookingSettings.bookableCarts.title") }}: </span>
			<v-chip class="font-weight-bold">
				<v-icon left small>mdi-golf-cart</v-icon>
				{{ bookableCarts.length }}/{{ devices.length }}
			</v-chip>
			<v-spacer></v-spacer>
			<v-btn color="primary" outlined small @click="cancel" :disabled="!isDirty || loading">{{ $t("general.cancel") }}</v-btn>
			<v-btn color="primary" :loading="loading" depressed small @click="save" :disabled="!isDirty"> {{ $t("general.save") }}</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			loading: false,
			clonedDevices: [],
		};
	},

	mounted() {
		this.clonedDevices = this.deepCopyDevices();
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			devices: (state) => state.devices.devices,
		}),

		isDirty() {
			return JSON.stringify(this.clonedDevices) !== JSON.stringify(this.devices);
		},

		bookableCarts() {
			return this.clonedDevices.filter((device) => device.bookableOnline);
		},
	},

	methods: {
		deepCopyDevices() {
			return JSON.parse(JSON.stringify(this.devices));
		},

		cancel() {
			this.clonedDevices = this.deepCopyDevices();
		},

		async save() {
			this.loading = true;

			try {
				this.clonedDevices.forEach(async (device) => {
					const { _id: id, bookableOnline } = device;

					const settings = {
						id,
						body: {
							updateData: {
								bookableOnline,
							},
						},
					};

					await this.$store.dispatch("updateDevice", settings);
				});

				this.$toast.success(this.$t("general.saved"));
			} catch (error) {
				this.$toast.error(this.$t("general.error"));
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
