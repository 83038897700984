<template>
	<v-col cols="12">
		<v-row>
			<v-col class="title accent--text text-center mx-auto mt-n14" cols="8" lg="6" md="8" sm="6">
				{{ $t("loginPage.resetPass") }}
			</v-col>
		</v-row>
		<v-row>
			<v-col class="white--text text-center mx-auto" cols="8" lg="6" md="8" sm="6">
				<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="sendLink">
					<v-text-field :label="$t('loginPage.email')" v-model="email" type="email" :rules="[rules.required, rules.email]" required outlined dense color="primary" />
					<v-divider class="mb-6"></v-divider>
					<v-hover v-slot="{ hover }">
						<v-btn block :loading="btnLoading" :disabled="valid === false || btnLoading" type="submit" class="primary" :elevation="hover ? 12 : 2">
							{{ $t("loginPage.sendLinkBtn") }}
						</v-btn>
					</v-hover>
				</v-form>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="caption accent--text text-center mx-auto" cols="8" lg="6" md="8" sm="6">
				<v-btn text color="primary" @click="login">
					{{ $t("loginPage.logInButton") }}
				</v-btn>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
export default {
	data() {
		return {
			email: "",
			valid: true,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				email: (v) => /.+@.+\..+/.test(v) || this.$i18n.t("rules.emailValid"),
			},
			btnLoading: false,
		};
	},
	methods: {
		async sendLink() {
			if (this.$refs.form.validate()) {
				this.btnLoading = true;
				try {
					await this.$store.dispatch("forgotPassword", { email: this.formatEmail(this.email) });

					const message = this.$i18n.t("loginPage.resetlinkSent");
					this.$toast.success(message);

					this.btnLoading = false;
				} catch (err) {
					const message = err.response.data.message;
					this.$toast.error(message);

					this.btnLoading = false;
				}
			}
		},

		login() {
			this.$router.push({
				path: `/login`,
			});
		},
	},
};
</script>
