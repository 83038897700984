<template>
	<v-row>
		<v-col>
			<div v-if="site.services.length">
				<v-card outlined class="rounded-lg mb-4 accent--text" color="grey">
					<div class="pa-4">
						<v-card-title class="pa-0">
							<span class="subtitle-1 font-weight-bold d-flex align-center">
								<v-icon left color="accent">mdi-golf</v-icon>
								{{ selectedService ? selectedService.name : $t("services.serviceSelect") }}
							</span>
							<v-spacer></v-spacer>
							<v-slide-x-transition>
								<v-btn v-if="selectedService" small icon @click="(selectedService = null), (selectedDevice = null)">
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-slide-x-transition>
						</v-card-title>

						<v-expand-transition>
							<v-item-group v-show="!selectedService" v-model="selectedService" active-class="primary lighten-4 primary--text" class="mt-4">
								<v-item v-slot="{ active, toggle }" v-for="(service, i) in applicableServices" :key="`service-${i}`" :value="service">
									<v-card
										:elevation="active ? '8' : '0'"
										:outlined="!active"
										:style="active && 'border: 2px solid #3F6DFA !important'"
										class="rounded-lg mb-4"
										:disabled="!hasSufficentCredits(service)"
										@click="toggle"
									>
										<v-list-item :value="i">
											<v-list-item-action>
												<v-checkbox :input-value="active" color="primary accent-4" on-icon="mdi-circle-slice-8" off-icon="mdi-circle-outline"></v-checkbox>
											</v-list-item-action>

											<v-list-item-content>
												<v-list-item-title class="font-weight-bold">
													{{ service.name }}
												</v-list-item-title>
											</v-list-item-content>

											<v-list-item-action class="text-right">
												<div class="d-flex font-weight-bold" :class="[hasUnlimitedCredits && 'text-decoration-line-through', active && 'primary--text']">
													<v-icon left small :color="active ? 'primary' : 'accent'"> mdi-ticket-confirmation-outline </v-icon>
													{{ service.price.credits }}
												</div>
												<div class="d-flex caption font-weight-bold" :class="active && 'primary--text'">
													{{ service.price.name }}
												</div>
											</v-list-item-action>
										</v-list-item>
									</v-card>
								</v-item>
							</v-item-group>
						</v-expand-transition>
					</div>
				</v-card>

				<v-card v-if="selectedService" :loading="loadingAvailableDevices" outlined class="rounded-lg mb-4 accent--text" color="grey">
					<template slot="progress">
						<v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
					</template>

					<div class="pa-4">
						<v-card-title class="pa-0">
							<span class="subtitle-1 font-weight-bold d-flex align-center">
								<v-icon left color="accent">mdi-golf-cart</v-icon>
								{{ selectedDevice ? `Cart ${selectedDevice.carNumber}` : $t("general.selectCart") }}
							</span>
							<v-spacer></v-spacer>
							<v-slide-x-transition>
								<v-btn v-if="selectedDevice" small icon @click="deselectDevice">
									<v-icon>mdi-close-circle</v-icon>
								</v-btn>
							</v-slide-x-transition>
						</v-card-title>

						<v-expand-transition v-if="availableDevices?.available?.length">
							<v-item-group v-show="!selectedDevice" v-model="selectedDevice" active-class="primary lighten-4 primary--text" class="mt-4">
								<v-item :value="item" v-for="(item, index) in availableDevices.available" :key="index">
									<template v-slot="{ active, toggle }">
										<v-card :elevation="active ? '8' : '0'" :outlined="!active" :style="active && 'border: 2px solid #3F6DFA !important'" class="rounded-lg mb-4" @click="toggle">
											<v-list-item>
												<v-list-item-action>
													<v-checkbox :input-value="active" color="primary accent-4" on-icon="mdi-circle-slice-8" off-icon="mdi-circle-outline"></v-checkbox>
												</v-list-item-action>

												<v-list-item-content>
													<v-list-item-title class="font-weight-bold"> Cart {{ item.carNumber }} </v-list-item-title>
												</v-list-item-content>

												<v-list-item-action v-if="item._id === availableDevices.recommended">
													<span class="d-flex align-center">
														<span class="caption font-weight-bold mr-2">recommended</span>
														<v-icon color="primary">mdi-star</v-icon>
													</span>
												</v-list-item-action>
											</v-list-item>
										</v-card>
									</template>
								</v-item>
							</v-item-group>
						</v-expand-transition>
					</div>
				</v-card>

				<v-card v-if="selectedService && selectedDevice" outlined class="rounded-xl purple--text" color="purple lighten-5">
					<div class="pa-4">
						<v-card-title class="pa-0">
							<span class="subtitle-1 font-weight-bold d-flex align-center">
								<v-icon left color="purple">mdi-calendar-month</v-icon>
								{{ $t("bookings.details") }}
							</span>
							<v-spacer></v-spacer>
						</v-card-title>

						<v-row class="text-center pt-4 mt-0">
							<v-col cols="3">
								<p class="mb-2 title purple--text font-weight-bold">{{ translatedFormattedDate(bookingStart, "HH:mm") }}</p>

								<v-chip label color="purple" class="white--text font-weight-bold" small>
									{{ $t("general.start") }}
								</v-chip>
							</v-col>
							<v-col cols="6" class="px-0">
								<div class="dotted-timeline">
									<div class="timeline-icon">
										<v-icon small color="purple "> mdi-record-circle-outline </v-icon>
									</div>
									<div class="timeline-line"></div>
									<div class="timeline-icon">
										<v-icon small color="purple "> mdi-golf-cart </v-icon>
									</div>
									<div class="timeline-line"></div>
									<div class="timeline-icon">
										<v-icon small color="purple "> mdi-record-circle-outline </v-icon>
									</div>
								</div>
								<div class="caption font-weight-bold purple--text mt-2">
									{{ formatDurationToTime(bookingStart, bookingEnd) }}
								</div>
							</v-col>
							<v-col cols="3">
								<p class="mb-2 title purple--text font-weight-bold">{{ translatedFormattedDate(bookingEnd, "HH:mm") }}</p>
								<v-chip label color="purple" class="white--text font-weight-bold" small>
									{{ $t("general.end") }}
								</v-chip>
							</v-col>
						</v-row>

						<v-row class="mt-4 px-2">
							<v-col class="grow">
								<div class="d-flex align-center" :class="[hasUnlimitedCredits && 'text-decoration-line-through']">
									<v-icon x-large color="purple" left>mdi-ticket-confirmation-outline</v-icon>
									<span class="text-h4 font-weight-bold purple--text">
										{{ selectedService.price.credits }}
									</span>
								</div>
								<div>
									<span class="font-weight-bold purple--text">{{ selectedService.name }} - {{ selectedService.price.name }}</span>
								</div>
							</v-col>
							<v-col class="shrink d-flex align-center">
								<v-icon large left color="purple">mdi-golf-cart</v-icon>
								<span class="text-h4 font-weight-bold purple--text">
									{{ selectedDevice.carNumber }}
								</span>
							</v-col>
						</v-row>

						<v-row class="mt-6">
							<v-col>
								<v-btn rounded block color="purple" class="white--text font-weight-bold elevation-10" @click="createBooking" :loading="loadingBooking">
									<v-icon color="white">mdi-chevron-right</v-icon>
									{{ $t("bookings.bookNow") }}
								</v-btn>
							</v-col>
						</v-row>
					</div>
				</v-card>
			</div>
		</v-col>

		<v-dialog v-model="dialog" max-width="500px" persistent>
			<v-card class="rounded-lg">
				<v-card-title> Session Expired </v-card-title>
				<v-card-text> Your session has expired. Please select a service to continue. </v-card-text>
				<v-card-actions>
					<v-btn color="accent" text @click="restartForm()"> OK </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import { addMinutes, roundToNearestMinutes } from "date-fns";

export default {
	props: {
		site: {
			type: Object,
			required: true,
		},
		virtualKey: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			selectedService: null,
			selectedDevice: null,
			availableDevices: null,

			currentTime: new Date(),

			dialog: false,
			timer: null,
			timerDuration: 3 * 60 * 1000, // 3 minutes
			timerExpired: false,

			loadingAvailableDevices: false,
			loadingBooking: false,
		};
	},
	watch: {
		selectedService(val) {
			if (val) {
				this.getAvailableDevices();
			} else {
				this.availableDevices = [];
			}
		},
	},
	created() {
		this.startTimer();
	},
	computed: {
		...mapState({
			devices: (state) => state.guest.devices.devices,
		}),

		applicableServices() {
			const userGroup = this.virtualKey.driver.userGroup || null;

			return this.getApplicableServices(this.site.services, userGroup, this.currentTime);
		},

		hasUnlimitedCredits() {
			return this.virtualKey.driver.credits === -1;
		},

		bookingStart() {
			return roundToNearestMinutes(this.currentTime, { nearestTo: 5 });
		},

		bookingEnd() {
			return addMinutes(this.bookingStart, this.selectedService?.duration || 0);
		},
	},
	methods: {
		deviceFromId(id) {
			return this.devices.find((device) => device._id === id);
		},

		startTimer() {
			this.timer = setTimeout(() => {
				this.timerExpired = true;
				this.dialog = true;
			}, this.timerDuration);
		},

		resetTimer() {
			clearTimeout(this.timer);
			this.timerExpired = false;
			this.startTimer();
		},

		restartForm() {
			this.selectedService = null;
			this.selectedDevice = null;
			this.availableDevices = null;
			this.dialog = false;
			this.currentTime = new Date();
			this.startTimer();
		},

		deselectDevice() {
			this.selectedDevice = null;
			this.availableDevices = null;
			this.getAvailableDevices();
		},

		async getAvailableDevices() {
			this.loadingAvailableDevices = true;

			const start = new Date();
			const end = addMinutes(start, this.selectedService.duration);

			try {
				const available = await this.$store.dispatch("guest/devices/getAvailableDevices", { siteId: this.site._id, query: { start, end } });

				let mappedDevices = available.available.map((id) => {
					return this.deviceFromId(id);
				});

				// sort by car number but keep the recommended device at the top
				mappedDevices = mappedDevices.sort((a, b) => {
					if (a._id === available.recommended) return -1;
					if (b._id === available.recommended) return 1;
					return a.carNumber - b.carNumber;
				});

				this.availableDevices = {
					available: mappedDevices,
					recommended: available.recommended,
				};

				// this.selectedDevice = this.availableDevices.available[0];
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loadingAvailableDevices = false;
			}
		},

		hasSufficentCredits(service) {
			return this.hasUnlimitedCredits || service.price.credits <= this.virtualKey.driver.credits;
		},

		getApplicableServices(services, userGroup, currentTime) {
			// Function to check if the current time matches the available times of a price
			const isTimeApplicable = (availableTimes) => {
				if (availableTimes.length === 0) return true; // Always available if empty

				const currentDay = currentTime.getDay();
				const currentTimeString = currentTime.getHours().toString().padStart(2, "0") + ":" + currentTime.getMinutes().toString().padStart(2, "0");

				return availableTimes.some((time) => time.day === currentDay && currentTimeString >= time.start && currentTimeString <= time.end);
			};

			return services
				.map((service) => {
					// Filter prices based on userGroup and available times, then immediately find the lowest price option
					const price = service.prices
						.filter((price) => (price.userGroup === userGroup || price.userGroup === null) && isTimeApplicable(price.availableTimes) && price.credits > 0)
						.reduce((lowestPrice, currentPrice) => {
							if (!lowestPrice) return currentPrice; // If there's no current lowest, return the current price

							if (currentPrice.credits < lowestPrice.credits) {
								return currentPrice;
							} else if (currentPrice.credits === lowestPrice.credits) {
								// If credits are equal, prefer the price with the matching userGroup
								if (currentPrice.userGroup === userGroup && lowestPrice.userGroup !== userGroup) {
									return currentPrice;
								}
							}
							return lowestPrice;
						}, null);

					if (price) {
						// Return a new service object with only the single most applicable price
						return {
							...service,
							price,
						};
					} else {
						return null; // If no price is applicable, prepare to filter this service out
					}
				})
				.filter((service) => service !== null); // Filter out services with no applicable price
		},

		async createBooking() {
			this.loadingBooking = true;
			try {
				const res = await this.$store.dispatch("guest/bookings/createBooking", {
					siteId: this.site._id,
					body: {
						serviceId: this.selectedService._id,
						priceId: this.selectedService.price._id,
						driverId: this.virtualKey.driver._id,
						deviceId: this.selectedDevice._id,
						start: this.bookingStart,
						end: this.bookingEnd,
					},
				});

				this.$router.push({ name: "guest.booking", params: { id: res.bookingId } });
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loadingBooking = false;
			}
		},
	},
	beforeDestroy() {
		clearTimeout(this.timer);
	},
};
</script>

<style lang="scss" scoped>
.dotted-timeline {
	display: flex;

	.timeline-line {
		width: 100%;
		position: relative;
		border-bottom: 1px solid #9a27b0;
	}
	.timeline-icon {
		position: relative;
		top: 8px;
	}
}

.paymentChip {
	position: absolute;
	top: -10px;
	right: 0;
	z-index: 100;
}
</style>
