<template>
  <v-speed-dial
    v-model="fab"
    fixed
    top
    right
    direction="bottom"
    transition="slide-y-transition"
  >
    <template v-slot:activator>
      <v-btn v-model="fab" color="white" dark small fab>
        <v-avatar size="24">
          <v-img :src="currentLanguageIcon" v-if="currentLanguageIcon" />
          <v-icon v-else>mdi-earth</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-btn
      fab
      dark
      small
      color="white"
      class="text-subtitle-1"
      v-for="(item, index) in languages"
      :key="index"
      @click="setLocale(item)"
    >
      <v-avatar size="24">
        <v-img :src="item.flagSrc" />
      </v-avatar>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  data() {
    return {
      fab: false,
      languages: [
        {
          id: "en",
          title: "English",
          flagSrc: "https://flagpedia.net/data/flags/w580/gb.png",
        },
        {
          id: "de",
          title: "Deutsche",
          flagSrc: "https://flagpedia.net/data/flags/w580/de.png",
        },
        {
          id: "da",
          title: "Dansk",
          flagSrc: "https://flagpedia.net/data/flags/w580/dk.png",
        },
      ],
      currentLanguage: null,
    };
  },
  computed: {
    currentLanguageIcon() {
      const locale = this.$i18n.locale;

      if (!locale) {
        return null;
      }

      const lang = this.languages.find((x) => x.id === locale);

      if (lang) {
        return lang.flagSrc;
      }

      return this.languages[0].flagSrc;
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale.id;
      localStorage.setItem("locale", locale.id);
    },
  },
};
</script>
