import Vue from "vue";

export const getBookings = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/bookings", {
			params: query,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getBooking = async (bookingId) => {
	try {
		const data = await Vue.prototype.$ioteeDashboardApi.get(`/bookings/${bookingId}`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const updateBooking = async (booking) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.patch(`/bookings/${booking._id}`, booking);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const createBooking = async (booking) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post("/bookings", booking);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const unlockWithBooking = async (bookingId) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.post(`/bookings/${bookingId}/unlock`);

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const deleteBooking = async (bookingId) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.delete(`/bookings/${bookingId}`);

		return data;
	} catch (err) {
		console.log("err", err);
		throw err.response.data;
	}
};

export const getAvailableDevices = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/bookings/availability/carts", {
			params: query,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};

export const getAvailableSlots = async (query) => {
	try {
		const { data } = await Vue.prototype.$ioteeDashboardApi.get("/bookings/availability/slots", {
			params: query,
		});

		return data;
	} catch (err) {
		throw err.response.data;
	}
};
