import * as devicesService from "@/services/guest/devices.service";

export default {
	namespaced: true,
	state: {
		devices: [],
		device: null,
	},
	mutations: {
		setDevices(state, devices) {
			state.devices = devices;
		},

		setDevice(state, device) {
			state.device = device;
		},
	},
	actions: {
		async getDevice({ commit }, id) {
			const device = await devicesService.getDevice(id);
			commit("setDevice", device);
		},

		async getDevices({ commit }, siteId) {
			const devices = await devicesService.getDevices(siteId);
			commit("setDevices", devices);
		},

		async getAvailableDevices(_, payload) {
			return await devicesService.getAvailableDevices(payload);
		},

		async toggleLockWithMasterKey(_, payload) {
			return await devicesService.toggleLockWithMasterKey(payload);
		},
	},
};
