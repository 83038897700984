<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-chip class="mr-2 font-weight-bold" :color="`${color}`" small label>
          <v-icon small :color="text">
            mdi-key-variant
          </v-icon>
        </v-chip>
      </span>
    </template>
    <span class="font-weight-bold">
      {{ keyText }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    keyAlarm: {
      required: true,
    },
  },
  data() {
    return {
      color: "primary lighten-4",
      text: "primary",
      interval: null,
    };
  },
  watch: {
    keyAlarm() {
      if (this.keyAlarm === "1") {
        this.keyAlarmColor();
      } else {
        this.color = "primary lighten-4";
        this.text = "primary";

        clearInterval(this.interval);
      }
    },
  },
  mounted() {
    if (this.keyAlarm === "1") {
      this.keyAlarmColor();
    }
  },
  computed: {
    keyText() {
      return this.keyAlarm === "1" ? "Key left on" : "Requires Key";
    },
  },
  methods: {
    keyAlarmColor() {
      this.interval = setInterval(() => {
        this.color =
          this.color === "primary lighten-4" ? "error" : "primary lighten-4";
        this.text = this.text === "primary" ? "white" : "primary";
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
