import Vue from "vue";

export default {
	async addRfidCode(payload) {
		const rfidCode = await Vue.prototype.$ioteeApi.post("/rfidCodes", payload);

		return rfidCode;
	},

	async updateRfidCode(payload) {
		const rfidCode = await Vue.prototype.$ioteeApi.patch(
			`/rfidCodes/${payload.id}`,
			payload.body
		);

		return rfidCode;
	},

	async deleteRfidCode(payload) {
		const rfidCode = await Vue.prototype.$ioteeApi.post(
			`/rfidCodes/${payload.id}/delete`,
			payload.body
		);

		return rfidCode;
	},

	async publishRfidCodes(payload) {
		const unlockCode = await Vue.prototype.$ioteeApi.post(
			`/rfidCodes/publish-rfid-codes`,
			payload
		);

		return unlockCode;
	},
};
