<template>
	<v-container fluid class="pa-0">
		<v-row no-gutters class="gp-full-height">
			<v-col cols="12" md="4" lg="3" class="accent lighten-1">
				<rides-list />
			</v-col>
			<v-col cols="12" md="8" lg="9" class="bg hidden-sm-and-down">
				<rides-detail v-if="selectedRide" :selectedRide="selectedRide" :selectedSite="selectedSite" @deleteRide="deleteRide" />
				<div v-else class="gp-full-height justify-center align-center d-flex">
					<div class="text-center">
						<v-img class="mx-auto" height="70" width="70" contain :src="logo" />
						<br />
						<span class="font-weight-bold accent--text">
							{{ $t("ridesPage.noRideSel") }}
						</span>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import RidesList from "@/components/rides/RidesList";
import RidesDetail from "@/components/rides/RidesDetail";

export default {
	components: {
		RidesList,
		RidesDetail,
	},
	data() {
		return {
			logo: require("../../../public/Logo.png"),
		};
	},

	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			selectedRide: (state) => state.rides.selectedRide,
		}),
	},
	methods: {
		deleteRide() {
			this.$store.commit("rides/setSelectedRide", null);

			this.getSiteRides();
		},
	},
	beforeDestroy() {
		this.$store.commit("rides/setSelectedRide", null);
	},
};
</script>
