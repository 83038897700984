<template>
	<v-card flat :color="color" :elevation="elevation" rounded="lg">
		<v-list-item>
			<v-list-item-avatar v-show="icon" :class="iconBg">
				<v-icon :color="color">{{ icon }}</v-icon>
			</v-list-item-avatar>

			<v-list-item-content :class="`${textColor}--text text-center`">
				<v-list-item-title class="subtitle-1">
					{{ text }}
				</v-list-item-title>

				<v-list-item-title v-if="currency" class="title font-wight-bold">
					{{ formattedPrice(value, currency) }}
				</v-list-item-title>
				<v-list-item-title v-else class="title">
					{{ value }}
				</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<slot />
	</v-card>
</template>

<script>
export default {
	props: {
		color: {
			default: "primary",
		},
		textColor: {
			default: "white",
		},
		iconBg: {
			default: "grey",
		},
		icon: {
			default: null,
		},
		currency: {
			default: null,
		},
		value: {
			default: 0,
		},
		text: {
			default: "",
		},
		elevation: {
			default: 0,
		},
	},
	computed: {},
};
</script>
