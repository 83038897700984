<template>
	<div class="ride-list">
		<div class="header">
			<v-row no-gutters class="pa-4" style="border-bottom: 3px solid #406dfa !important">
				<v-col cols="12" class="text-center">
					<span class="title font-weight-bold warning--text">
						{{ totalItems }}
						{{ totalItems === 1 ? $t("ridesPage.ride") : $t("ridesPage.rides") }}
					</span>
				</v-col>
				<v-col cols="12" class="mt-4 text-center">
					<v-btn-toggle class="mx-auto" v-model="toggleDateSelection" dense light color="primary" mandatory>
						<v-btn> {{ $t("date.today") }} </v-btn>

						<v-btn> {{ $t("date.7days") }} </v-btn>

						<v-btn> {{ $t("date.month") }} </v-btn>

						<v-btn> {{ $t("date.custom") }} </v-btn>
					</v-btn-toggle>
				</v-col>
				<v-col v-if="showDateSelect" cols="12" class="mt-4">
					<from-to-picker @filter="filterByDate" />
				</v-col>
				<v-col cols="12" class="mt-4">
					<v-text-field
						v-model="search"
						solo
						flat
						dense
						hide-details
						clearable
						:class="search && 'font-weight-bold'"
						:placeholder="searchTypes[selectedType].text"
						:background-color="search ? 'white' : 'rgba(255, 255, 255, 0.5)'"
						@keyup.enter="searchRides"
					>
						<template slot="append">
							<v-menu :close-on-content-click="false" bottom left offset-y :nudge-right="$vuetify.breakpoint.smAndUp ? '20' : '0'">
								<template v-slot:activator="{ on: menu }">
									<v-btn depressed v-on="menu" small color="white" class="padlessBtn">
										<v-icon small color="primary">mdi-filter-variant</v-icon>
									</v-btn>
								</template>

								<v-list dense nav>
									<v-list-item-group v-model="selectedType" color="primary" mandatory>
										<v-list-item v-for="(item, i) in searchTypes" :key="i">
											<template v-slot:default="{ active }">
												<v-list-item-content>
													<v-list-item-title>
														<v-icon v-if="active" left small> mdi-check-circle-outline </v-icon>
														{{ item.text }}
													</v-list-item-title>
												</v-list-item-content>
											</template>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-menu>
						</template>
					</v-text-field>
				</v-col>
			</v-row>
		</div>

		<v-data-iterator :items="rides" class="content" hide-default-footer>
			<template v-slot:default="{ items }">
				<template v-if="loading">
					<v-col v-for="i in 10" :key="i" cols="12" class="px-4 py-2">
						<v-skeleton-loader style="opacity: 0.2" loading class="mx-auto rounded-lg" type="card-heading"></v-skeleton-loader>
					</v-col>
				</template>
				<v-col v-else v-for="(item, index) in items" :key="index" cols="12" class="px-4 py-2">
					<ride-card @cardClick="selectRide(item)" :selectedSite="selectedSite" :selectedRide="selectedRide" :ride="item" />
				</v-col>
			</template>

			<template v-slot:no-data>
				<v-col cols="12" class="px-4 py-2">
					<v-card flat class="accent lighten-3 rounded-lg">
						<v-card-text class="font-weight-bold">
							<v-icon small left>mdi-emoticon-sad-outline</v-icon>
							{{ $t("ridesPage.noRides") }}
						</v-card-text>
					</v-card>
				</v-col>
			</template>
		</v-data-iterator>

		<div class="footer py-2" style="border-top: 3px solid #406dfa !important">
			<v-pagination :disabled="currentPage < 1" class="mx-auto" color="primary" v-model="page" :length="totalPages"></v-pagination>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { format, parseISO, subDays, startOfMonth, startOfDay, endOfDay } from "date-fns";
import FromToPicker from "../FromToPicker.vue";
import RideCard from "@/components/rides/RideCard.vue";

export default {
	components: {
		RideCard,
		FromToPicker,
	},
	data() {
		return {
			toggleDateSelection: 0,
			showDateSelect: false,
			search: "",
			selectedType: 0,
			searchTypes: [
				{
					val: "device",
					text: this.$t("carDetail.carNumber"),
				},
				{
					val: "meta.name",
					text: this.$t("carDetail.driverName"),
				},
				{
					val: "meta.email",
					text: this.$t("general.email"),
				},
			],
			startDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
			endDate: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
		};
	},
	mounted: function () {
		this.getSiteRides();
	},
	watch: {
		toggleDateSelection(val) {
			switch (val) {
				case 0:
					this.startDate = format(new Date(), "yyyy-MM-dd");
					this.endDate = format(new Date(), "yyyy-MM-dd");
					break;

				case 1:
					this.startDate = format(subDays(new Date(), 7), "yyyy-MM-dd");
					this.endDate = format(new Date(), "yyyy-MM-dd");
					break;

				case 2:
					this.startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
					this.endDate = format(new Date(), "yyyy-MM-dd");
					break;

				case 3:
					return (this.showDateSelect = true);

				default:
					break;
			}

			this.showDateSelect = false;
			this.getSiteRides();
		},

		search(val) {
			setTimeout(() => {
				if (val === this.search) {
					this.searchRides();
				}
			}, 700);
		},

		selectedType() {
			this.search = "";
		},
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			selectedRide: (state) => state.rides.selectedRide,
			rides: (state) => state.rides.rides,
			currentPage: (state) => state.rides.currentPage,
			itemsPerPage: (state) => state.rides.itemsPerPage,
			totalItems: (state) => state.rides.totalItems,
			totalPages: (state) => Math.ceil(state.rides.totalItems / state.rides.itemsPerPage),
			loading: (state) => state.rides.loading,
		}),

		...mapGetters(["deviceFromCarNumber"]),

		page: {
			get() {
				return this.currentPage;
			},
			set(value) {
				this.$store.dispatch("rides/setCurrentPage", {
					page: value,
					queryParams: this.prepareQuery(),
				});
			},
		},

		perPage: {
			get() {
				return this.itemsPerPage;
			},
			set(value) {
				this.$store.dispatch("rides/setItemsPerPage", {
					itemsPerPage: value,
					queryParams: this.prepareQuery(),
				});
			},
		},
	},
	methods: {
		prepareQuery() {
			const selectedSearchType = this.searchTypes[this.selectedType];

			let searchQuery = {};

			if (selectedSearchType.val === "device") {
				const device = this.deviceFromCarNumber(this.search);
				if (device) {
					searchQuery = { device: device._id };
				}
			} else {
				searchQuery = { [selectedSearchType.val]: this.search };
			}

			const queryParams = {
				start: startOfDay(new Date(this.startDate)),
				end: endOfDay(new Date(this.endDate)),
				sort: "start:desc",
				...searchQuery,
			};

			return queryParams;
		},

		filterByDate({ startDate, endDate }) {
			this.startDate = startDate;
			this.endDate = endDate;

			this.getSiteRides();
		},

		searchRides() {
			this.getSiteRides();
		},

		async getSiteRides() {
			await this.$store.dispatch("rides/getSiteRides", this.prepareQuery());
		},

		selectRide(item) {
			if (this.selectedRide && item._id === this.selectedRide._id) {
				this.$store.commit("rides/setSelectedRide", null);
			} else {
				this.$store.dispatch("rides/getRide", item._id);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.ride-list {
	display: flex;
	flex-direction: column;
	height: calc(var(--vh, 1vh) * 100);
	position: relative;
	overflow: hidden; // Prevents the entire container from scrolling
}

.header,
.footer {
	flex-shrink: 0; // Prevents header and footer from growing
	background-color: #283555;
}

.content {
	overflow-y: auto; // Makes only the content scrollable
	flex-grow: 1; // Takes up all available space
}

::-webkit-scrollbar {
	display: none;
}

.v-card:focus::before {
	opacity: 0 !important;
}
</style>
