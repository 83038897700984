<template>
	<div>
		<apexchart :height="chartOptions.chart.height" :options="chartOptions" :series="seriesData"></apexchart>
	</div>
</template>

<script>
import { format, differenceInCalendarMonths, parseISO } from "date-fns";

export default {
	props: {
		rides: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		dateRangeExceedsTwoMonths() {
			if (!this.rides.length) return false;
			const dates = this.rides.map((ride) => parseISO(ride.start));
			const minDate = new Date(Math.min.apply(null, dates));
			const maxDate = new Date(Math.max.apply(null, dates));
			return differenceInCalendarMonths(maxDate, minDate) > 2;
		},

		categories() {
			if (this.dateRangeExceedsTwoMonths) {
				// Group by month
				return [...new Set(this.rides.map((ride) => format(parseISO(ride.start), "yyyy-MM")))];
			} else {
				// Group by day
				return [...new Set(this.rides.map((ride) => ride.start.split("T")[0]))];
			}
		},

		formattedCategories() {
			if (this.dateRangeExceedsTwoMonths) {
				// Format as 'MMM yyyy' for month grouping
				return this.categories.map((category) => format(parseISO(`${category}-01`), "MMM yyyy"));
			} else {
				// Format as 'd MMM' for day grouping
				return this.categories.map((category) => format(new Date(category), "d MMM"));
			}
		},

		seriesData() {
			const groupBy = this.dateRangeExceedsTwoMonths ? "yyyy-MM" : "yyyy-MM-dd";
			const countsByDate = this.rides.reduce((acc, ride) => {
				const formattedDate = format(parseISO(ride.start), groupBy);
				acc[formattedDate] = (acc[formattedDate] || 0) + 1;
				return acc;
			}, {});

			const data = this.categories.map((category) => countsByDate[category] || 0);

			return [
				{
					name: "Rounds",
					data,
				},
			];
		},

		chartOptions() {
			return {
				chart: {
					height: 250,
					type: "area",
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					dropShadow: {
						enabled: true,
						top: 4,
						left: 0,
						blur: 2,
						opacity: 0.1,
					},
				},
				dataLabels: {
					enabled: true,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					categories: this.formattedCategories,
					labels: {
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
							cssClass: "apexcharts-xaxis-label",
						},
					},
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					crosshairs: {
						show: false,
					},
					tooltip: {
						enabled: false,
					},
				},
				yaxis: {
					title: {
						text: "Rounds",
					},
					labels: {
						showDuplicates: false,
						style: {
							fontSize: "11px",
							fontFamily: "Quicksand, Arial, sans-serif",
							fontWeight: 800,
							cssClass: "apexcharts-xaxis-label",
						},
						formatter: (val) => {
							return parseInt(val) === val ? val : "";
						},
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "dark",
						gradientToColors: ["#406DFA"],
						shadeIntensity: 1,
						type: "vertical",
						opacityFrom: 0.6,
						opacityTo: 0.2,
						stops: [0, 100, 100, 100],
					},
				},
				grid: {
					show: false,
				},
				colors: ["#406DFA"],
				tooltip: {
					custom: function ({ series, seriesIndex, dataPointIndex }) {
						// vuetify chip with the date and the number of rides
						return `<div class="pa-2 font-weight-bold accent--text">
              <span >${series[seriesIndex][dataPointIndex]} Rounds</span>
            </div>`;
					},
					followCursor: true,
				},
			};
		},
	},
};
</script>
