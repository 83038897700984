<template>
	<v-dialog v-model="dialog" max-width="500px">
		<v-card class="rounded-lg" :loading="loading">
			<template slot="progress">
				<v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
			</template>

			<v-card-title>
				<span class="subtitle-1 font-weight-bold d-flex align-center">
					<v-icon left color="accent">mdi-golf-cart</v-icon>
					{{ selectedDevice ? `Cart ${selectedDevice.carNumber}` : $t("general.selectCart") }}
				</span>
				<v-spacer></v-spacer>
				<v-slide-x-transition>
					<v-btn v-if="selectedDevice" small icon @click="deselectDevice">
						<v-icon>mdi-close-circle</v-icon>
					</v-btn>
				</v-slide-x-transition>
			</v-card-title>

			<v-expand-transition v-if="items.length">
				<v-card-text v-show="!selectedDevice">
					<v-item-group v-model="selectedDevice" active-class="primary lighten-4 primary--text" class="mt-4">
						<v-virtual-scroll :items="items" height="300" item-height="64" bench="2">
							<template v-slot="{ item }">
								<v-item :value="item">
									<template v-slot="{ active, toggle }">
										<v-card :elevation="active ? '8' : '0'" :outlined="!active" :style="active && 'border: 2px solid #3F6DFA !important'" class="rounded-lg mb-4" @click="toggle">
											<v-list-item>
												<v-list-item-action>
													<v-checkbox :input-value="active" color="primary accent-4" on-icon="mdi-circle-slice-8" off-icon="mdi-circle-outline"></v-checkbox>
												</v-list-item-action>

												<v-list-item-content>
													<v-list-item-title class="font-weight-bold"> Cart {{ item.carNumber }} </v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</v-card>
									</template>
								</v-item>
							</template>
						</v-virtual-scroll>
					</v-item-group>
				</v-card-text>
			</v-expand-transition>

			<v-card-text v-else-if="!loading">
				<v-alert dense outlined type="info" class="mt-4"> {{ $t("bookings.changeCart.noCarts") }} </v-alert>
			</v-card-text>

			<v-card-text>
				<v-btn block rounded color="success" class="mt-3" @click="save" :disabled="!selectedDevice">{{ $t("general.continue") }}</v-btn>
				<v-btn block rounded outlined color="accent" class="mt-3" @click="close">{{ $t("general.cancel") }}</v-btn>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {
		currentDevice: {
			type: Object,
			required: true,
		},
		booking: {
			type: Object,
			required: true,
		},
		service: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			dialog: false,
			items: [],
			selectedDevice: null,

			loading: false,
		};
	},
	computed: {
		...mapState({
			devices: (state) => state.guest.devices.devices,
		}),
	},
	methods: {
		async open() {
			this.dialog = true;

			await this.getSiteDevices();
			await this.getAvailableDevices();
		},

		close() {
			this.dialog = false;
		},

		deviceFromId(id) {
			return this.devices.find((device) => device._id === id);
		},

		deselectDevice() {
			this.selectedDevice = null;
			this.items = [];
			this.getAvailableDevices();
		},

		async getSiteDevices() {
			this.loading = true;

			try {
				await this.$store.dispatch("guest/devices/getDevices", this.booking.site);
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loading = false;
			}
		},

		async getAvailableDevices() {
			this.loading = true;

			const start = this.booking.start;
			const end = this.booking.end;

			try {
				const res = await this.$store.dispatch("guest/devices/getAvailableDevices", { siteId: this.booking.site, query: { start, end } });

				const { available } = res;

				// remove the current device from the list
				const devices = available.filter((device) => device._id !== this.currentDevice._id);

				let mappedDevices = devices.map((id) => {
					return this.deviceFromId(id);
				});

				// sort by car number but keep the recommended device at the top
				mappedDevices = mappedDevices.sort((a, b) => a.carNumber - b.carNumber);

				this.items = mappedDevices;
			} catch (err) {
				this.$toast.error(err.error.message || "An error occurred");
			} finally {
				this.loading = false;
			}
		},

		save() {
			this.$emit("updateBooking", { device: this.selectedDevice._id });
			this.dialog = false;
		},
	},
};
</script>
