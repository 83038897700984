import * as authService from "@/services/auth.service";
import router from "@/router";
import { setApiHeaders } from "@/axios";

export default {
	state: {
		isLoggedIn: false,
		// User
		user: null,

		// All Users
		allUsers: [],
	},

	mutations: {
		// Logged In
		setLoggedIn(state, payload) {
			state.isLoggedIn = payload;
		},

		// User
		setUser(state, payload) {
			state.user = payload;
		},

		// Token
		setToken(state, payload) {
			state.token = payload;
		},

		// All Users
		setUsers(state, payload) {
			state.allUsers = payload;
		},
	},

	actions: {
		async authenticateUser({ commit, dispatch }, token) {
			try {
				setApiHeaders({ token });
				await dispatch("getUser");

				commit("setLoggedIn", true);
			} catch (err) {
				dispatch("logout");
			}
		},

		async login({ dispatch }, payload) {
			const token = await authService.login(payload);
			localStorage.setItem("user", token);

			await dispatch("authenticateUser", token);
			await dispatch("handlePostLoginRequests");

			router.push("/");
		},

		async logout({ commit }) {
			try {
				localStorage.removeItem("user");
				localStorage.removeItem("selectedSiteID");

				commit("setLoggedIn", false);

				location.reload();
			} catch (error) {
				return error;
			}
		},

		async getUser({ commit }) {
			const user = await authService.getUser();
			commit("setUser", user);
		},

		async updateUser({ commit }, payload) {
			const user = await authService.updateUser(payload);
			commit("setUser", user);
		},

		async registerUser(_, payload) {
			await authService.registerUser(payload);
		},

		async forgotPassword(_, payload) {
			await authService.forgotPassword(payload);
		},

		async resetPassword(_, payload) {
			await authService.resetPassword(payload);
		},
	},

	getters: {
		user(state) {
			return state.user;
		},
	},
};
