<template>
	<v-dialog v-model="dialog" width="420" scrollable persistent :fullscreen="$vuetify.breakpoint.smAndDown">
		<template v-slot:activator="{ on, attrs }">
			<div>
				<v-btn v-show="$vuetify.breakpoint.smAndUp" color="primary white--text" small v-bind="attrs" v-on="on" :disabled="!hasRole('user')">
					<v-icon :left="$vuetify.breakpoint.smAndUp" small>mdi-plus</v-icon>
					{{ $vuetify.breakpoint.smAndUp ? $t("virtualKeys.editDriver.addMember") : "" }}
				</v-btn>
			</div>
		</template>

		<v-card>
			<v-card-title class="title accent white--text" style="border-bottom: 3px solid #406dfa !important">
				{{ $t("virtualKeys.editDriver.addMember") }}
			</v-card-title>
			<v-btn @click="dialog = false" icon small style="position: absolute; right: 15px; top: 15px; z-index: 5">
				<v-icon color="white">mdi-close-circle-outline</v-icon>
			</v-btn>

			<v-card-text style="max-height: 700px" class="px-2">
				<v-form ref="form" v-model="valid" autocomplete="off" class="pa-0">
					<v-list class="pt-0">
						<v-list-item class="mt-4">
							<v-list-item-content class="pa-0">
								<v-list-item-title class="font-weight-bold accent--text"> {{ $t("virtualKeys.fields.name") }}: </v-list-item-title>
								<v-list-item-subtitle class="pt-1">
									<v-text-field label="Max Mustermann" v-model="driver.name" :rules="[rules.required]" outlined solo flat dense></v-text-field>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item>
							<v-list-item-content class="pa-0">
								<v-list-item-title class="font-weight-bold accent--text"> {{ $t("virtualKeys.fields.email") }}: </v-list-item-title>
								<v-list-item-subtitle class="pt-1 pb-5">
									<v-text-field
										label="max@example.com"
										type="email"
										v-model="driver.email"
										:rules="[rules.required, rules.emailValid]"
										hide-details
										outlined
										solo
										flat
										dense
									></v-text-field>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item v-if="!hasKeypadEnabled()">
							<v-list-item-content class="pa-0">
								<v-list-item-title class="font-weight-bold accent--text"> {{ $t("virtualKeys.fields.memberNumber") }}: </v-list-item-title>
								<v-list-item-subtitle class="pt-1">
									<v-text-field v-model="driver.memberNumber" outlined solo flat dense></v-text-field>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item v-if="hasKeypadEnabled()">
							<v-list-item-content class="pa-0">
								<v-list-item-title class="font-weight-bold accent--text"> Phone Number </v-list-item-title>
								<v-list-item-subtitle class="pt-1 pb-5">
									<v-text-field v-model="driver.phone" outlined clearable solo flat dense hide-details></v-text-field>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item v-if="hasKeypadEnabled()">
							<v-list-item-content class="pa-0">
								<v-list-item-title class="font-weight-bold accent--text"> Company/Department </v-list-item-title>
								<v-list-item-subtitle class="pt-1">
									<v-text-field v-model="driver.company" outlined clearable solo flat dense hide-details></v-text-field>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>

						<v-list-item v-if="hasKeypadEnabled()">
							<v-list-item-content class="pa-0">
								<v-list-item-subtitle class="pt-0">
									<v-checkbox v-model="driver.induction" hide-details>
										<template v-slot:label>
											<div class="font-weight-bold">Received Induction</div>
										</template>
									</v-checkbox>
								</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-form>
			</v-card-text>
			<v-divider class="mx-4"></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn @click="close" text color="primary">
					{{ $t("general.cancel") }}
				</v-btn>
				<v-btn color="primary" @click="addMember" :loading="loading" :disabled="loading || !valid">
					{{ $t("general.save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
// import tagInput from "../fleet/TagInput.vue";
// import VPhoneInput from "./PhoneInput.vue";

export default {
	props: {
		cars: {
			type: Array,
		},
		selectedSite: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
				emailValid: (value) => !value || /.+@.+\..+/.test(value) || this.$i18n.t("rules.emailValid"),
			},
			driver: {
				name: "",
				email: "",
				induction: false,
			},
			valid: true,
			dialog: false,
			loading: false,
		};
	},
	methods: {
		setPhone(val) {
			this.driver.phone = val;
		},

		async addMember() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				try {
					const driver = {
						...this.driver,
						site: this.selectedSite._id,
					};

					await this.$store.dispatch("addMember", driver);

					const message = this.$i18n.t("virtualKeys.editDriver.responseMessages.added");
					this.$toast.success(message);

					this.close();
				} catch (err) {
					if (err.error) {
						const { status, message } = err.error;

						if (status === 409) {
							this.$toast.error("Email already exists, please use a different email and try again.");
						} else {
							this.$toast.error(message);
						}
					}
				} finally {
					this.loading = false;
				}
			}
		},

		close() {
			this.dialog = false;
			this.$refs.form.resetValidation();
			this.driver = {
				name: "",
				email: "",
			};
		},
	},
};
</script>

<style lang="scss">
.v-otp-input {
	transform: scale(0.9);
}
</style>
