<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<v-chip small label color="accent" class="mr-2 elevation-2" v-bind="attrs" v-on="on">
				<v-icon small>mdi-note-text-outline</v-icon>
			</v-chip>
		</template>

		<v-card class="rounded-xl elevation-24">
			<v-card-text class="title font-weight-bold text-center">
				<span>Note</span>
			</v-card-text>
			<v-card-text>
				<v-textarea
					rows="3"
					no-resize
					:readonly="readonly"
					label="Note"
					hide-details="auto"
					v-model="defaultItem.carNote"
					:outlined="!readonly"
					rounded
					dense
					clear-icon="mdi-close-circle"
					:clearable="!readonly"
				></v-textarea>
				<v-hover v-slot="{ hover }">
					<v-btn v-if="readonly" block rounded @click="readonly = !readonly" color="primary" class="mt-3" :elevation="hover ? 12 : 0"> Edit </v-btn>
				</v-hover>
				<v-hover v-slot="{ hover }">
					<v-btn v-if="!readonly" block rounded @click="saveNote" color="primary" class="mt-3" :loading="loading1" :disabled="loading1" :elevation="hover ? 12 : 0"> Save </v-btn>
				</v-hover>
				<v-hover v-slot="{ hover }">
					<v-btn v-if="!readonly" block rounded outlined @click="deleteNote" color="error" class="mt-3" :loading="loading2" :disabled="loading2" :elevation="hover ? 12 : 0">
						Delete
					</v-btn>
				</v-hover>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: ["car"],
	data() {
		return {
			dialog: false,
			readonly: true,
			loading1: false,
			loading2: false,
		};
	},
	computed: {
		defaultItem() {
			return Object.assign({}, this.car);
		},
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.readonly = true;
			}
		},
	},
	methods: {
		async saveNote() {
			this.loading1 = true;

			const settings = {
				id: this.car._id,
				body: {
					updateData: {
						carNote: this.defaultItem.carNote,
					},
				},
			};

			await this.$store.dispatch("updateDevice", settings);
			this.loading1 = false;
			this.readonly = true;
			this.dialog = false;
		},

		async deleteNote() {
			this.loading2 = true;

			const settings = {
				id: this.car._id,
				body: {
					updateData: {
						carNote: "",
					},
				},
			};

			await this.$store.dispatch("updateDevice", settings);
			this.loading2 = false;
			this.readonly = true;
			this.dialog = false;
		},
	},
};
</script>
