<template>
	<v-select
		v-model="selectedValue"
		:items="userGroupsOptions"
		:loading="loading"
		item-text="name"
		item-value="_id"
		:label="$t('userGroups.userGroup')"
		hide-details
		outlined
		:solo="!showLabel"
		flat
		dense
	></v-select>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {
		value: {
			type: String,
			default: null,
		},
		showLabel: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			loading: false,
			selectedValue: this.value,
		};
	},
	watch: {
		selectedValue(newVal) {
			this.$emit("input", newVal);
		},
	},
	computed: {
		...mapState({
			userGroups: (state) => state.sites.userGroups,
		}),

		userGroupsOptions() {
			return [{ _id: null, name: this.$t("general.memberTypes.guest") }, ...this.userGroups];
		},
	},
};
</script>
