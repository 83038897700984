<template>
	<v-container class="bg" style="height: 100%" fluid>
		<v-row>
			<v-col>
				<v-row class="accent elevation-4" dark style="position: sticky; top: 0; border-bottom: 3px solid #406dfa !important">
					<v-col>
						<v-row>
							<v-col cols="8">
								<span class="title font-weight-bold white--text">
									{{ $t("virtualKeys.title") }}
								</span>
							</v-col>

							<v-spacer></v-spacer>

							<v-col cols="4" class="text-right">
								<v-btn :href="helpURL" target="blank" dark icon class="ml-2" v-show="$vuetify.breakpoint.smAndUp">
									<v-icon>mdi-help-box</v-icon>
								</v-btn>

								<v-btn
									@click="
										$router.push({
											path: '/fleet',
										})
									"
									icon
									color="white"
									class="ml-2"
								>
									<v-icon color="white"> mdi-arrow-left </v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-resize="onResize">
				<v-card flat>
					<v-card-title>
						<div class="searchbox px-4">
							<v-row>
								<v-text-field
									v-model="search"
									outlined
									flat
									dense
									hide-details
									clearable
									:class="search && 'font-weight-bold'"
									:placeholder="searchTypes[selectedType].text"
									:background-color="search ? 'white' : 'rgba(255, 255, 255, 0.5)'"
								>
									<template slot="append">
										<v-menu :close-on-content-click="false" bottom left offset-y :nudge-right="$vuetify.breakpoint.smAndUp ? '20' : '0'">
											<template v-slot:activator="{ on: menu }">
												<v-btn depressed v-on="menu" small color="grey darken-1" class="padlessBtn">
													<v-icon small color="primary">mdi-filter-variant</v-icon>
												</v-btn>
											</template>

											<v-list dense nav>
												<v-list-item-group v-model="selectedType" color="primary" mandatory>
													<v-list-item v-for="(item, i) in searchTypes" :key="i">
														<template v-slot:default="{ active }">
															<v-list-item-content>
																<v-list-item-title>
																	<v-icon v-if="active" left small> mdi-check-circle-outline </v-icon>
																	{{ item.text }}
																</v-list-item-title>
															</v-list-item-content>
														</template>
													</v-list-item>
												</v-list-item-group>
											</v-list>
										</v-menu>
									</template>
								</v-text-field>
							</v-row>
						</div>

						<!-- <v-autocomplete
							ref="select"
							v-model="selectedCars"
							label="Filter by car"
							multiple
							chips
							:items="cars"
							item-text="carNumber"
							item-value="carNumber"
							:return-object="false"
							:menu-props="{ offsetY: true }"
							outlined
							dense
							hide-details
							clearable
							class="searchbox ml-n6"
							:no-data-text="$t('bookings.modal.noCarts')"
							style="max-width: 250px"
						>
							<template slot="item" slot-scope="{ item }"> {{ $t("carDetail.car") }} {{ item.carNumber }} </template>
						</v-autocomplete> -->

						<v-spacer></v-spacer>
						<actions-menu v-show="$vuetify.breakpoint.smAndUp" :selectedSite="selectedSite" :selectedMembers="selectedMembers" @resetSelected="resetSelected" />
						<publish-codes :selectedSite="selectedSite" />
						<find-tag v-if="hasKeypadEnabled()" />
						<import-members :selectedSite="selectedSite" />
						<add-member-modal :selectedSite="selectedSite" :cars="cars" />
					</v-card-title>
					<v-data-table
						v-model="selectedMembers"
						class="rounded-lg fleet-table"
						:headers="filteredHeaders"
						:items="filteredMembers"
						show-select
						item-key="_id"
						:mobile-breakpoint="0"
						:items-per-page="membersPagination.itemsPerPage"
						hide-default-footer
						:header-props="{
							'sort-icon': 'mdi-menu-down',
						}"
						sort-by="name"
						:height="windowSize.y - 48 - 160"
						fixed-header
						no-data-text="No drivers found"
						dense
						:loading="loadingMembers"
					>
						<template v-slot:header.data-table-select="{ props, on }">
							<v-simple-checkbox color="primary" v-if="props.indeterminate" v-ripple v-bind="props" :value="props.indeterminate" v-on="on"></v-simple-checkbox>
							<v-simple-checkbox color="primary" v-if="!props.indeterminate" v-ripple v-bind="props" v-on="on"></v-simple-checkbox>
						</template>
						<template v-slot:item.data-table-select="{ item, isSelected, select }">
							<v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
						</template>

						<template v-slot:item.name="{ item }">
							<v-list-item two-line>
								<v-list-item-icon>
									<v-icon color="indigo"> mdi-account-circle-outline </v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">
										{{ item.name }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</template>

						<template v-slot:item.company="{ item }">
							<span class="font-weight-bold caption accent--text">
								{{ item.company }}
							</span>
						</template>

						<template v-slot:item.phone="{ item }">
							<span class="font-weight-bold caption accent--text">
								{{ item.phone }}
							</span>
						</template>

						<template v-slot:item.induction="{ item }">
							<v-icon :color="item.induction ? 'success' : 'error'" v-text="item.induction ? 'mdi-check-circle' : 'mdi-close-circle'" />
						</template>

						<template v-slot:item.memberNumber="{ item }">
							<v-chip v-if="item.memberNumber" class="font-weight-bold accent--text" dark label small color="blue lighten-3">
								<v-icon color="blue darken-2" small left> mdi-card-account-details-outline </v-icon>
								{{ item.memberNumber }}
							</v-chip>
						</template>

						<template v-slot:item.userGroup="{ item }">
							<v-chip class="font-weight-bold accent--text" dark label small color="blue lighten-3">
								<v-icon color="blue darken-2" small left> mdi-account-group</v-icon>
								{{ getUserGroupName(item.userGroup) }}
								<v-icon v-if="item.profile" color="blue darken-2" small right>mdi-cellphone-check</v-icon>
							</v-chip>
						</template>

						<template v-slot:item.code="{ item }">
							<v-row>
								<div v-if="item.code.length">
									<manage-code v-for="code in item.code" :key="code._id" :code="code" :driver="item" @getMembers="getMembers" />
								</div>

								<div>
									<manage-code :driver="item" @getMembers="getMembers" />
								</div>
							</v-row>
						</template>

						<template v-slot:item.credits="{ item }">
							<manage-credits :driver="item" :cars="cars" />
						</template>

						<template v-slot:item.actions="{ item }">
							<div class="no-wrap">
								<div v-if="item">
									<edit-driver-modal :driver="item" :cars="cars" />
									<v-btn small depressed color="error" class="padlessBtn ml-1" @click="deleteMember(item)" :disabled="!hasRole('user')">
										<v-icon small>mdi-trash-can-outline</v-icon>
									</v-btn>
								</div>
							</div>
						</template>
					</v-data-table>
					<v-row no-gutters class="text-center" style="position: fixed; left: 0; width: 100%; bottom: 0">
						<v-toolbar flat dense class="mx-3 rounded-t-lg">
							<v-pagination
								class="mx-auto"
								color="primary"
								:value="membersPagination.currentPage"
								@input="onPageChange"
								:length="membersPagination.totalPages"
								:total-visible="7"
							></v-pagination>
							<v-select
								class="perPage"
								:value="membersPagination.itemsPerPage"
								:items="items"
								@input="onPerPageChange"
								label="Select"
								hide-details
								return-object
								single-line
								dense
								outlined
							></v-select>
						</v-toolbar>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<confirm ref="confirm" />
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import AddMemberModal from "@/components/drivers/AddMemberModal.vue";
import EditDriverModal from "@/components/drivers/EditDriverModal.vue";
import ImportMembers from "@/components/drivers/ImportMembers.vue";
import ManageCredits from "@/components/drivers/ManageCredits.vue";
import PublishCodes from "@/components/drivers/PublishCodes.vue";

import ActionsMenu from "@/components/drivers/ActionsMenu.vue";

import ManageCode from "@/components/drivers/ManageCode.vue";
import FindTag from "@/components/drivers/FindTag.vue";

import Confirm from "@/components/Confirm";

export default {
	components: {
		AddMemberModal,
		EditDriverModal,
		Confirm,
		ImportMembers,
		ActionsMenu,
		ManageCredits,
		PublishCodes,
		ManageCode,
		FindTag,
	},
	data() {
		return {
			csv: null,
			loadingMembers: false,
			startMenu: false,
			editModal: false,
			selectedMembers: [],
			toggleDisplay: 1,
			fullScreen: true,
			windowSize: {
				x: 0,
				y: 0,
			},
			items: [20, 30, 50, 100],
			selectedCars: [],

			search: "",
			selectedType: 0,
			searchTypes: [
				{
					val: "name",
					text: this.$t("carDetail.driverName"),
				},
				{
					val: "company",
					text: "Company Name",
				},
				{
					val: "email",
					text: this.$t("general.email"),
				},
			],
		};
	},
	watch: {
		search(val) {
			setTimeout(() => {
				if (val === this.search) {
					this.getMembers();
				}
			}, 700);
		},

		"membersPagination.currentPage": {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.getMembers();
				}
			},
		},

		"membersPagination.itemsPerPage": {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.getMembers();
				}
			},
		},
	},
	mounted() {
		this.getMembers();
	},
	computed: {
		...mapState({
			members: (state) => state.members.members,
			membersPagination: (state) => state.members.membersPagination,
			cars: (state) => state.devices.devices,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		...mapGetters({
			getUserGroupName: "getUserGroupName",
		}),

		headers() {
			return [
				{
					text: this.$i18n.t("virtualKeys.fields.name"),
					align: "start",
					sortable: true,
					value: "name",
					show: true,
				},
				...(this.hasKeypadEnabled()
					? // Show if the site has keypad enabled ( event site )
					  [
							{
								text: "Company",
								value: "company",
								show: true,
							},
							{
								text: "Phone Number",
								value: "phone",
								show: true,
							},
							{
								text: "Induction",
								value: "induction",
								show: true,
							},
							{
								text: this.$i18n.t("virtualKeys.fields.keyCode"),
								value: "code",
								show: true,
							},
					  ]
					: // Show if the site does not have keypad enabled ( golf site )
					  [
							{
								text: this.$i18n.t("virtualKeys.fields.memberNumber"),
								value: "memberNumber",
								show: true,
							},
							{
								text: this.$i18n.t("userGroups.userGroup"),
								value: "userGroup",
								show: true,
							},
							{
								text: this.$i18n.t("virtualKeys.fields.keyCode"),
								value: "code",
								show: true,
							},
							{
								text: this.$i18n.t("virtualKeys.fields.credits"),
								value: "credits",
								show: true,
							},
					  ]),
				{
					text: this.$i18n.t("virtualKeys.fields.actions"),
					value: "actions",
					show: true,
				},
			];
		},

		filteredHeaders() {
			return this.headers.filter((x) => x.show);
		},

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/article/erste-schritte-mit-virtuellen-schlusseln-b7t56r/";

				default:
					return "https://help.iotee.eu/en/article/getting-started-with-virtual-keys-5e5et7/";
			}
		},

		filteredMembers() {
			if (!this.selectedCars.length) return this.members;

			return this.members.filter((member) => {
				if (!member.code && !member.rfidCode) {
					return false;
				}
				if (member.code && member.code.allDevices) {
					return true;
				}
				if (member.rfidCode && member.rfidCode.allDevices) {
					return true;
				}
				let intersection = [];
				if (member.code) {
					intersection = member.code.devices.filter((n) => this.selectedCars.includes(String(n) || n));
				}
				if (member.rfidCode) {
					intersection = [...intersection, ...member.rfidCode.devices.filter((n) => this.selectedCars.includes(String(n) || n))];
				}
				return intersection.length > 0;
			});
		},
	},
	methods: {
		onResize() {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},

		buildQuery() {
			let query = {};

			// add to query based on selected search type
			const selectedSearchType = this.searchTypes[this.selectedType];

			if (selectedSearchType.val === "name") {
				query.name = this.search;
			} else if (selectedSearchType.val === "company") {
				query.company = this.search;
			} else if (selectedSearchType.val === "email") {
				query.email = this.search;
			}

			return query;
		},

		async getMembers() {
			this.loadingMembers = true;

			const query = this.buildQuery();

			try {
				await this.$store.dispatch("getMembers", query);
			} catch (err) {
				this.$toast.error(err.error.message);
			} finally {
				this.loadingMembers = false;
			}
		},

		onPageChange(newPage) {
			this.$store.commit("setMembersCurrentPage", newPage);
		},

		onPerPageChange(newPerPage) {
			this.$store.commit("setMembersItemsPerPage", newPerPage);
		},

		async deleteMember(item) {
			const res = await this.$refs.confirm.open(`${this.$i18n.t("general.delete")} ${item.name}`, "", "accent", "mdi-trash-can-outline");

			if (res === true) {
				await this.$store.dispatch("deleteMember", item._id);

				const message = "Driver deleted";
				this.$toast.success(message);
			}
		},

		resetSelected(val) {
			this.selectedMembers = val;
		},
	},
};
</script>

<style lang="scss" scoped>
.tooltip-menu {
	background-color: #ffffff !important;
	opacity: 1;
	-webkit-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	-moz-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
}

.tooltip-menu::before {
	left: 25px;
	top: -5px;
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 7px 7px 7px;
	border-color: transparent transparent #fff transparent;
	// transform: rotate(-90deg);
}

.searchbox {
	transform: scale(0.8);
	-webkit-transform-origin-x: 0;
}
</style>
