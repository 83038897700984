<template>
	<div>
		<v-card class="rounded-lg" outlined>
			<v-list two-line>
				<v-list-item>
					<v-list-item-avatar size="55" color="error">
						<v-icon color="white">mdi-store-clock-outline</v-icon>
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title class="title font-weight-bold">
							{{ $t("s2r.closed.closed") }}
						</v-list-item-title>
						<v-list-item-subtitle class="text-wrap subtitle-1"> {{ $t("s2r.closed.subtitle") }}: </v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-list>
				<v-list-item v-for="(hours, i) in openingHoursArray" :key="i" class="justify-space-between" :class="{ highlight: hours.isToday }">
					<v-list-item-content>
						<span class="font-weight-bold">{{ hours.day }}:</span>
					</v-list-item-content>
					<v-list-item-content>
						{{ hours.hours }}
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-card>

		<bottom-sheet class="mt-6" :btnTitle="$t('s2r.master.title')" btnColor="accent lighten-3" :title="$t('s2r.master.subtitle')" @code-entered="goToVirtualKey" />
	</div>
</template>

<script>
import { format, parse } from "date-fns";
import { de, da } from "date-fns/locale";
const locales = { de, da };

import BottomSheet from "./BottomSheet";

export default {
	props: {
		site: {
			type: Object,
			required: true,
		},
	},
	components: {
		BottomSheet,
	},
	data() {
		return {
			openingHoursArray: [],
			currentDay: new Date().getDay(),
		};
	},
	mounted() {
		for (let i in this.site.openingHours) {
			let day = this.site.openingHours[i];
			if (day) {
				let openTime = this.formatTime(day.openTime);
				let closeTime = this.formatTime(day.closeTime);
				this.openingHoursArray.push({
					day: this.getDayOfWeek(i),
					hours: `${openTime} - ${closeTime}`,
					isToday: parseInt(i) === this.currentDay,
				});
			} else {
				this.openingHoursArray.push({
					day: this.getDayOfWeek(i),
					hours: this.$t("s2r.closed.closed"),
					isToday: parseInt(i) === this.currentDay,
				});
			}
		}
	},
	methods: {
		formatTime(timeStr) {
			const parsedTime = parse(timeStr, "HH:mm", new Date());
			return format(parsedTime, "HH:mm", {
				locale: locales[this.$i18n.locale],
			});
		},

		getDayOfWeek(index) {
			const date = new Date();
			date.setDate(date.getDate() - date.getDay() + parseInt(index));
			return format(date, "eeee", { locale: locales[this.$i18n.locale] });
		},

		goToVirtualKey(code) {
			this.$router.push({
				name: "RideVirtualKey",
				params: {
					id: this.$route.params.id,
				},
				query: {
					siteId: this.site.id,
					virtualKey: code,
				},
			});
		},
	},
};
</script>

<style scoped>
.highlight {
	background-color: #e8edff;
}
</style>
