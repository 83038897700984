import rfidCodeService from "@/services/rfidCodeService";

export default {
	state: {},

	mutations: {},

	actions: {
		async addRfidCode(_, payload) {
			await rfidCodeService.addRfidCode(payload);

			return;
		},

		async updateRfidCode(_, payload) {
			await rfidCodeService.updateRfidCode(payload);

			return;
		},

		async deleteRfidCode(_, payload) {
			await rfidCodeService.deleteRfidCode(payload);

			return;
		},
	},
};
